// src/hooks/MiniApp/userVideoValidation.tsx

import { useState, useEffect } from 'react';
import useUserDashboard from '../hooks/Dashboard/useUserDashboard';
import { useEnv } from '../context/EnvContext'; 

const useVideoValidation = () => {
  const { user } = useUserDashboard();
  const { apiUrl } = useEnv(); 
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    if (user && user.email) {
      setIsUserLoaded(true);
    }
  }, [user]);

  const validateVideoPermission = async (videoId: string): Promise<boolean> => {
    if (!isUserLoaded) {
      return false;
    }

    // Check if user information is available
    if (!user || !user.email) {
      console.error('User information is missing. Cannot validate permission.');
      return false;
    }

    try {
      // Make the fetch call to validate video permission based on the video ID and user email
      const response = await fetch(`${apiUrl}/validate-video-permission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: user.email,  
          videoId,                
        }),
      });

      const data = await response.json();

      // Check the response and return the permission status
      if (data.hasPermission) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error validating video permission:', error);
      return false;
    }
  };

  // Validate playlist permission based on playlistId and user email
  const validatePlaylistPermission = async (playlistId: string): Promise<boolean> => {
    // Wait until user data is loaded
    if (!isUserLoaded) {
      return false;
    }

    if (!user?.email) {
      console.error('User not authenticated');
      return false;
    }

    try {
      const response = await fetch(`${apiUrl}/validate-playlist-permission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: user.email,
          playlistId,
        }),
      });

      if (!response.ok) {
        throw new Error('Permission validation failed');
      }

      const data = await response.json();
      return data.hasPermission;
    } catch (error) {
      console.error('Error validating playlist permission:', error);
      return false;
    }
  };

  return { validateVideoPermission, validatePlaylistPermission, isUserLoaded };
};

export default useVideoValidation;



// // update code --> 22 Jan 2024
// // src/hooks/MiniApp/userVideoValidation.tsx

// import useUserDashboard from '../hooks/Dashboard/useUserDashboard';
// import { useEnv } from '../context/EnvContext'; 

// const useVideoValidation = () => {
//   const { user } = useUserDashboard();
//   const { apiUrl } = useEnv(); 

//   const validateVideoPermission = async (videoId: string): Promise<boolean> => {
//     // Check if user information is available
//     if (!user || !user.email) {
//       console.error('User information is missing. Cannot validate permission.');
//       return false;
//     }

//     try {
//       // Make the fetch call to validate video permission based on the video ID and user email
//       const response = await fetch(`${apiUrl}/validate-video-permission`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           userEmail: user.email,  
//           videoId,                
//         }),
//       });

//       const data = await response.json();

//       // Check the response and return the permission status
//       if (data.hasPermission) {
//         return true;
//       } else {
//         return false;
//       }
//     } catch (error) {
//       console.error('Error validating video permission:', error);
//       return false;
//     }
//   };

//   // Validate playlist permission based on playlistId and user email
//   const validatePlaylistPermission = async (playlistId: string): Promise<boolean> => {
//     if (!user || !user.email) {
//       console.error('User information is missing. Cannot validate playlist permission.');
//       return false;
//     }

//     try {
//       const response = await fetch(`${apiUrl}/validate-playlist-permission`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           userEmail: user.email,
//           playlistId,
//         }),
//       });

//       const data = await response.json();
//       return data.hasPermission;
//     } catch (error) {
//       console.error('Error validating playlist permission:', error);
//       return false;
//     }
//   };

//   return { validateVideoPermission, validatePlaylistPermission };
// };

// export default useVideoValidation;

