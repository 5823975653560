// src/components/admin/EventSetting/ProgramList.tsx

import React, { useState, useRef } from 'react';

// import context file
import { useTheme } from '../../../context/ThemeContext';
import { useEnv } from '../../../context/EnvContext';
import { useAuth } from '../../../context/AuthContext';   
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';

// import hook files
import useProgram, { Program, convertToShortForm, getTagColor, formatTimeTo12Hour, formatAmount } from '../../../hooks/AdminDashboard/useProgram';

// import react-icons
import { FaCirclePlay, FaCirclePause, FaCalendarMinus } from "react-icons/fa6";
import { RiRefreshFill } from "react-icons/ri";
import { 
  FaSave, FaEdit, FaUndo, FaTrash, 
  FaPlayCircle, FaPauseCircle, FaExclamationTriangle,
  FaTag, FaQrcode, FaDownload 
} from 'react-icons/fa';
import { QRCodeCanvas } from 'qrcode.react';

// import file source
import SearchFilter from '../EventSetting/ProgramFilter';
import ProgramDetailModal from './ProgramDetailModal';
import ProgramListAlert from './ProgramListAlert';
import ProgramStats from './ProgramStats'; 
import r1_logo from '../../../assets/login/rone_logo_v03.jpg';

const ProgramList: React.FC = () => {
  // handle custom states
  const { apiUrl } = useEnv();
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');
  const { theme } = useTheme();
  // handle fetch program states
  const { programs, setPrograms, loading, error, updateProgramStatus, deleteProgram, updateProgramDetails, fetchPrograms } = useProgram();
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [activeTab, setActiveTab] = useState<'latest' | 'expired'>('latest');
  const [isEditing, setIsEditing] = useState(false);
  const [fieldValues, setFieldValues] = useState<Partial<Program>>({});
  const [searchText, setSearchText] = useState('');
  const [filterDates, setFilterDates] = useState<{ startDate: string; endDate: string }>({ startDate: '', endDate: '' });
  const [filterStatus, setFilterStatus] = useState<'all' | 'running' | 'stopped'>('all');
  const [showQRCode, setShowQRCode] = useState(false); // State to show/hide the QR code modal
  const [qrCodeValue, setQRCodeValue] = useState<string>('');
  const qrCodeRef = useRef<HTMLCanvasElement>(null); 
  const [alertDetails, setAlertDetails] = useState<{
    title: string;
    message: string;
    icon: React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
  } | null>(null);

  const handleApply = (programId: number) => {
    setAlertDetails({
      title: 'Start Program',
      message: 'Are you sure you want to start this program?',
      icon: <FaPlayCircle className="h-6 w-6 text-green-600" aria-hidden="true" />,
      onConfirm: async () => {
        await updateProgramStatus(programId, 'running');
        setAlertDetails(null);
        fetchPrograms();
      },
      onCancel: () => setAlertDetails(null),
    });
  };

  const handleCancel = (programId: number) => {
    setAlertDetails({
      title: 'Stop Program',
      message: 'Are you sure you want to stop this program?',
      icon: <FaPauseCircle className="h-6 w-6 text-red-600" aria-hidden="true" />,
      onConfirm: async () => {
        await updateProgramStatus(programId, 'stopped');
        setAlertDetails(null);
        fetchPrograms();
      },
      onCancel: () => setAlertDetails(null),
    });
  };

  const handleDelete = (programId: number) => {
    setAlertDetails({
      title: 'Delete Program',
      message: 'Are you sure you want to delete this program? This action cannot be undone.',
      icon: <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />,
      onConfirm: async () => {
        await deleteProgram(programId);
        setAlertDetails(null);
        fetchPrograms();
      },
      onCancel: () => setAlertDetails(null),
    });
  };

  const handleRevoke = (programId: number) => {
    setAlertDetails({
      title: 'Revoke Program',
      message: 'Are you sure you want to revoke this program?',
      icon: <FaUndo className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
      onConfirm: async () => {
        await updateProgramStatus(programId, 'stopped');
        setAlertDetails(null);
        fetchPrograms();
      },
      onCancel: () => setAlertDetails(null),
    });
  };

  const handleExpire = (programId: number) => {
    setAlertDetails({
      title: 'Expire Program',
      message: 'Are you sure you want to mark this program as expired?',
      icon: <FaCalendarMinus className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
      onConfirm: async () => {
        try {
          const updatedProgram = await updateProgramStatus(programId, 'expired');
          if (updatedProgram) {
            setPrograms((prevPrograms) =>
              prevPrograms.map((program) =>
                program.id === programId ? { ...program, status: 'expired' } : program
              )
            );
            // Switch to the expired tab
            setActiveTab('expired');
          }
        } catch (err) {
          console.error('Error expiring program:', err);
        }
        setAlertDetails(null);
      },
      onCancel: () => setAlertDetails(null),
    });
  };  

  const formatDate = (date: string | null | undefined) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  };

  const handleProgramClick = (program: Program) => {
    setSelectedProgram(program);
    setIsEditing(false);
    setFieldValues({});
  };

  const handleFieldChange = (field: keyof Program, value: any) => {
    setFieldValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    if (selectedProgram) {
      setAlertDetails({
        title: 'Confirm Save',
        message: `Are you sure you want to save the changes to ${selectedProgram.name}?`,
        icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
        onConfirm: async () => {
          await updateProgramDetails(selectedProgram.id, fieldValues);
          setSelectedProgram(null);
          fetchPrograms();
          setAlertDetails({
            title: 'Save Successful',
            message: `The program ${selectedProgram.name} has been updated successfully.`,
            icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
            onConfirm: () => setAlertDetails(null),
            onCancel: () => setAlertDetails(null),
          });
        },
        onCancel: () => setAlertDetails(null),
      });
    }
  };  

  const handleSearch = (text: string) => {
    setSearchText(text);
  };

  const handleFilter = (startDate: string, endDate: string, status: 'all' | 'running' | 'stopped') => {
    setFilterDates({ startDate, endDate });
    setFilterStatus(status);
  };

  const filteredPrograms = programs.filter(program => {
    const matchesTab = activeTab === 'latest' ? program.status !== 'expired' : program.status === 'expired';
    const matchesSearch = program.name?.toLowerCase().includes(searchText.toLowerCase()) ?? false;
    const matchesFilterDates = (!filterDates.startDate || new Date(program.startDate!) >= new Date(filterDates.startDate)) &&
                              (!filterDates.endDate || new Date(program.endDate!) <= new Date(filterDates.endDate));
    const matchesFilterStatus = filterStatus === 'all' || program.status === filterStatus;
    return matchesTab && matchesSearch && matchesFilterDates && matchesFilterStatus;
  });    

  // Modify the renderOverlay function in ProgramList component
  const renderOverlay = (program: Program) => {
    if (program.status === 'expired') {
      return (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center text-white">
          <div className="text-center p-2 bg-red-500 rounded">
            <p>Expired: {formatDate(program.endDate)}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  // Function to download the QR code image
  const downloadQRCode = async (
    ref: React.RefObject<HTMLCanvasElement>,
    qrCodeValue: string,
    fileName: string
  ) => {
    const tempCanvas = ref.current;
  
    if (tempCanvas) {
      const ctx = tempCanvas.getContext("2d");
      const size = 500; // Size of the QR code
  
      // Set canvas size
      tempCanvas.width = size;
      tempCanvas.height = size;
  
      // Draw QR Code from the DOM element
      const qrCodeCanvas = document.querySelector("#qrCodeCanvas") as HTMLCanvasElement;
      if (qrCodeCanvas && ctx) {
        ctx.drawImage(qrCodeCanvas, 0, 0, size, size);
  
        // Add Logo
        const logo = new Image();
        logo.src = r1_logo; // Path to the logo image
        logo.onload = () => {
          const logoSize = 80; // Adjust logo size
          ctx.drawImage(
            logo,
            (size - logoSize) / 2, // Center the logo
            (size - logoSize) / 2,
            logoSize,
            logoSize
          );
  
          // Create download link
          const pngUrl = tempCanvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
          const link = document.createElement("a");
          link.href = pngUrl;
          link.download = `${fileName}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
      }
    } else {
      console.error("Canvas is not available for QR code generation.");
    }
  };
  
  const handleQRCodeClick = (programId: number) => {
    setQRCodeValue(`${window.location.origin}/event/${programId}`);
    setShowQRCode(true);
  };

  const closeQRCodeModal = () => {
    setShowQRCode(false);
    setQRCodeValue('');
  };
  

  return (
    <div className="w-[850px] mr-4 py-10">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-cyan-800">
          Event List
        </h2>
      </div>
      <div className="flex mb-2">
      <button
          className="px-4 py-2 rounded-t-xl"
          onClick={() => setActiveTab('latest')}
          style={{
            fontFamily: theme.fontFamily,
            color: activeTab === 'latest' ? theme.text2Color : theme.textColor,
            backgroundColor: activeTab === 'latest' ? theme.primaryColor : '#E5E7EB', 
          }}
        >
          Latest Events
        </button>
        <button
          className="px-4 py-2 rounded-t-xl"
          onClick={() => setActiveTab('expired')}
          style={{
            fontFamily: theme.fontFamily,
            color: activeTab === 'expired' ? theme.text2Color : theme.textColor,
            backgroundColor: activeTab === 'expired' ? theme.primaryColor : '#E5E7EB',
          }}
        >
          Expired Events
        </button>
        <button
          className="px-4 py-2 text-gray-700"
          onClick={fetchPrograms}
        >
          <RiRefreshFill 
            className={`inline-block mr-4 w-8 h-8 text-cyan-800 
              ${permissions?.eventSetting?.editEvent !== 'enable'
                  ? 'text-gray-400 cursor-not-allowed' : ''
              }`}
          />
        </button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {alertDetails && (
        <ProgramListAlert
          title={alertDetails.title}
          message={alertDetails.message}
          icon={alertDetails.icon}
          onConfirm={alertDetails.onConfirm}
          onCancel={alertDetails.onCancel}
        />
      )}
       {filteredPrograms.length === 0 ? (
        <div className="border-2 border-dashed border-gray-300 p-8 text-center text-cyan-800 font-bold">
          {activeTab === 'latest' ? 'No events listed' : 'No expired events listed'}
        </div>
      ) : (
        <div className="p-4 bg-white rounded-t shadow-lg border-gray-300 text-cyan-800 shadow-ml h-[935px] overflow-y-auto">
          <ul>
            {filteredPrograms.map((program) => (
              <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded flex justify-between items-center relative">
                <div className="flex items-center space-x-4">
                  {program.image && ( 
                    <img 
                      src={`${apiUrl}/uploads/forEventTicket/${program.image}`} 
                      alt={program.name} 
                      className="object-cover rounded" 
                      style={{ width: '250px', height: '250px' }} 
                    />
                  )}
                  <div>

                    {/* event status */}
                    <h3 className="font-semibold" 
                      style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
                      {program.name}
                      <span className={`ml-2 px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'} text-white`}>
                        {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
                      </span>
                    </h3>

                    {/* event member role tag */}
                    <div className="flex space-x-2 mt-2">
                      {program.memberType && Array.isArray(program.memberType) && program.memberType.map((type, index) => {
                        const shortForm = convertToShortForm(type);
                        const tagColor = getTagColor(shortForm);
                        return (
                          <span key={index} className={`flex items-center px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
                            <FaTag className={`mr-1 ${tagColor}`} />
                            {shortForm}
                          </span>
                        );
                      })}
                    </div>

                    {/* event detail info */}
                    <div className="flex flex-col space-y-1">
                      <p>
                        <span className="font-bold">Event Date:</span> {formatDate(program.startDate)} on {program.time ? formatTimeTo12Hour(program.time) : 'N/A'}
                      </p>
                      <p>
                        <span className="font-bold">Amount:</span> {program.currency === 'FREE' ? 'Free of charge' : `${formatAmount(program.amount)} ${program.currency}`}
                      </p>
                      <p className="font-bold">Setting:</p>
                      <ul className="list-disc ml-5 text-sm">
                        <li>{program.slot === -1 || program.initialSlot === -1 ? 'Unlimited Slots' : `${program.slot} out of ${program.initialSlot} Slots`}</li>
                        <li>Redeem with {program.redeemNumber === 0 ? 'Unlimited' : program.redeemNumber} Count</li>
                        <li>{program.broker}, {program.lotSize} lot Size, {formatAmount(program.deposit)} USD</li>
                      </ul>
                      <p className="mt-2 font-semibold">Created Date: {formatDate(program.createdAt)}</p>
                    </div>

                  </div>
                </div>
                {renderOverlay(program)}
                {program.status !== 'expired' && (
                  <div className="absolute top-4 right-4 flex space-x-2">
                    {/* QR Code Button */}
                    <FaQrcode
                      className={`cursor-pointer text-2xl text-cyan-800 
                        ${permissions?.eventSetting?.editEvent !== 'enable'
                          ? 'text-gray-400' : ''
                        }`} 
                      onClick={() => {
                        if (permissions?.eventSetting?.editEvent === 'enable')
                          handleQRCodeClick(program.id)
                        }
                      }
                    />
                    <FaEdit 
                      className={`cursor-pointer text-2xl text-cyan-800 
                        ${permissions?.eventSetting?.editEvent !== 'enable'
                          ? 'text-gray-400' : ''
                        }`} 
                      onClick={() => {
                        if (permissions?.eventSetting?.editEvent === 'enable')
                          handleProgramClick(program);
                        }
                      }
                    />
                    <FaTrash 
                      className={`cursor-pointer text-2xl text-cyan-800 
                        ${permissions?.eventSetting?.deleteEvent !== 'enable'
                          ? 'text-gray-400' : ''
                        }`} 
                      onClick={() => {
                        if (permissions?.eventSetting?.editEvent === 'enable')
                          handleDelete(program.id);
                        }
                      }
                    />
                  </div>
                )}
                <div className="absolute bottom-4 right-4 flex space-x-2">
                  {program.status === 'expired' ? (
                    <>
                      {/* Event List Revoke button */}
                      <button 
                        onClick={() => handleRevoke(program.id)} 
                        className={`flex items-center space-x-1 px-2 py-1 rounded
                          ${permissions?.eventSetting?.editEvent !== 'enable'
                            ? 'bg-gray-500 text-gray-100 cursor-not-allowed' : 'bg-green-500 text-white'
                        }`}
                        disabled={permissions?.eventSetting?.editEvent !== 'enable'} 
                      >
                        <FaUndo />
                        <span>Revoke</span>
                      </button>

                      {/* Event List Remove button */}
                      <button 
                        onClick={() => handleDelete(program.id)} 
                        className={`flex items-center space-x-1 px-2 py-1 rounded 
                          ${permissions?.eventSetting?.deleteEvent !== 'enable'
                          ? 'bg-gray-500 text-gray-100 cursor-not-allowed' : 'bg-red-500 text-white'
                      }`}
                        disabled={permissions?.eventSetting?.deleteEvent !== 'enable'} 
                      >
                        <FaTrash />
                        <span>Remove</span>
                      </button>
                    </>
                  ) : (
                    <>
                      {/* Event List Play button */}
                      <button 
                        onClick={() => handleApply(program.id)} 
                        className={`flex items-center space-x-1 px-2 py-1 rounded 
                          ${permissions?.eventSetting?.editEvent !== 'enable'
                            ? 'text-gray-400 cursor-not-allowed' : 'text-green-700'
                          }`}
                        disabled={permissions?.eventSetting?.editEvent !== 'enable'} 
                      >
                        <FaCirclePlay />
                        <span>Start</span>
                      </button>
                      
                      {/* Event List Stop button */}
                      <button 
                        onClick={() => handleCancel(program.id)} 
                        className={`flex items-center space-x-1 px-2 py-1 rounded 
                          ${permissions?.eventSetting?.editEvent !== 'enable'
                            ? 'text-gray-400 cursor-not-allowed' : 'text-red-700'
                          }`}
                        disabled={permissions?.eventSetting?.editEvent !== 'enable'} 
                      >
                        <FaCirclePause />
                        <span>Stopped</span>
                      </button>

                      {/* Event List Expire button */}
                      <button 
                        onClick={() => handleExpire(program.id)} 
                        className={`flex items-center space-x-1 px-2 py-1 rounded 
                          ${permissions?.eventSetting?.editEvent !== 'enable'
                            ? 'text-gray-400 cursor-not-allowed' : 'text-yellow-700'
                        }`}
                        disabled={permissions?.eventSetting?.editEvent !== 'enable'} 
                      >
                        <FaCalendarMinus />
                        <span>Expire</span>
                      </button>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedProgram && (
        <ProgramDetailModal
          program={selectedProgram}
          fieldValues={fieldValues}
          isEditing={isEditing}
          onClose={() => setSelectedProgram(null)}
          onSave={handleSave}
          onFieldChange={handleFieldChange}
          apiUrl={apiUrl}
        />
      )}

      {/* QR Code Modal */}
      {showQRCode && (
        <div
          className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={closeQRCodeModal}
        >
          <div
            className="bg-white p-10 rounded shadow-lg relative text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Hidden Canvas for Download */}
            <canvas ref={qrCodeRef} className="hidden" />

            {/* QR Code Display */}
            <div style={{ position: "relative", display: "inline-block" }}>
              <QRCodeCanvas
                value={qrCodeValue}
                size={500}
                bgColor="#ffffff"
                fgColor={theme.primaryColor}
                level="H"
                marginSize={2}
                id="qrCodeCanvas"
              />
              <img
                src={r1_logo}
                alt="Logo"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80px",
                  height: "80px",
                }}
              />
            </div>

            <div className="mt-4">
              {/* Save QR Code Button */}
              <button
                className="bg-cyan-800 text-white font-bold px-4 py-2 rounded mr-2"
                onClick={() => downloadQRCode(qrCodeRef, qrCodeValue, "Event_QR_Code")}
              >
                <FaDownload className="inline mr-2" /> Save QR Code
              </button>

              {/* Close Modal Button */}
              <button
                onClick={closeQRCodeModal}
                className="bg-cyan-800 text-white font-bold mt-2 px-4 py-2 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="p-4 bg-white rounded-md shadow-md my-4  z-10">
        <div className="flex items-center space-x-4">
          <SearchFilter onSearch={handleSearch} onFilter={handleFilter} />
        </div>
      </div>
      <ProgramStats />
    </div>
  );
};

export default ProgramList;

// // update code --> 14 jan 2024
// // src/components/admin/EventSetting/ProgramList.tsx

// import React, { useState } from 'react';

// // import context file
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';
// import { useAuth } from '../../../context/AuthContext';   
// import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';

// // import hook files
// import useProgram, { Program, convertToShortForm, getTagColor, formatTimeTo12Hour, formatAmount } from '../../../hooks/AdminDashboard/useProgram';

// // import react-icons
// import { FaCirclePlay, FaCirclePause, FaCalendarMinus } from "react-icons/fa6";
// import { RiRefreshFill } from "react-icons/ri";
// import { 
//   FaSave, FaEdit, FaUndo, FaTrash, 
//   FaPlayCircle, FaPauseCircle, FaExclamationTriangle,
//   FaTag 
// } from 'react-icons/fa';

// // import file source
// import SearchFilter from '../EventSetting/ProgramFilter';
// import ProgramDetailModal from './ProgramDetailModal';
// import ProgramListAlert from './ProgramListAlert';
// import ProgramStats from './ProgramStats'; 

// const ProgramList: React.FC = () => {
//   // handle custom states
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();
//   const { permissions } = useRoleAccess(user?.email || '');
//   const { theme } = useTheme();
//   // handle fetch program states
//   const { programs, setPrograms, loading, error, updateProgramStatus, deleteProgram, updateProgramDetails, fetchPrograms } = useProgram();
//   const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
//   const [activeTab, setActiveTab] = useState<'latest' | 'expired'>('latest');
//   const [isEditing, setIsEditing] = useState(false);
//   const [fieldValues, setFieldValues] = useState<Partial<Program>>({});
//   const [searchText, setSearchText] = useState('');
//   const [filterDates, setFilterDates] = useState<{ startDate: string; endDate: string }>({ startDate: '', endDate: '' });
//   const [filterStatus, setFilterStatus] = useState<'all' | 'running' | 'stopped'>('all');
//   const [alertDetails, setAlertDetails] = useState<{
//     title: string;
//     message: string;
//     icon: React.ReactNode;
//     onConfirm: () => void;
//     onCancel: () => void;
//   } | null>(null);

//   const handleApply = (programId: number) => {
//     setAlertDetails({
//       title: 'Start Program',
//       message: 'Are you sure you want to start this program?',
//       icon: <FaPlayCircle className="h-6 w-6 text-green-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'running');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleCancel = (programId: number) => {
//     setAlertDetails({
//       title: 'Stop Program',
//       message: 'Are you sure you want to stop this program?',
//       icon: <FaPauseCircle className="h-6 w-6 text-red-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'stopped');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleDelete = (programId: number) => {
//     setAlertDetails({
//       title: 'Delete Program',
//       message: 'Are you sure you want to delete this program? This action cannot be undone.',
//       icon: <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await deleteProgram(programId);
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleRevoke = (programId: number) => {
//     setAlertDetails({
//       title: 'Revoke Program',
//       message: 'Are you sure you want to revoke this program?',
//       icon: <FaUndo className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'stopped');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleExpire = (programId: number) => {
//     setAlertDetails({
//       title: 'Expire Program',
//       message: 'Are you sure you want to mark this program as expired?',
//       icon: <FaCalendarMinus className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         try {
//           const updatedProgram = await updateProgramStatus(programId, 'expired');
//           if (updatedProgram) {
//             setPrograms((prevPrograms) =>
//               prevPrograms.map((program) =>
//                 program.id === programId ? { ...program, status: 'expired' } : program
//               )
//             );
//             // Switch to the expired tab
//             setActiveTab('expired');
//           }
//         } catch (err) {
//           console.error('Error expiring program:', err);
//         }
//         setAlertDetails(null);
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };  

//   const formatDate = (date: string | null | undefined) => {
//     if (!date) return '';
//     const d = new Date(date);
//     return d.toISOString().split('T')[0];
//   };

//   const handleProgramClick = (program: Program) => {
//     setSelectedProgram(program);
//     setIsEditing(false);
//     setFieldValues({});
//   };

//   const handleFieldChange = (field: keyof Program, value: any) => {
//     setFieldValues((prev) => ({ ...prev, [field]: value }));
//   };

//   const handleSave = async () => {
//     if (selectedProgram) {
//       setAlertDetails({
//         title: 'Confirm Save',
//         message: `Are you sure you want to save the changes to ${selectedProgram.name}?`,
//         icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
//         onConfirm: async () => {
//           await updateProgramDetails(selectedProgram.id, fieldValues);
//           setSelectedProgram(null);
//           fetchPrograms();
//           setAlertDetails({
//             title: 'Save Successful',
//             message: `The program ${selectedProgram.name} has been updated successfully.`,
//             icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
//             onConfirm: () => setAlertDetails(null),
//             onCancel: () => setAlertDetails(null),
//           });
//         },
//         onCancel: () => setAlertDetails(null),
//       });
//     }
//   };  

//   const handleSearch = (text: string) => {
//     setSearchText(text);
//   };

//   const handleFilter = (startDate: string, endDate: string, status: 'all' | 'running' | 'stopped') => {
//     setFilterDates({ startDate, endDate });
//     setFilterStatus(status);
//   };

//   const filteredPrograms = programs.filter(program => {
//     const matchesTab = activeTab === 'latest' ? program.status !== 'expired' : program.status === 'expired';
//     const matchesSearch = program.name?.toLowerCase().includes(searchText.toLowerCase()) ?? false;
//     const matchesFilterDates = (!filterDates.startDate || new Date(program.startDate!) >= new Date(filterDates.startDate)) &&
//                               (!filterDates.endDate || new Date(program.endDate!) <= new Date(filterDates.endDate));
//     const matchesFilterStatus = filterStatus === 'all' || program.status === filterStatus;
//     return matchesTab && matchesSearch && matchesFilterDates && matchesFilterStatus;
//   });    

//   // Modify the renderOverlay function in ProgramList component
//   const renderOverlay = (program: Program) => {
//     if (program.status === 'expired') {
//       return (
//         <div className="absolute inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center text-white">
//           <div className="text-center p-2 bg-red-500 rounded">
//             <p>Expired: {formatDate(program.endDate)}</p>
//           </div>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <div className="w-[850px] mr-4 py-10">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-2xl font-bold text-cyan-800">
//           Event List
//         </h2>
//       </div>
//       <div className="flex mb-2">
//       <button
//           className="px-4 py-2 rounded-t-xl"
//           onClick={() => setActiveTab('latest')}
//           style={{
//             fontFamily: theme.fontFamily,
//             color: activeTab === 'latest' ? theme.text2Color : theme.textColor,
//             backgroundColor: activeTab === 'latest' ? theme.primaryColor : '#E5E7EB', 
//           }}
//         >
//           Latest Events
//         </button>
//         <button
//           className="px-4 py-2 rounded-t-xl"
//           onClick={() => setActiveTab('expired')}
//           style={{
//             fontFamily: theme.fontFamily,
//             color: activeTab === 'expired' ? theme.text2Color : theme.textColor,
//             backgroundColor: activeTab === 'expired' ? theme.primaryColor : '#E5E7EB',
//           }}
//         >
//           Expired Events
//         </button>
//         <button
//           className="px-4 py-2 text-gray-700"
//           onClick={fetchPrograms}
//         >
//           <RiRefreshFill 
//             className={`inline-block mr-4 w-8 h-8 text-cyan-800 
//               ${permissions?.eventSetting?.editEvent !== 'enable'
//                   ? 'text-gray-400 cursor-not-allowed' : ''
//               }`}
//           />
//         </button>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       {alertDetails && (
//         <ProgramListAlert
//           title={alertDetails.title}
//           message={alertDetails.message}
//           icon={alertDetails.icon}
//           onConfirm={alertDetails.onConfirm}
//           onCancel={alertDetails.onCancel}
//         />
//       )}
//        {filteredPrograms.length === 0 ? (
//         <div className="border-2 border-dashed border-gray-300 p-8 text-center text-cyan-800 font-bold">
//           {activeTab === 'latest' ? 'No events listed' : 'No expired events listed'}
//         </div>
//       ) : (
//         <div className="p-4 bg-white rounded-t shadow-lg border-gray-300 text-cyan-800 shadow-ml h-[935px] overflow-y-auto">
//           <ul>
//             {filteredPrograms.map((program) => (
//               <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded flex justify-between items-center relative">
//                 <div className="flex items-center space-x-4">
//                   {program.image && <img src={`${apiUrl}/uploads/forEventTicket/${program.image}`} alt={program.name} className="object-cover rounded" 
//                     style={{ width: '250px', height: '250px' }} />}
//                   <div>

//                     {/* event status */}
//                     <h3 className="font-semibold" 
//                       style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//                       {program.name}
//                       <span className={`ml-2 px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'} text-white`}>
//                         {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
//                       </span>
//                     </h3>

//                     {/* event member role tag */}
//                     <div className="flex space-x-2 mt-2">
//                       {program.memberType && Array.isArray(program.memberType) && program.memberType.map((type, index) => {
//                         const shortForm = convertToShortForm(type);
//                         const tagColor = getTagColor(shortForm);
//                         return (
//                           <span key={index} className={`flex items-center px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
//                             <FaTag className={`mr-1 ${tagColor}`} />
//                             {shortForm}
//                           </span>
//                         );
//                       })}
//                     </div>

//                     {/* event detail info */}
//                     <div className="flex flex-col space-y-1">
//                       <p>
//                         <span className="font-bold">Event Date:</span> {formatDate(program.startDate)} on {program.time ? formatTimeTo12Hour(program.time) : 'N/A'}
//                       </p>
//                       <p>
//                         <span className="font-bold">Amount:</span> {program.currency === 'FREE' ? 'Free of charge' : `${formatAmount(program.amount)} ${program.currency}`}
//                       </p>
//                       <p className="font-bold">Setting:</p>
//                       <ul className="list-disc ml-5 text-sm">
//                         <li>{program.slot === -1 || program.initialSlot === -1 ? 'Unlimited Slots' : `${program.slot} out of ${program.initialSlot} Slots`}</li>
//                         <li>Redeem with {program.redeemNumber === 0 ? 'Unlimited' : program.redeemNumber} Count</li>
//                         <li>{program.broker}, {program.lotSize} lot Size, {formatAmount(program.deposit)} USD</li>
//                       </ul>
//                       <p className="mt-2 font-semibold">Created Date: {formatDate(program.createdAt)}</p>
//                     </div>

//                   </div>
//                 </div>
//                 {renderOverlay(program)}
//                 {program.status !== 'expired' && (
//                   <div className="absolute top-4 right-4 flex space-x-2">
//                     <FaEdit 
//                       className={`cursor-pointer text-2xl text-cyan-800 
//                         ${permissions?.eventSetting?.editEvent !== 'enable'
//                           ? 'text-gray-400' : ''
//                         }`} 
//                       onClick={() => {
//                         if (permissions?.eventSetting?.editEvent === 'enable')
//                           handleProgramClick(program);
//                         }
//                       }
//                     />
//                     <FaTrash 
//                       className={`cursor-pointer text-2xl text-cyan-800 
//                         ${permissions?.eventSetting?.deleteEvent !== 'enable'
//                           ? 'text-gray-400' : ''
//                         }`} 
//                       onClick={() => {
//                         if (permissions?.eventSetting?.editEvent === 'enable')
//                           handleDelete(program.id);
//                         }
//                       }
//                     />
//                   </div>
//                 )}
//                 <div className="absolute bottom-4 right-4 flex space-x-2">
//                   {program.status === 'expired' ? (
//                     <>
//                       {/* Event List Revoke button */}
//                       <button 
//                         onClick={() => handleRevoke(program.id)} 
//                         className={`flex items-center space-x-1 px-2 py-1 rounded
//                           ${permissions?.eventSetting?.editEvent !== 'enable'
//                             ? 'bg-gray-500 text-gray-100 cursor-not-allowed' : 'bg-green-500 text-white'
//                         }`}
//                         disabled={permissions?.eventSetting?.editEvent !== 'enable'} 
//                       >
//                         <FaUndo />
//                         <span>Revoke</span>
//                       </button>

//                       {/* Event List Remove button */}
//                       <button 
//                         onClick={() => handleDelete(program.id)} 
//                         className={`flex items-center space-x-1 px-2 py-1 rounded 
//                           ${permissions?.eventSetting?.deleteEvent !== 'enable'
//                           ? 'bg-gray-500 text-gray-100 cursor-not-allowed' : 'bg-red-500 text-white'
//                       }`}
//                         disabled={permissions?.eventSetting?.deleteEvent !== 'enable'} 
//                       >
//                         <FaTrash />
//                         <span>Remove</span>
//                       </button>

//                     </>
//                   ) : (
//                     <>
//                       {/* Event List Play button */}
//                       <button 
//                         onClick={() => handleApply(program.id)} 
//                         className={`flex items-center space-x-1 px-2 py-1 rounded 
//                           ${permissions?.eventSetting?.editEvent !== 'enable'
//                             ? 'text-gray-400 cursor-not-allowed' : 'text-green-700'
//                           }`}
//                         disabled={permissions?.eventSetting?.editEvent !== 'enable'} 
//                       >
//                         <FaCirclePlay />
//                         <span>Start</span>
//                       </button>
                      
//                       {/* Event List Stop button */}
//                       <button 
//                         onClick={() => handleCancel(program.id)} 
//                         className={`flex items-center space-x-1 px-2 py-1 rounded 
//                           ${permissions?.eventSetting?.editEvent !== 'enable'
//                             ? 'text-gray-400 cursor-not-allowed' : 'text-red-700'
//                           }`}
//                         disabled={permissions?.eventSetting?.editEvent !== 'enable'} 
//                       >
//                         <FaCirclePause />
//                         <span>Stopped</span>
//                       </button>

//                       {/* Event List Expire button */}
//                       <button 
//                         onClick={() => handleExpire(program.id)} 
//                         className={`flex items-center space-x-1 px-2 py-1 rounded 
//                           ${permissions?.eventSetting?.editEvent !== 'enable'
//                             ? 'text-gray-400 cursor-not-allowed' : 'text-yellow-700'
//                         }`}
//                         disabled={permissions?.eventSetting?.editEvent !== 'enable'} 
//                       >
//                         <FaCalendarMinus />
//                         <span>Expire</span>
//                       </button>
//                     </>
//                   )}
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}

//       {selectedProgram && (
//         <ProgramDetailModal
//           program={selectedProgram}
//           fieldValues={fieldValues}
//           isEditing={isEditing}
//           onClose={() => setSelectedProgram(null)}
//           onSave={handleSave}
//           onFieldChange={handleFieldChange}
//           apiUrl={apiUrl}
//         />
//       )}

//       <div className="p-4 bg-white rounded-md shadow-md my-4  z-10">
//         <div className="flex items-center space-x-4">
//           <SearchFilter onSearch={handleSearch} onFilter={handleFilter} />
//         </div>
//       </div>
//       <ProgramStats />
//     </div>
//   );
// };

// export default ProgramList;