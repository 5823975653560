// src/pages/p02_Login.tsx

// import react component
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import react icon
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { BiSolidToggleLeft, BiSolidToggleRight } from "react-icons/bi";
// import custom component
import AppFooter from '../components/Footer';
import ResetPassword from '../components/login/ResetPassword';
import SetupPassword from '../components/login/SignUp';
import SuccessModal from '../components/login/SuccessModal';
import ErrorModal from '../components/login/ErrorModal';
import CookieNotification from '../components/CookieNotification';
// import custom context
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LangContext';
// import custom hook
import useGeoIPCheck from '../hooks/useGeoIP';
// import asset
import r1logoOri from '../assets/login/logo_r1_ori_v02.png';
import r1bg from '../assets/login/bg_r1.png';
// import 3rd party external
import { motion } from 'framer-motion'; 
import Cookies from 'js-cookie';

const LoginView: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false);
  const [isSetupPasswordVisible, setIsSetupPasswordVisible] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const [isLanguageManuallyChanged, setIsLanguageManuallyChanged] = useState(false);

  const navigate = useNavigate();
  const { theme } = useTheme();
  const { login } = useAuth();
  const { language, toggleLanguage, setLanguageDirectly } = useLanguage(); 
  const { geoData, isLoading } = useGeoIPCheck('live');

  useEffect(() => {
    if (!isLoading && geoData && !isLanguageManuallyChanged) {
      const detectedLanguage = geoData.country_code === 'TW' ? 'zh-TW' : 'zh-CN';
      if (language !== detectedLanguage) {
        setLanguageDirectly(detectedLanguage);
      }
    }
  
    // Load stored user preferences for login if "Remember Me" was used
    const storedEmail = Cookies.get('email');
    const storedPassword = Cookies.get('password');
    const storedRememberMe = Cookies.get('rememberMe') === 'true';
  
    if (storedRememberMe && storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, [geoData, isLoading, language, isLanguageManuallyChanged, setLanguageDirectly]);        

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
  
      if (response && response.token) {
        if (rememberMe) {
          Cookies.set('email', email, { expires: 7 });
          Cookies.set('password', password, { expires: 7 });
          Cookies.set('rememberMe', 'true', { expires: 7 });
          Cookies.set('token', response.token, { expires: 7 });
        } else {
          Cookies.remove('email');
          Cookies.remove('password');
          Cookies.set('rememberMe', 'false');
        }
        setIsSuccessModalVisible(true);
  
        // Set a timeout to redirect after 5 seconds
        setTimeout(() => handleCloseSuccessModal(response.role), 3000);
      } else {
        setIsErrorModalVisible(true);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setIsErrorModalVisible(true);
    }
  };  

  const handleCloseSuccessModal = (role: string) => {
    setIsSuccessModalVisible(false);
  
    // If user is logged in as an admin, redirect to the admin dashboard.
    if (role === 'admin') {
      navigate('/admin-dashboard');
    } else {
      // Redirect based on other roles, if needed.
      const adminRoles = ['sale', 'manager', 'marketing', 'support', 'assistant', 'custom'];
      if (adminRoles.includes(role)) {
        navigate('/admin-dashboard/user-data');
      } else {
        navigate('/dashboard');
      }
    }
  };

  const handleForgotPassword = (e: React.FormEvent) => {
    e.preventDefault();
    setIsResetModalVisible(true);
  };

  const handleCloseResetModal = () => {
    setIsResetModalVisible(false);
  };

  const handleOpenSetupPassword = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSetupPasswordVisible(true);
  };

  const handleCloseSetupPassword = () => {
    setIsSetupPasswordVisible(false);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalVisible(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${r1bg})`, backgroundSize: 'cover' }}>
      <CookieNotification />
      <div className="flex-1 flex justify-center items-center z-10 px-4 sm:px-2">
        <motion.div
          className="bg-white p-6 sm:p-10 rounded-lg shadow-lg w-full max-w-md"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex justify-end">
            <button
              onClick={() => {
                setIsLanguageManuallyChanged(true);
                toggleLanguage();
              }}
              className="text-cyan-800 flex items-center"
            >
              {language === 'en' ? (
                <>
                  English
                  <BiSolidToggleLeft className="inline-block text-4xl ml-2" />
                </>
              ) : language === 'zh-TW' ? (
                <>
                  繁體中文
                  <BiSolidToggleRight className="inline-block text-4xl ml-2" />
                </>
              ) : (
                <>
                  简体中文
                  <BiSolidToggleRight className="inline-block text-4xl ml-2" />
                </>
              )}
            </button>
          </div>
          <motion.div
            className="flex flex-col items-center space-y-6 xs:space-y-4"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <motion.img
              src={r1logoOri}
              alt="R One Century Logo"
              className="w-32 h-32 sm:w-1/2 sm:h-1/2"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeIn" }}
            />
            <motion.h3
              className="text-lg font-medium text-center sm:text-xl xs:text-sm"
              style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              dangerouslySetInnerHTML={{
                __html: language === 'en' 
                  ? 'Welcome to R One Century 創市紀,<br /> Login Your Account'
                  : language === 'zh-TW' 
                  ? '歡迎來到 R One Century 創市紀，<br />請登入您的帳號'
                  : '欢迎来到 R One Century 创市纪，<br />请登录您的帐号',
              }}
            >
            </motion.h3>
            <form className="w-full" onSubmit={handleLogin}>
              <motion.div
                className="mb-4 xs:mb-2"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <div className="flex items-center border rounded px-3 py-2">
                  <FaEnvelope className="text-cyan-800 mr-3"/>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full py-2 xs:py-1 focus:outline-none"
                  />
                </div>
              </motion.div>
              <motion.div
                className="mb-4"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 1 }}
              >
                <div className="flex items-center border rounded px-3 py-2 relative">
                  <FaLock className="text-cyan-800 mr-3"/>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full py-2 xs:py-1 focus:outline-none"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 focus:outline-none text-gray-500"
                  >
                    {showPassword ? <FaEyeSlash className="text-cyan-800" /> : <FaEye className="text-cyan-800" />}
                  </button>
                </div>
              </motion.div>
              <motion.div
                className="flex justify-between items-center mb-4"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.2 }}
              >
                <label className="flex items-center" style={{ color: theme.primaryColor }}>
                  <input
                    type="checkbox"
                    className="mr-2 w-4 h-4"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <span className="text-base xs:text-xs"
                    style={{ 
                      color: theme.textColor, fontFamily: theme.fontFamily }}>
                      {
                        language === 'en' 
                          ? 'Remember me' 
                          : language === 'zh-TW'
                          ? '記得我' : '记得我' 
                      }
                  </span>
                </label>
                <button
                  onClick={handleForgotPassword}
                  className="text-pink-500 xs:text-xs underline"
                  style={{ fontFamily: theme.fontFamily }}
                >
                  {
                    language === 'en' 
                      ? 'Forget password?' 
                      : language === 'zh-TW'
                      ? '忘記密碼' : '忘记密码'
                  }
                </button>
              </motion.div>
              <motion.div
                className="mb-1"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.4 }}
              >
                <button
                  type="submit"
                  className="w-full py-3 rounded xs:py-2 xs:text-sm  bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
                  style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
                >
                  {
                    language === 'en' 
                      ? 'Sign in'
                      : language === 'zh-TW'
                      ? '登入' : '登录'
                  }
                </button>
              </motion.div>
            </form>
            <motion.p
              className="text-center xs:text-xs text-gray-600"
              style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 1.6 }}
            >
              {
                language === 'en' 
                  ? 'First time Sign in?' 
                  : language === 'zh-TW'
                  ? '首次登入? ' : '首次登录? '
                }
              <button
                onClick={handleOpenSetupPassword}
                className="text-pink-500 underline"
                style={{ fontFamily: theme.fontFamily }}
              >
                {
                  language === 'en' 
                    ? 'Register here' 
                    : language === 'zh-TW'
                    ? '這裡註冊' : '这里注册'
                }
              </button>
            </motion.p>
          </motion.div>
        </motion.div>
      </div>
      <div className="flex-shrink-0 w-full z-10">
        <AppFooter />
      </div>
      <ResetPassword visible={isResetModalVisible} onClose={handleCloseResetModal} />
      <SetupPassword visible={isSetupPasswordVisible} onClose={handleCloseSetupPassword} />
      <SuccessModal
        visible={isSuccessModalVisible}
        onClose={() => setIsSuccessModalVisible(false)}
        message={
          language === 'en' 
            ? 'Sign In Successful!' 
            : language === 'zh-TW'
            ? '成功登入!' : '成功登录'
          }
      />
      <ErrorModal visible={isErrorModalVisible} onClose={handleCloseErrorModal} />
    </div>
  );
};

export default LoginView;

// // udpare code --> 13 dec 2024
// // src/pages/p02_Login.tsx

// // import component
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

// import { useTheme } from '../context/ThemeContext';
// import AppFooter from '../components/Footer';
// import ResetPassword from '../components/login/ResetPassword';
// import SetupPassword from '../components/login/SignUp';
// import SuccessModal from '../components/login/SuccessModal';
// import ErrorModal from '../components/login/ErrorModal';
// import { useAuth } from '../context/AuthContext';
// import CookieNotification from '../components/CookieNotification';

// import r1logoOri from '../assets/login/logo_r1_ori_v02.png';
// import r1bg from '../assets/login/bg_r1.png';

// import { motion } from 'framer-motion'; 
// import Cookies from 'js-cookie';

// const LoginView: React.FC = () => {
//   const [email, setEmail] = useState<string>('');
//   const [password, setPassword] = useState<string>('');
//   const [showPassword, setShowPassword] = useState<boolean>(false);
//   const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false);
//   const [isSetupPasswordVisible, setIsSetupPasswordVisible] = useState<boolean>(false);
//   const [rememberMe, setRememberMe] = useState<boolean>(false);
//   const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
//   const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

//   const navigate = useNavigate();
//   const { theme } = useTheme();
//   const { login } = useAuth();

//   useEffect(() => {
//     const storedEmail = Cookies.get('email');
//     const storedPassword = Cookies.get('password');
//     const storedRememberMe = Cookies.get('rememberMe') === 'true';

//     if (storedRememberMe && storedEmail && storedPassword) {
//       setEmail(storedEmail);
//       setPassword(storedPassword);
//       setRememberMe(true);
//     }
//   }, []);

//   const handleLogin = async (e: React.FormEvent) => {
//     e.preventDefault();
//     try {
//       const response = await login(email, password);
  
//       if (response && response.token) {
//         if (rememberMe) {
//           Cookies.set('email', email, { expires: 7 });
//           Cookies.set('password', password, { expires: 7 });
//           Cookies.set('rememberMe', 'true', { expires: 7 });
//           Cookies.set('token', response.token, { expires: 7 });
//         } else {
//           Cookies.remove('email');
//           Cookies.remove('password');
//           Cookies.set('rememberMe', 'false');
//         }
//         setIsSuccessModalVisible(true);
  
//         // Set a timeout to redirect after 5 seconds
//         setTimeout(() => handleCloseSuccessModal(response.role), 3000);
//       } else {
//         setIsErrorModalVisible(true);
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       setIsErrorModalVisible(true);
//     }
//   };  

//   const handleCloseSuccessModal = (role: string) => {
//     setIsSuccessModalVisible(false);
  
//     // If user is logged in as an admin, redirect to the admin dashboard.
//     if (role === 'admin') {
//       navigate('/admin-dashboard');
//     } else {
//       // Redirect based on other roles, if needed.
//       const adminRoles = ['sale', 'manager', 'marketing', 'support', 'assistant', 'custom'];
//       if (adminRoles.includes(role)) {
//         navigate('/admin-dashboard/user-data');
//       } else {
//         navigate('/dashboard');
//       }
//     }
//   };
  

//   const handleForgotPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsResetModalVisible(true);
//   };

//   const handleCloseResetModal = () => {
//     setIsResetModalVisible(false);
//   };

//   const handleOpenSetupPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsSetupPasswordVisible(true);
//   };

//   const handleCloseSetupPassword = () => {
//     setIsSetupPasswordVisible(false);
//   };

//   const handleCloseErrorModal = () => {
//     setIsErrorModalVisible(false);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="relative flex flex-col min-h-screen bg-cover bg-center"
//       style={{ backgroundImage: `url(${r1bg})`, backgroundSize: 'cover' }}>
//       <CookieNotification />
//       <div className="flex-1 flex justify-center items-center z-10 px-4 sm:px-2">
//         <motion.div
//           className="bg-white p-6 sm:p-10 rounded-lg shadow-lg w-full max-w-md"
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.5 }}
//         >
//           <motion.div
//             className="flex flex-col items-center space-y-6 xs:space-y-4"
//             initial={{ y: -50, opacity: 0 }}
//             animate={{ y: 0, opacity: 1 }}
//             transition={{ duration: 0.5, delay: 0.2 }}
//           >
//             <motion.img
//               src={r1logoOri}
//               alt="R One Century Logo"
//               className="w-32 h-32 sm:w-1/2 sm:h-1/2"
//               initial={{ opacity: 0, y: -50 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.5, ease: "easeIn" }}
//             />
//             <motion.h3
//               className="text-lg font-medium text-center sm:text-xl xs:text-sm"
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//               initial={{ y: -20, opacity: 0 }}
//               animate={{ y: 0, opacity: 1 }}
//               transition={{ duration: 0.5, delay: 0.6 }}
//             >
//               Welcome to R One Century 创市纪, <br />
//               Login Your Account
//             </motion.h3>
//             <form className="w-full" onSubmit={handleLogin}>
//               <motion.div
//                 className="mb-4 xs:mb-2"
//                 initial={{ x: -50, opacity: 0 }}
//                 animate={{ x: 0, opacity: 1 }}
//                 transition={{ duration: 0.5, delay: 0.8 }}
//               >
//                 <div className="flex items-center border rounded px-3 py-2">
//                   <FaEnvelope className="text-gray-500 mr-3"
//                     style={{ color: theme.primaryColor }} />
//                   <input
//                     type="email"
//                     placeholder="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     className="w-full py-2 xs:py-1 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                 </div>
//               </motion.div>
//               <motion.div
//                 className="mb-4"
//                 initial={{ x: -50, opacity: 0 }}
//                 animate={{ x: 0, opacity: 1 }}
//                 transition={{ duration: 0.5, delay: 1 }}
//               >
//                 <div className="flex items-center border rounded px-3 py-2 relative">
//                   <FaLock className="text-gray-500 mr-3" 
//                     style={{ color: theme.primaryColor }}/>
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     className="w-full py-2 xs:py-1 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                   <button
//                     type="button"
//                     onClick={togglePasswordVisibility}
//                     className="absolute right-3 focus:outline-none text-gray-500"
//                   >
//                     {showPassword ? <FaEyeSlash style={{ color: theme.primaryColor }} /> : <FaEye style={{ color: theme.primaryColor }}/>}
//                   </button>
//                 </div>
//               </motion.div>
//               <motion.div
//                 className="flex justify-between items-center mb-4"
//                 initial={{ x: -50, opacity: 0 }}
//                 animate={{ x: 0, opacity: 1 }}
//                 transition={{ duration: 0.5, delay: 1.2 }}
//               >
//                 <label className="flex items-center" style={{ color: theme.primaryColor }}>
//                   <input
//                     type="checkbox"
//                     className="mr-2 w-4 h-4"
//                     checked={rememberMe}
//                     onChange={(e) => setRememberMe(e.target.checked)}
//                     style={{ accentColor: theme.primaryColor }}
//                   />
//                   <span className="text-base xs:text-xs"
//                     style={{ 
//                       color: theme.textColor, fontFamily: theme.fontFamily }}>
//                       Remember me
//                   </span>
//                 </label>
//                 <button
//                   onClick={handleForgotPassword}
//                   className="text-pink-500 xs:text-xs underline"
//                   style={{ fontFamily: theme.fontFamily }}
//                 >
//                   Forget password?
//                 </button>
//               </motion.div>
//               <motion.div
//                 className="mb-1"
//                 initial={{ x: -50, opacity: 0 }}
//                 animate={{ x: 0, opacity: 1 }}
//                 transition={{ duration: 0.5, delay: 1.4 }}
//               >
//                 <button
//                   type="submit"
//                   className="w-full py-3 rounded xs:py-2 xs:text-sm  bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//                   style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//                 >
//                   Sign in
//                 </button>
//               </motion.div>
//             </form>
//             <motion.p
//               className="text-center xs:text-xs text-gray-600"
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//               initial={{ y: 20, opacity: 0 }}
//               animate={{ y: 0, opacity: 1 }}
//               transition={{ duration: 0.5, delay: 1.6 }}
//             >
//               First time Sign in?
//               <button
//                 onClick={handleOpenSetupPassword}
//                 className="text-pink-500 underline"
//                 style={{ fontFamily: theme.fontFamily }}
//               >
//                 Register here
//               </button>
//             </motion.p>
//           </motion.div>
//         </motion.div>
//       </div>
//       <div className="flex-shrink-0 w-full z-10">
//         <AppFooter />
//       </div>
//       <ResetPassword visible={isResetModalVisible} onClose={handleCloseResetModal} />
//       <SetupPassword visible={isSetupPasswordVisible} onClose={handleCloseSetupPassword} />
//       <SuccessModal
//         visible={isSuccessModalVisible}
//         onClose={() => setIsSuccessModalVisible(false)}
//         message="Sign In Successful!"
//       />
//       <ErrorModal visible={isErrorModalVisible} onClose={handleCloseErrorModal} />
//     </div>
//   );
// };

// export default LoginView;