// src/contexts/FundedRuleTerm.tsx

import React from 'react';

interface FundedRuleTermProps {
    onClose: () => void;
  }

const FundedRuleTerm: React.FC<FundedRuleTermProps> = ({ onClose }) => {
  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="bg-white rounded-lg p-20 relative w-auto max-w-[800px] max-h-screen">
        <h2 className="text-2xl font-bold mb-4" id="modal-title">Rules and Regulations</h2>
        <div className="overflow-y-auto h-auto max-h-[800px] flex-grow">
          {/* Eligibility Criteria */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">1. Eligibility Criteria</h3><br />
            <p className="mx-2"><strong>1.1 Age Requirement:</strong><br />Participants must be at least 18 years old.</p><br />
            <p className="mx-2"><strong>1.2 Legal Capacity:</strong><br />Participants must have the legal capacity to enter into these Rules and engage in trading activities within their jurisdiction.</p><br />
            <p className="mx-2"><strong>1.3 Geographical Restrictions:</strong><br />The Program is open to residents of [specify allowed countries]. Participants from restricted jurisdictions are not eligible to apply.</p><br />
            <p className="mx-2"><strong>1.4 Account Requirements:</strong><br />Participants must have a valid trading account with [specify broker/platform if applicable] unless otherwise instructed.</p><br />
          </section>

          {/* Application and Registration */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">2. Application and Registration</h3><br />
            <p className="mx-2"><strong>2.1 Application Process:</strong><br />Participants must complete the online registration form accurately and provide all required information. Incomplete or misleading applications may be rejected.</p><br />
            <p className="mx-2"><strong>2.2 Verification:</strong><br />Participants may be required to undergo identity verification and provide additional documentation as part of the registration process.</p><br />
            <p className="mx-2"><strong>2.3 Acceptance:</strong><br />Submission of an application does not guarantee acceptance into the Program. Acceptance is at the sole discretion of [Your Company Name] ("Provider," "We," "Us," or "Our").</p><br />
          </section>

          {/* Evaluation Phase */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">3. Evaluation Phase</h3>
            <p className="mx-2"><strong>3.1 Objective:</strong><br />The evaluation phase assesses the participant’s trading skills, risk management, and adherence to Program rules.</p><br />
            <p className="mx-2"><strong>3.2 Duration:</strong><br />The evaluation period lasts for [insert duration, e.g., 30 days], during which participants must meet the required performance standards.</p><br />
            <p className="mx-2"><strong>3.3 Performance Metrics:</strong><br />Participants must achieve the following during the evaluation phase:</p>
            <ul className="list-disc list-inside mb-2 mx-4">
              <li><strong>Profit Target:</strong><br />Achieve a minimum profit of [insert percentage, e.g., 10%] on the allocated evaluation capital.</li>
              <li><strong>Drawdown Limit:</strong><br />Maintain a maximum drawdown of [insert percentage, e.g., 5%].</li>
              <li><strong>Consistency:</strong><br />Demonstrate consistent trading performance without significant violations of trading rules.</li>
            </ul><br />
            <p><strong>3.4 Compliance:</strong><br />Participants must strictly adhere to all trading rules and guidelines outlined in these Rules. Non-compliance may result in immediate disqualification.</p><br />

            <p><strong>3.5 Outcome:</strong></p>
            <ul className="list-disc list-inside mx-4">
              <li><strong>Successful Completion:</strong>Participants who meet all evaluation criteria will be allocated trading capital.</li>
              <li><strong>Failure to Meet Criteria:</strong> Participants who fail to meet the evaluation criteria will be disqualified from the Program.</li>
            </ul>
          </section>

          {/* Trading Rules */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">4. Trading Rules</h3><br />
            <p className="mx-2"><strong>4.1 Permitted Instruments:</strong><br />Participants may trade the following financial instruments:</p>
            <ul className="list-disc list-inside mb-2 mx-4">
              <li>Forex</li>
              <li>Stocks</li>
              <li>Commodities</li>
              <li>Cryptocurrencies</li>
            </ul><br />

            <p><strong>4.2 Leverage and Position Sizing:</strong></p>
            <ul className="list-disc list-inside mb-2 mx-4">
              <li><strong>Leverage:</strong> Trading leverage must not exceed [insert maximum leverage, e.g., 1:10].</li>
              <li><strong>Position Sizing:</strong><br />Individual trade sizes must not exceed [insert maximum position size, e.g., 2% of total capital].</li>
            </ul><br />
            <p className="mx-2"><strong>4.3 Profit Targets:</strong><br />Participants must achieve a minimum profit target of [insert percentage, e.g., 10%] during the evaluation phase to qualify for funding.</p><br />
            <p className="mx-2"><strong>4.4 Drawdown Limits:</strong><br />The maximum allowable drawdown is [insert percentage, e.g., 5%]. Exceeding this limit may lead to immediate disqualification.</p><br />
            <p className="mx-2"><strong>4.5 Trading Hours:</strong><br />Participants must conduct trades within the designated trading hours: [specify if applicable, e.g., 24/5 trading].</p><br />
            <p className="mx-2"><strong>4.6 Prohibited Activities:</strong></p>
            <ul className="list-disc list-inside mb-2 mx-4">
              <li><strong>Market Manipulation:</strong> Any form of market manipulation is strictly prohibited.</li>
              <li><strong>Automated Trading:</strong> Use of automated trading systems without prior approval is forbidden.</li>
              <li><strong>Non-Compliance:</strong> Violating any trading rules will result in disqualification.</li>
            </ul><br />
            <p className="mx-2"><strong>4.7 Trading Style:</strong><br />Participants may adopt any trading style (e.g., day trading, swing trading) as long as it complies with the Program’s risk management rules.</p>
          </section>

          {/* Risk Management */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">5. Risk Management</h3><br />
            <p className="mx-2"><strong>5.1 Risk Per Trade:</strong><br />Participants must limit risk per trade to a maximum of [insert percentage, e.g., 2%] of their allocated capital.</p><br />
            <p className="mx-2"><strong>5.2 Maximum Concurrent Positions:</strong><br />Participants may hold no more than [insert number, e.g., 5] concurrent open positions at any given time.</p><br />
            <p className="mx-2"><strong>5.3 Stop-Loss Orders:</strong><br />Use of stop-loss orders is mandatory for all trades to manage and mitigate potential losses.</p><br />
            <p className="mx-2"><strong>5.4 Daily Loss Limit:</strong><br />Participants must not exceed a daily loss limit of [insert percentage, e.g., 3%]. Exceeding this limit may result in suspension or disqualification.</p><br />
          </section>

          {/* Funding and Capital Allocation */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">6. Funding and Capital Allocation</h3><br />
            <p className="mx-2"><strong>6.1 Allocated Capital:</strong><br />Upon successful evaluation, participants will receive trading capital of [insert amount, e.g., $50,000].</p><br />
            <p className="mx-2"><strong>6.2 Use of Funds:</strong><br />Allocated funds must be used solely for trading within the Program’s permitted instruments and according to the established guidelines.</p><br />
            <p className="mx-2"><strong>6.3 Profit Sharing:</strong><br />Profits generated from trading will be shared as follows:</p>
            <ul className="list-disc list-inside mb-2 mx-4">
              <li><strong>Participant:</strong> [insert percentage, e.g., 80%]</li>
              <li><strong>Provider:</strong> [insert percentage, e.g., 20%]</li>
            </ul><br />
            <p className="mx-2"><strong>6.4 Loss Responsibility:</strong><br />Participants are responsible for all trading losses. The Provider does not cover any losses incurred.</p><br />
          </section>

          {/* Profit Withdrawal and Payouts */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">7. Profit Withdrawal and Payouts</h3><br />
            <p className="mx-2"><strong>7.1 Withdrawal Eligibility:</strong><br />Participants may request withdrawal of profits once a minimum profit threshold of [insert amount or percentage] is achieved.</p><br />
            <p className="mx-2"><strong>7.2 Processing Time:</strong><br />Withdrawal requests will be processed within [insert time frame, e.g., 7 business days].</p><br />
            <p className="mx-2"><strong>7.3 Payment Methods:</strong><br />Payouts will be made via [specify payment methods, e.g., bank transfer, PayPal].</p><br />
            <p className="mx-2"><strong>7.4 Withdrawal Limits:</strong><br />There may be limits on the frequency and amount of withdrawals as detailed in the Program guidelines.</p><br />
          </section>

          {/* Compliance and Monitoring */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">8. Compliance and Monitoring</h3><br />
            <p className="mx-2"><strong>8.1 Monitoring:</strong> The Provider reserves the right to monitor all trading activities to ensure compliance with these Rules.</p><br />
            <p className="mx-2"><strong>8.2 Reporting:</strong> Participants must provide accurate and timely reports of their trading activities as required by the Program.</p><br />
            <p className="mx-2"><strong>8.3 Audits:</strong> The Provider may conduct audits of participants’ trading accounts to verify compliance with Program rules.</p><br />
          </section>

          {/* Disqualification */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">9. Disqualification</h3><br />
            <p className="mx-2"><strong>9.1 Immediate Disqualification:</strong><br />Participants may be immediately disqualified for:</p>
            <ul className="list-disc list-inside mb-2 mx-4">
              <li>Exceeding the maximum drawdown limit.</li>
              <li>Engaging in prohibited activities.</li>
              <li>Failing to adhere to trading rules and guidelines.</li>
            </ul><br />
            <p className="mx-2"><strong>9.2 Review Process:</strong><br />Disqualified participants may have their cases reviewed upon request, but the final decision rests with the Provider.</p><br />
            <p className="mx-2"><strong>9.3 Effects of Disqualification:</strong><br />Upon disqualification, participants will forfeit any allocated trading capital and may be barred from reapplying to the Program.</p><br />
          </section>

          {/* Termination of Participation */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">10. Termination of Participation</h3><br />
            <p className="mx-2"><strong>10.1 By Participant:</strong><br />Participants may terminate their participation by [specify process, e.g., contacting support].</p><br />
            <p className="mx-2"><strong>10.2 By Provider:</strong><br />The Provider may terminate a Participant’s involvement for reasons including, but not limited to, non-compliance, fraudulent activity, or breach of Rules.</p><br />
            <p className="mx-2"><strong>10.3 Post-Termination Obligations:</strong><br />Upon termination, all rights granted under these Rules will cease immediately, and any outstanding obligations must be fulfilled.</p><br />
          </section>

          {/* Confidentiality and Intellectual Property */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">11. Confidentiality and Intellectual Property</h3><br />
            <p className="mx-2"><strong>11.1 Confidential Information:</strong><br />Participants must keep all Program-related information confidential and not disclose it to third parties.</p><br />
            <p className="mx-2"><strong>11.2 Intellectual Property:</strong><br />All materials, content, and intellectual property related to the Program are owned by the Provider. Participants are granted a limited, 
              non-transferable license to use Program materials solely for personal, non-commercial purposes.</p><br />
            <p className="mx-2"><strong>11.3 Prohibited Uses:</strong><br />Participants may not reproduce, distribute, or create derivative works from Program materials without explicit permission.</p><br />
          </section>

          {/* Liability and Indemnification */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">12. Liability and Indemnification</h3><br />
            <p className="mx-2"><strong>12.1 Limitation of Liability:</strong><br />The Provider is not liable for any direct, indirect, incidental, or consequential damages arising from participation in the Program.</p><br />
            <p className="mx-2"><strong>12.2 Indemnification:</strong><br />Participants agree to indemnify and hold harmless the Provider from any claims, damages, liabilities, and expenses arising from their participation in the Program.</p><br />
          </section>

          {/* Amendments to Rules */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">13. Amendments to Rules</h3><br />
            <p className="mx-2"><strong>13.1 Modification of Rules:</strong><br />The Provider reserves the right to modify, suspend, or terminate the Program Rules at any time without prior notice.</p><br />
            <p className="mx-2"><strong>13.2 Notification:</strong><br />Participants will be notified of significant changes through [specify communication channels, e.g., email, website].</p><br />
            <p className="mx-2"><strong>13.3 Acceptance of Changes:</strong><br />Continued participation after changes constitute acceptance of the modified Rules.</p><br />
          </section>

          {/* Governing Law and Dispute Resolution */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">14. Governing Law and Dispute Resolution</h3><br />
            <p className="mx-2"><strong>14.1 Governing Law:</strong><br />These Rules are governed by and construed in accordance with the laws of [Your Jurisdiction].</p><br />
            <p className="mx-2"><strong>14.2 Dispute Resolution:</strong><br />Any disputes arising from these Rules or the Program shall be resolved through [specify method, e.g., arbitration, courts of specific jurisdiction].</p><br />
            <p className="mx-2"><strong>14.3 Jurisdiction:</strong><br />Participants agree to submit to the exclusive jurisdiction of the courts located in [Your Jurisdiction].</p><br />
          </section>

          {/* Miscellaneous */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">15. Miscellaneous</h3><br />
            <p className="mx-2"><strong>15.1 Entire Agreement:</strong><br />These Rules constitute the entire agreement between the Participant and the Provider regarding the Program.</p><br />
            <p className="mx-2"><strong>15.2 Severability:</strong><br />If any provision of these Rules is found to be unenforceable, the remaining provisions will continue in full force and effect.</p><br />
            <p className="mx-2"><strong>15.3 Waiver:</strong><br />Failure to enforce any right or provision of these Rules does not constitute a waiver of such right or provision.</p><br />
          </section>

          {/* Acknowledgment */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">16. Acknowledgment</h3>
            <p className="mx-2"> By registering for and participating in the R One Funded Trading Program, you acknowledge that you 
                have read, understood, and agree to be bound by these Rules and Regulations, as well as any additional 
                guidelines and policies referenced herein.
            </p><br />
          </section>
        </div>

        <div className="flex justify-end mt-4">
          <button 
            className="bg-cyan-800 text-white text-xl font-bold rounded-full px-8 py-2" 
            onClick={onClose}
            aria-label="Close"
          >
            Close 
          </button>
        </div>
      </div>
    </div>
  );
};

export default FundedRuleTerm;
