// src/components/admin/FundedProgram/FundProgramSetting.tsx

import React, { useState, useEffect } from 'react';
import { IoIosCloseCircle } from "react-icons/io";
import { TbLayoutSidebarRightExpand, TbLayoutSidebarLeftExpandFilled   } from "react-icons/tb";
import useFundProgramSetting from '../../../hooks/AdminDashboard/useFundProgramSetting';
import FundProgramSettingModal from './FundProgramSettingModal';

const FundProgramSetting: React.FC = () => {
  const { fetchPrograms, addProgram, updateProgram, deletePrograms, programs, loading } = useFundProgramSetting();
  const [newProgram, setNewProgram] = useState({
    fundNameSet: '',
    fundDescriptionSet: '',
    fundStepSet: '',
    fundSizeSet: '',
    fundFeatureSet: {},
    fundStatusSet: 'Running',
    fundAmountSet: 0,
  });
  // add new setting state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  // selected row data state
  const [selectedFeatures, setSelectedFeatures] = useState<{ [key: string]: string }>({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set()); 
  // edit setting state
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | {
      target: {
        name: string;
        value: { [key: string]: string } | string | number;
      };
    }
  ) => {
    const { name, value } = e.target;
    setNewProgram((prev) => ({
      ...prev,
      [name]: name === 'fundAmountSet'
        ? Number(value)
        : name === 'fundFeatureSet' && typeof value === 'object'
          ? { ...prev.fundFeatureSet, ...value }
          : value,
    }));
  };
  
  const handleAddProgram = async (updatedProgram: typeof newProgram) => {    
    await addProgram(updatedProgram);
    // Resetting the state
    setNewProgram({
      fundNameSet: '',
      fundDescriptionSet: '',
      fundStepSet: '',
      fundSizeSet: '',
      fundFeatureSet: {},
      fundStatusSet: 'Running',
      fundAmountSet: 0,
    });
    setIsModalOpen(false);
  };
  
  const handleRemoveSettings = async () => {
    if (selectedRows.size === 0) {
      alert('Please select at least one program to remove');
      return;
    }

    if (window.confirm('Are you sure you want to remove the selected programs?')) {
      await deletePrograms(Array.from(selectedRows), () => {
        setSelectedRows(new Set()); 
        setIsExpanded(false); 
      });
    }
  };

  const handleViewFeatures = (features: { [key: string]: string }) => {
    setSelectedFeatures(features);
    setFeatureModalOpen(true);
  };
  
  const handleRowSelect = (fundSetId: string) => {
    setSelectedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(fundSetId)) {
        newSet.delete(fundSetId);
      } else {
        newSet.add(fundSetId);
      }
      return newSet;
    });
  };
    
  const handleColumnExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setSelectedRows(new Set());
    }
  };

  // Update handleRowDoubleClick function
  const handleRowDoubleClick = (program: typeof newProgram) => {
    setNewProgram({
      ...program,
      fundFeatureSet: program.fundFeatureSet || {}
    });
    setIsEdit(true);
    setIsModalOpen(true);
  };

  // Add handleUpdateProgram function
  const handleUpdateProgram = async (fundSetId: string, updatedProgram: typeof newProgram) => {
    try {
      await updateProgram(fundSetId, {
        ...updatedProgram,
        fundFeatureSet: updatedProgram.fundFeatureSet || {},
      });
      setIsModalOpen(false);
      setIsEdit(false);
      setNewProgram({
        fundNameSet: '',
        fundDescriptionSet: '',
        fundStepSet: '',
        fundSizeSet: '',
        fundFeatureSet: {},
        fundStatusSet: 'Running',
        fundAmountSet: 0,
      });
      await fetchPrograms(); // Refresh the data
    } catch (error) {
      console.error('Failed to update program:', error);
    }
  };

  return (
    <div className="p-4 border rounded">
      {/* toggle checkbox button */}
      <div className="flex justify-end items-center space-x-2 mr-14">
        <div className="mb-2">
          <button
            className="text-cyan-800 text-4xl"
            onClick={handleColumnExpand}
          >
            {isExpanded ? <TbLayoutSidebarLeftExpandFilled  /> : <TbLayoutSidebarRightExpand />}
          </button>
        </div>

        {/* remove setting button */}
        <div className="mb-4">
          <button
            className={`bg-cyan-800 text-white px-6 py-1 rounded ${
              !isExpanded || selectedRows.size === 0 ? 'bg-gray-400 text-gray-200 opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleRemoveSettings}
            disabled={!isExpanded || selectedRows.size === 0}
          >
            Remove Setting
          </button>
        </div>

        {/* add setting button */}
        <div className="mb-4">
          <button
            className="bg-cyan-800 text-white px-6 py-1 rounded"
            onClick={() => setIsModalOpen(true)}
          >
            Add Setting
          </button>
        </div>
      </div>

      {/* Modal */}
      <FundProgramSettingModal
        isOpen={isModalOpen}
        newProgram={newProgram}
        onClose={() => {
          setIsModalOpen(false);
          setIsEdit(false);
          setNewProgram({
            fundNameSet: '',
            fundDescriptionSet: '',
            fundStepSet: '',
            fundSizeSet: '',
            fundFeatureSet: {},
            fundStatusSet: 'Running',
            fundAmountSet: 0,
          });
        }}
        onSave={handleAddProgram}
        onChange={handleInputChange}
        isEdit={isEdit}
        onUpdate={handleUpdateProgram}
      />

      {/* Feature Modal */}
      {featureModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-10 w-[400px] relative">
            <button
              className="absolute top-12 right-10 text-rose-500"
              onClick={() => setFeatureModalOpen(false)}
            >
              <IoIosCloseCircle />
            </button>
            <h2 className="text-2xl text-cyan-800 font-bold mb-4">
              Features
            </h2>
            <div className="flex flex-col">
              {Object.entries(selectedFeatures).map(([key, value]) => (
                <div key={key} className="mb-2 flex justify-between">
                  <strong>{key}:</strong> {value}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Program Table */}
      <div className="overflow-y-auto h-auto max-h-[220px] justify-self-center border">
        <table className="min-w-full bg-white border text-sm text-nowrap">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              {isExpanded && (
                <th className="py-2 px-4 border-b w-12">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRows(new Set(programs.map(p => p.fundSetId).filter(Boolean) as string[]));
                      } else {
                        setSelectedRows(new Set());
                      }
                    }}
                    checked={programs.length > 0 && selectedRows.size === programs.length}
                  />
                </th>
              )}
              <th className="py-2 px-4 border-b">Fund Id</th>
              <th className="py-2 px-4 border-b">Fund Name</th>
              <th className="py-2 px-4 border-b">Fund Description</th>
              <th className="py-2 px-4 border-b">Fund Step</th>
              <th className="py-2 px-4 border-b">Fund Size (USD)</th>
              <th className="py-2 px-4 border-b">Fund Features</th>
              <th className="py-2 px-4 border-b">Fund Status</th>
              <th className="py-2 px-4 border-b">Fund Amount (USD)</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={8} className="text-center py-8">Loading...</td>
              </tr>
            ) : programs && programs.length > 0 ? (
              programs.map((program) => (
                <tr 
                  key={program.fundSetId || ''} 
                  className={`text-center cursor-pointer ${program.fundSetId && selectedRows.has(program.fundSetId) ? 'bg-gray-100' : ''}`}
                  onDoubleClick={() => handleRowDoubleClick(program)}

                >
                  {isExpanded && (
                    <td className="py-2 px-4 text-nowrap">
                      <input
                        type="checkbox"
                        className="accent-cyan-800"
                        checked={program.fundSetId ? selectedRows.has(program.fundSetId) : false}
                        onChange={() => program.fundSetId && handleRowSelect(program.fundSetId)}
                      />
                    </td>
                  )}
                  <td className="py-2 px-4 text-nowrap">{program.fundSetId}</td>
                  <td className="py-2 px-4 text-nowrap">{program.fundNameSet}</td>
                  <td className="py-2 px-4 text-nowrap">{program.fundDescriptionSet}</td>
                  <td className="py-2 px-4 text-nowrap">{program.fundStepSet}</td>
                  <td className="py-2 px-4 text-nowrap">{program.fundSizeSet}</td>
                  <td className="py-2 px-4 text-nowrap">
                    <button
                      className="text-blue-600 underline"
                      onClick={() => handleViewFeatures(program.fundFeatureSet)}
                    >
                      View Feature
                    </button>
                  </td>
                  <td className="py-2 px-4 text-nowrap">{program.fundStatusSet}</td>
                  <td className="py-2 px-4 text-nowrap">{program.fundAmountSet}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={isExpanded ? 9 : 8} className="text-center py-8">No Programs Available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FundProgramSetting;

