// src/hooks/AdminDashboard/useAdminRoles.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface AdminUser {
  roleId: string;
  name: string;
  email: string;
  password: string;
  role: string;
  roleCustom?: string;
  roleAssign: string;
  status: string;
}

export interface AssignUser {
  assignId: string;
  assignAccount: string;
  assignMember: string;
  assignGroup: string;
  assignPermission: string;
  assignStatus: string;
}

export const useAdminUsers = () => {
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [assignUsers, setAssignUsers] = useState<AssignUser[]>([]);
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();

  // Function to get default permissions based on the selected role
  const getDefaultPermissions = (role: string) => {
    switch (role) {
      case 'admin':
        return {
          fullAccess: true,
          overview: false,
          userData: false,
          emailSchedule: false,
          eventSetting: false,
          eventNotification: false,
          productSubscription: false,
          fundedProgram: false,
          videoManage: false,
          adminSetting: false,
        };
      case 'manager':
        return {
          fullAccess: false,
          overview: false,
          userData: true,
          emailSchedule: true,
          eventSetting: true,
          eventNotification: true,
          productSubscription: true,
          fundedProgram: true,
          videoManage: true,
          adminSetting: false,
        };
      case 'marketing':
        return {
          fullAccess: false,
          overview: true,
          userData: true,
          emailSchedule: true,
          eventSetting: true,
          eventNotification: false,
          productSubscription: false,
          fundedProgram: false,
          videoManage: false,
          adminSetting: false,
        };
      case 'sale':
        return {
          fullAccess: false,
          overview: true,
          userData: true,
          emailSchedule: false,
          eventSetting: false,
          eventNotification: false,
          productSubscription: false,
          fundedProgram: false,
          videoManage: false,
          adminSetting: false,
        };
      case 'support':
        return {
          fullAccess: false,
          overview: false,
          userData: true,
          emailSchedule: false,
          eventSetting: false,
          eventNotification: false,
          productSubscription: false,
          fundedProgram: false,
          videoManage: false,
          adminSetting: false,
        };
      case 'assistant':
        return {
          fullAccess: false,
          overview: false,
          userData: true,
          emailSchedule: false,
          eventSetting: false,
          eventNotification: false,
          productSubscription: false,
          fundedProgram: false,
          videoManage: false,
          adminSetting: false,
        };
      default:
        return {
          fullAccess: false,
          overview: false,
          userData: false,
          emailSchedule: false,
          eventSetting: false,
          eventNotification: false,
          productSubscription: false,
          fundedProgram: false,
          videoManage: false,
          adminSetting: false,
        };
      }
    };


  // Fetch user emails for the custom group modal
  const fetchUserEmails = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/users`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch user emails');
      }
  
      const data = await response.json();
  
      if (data.emails && Array.isArray(data.emails)) {
        setUserEmails(data.emails);
      } else {
        throw new Error('Invalid email data format');
      }
    } catch (error) {
      console.error('Error fetching user emails:', error);
    }
  }, [apiUrl]);  

  const searchUserInfo = useCallback(async (searchTerm: string) => {
    try {
      const response = await fetch(`${apiUrl}/users/search?term=${encodeURIComponent(searchTerm)}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch user info');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user info:', error);
      return [];
    }
  }, [apiUrl]);

  // Fetch admin users
  const fetchAdminUsers = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/admin-users`);
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setAdminUsers(data);
    } catch (err: any) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  const addAdminUser = async (formData: any) => {
    // console.log('Data sent to add user API:', formData); 
    const response = await fetch(`${apiUrl}/admin-users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      throw new Error('Failed to add user');
    }
  }; 
  
  const updateAdminUser = async (formData: AdminUser) => {
    // console.log('Data sent to update user API:', formData);
    const response = await fetch(`${apiUrl}/admin-users/${formData.roleId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
  
    if (!response.ok) {
      throw new Error('Failed to update user');
    }
  }; 
  
  // Delete selected admin users
  const deleteAdminUsers = useCallback(async (roleIds: string[]) => {
    try {
      await Promise.all(
        roleIds.map(async (roleId) => {
          const response = await fetch(`${apiUrl}/admin-users/${roleId}`, {
            method: 'DELETE',
          });
          if (!response.ok) {
            throw new Error('Failed to delete user');
          }
        })
      );
      fetchAdminUsers(); 
    } catch (err: any) {
      setError(err.message || 'An error occurred during deletion');
    }
  }, [apiUrl, fetchAdminUsers]);

  // --------------------------------- fetch assign users -------------------------------- //
  // Fetch assigned users
  const fetchAssignUsers = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/assign-users`);
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setAssignUsers(data);
    } catch (err: any) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  // Assign members to an admin account
  const assignMembers = async (formData: any) => {
    try {
      const response = await fetch(`${apiUrl}/assign-users`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        const errorText = await response.text(); 
        throw new Error(`Failed to assign members: ${errorText}`);
      }

    } catch (error) {
      console.error("Error in assignMembers:", error);
      throw error;
    }
  };

  const updateAssignUser = async (formData: AssignUser) => {
    // console.log("Updating assign user with assignId:", formData.assignId); 
    
    const response = await fetch(`${apiUrl}/assign-users/${formData.assignId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
  
    if (!response.ok) {
      throw new Error('Failed to update assigned member');
    }
  };

  // Function to delete selected assigned users
  const deleteAssignUsers = useCallback(async (assignIds: string[]) => {
    try {
      await Promise.all(
        assignIds.map(async (assignId) => {
          const response = await fetch(`${apiUrl}/assign-users/${assignId}`, {
            method: 'DELETE',
          });
          if (!response.ok) {
            throw new Error(`Failed to delete assigned user with ID ${assignId}`);
          }
        })
      );
      // Refetch the updated list of assigned users after deletion
      await fetchAssignUsers();
    } catch (err: any) {
      console.error('Error deleting assigned users:', err.message || err);
    }
  }, [apiUrl, fetchAssignUsers]);

  useEffect(() => {
    fetchAdminUsers();
    fetchAssignUsers();
    fetchUserEmails();
  }, [fetchAdminUsers, fetchAssignUsers, fetchUserEmails]);

  return { 
    adminUsers, assignUsers, userEmails, searchUserInfo,
    loading, error,
    fetchUserEmails, getDefaultPermissions,
    fetchAdminUsers, fetchAssignUsers, 
    assignMembers, deleteAssignUsers, updateAssignUser,
    deleteAdminUsers, addAdminUser, updateAdminUser };
};

// // udpate code --> 14 jan 2024
// // src/hooks/AdminDashboard/useAdminRoles.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface AdminUser {
//   roleId: string;
//   name: string;
//   email: string;
//   password: string;
//   role: string;
//   roleCustom?: string;
//   roleAssign: string;
//   status: string;
// }

// export interface AssignUser {
//   assignId: string;
//   assignAccount: string;
//   assignMember: string;
//   assignGroup: string;
//   assignPermission: string;
//   assignStatus: string;
// }

// export const useAdminUsers = () => {
//   const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
//   const [assignUsers, setAssignUsers] = useState<AssignUser[]>([]);
//   const [userEmails, setUserEmails] = useState<string[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   // Function to get default permissions based on the selected role
//   const getDefaultPermissions = (role: string) => {
//     switch (role) {
//       case 'admin':
//         return {
//           fullAccess: true,
//           overview: false,
//           userData: false,
//           emailSchedule: false,
//           eventSetting: false,
//           eventNotification: false,
//           productSubscription: false,
//           fundedProgram: false,
//           videoManage: false,
//           adminSetting: false,
//         };
//       case 'manager':
//         return {
//           fullAccess: false,
//           overview: false,
//           userData: true,
//           emailSchedule: true,
//           eventSetting: true,
//           eventNotification: true,
//           productSubscription: true,
//           fundedProgram: true,
//           videoManage: true,
//           adminSetting: false,
//         };
//       case 'marketing':
//         return {
//           fullAccess: false,
//           overview: true,
//           userData: true,
//           emailSchedule: true,
//           eventSetting: true,
//           eventNotification: false,
//           productSubscription: false,
//           fundedProgram: false,
//           videoManage: false,
//           adminSetting: false,
//         };
//       case 'sale':
//         return {
//           fullAccess: false,
//           overview: true,
//           userData: true,
//           emailSchedule: false,
//           eventSetting: false,
//           eventNotification: false,
//           productSubscription: false,
//           fundedProgram: false,
//           videoManage: false,
//           adminSetting: false,
//         };
//       case 'support':
//         return {
//           fullAccess: false,
//           overview: false,
//           userData: true,
//           emailSchedule: false,
//           eventSetting: false,
//           eventNotification: false,
//           productSubscription: false,
//           fundedProgram: false,
//           videoManage: false,
//           adminSetting: false,
//         };
//       case 'assistant':
//         return {
//           fullAccess: false,
//           overview: false,
//           userData: true,
//           emailSchedule: false,
//           eventSetting: false,
//           eventNotification: false,
//           productSubscription: false,
//           fundedProgram: false,
//           videoManage: false,
//           adminSetting: false,
//         };
//       default:
//         return {
//           fullAccess: false,
//           overview: false,
//           userData: false,
//           emailSchedule: false,
//           eventSetting: false,
//           eventNotification: false,
//           productSubscription: false,
//           fundedProgram: false,
//           videoManage: false,
//           adminSetting: false,
//         };
//       }
//     };


//   // Fetch user emails for the custom group modal
//   const fetchUserEmails = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/users`);
      
//       if (!response.ok) {
//         throw new Error('Failed to fetch user emails');
//       }
  
//       const data = await response.json();
  
//       if (data.emails && Array.isArray(data.emails)) {
//         setUserEmails(data.emails);
//       } else {
//         throw new Error('Invalid email data format');
//       }
//     } catch (error) {
//       console.error('Error fetching user emails:', error);
//     }
//   }, [apiUrl]);  

//   // Fetch admin users
//   const fetchAdminUsers = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/admin-users`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch users');
//       }
//       const data = await response.json();
//       setAdminUsers(data);
//     } catch (err: any) {
//       setError(err.message || 'An error occurred');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const addAdminUser = async (formData: any) => {
//     // console.log('Data sent to add user API:', formData); 
//     const response = await fetch(`${apiUrl}/admin-users`, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(formData),
//     });
//     if (!response.ok) {
//       throw new Error('Failed to add user');
//     }
//   }; 
  
//   const updateAdminUser = async (formData: AdminUser) => {
//     // console.log('Data sent to update user API:', formData);
//     const response = await fetch(`${apiUrl}/admin-users/${formData.roleId}`, {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(formData),
//     });
  
//     if (!response.ok) {
//       throw new Error('Failed to update user');
//     }
//   }; 
  
//   // Delete selected admin users
//   const deleteAdminUsers = useCallback(async (roleIds: string[]) => {
//     try {
//       await Promise.all(
//         roleIds.map(async (roleId) => {
//           const response = await fetch(`${apiUrl}/admin-users/${roleId}`, {
//             method: 'DELETE',
//           });
//           if (!response.ok) {
//             throw new Error('Failed to delete user');
//           }
//         })
//       );
//       fetchAdminUsers(); 
//     } catch (err: any) {
//       setError(err.message || 'An error occurred during deletion');
//     }
//   }, [apiUrl, fetchAdminUsers]);

//   // --------------------------------- fetch assign users -------------------------------- //
//   // Fetch assigned users
//   const fetchAssignUsers = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/assign-users`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch users');
//       }
//       const data = await response.json();
//       setAssignUsers(data);
//     } catch (err: any) {
//       setError(err.message || 'An error occurred');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   // Assign members to an admin account
//   const assignMembers = async (formData: any) => {
//     try {
//       const response = await fetch(`${apiUrl}/assign-users`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(formData),
//       });
//       if (!response.ok) {
//         const errorText = await response.text(); 
//         throw new Error(`Failed to assign members: ${errorText}`);
//       }

//     } catch (error) {
//       console.error("Error in assignMembers:", error);
//       throw error;
//     }
//   };

//   const updateAssignUser = async (formData: AssignUser) => {
//     // console.log("Updating assign user with assignId:", formData.assignId); 
    
//     const response = await fetch(`${apiUrl}/assign-users/${formData.assignId}`, {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(formData),
//     });
  
//     if (!response.ok) {
//       throw new Error('Failed to update assigned member');
//     }
//   };

//   // Function to delete selected assigned users
//   const deleteAssignUsers = useCallback(async (assignIds: string[]) => {
//     try {
//       await Promise.all(
//         assignIds.map(async (assignId) => {
//           const response = await fetch(`${apiUrl}/assign-users/${assignId}`, {
//             method: 'DELETE',
//           });
//           if (!response.ok) {
//             throw new Error(`Failed to delete assigned user with ID ${assignId}`);
//           }
//         })
//       );
//       // Refetch the updated list of assigned users after deletion
//       await fetchAssignUsers();
//     } catch (err: any) {
//       console.error('Error deleting assigned users:', err.message || err);
//     }
//   }, [apiUrl, fetchAssignUsers]);

//   useEffect(() => {
//     fetchAdminUsers();
//     fetchAssignUsers();
//     fetchUserEmails();
//   }, [fetchAdminUsers, fetchAssignUsers, fetchUserEmails]);

//   return { 
//     adminUsers, assignUsers, userEmails,
//     loading, error,
//     fetchUserEmails, getDefaultPermissions,
//     fetchAdminUsers, fetchAssignUsers, 
//     assignMembers, deleteAssignUsers, updateAssignUser,
//     deleteAdminUsers, addAdminUser, updateAdminUser };
// };