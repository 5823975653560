// src/components/admin/AdminSetting/AdminUserAssignEmail.tsx

import React, { useState, useEffect } from 'react';
import { FaSearchPlus } from "react-icons/fa";
import { useAdminUsers  } from '../../../hooks/AdminDashboard/useAdminRoles'; 

interface AssignAdminEmailProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddEmail: (emailList: string[]) => void; 
  initialEmails?: string[];
}

const AssignAdminEmail: React.FC<AssignAdminEmailProps> = ({ 
  isOpen, 
  onClose, 
  handleAddEmail, 
  initialEmails = [],
}) => {
  const [filteredEmailList, setFilteredEmailList] = useState<string[]>([]); 
  const [selectedEmails, setSelectedEmails] = useState<string[]>(initialEmails);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<{ email: string, label: string, state: string }[]>([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const emailsPerPage = 10;

  // Fetch user emails from the hook
  const { userEmails, searchUserInfo } = useAdminUsers();  

  useEffect(() => {
    // Exclude already selected emails from the available list
    const availableEmails = userEmails.filter((email) => !initialEmails.includes(email));
    setFilteredEmailList(availableEmails);
  }, [userEmails, initialEmails]);
  
  useEffect(() => {
    setSelectedEmails(initialEmails);
  }, [initialEmails]);

  // Handle searching emails
  useEffect(() => {
    const filtered = userEmails.filter((email: string) =>
      email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEmailList(filtered.filter((email) => !selectedEmails.includes(email)));
    setCurrentPage(1);
  }, [searchTerm, userEmails, selectedEmails]);

  const handleSelectEmail = (email: string) => {
    setSelectedEmails([...selectedEmails, email]);
    setFilteredEmailList(filteredEmailList.filter(e => e !== email)); 
  };

  useEffect(() => {
    if (searchTerm) {
      searchUserInfo(searchTerm).then((results) => {
        setSearchResults(results);
      });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, searchUserInfo]);

  const handleRemoveEmail = (email: string) => {
    setFilteredEmailList([...filteredEmailList, email]);
    setSelectedEmails(selectedEmails.filter(e => e !== email));
  };

  const handleSubmit = () => {
    if (selectedEmails.length > 0) {
      handleAddEmail(selectedEmails); 
      onClose(); 
    } else {
      alert("Please enter a group name and add at least one email.");
    }
  };

  // Paginate emails - show 10 at a time
  const paginatedEmails = filteredEmailList.slice(0, currentPage * emailsPerPage);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div className="bg-white w-full max-w-[800px] h-auto p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-cyan-800 mb-4">Add Email User</h2>

        {/* Email selection */}
        <div className="flex flex-col">
          <label className="block text-cyan-800 font-semibold mb-2">Select a Email</label>

          {/* Email content */}
          <div className="flex justify-between space-x-4">
            {/* email list for preview */}
            <div className="w-[500px]">
              <h3 className="text-cyan-800 font-semibold text-xs mb-2 mx-2">
                Search for email info
              </h3>
              {/* Search bar */}
              <div className="flex justify-between items-center mb-2">
                <FaSearchPlus className="text-cyan-800 mr-2"/>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="block w-full px-4 py-1 text-sm text-gray-700 border border-gray-200 rounded"
                  placeholder="Search emails..."
                />
                {/* Load more button */}
                {filteredEmailList.length > currentPage * emailsPerPage && (
                  <button
                    onClick={handleLoadMore}
                    className="text-sm text-white bg-cyan-800 px-4 py-1 rounded ml-2"
                  >
                    Load
                  </button>
                )}
              </div>
              <ul className="border p-2 h-40 overflow-y-auto">
                {searchResults.map((user) => (
                  <li
                    key={user.email}
                    className="bg-gray-100 border border-gray-300 rounded p-3 mb-2 shadow-sm hover:bg-gray-200 cursor-pointer"
                  >
                    <p className="text-sm">
                      <strong>Email:</strong> {user.email}
                    </p>
                    <p className="text-sm">
                      <strong>Label:</strong> {user.label}
                    </p>
                    <p className="text-sm">
                      <strong>State:</strong> {user.state}
                    </p>
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-[400px]">
              <h3 className="text-cyan-800 font-semibold text-xs mb-2 mx-4">Available Users</h3>          
              <ul className="border p-2 h-[200px] overflow-y-auto">
                {paginatedEmails.map((email) => (
                  <li
                    key={email}
                    className="text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleSelectEmail(email)}
                  >
                    {email}
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-[400px]">
              <h3 className="text-cyan-800 font-semibold text-xs mb-2 mx-4">Selected Emails</h3>
              <ul className="border p-2 h-[200px] overflow-y-auto">
                {selectedEmails.map((email) => (
                  <li
                    key={email}
                    className="text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleRemoveEmail(email)}
                  >
                    {email}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4 space-x-2 text-xs">
          <button
            onClick={onClose}
            className="text-cyan-800 px-6 py-1 rounded-lg border border-cyan-800 hover:bg-cyan-800 hover:text-white"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-cyan-800 text-white px-6 py-1 rounded-lg shadow"
          >
            Add Email
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignAdminEmail;

// // udpate code --> 14 jan 2024
// // src/components/admin/AdminSetting/AdminUserAssignEmail.tsx

// import React, { useState, useEffect } from 'react';
// import { useAdminUsers  } from '../../../hooks/AdminDashboard/useAdminRoles'; 

// interface AssignAdminEmailProps {
//   isOpen: boolean;
//   onClose: () => void;
//   handleAddEmail: (emailList: string[]) => void; 
//   initialEmails?: string[];
// }

// const AssignAdminEmail: React.FC<AssignAdminEmailProps> = ({ 
//   isOpen, 
//   onClose, 
//   handleAddEmail, 
//   initialEmails = [],
// }) => {
//   const [filteredEmailList, setFilteredEmailList] = useState<string[]>([]); 
//   const [selectedEmails, setSelectedEmails] = useState<string[]>(initialEmails);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(1); 
//   const emailsPerPage = 10;

//   // Fetch user emails from the hook
//   const { userEmails } = useAdminUsers();  

//   useEffect(() => {
//     // Exclude already selected emails from the available list
//     const availableEmails = userEmails.filter((email) => !initialEmails.includes(email));
//     setFilteredEmailList(availableEmails);
//   }, [userEmails, initialEmails]);
  
//   useEffect(() => {
//     setSelectedEmails(initialEmails);
//   }, [initialEmails]);

//   // Handle searching emails
//   useEffect(() => {
//     const filtered = userEmails.filter((email: string) =>
//       email.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredEmailList(filtered.filter((email) => !selectedEmails.includes(email)));
//     setCurrentPage(1);
//   }, [searchTerm, userEmails, selectedEmails]);

//   const handleSelectEmail = (email: string) => {
//     setSelectedEmails([...selectedEmails, email]);
//     setFilteredEmailList(filteredEmailList.filter(e => e !== email)); 
//   };

//   const handleRemoveEmail = (email: string) => {
//     setFilteredEmailList([...filteredEmailList, email]);
//     setSelectedEmails(selectedEmails.filter(e => e !== email));
//   };

//   const handleSubmit = () => {
//     if (selectedEmails.length > 0) {
//       handleAddEmail(selectedEmails); 
//       onClose(); 
//     } else {
//       alert("Please enter a group name and add at least one email.");
//     }
//   };

//   // Paginate emails - show 10 at a time
//   const paginatedEmails = filteredEmailList.slice(0, currentPage * emailsPerPage);

//   const handleLoadMore = () => {
//     setCurrentPage(currentPage + 1);
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
//       <div className="bg-white w-full max-w-lg h-auto p-6 rounded-lg shadow-lg">
//         <h2 className="text-2xl font-semibold text-cyan-800 mb-4">Add Email User</h2>

//         {/* Email selection */}
//         <div className="flex flex-col">
//           <label className="block text-cyan-800 font-semibold mb-2">Select a Email</label>
//           <div className="flex justify-between space-x-4">
//             <div className="w-1/2">
//               <h3 className="text-cyan-800 font-semibold text-xs mb-2 mx-4">Available Users</h3>
//               {/* Search bar */}
//               <div className="mb-2">
//                 <input
//                   type="text"
//                   value={searchTerm}
//                   onChange={(e) => setSearchTerm(e.target.value)}
//                   className="block w-full px-4 py-1 text-sm text-gray-700 border border-gray-200 rounded"
//                   placeholder="Search emails..."
//                 />
//               </div>
                
//               <ul className="border p-2 h-40 overflow-y-auto">
//                 {paginatedEmails.map((email) => (
//                   <li
//                     key={email}
//                     className="text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
//                     onClick={() => handleSelectEmail(email)}
//                   >
//                     {email}
//                   </li>
//                 ))}
//               </ul>

//               {/* Load more button */}
//               {filteredEmailList.length > currentPage * emailsPerPage && (
//                 <button
//                   onClick={handleLoadMore}
//                   className="mt-2 text-xs text-white bg-cyan-800 px-4 py-1 rounded"
//                 >
//                   Load More
//                 </button>
//               )}
//             </div>

//             <div className="w-1/2">
//               <h3 className="text-cyan-800 font-semibold text-xs mb-2 mx-4">Selected Emails</h3>
//               <ul className="border p-2 h-[198px] overflow-y-auto">
//                 {selectedEmails.map((email) => (
//                   <li
//                     key={email}
//                     className="text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
//                     onClick={() => handleRemoveEmail(email)}
//                   >
//                     {email}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         </div>

//         <div className="flex justify-end mt-4 space-x-2 text-xs">
//           <button
//             onClick={onClose}
//             className="text-cyan-800 px-6 py-1 rounded-lg border border-cyan-800 hover:bg-cyan-800 hover:text-white"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleSubmit}
//             className="bg-cyan-800 text-white px-6 py-1 rounded-lg shadow"
//           >
//             Add Email
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AssignAdminEmail;