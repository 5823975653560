// src/components/admin/ClientData/UsersAddLabel.tsx

import React, { useState, useEffect, useCallback } from 'react';

interface LabelModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (label: string) => void;
  fetchLabels: () => Promise<string[]>; 
  existingLabels: string[]; 
}

const LabelModal: React.FC<LabelModalProps> = ({ isOpen, onClose, onSave, fetchLabels }) => {
  const [selectedLabel, setSelectedLabel] = useState('');
  const [newLabel, setNewLabel] = useState('');
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [existingLabels, setExistingLabels] = useState<string[]>([]);

  const handleSave = () => {
    if (isCreatingNew && newLabel.trim()) {
      onSave(newLabel.trim()); // Save the new label(s)
    } else if (selectedLabel && selectedLabel !== 'create_new') {
      onSave(selectedLabel.trim()); // Save only the selected label
    }
  
    onClose();
    setSelectedLabel('');
    setNewLabel('');
    setIsCreatingNew(false);
  };
  
  
  const fetchAvailableLabels = useCallback(async () => {
    const labels = await fetchLabels();
    const uniqueLabels = Array.from(new Set(labels));
    setExistingLabels(uniqueLabels);
  }, [fetchLabels]);
  
  

  // Add fetchAvailableLabels to the dependency array
  useEffect(() => {
    fetchAvailableLabels();
  }, [fetchAvailableLabels]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4 text-cyan-800">Manage Label</h2>
        <div className="mb-4 space-y-4">
          <select
            value={selectedLabel}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedLabel(value);
              setIsCreatingNew(value === 'create_new');
            }}
            className="w-full border p-2 rounded"
          >
            <option value="">Select a label</option>
            {existingLabels
              .filter(label => label.trim()) 
              .map((label) => (
                <option key={label} value={label}>
                  {label}
                </option>
              ))}
            <option value="create_new">+ Create New Label</option>
          </select>

          <input
            type="text"
            value={newLabel}
            onChange={(e) => setNewLabel(e.target.value)}
            placeholder="Enter new label name"
            className={`w-full border p-2 rounded ${
              isCreatingNew ? '' : 'opacity-50 cursor-not-allowed'
            }`}
            disabled={!isCreatingNew}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 border rounded">
            Cancel
          </button>
          <button onClick={handleSave} className="px-4 py-2 bg-cyan-800 text-white rounded">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default LabelModal;


// // update code --> 13 jan 2024 v03
// // src/components/admin/ClientData/UsersAddLabel.tsx

// import React, { useState, useEffect, useCallback } from 'react';

// interface LabelModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSave: (label: string) => void;
//   fetchLabels: () => Promise<string[]>; 
//   existingLabels: string[]; 
// }

// const LabelModal: React.FC<LabelModalProps> = ({ isOpen, onClose, onSave, fetchLabels }) => {
//   const [selectedLabel, setSelectedLabel] = useState('');
//   const [newLabel, setNewLabel] = useState('');
//   const [isCreatingNew, setIsCreatingNew] = useState(false);
//   const [existingLabels, setExistingLabels] = useState<string[]>([]);

//   const handleSave = () => {
//     let labelToSave = '';
    
//     if (isCreatingNew && newLabel.trim()) {
//       // For new labels, just use the trimmed value without $ prefix
//       labelToSave = newLabel.trim();
//     } else if (selectedLabel && selectedLabel !== 'create_new') {
//       labelToSave = selectedLabel;
//     }
  
//     if (labelToSave) {
//       // Get existing labels and combine with new label
//       const existingLabelArray = existingLabels
//         .filter(l => l.trim())  
//         .map(l => l.trim());  
  
//       // Add new label if it doesn't exist
//       if (!existingLabelArray.includes(labelToSave)) {
//         existingLabelArray.push(labelToSave);
//       }
  
//       // Join all labels with comma and space
//       const finalLabels = existingLabelArray.join(', ');
//       onSave(finalLabels);
//     }
  
//     onClose();
//     setSelectedLabel('');
//     setNewLabel('');
//     setIsCreatingNew(false);
//   };  

//   // Wrap fetchAvailableLabels in useCallback
//   const fetchAvailableLabels = useCallback(async () => {
//     const labels = await fetchLabels(); // Fetch labels from the parent
//     setExistingLabels(labels);
//   }, [fetchLabels]);

//   // Add fetchAvailableLabels to the dependency array
//   useEffect(() => {
//     fetchAvailableLabels();
//   }, [fetchAvailableLabels]);

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg w-96">
//         <h2 className="text-xl font-semibold mb-4 text-cyan-800">Manage Label</h2>
//         <div className="mb-4 space-y-4">
//           <select
//             value={selectedLabel}
//             onChange={(e) => {
//               const value = e.target.value;
//               setSelectedLabel(value);
//               setIsCreatingNew(value === 'create_new');
//             }}
//             className="w-full border p-2 rounded"
//           >
//             <option value="">Select a label</option>
//             {existingLabels.length > 0 ? (
//               existingLabels.map((label) => (
//                 <option key={label} value={label}>
//                   {label}
//                 </option>
//               ))
//             ) : (
//               <option value="" disabled>
//                 No labels available
//               </option>
//             )}
//             <option value="create_new">+ Create New Label</option>
//           </select>

//           <input
//             type="text"
//             value={newLabel}
//             onChange={(e) => setNewLabel(e.target.value)}
//             placeholder="Enter new label name"
//             className={`w-full border p-2 rounded ${
//               isCreatingNew ? '' : 'opacity-50 cursor-not-allowed'
//             }`}
//             disabled={!isCreatingNew}
//           />
//         </div>
//         <div className="flex justify-end space-x-2">
//           <button onClick={onClose} className="px-4 py-2 text-gray-600 border rounded">
//             Cancel
//           </button>
//           <button onClick={handleSave} className="px-4 py-2 bg-cyan-800 text-white rounded">
//             Save
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LabelModal;



// // udpate code --> 13 jan 2024 v02
// // src/components/admin/ClientData/UsersAddLabel.tsx

// import React, { useState } from 'react';

// interface LabelModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSave: (label: string) => void;
//   existingLabels: string[];
// }

// const LabelModal: React.FC<LabelModalProps> = ({ isOpen, onClose, onSave, existingLabels }) => {
//   const [selectedLabel, setSelectedLabel] = useState('');
//   const [newLabel, setNewLabel] = useState('');
//   const [isCreatingNew, setIsCreatingNew] = useState(false);

//   const handleSave = () => {
//     const labelToSave = isCreatingNew ? newLabel.trim() : selectedLabel;
//     if (labelToSave) {
//       onSave(labelToSave);
//     }
//     onClose();
//     setSelectedLabel('');
//     setNewLabel('');
//     setIsCreatingNew(false);
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg w-96">
//         <h2 className="text-xl font-semibold mb-4 text-cyan-800">Manage Label</h2>
//         <div className="mb-4 space-y-4">
//           {/* Dropdown to select an existing label */}
//           <select
//             value={selectedLabel}
//             onChange={(e) => {
//               const value = e.target.value;
//               setSelectedLabel(value);
//               setIsCreatingNew(value === 'create_new');
//             }}
//             className="w-full border p-2 rounded"
//           >
//             <option value="">Select a label</option>
//             {existingLabels.length > 0 ? (
//               existingLabels.map((label) => (
//                 <option key={label} value={label}>
//                   {label}
//                 </option>
//               ))
//             ) : (
//               <option value="" disabled>
//                 No labels available
//               </option>
//             )}
//             <option value="create_new">+ Create New Label</option>
//           </select>

//           {/* Input bar to create a new label */}
//           <input
//             type="text"
//             value={newLabel}
//             onChange={(e) => setNewLabel(e.target.value)}
//             placeholder="Enter new label name"
//             className={`w-full border p-2 rounded ${
//               isCreatingNew ? '' : 'opacity-50 cursor-not-allowed'
//             }`}
//             disabled={!isCreatingNew}
//           />
//         </div>
//         <div className="flex justify-end space-x-2">
//           <button onClick={onClose} className="px-4 py-2 text-gray-600 border rounded">
//             Cancel
//           </button>
//           <button onClick={handleSave} className="px-4 py-2 bg-cyan-800 text-white rounded">
//             Save
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LabelModal;