// src/hooks/useAccountJournal.tsx

import { useCallback } from 'react';
import { useEnv } from '../context/EnvContext';

export const useAccountJournal = () => {
  const { apiUrl } = useEnv();

  const fetchAccountJournal = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/account-journal`);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching account journal:', error);
      throw new Error('Failed to fetch account journal data');
    }
  }, [apiUrl]);

  const fetchAccountHistoryFromFile = useCallback(async (accountNumber: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/algo_journal_data/${accountNumber}/history.json`);
      if (!response.ok) {
        throw new Error(`Failed to fetch history JSON file for account: ${accountNumber}`);
      }
  
      const data = await response.json();
      // console.log('Raw fetched history data:', data);
  
      // Extract `ret_msg` if present
      const normalizedData = Array.isArray(data.ret_msg) ? data.ret_msg : [];
      // console.log('Normalized history data:', normalizedData);
  
      return normalizedData;
    } catch (error) {
      console.error('Error fetching account history JSON:', error);
      throw new Error('Failed to fetch account history data from file');
    }
  }, [apiUrl]);
  
  const fetchAccountPositionFromFile = useCallback(async (accountNumber: string) => {
    try {
      // console.log(`Attempting to fetch position JSON for account: ${accountNumber}`);
      const response = await fetch(`${apiUrl}/api/algo_journal_data/${accountNumber}/position.json`);
      if (!response.ok) {
        throw new Error(`Failed to fetch position JSON file for account: ${accountNumber}`);
      }
  
      const data = await response.json();
      // console.log('Raw position data:', data);
  
      // Extract `ret_msg` if present
      const normalizedData = Array.isArray(data.ret_msg) ? data.ret_msg : [];
      // console.log('Normalized position data:', normalizedData);
  
      return normalizedData;
    } catch (error) {
      console.error('Error fetching account position JSON:', error);
      throw new Error('Failed to fetch account position data from file');
    }
  }, [apiUrl]); 
  
  const fetchAccountStats = useCallback(async (accountNumber: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/account-stats/${accountNumber}`);
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error || `Failed to fetch stats for account: ${accountNumber}`);
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching account stats for account ${accountNumber}:`, error);
      throw error;
    }
  }, [apiUrl]);  

  const fetchAndInsertHistory = useCallback(async (accountNumber: string) => {
    const currentDate = new Date();
    const closeTimeTo = currentDate.toISOString().split('T')[0];
  
    // Calculate the date 90 days ago
    const closeTimeFrom = new Date(currentDate.setDate(currentDate.getDate() - 90)).toISOString().split('T')[0];
  
    try {
      const response = await fetch(`${apiUrl}/api/fetch-and-insert-history`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          close_time_from: closeTimeFrom,
          close_time_to: closeTimeTo,
          offset: 0,
          limit: 100000,
          login: accountNumber,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch and insert history for account: ${accountNumber}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error(`Error fetching and inserting history for account ${accountNumber}:`, error);
      throw error;
    }
  }, [apiUrl]);  
  
  const fetchAndInsertPosition = useCallback(async (accountNumber: string) => {
    const currentDate = new Date();
    const openTimeTo = currentDate.toISOString().split('T')[0];

    // Calculate the date 90 days ago
    const openTimeFrom = new Date(currentDate.setDate(currentDate.getDate() - 90)).toISOString().split('T')[0];

    try {
      const response = await fetch(`${apiUrl}/api/fetch-and-insert-position`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          open_time_from: openTimeFrom,
          open_time_to: openTimeTo,
          offset: 0,
          limit: 100000, 
          login: accountNumber,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch and insert position for account: ${accountNumber}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error(`Error fetching and inserting position for account ${accountNumber}:`, error);
      throw error;
    }
  }, [apiUrl]);
       
  
  return {
    fetchAccountJournal,
    fetchAccountStats,
    fetchAccountHistoryFromFile,
    fetchAccountPositionFromFile,
    fetchAndInsertHistory,
    fetchAndInsertPosition,
  };
};



// // udpate code --> 24 dec 2024
// // src/hooks/useAccountJournal.tsx

// import { useEnv } from '../context/EnvContext';
// import { useCallback } from 'react';

// export const useAccountJournal = () => {
//   const { apiUrl } = useEnv();

//   const fetchAccountJournal = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/account-journal`);
//       if (!response.ok) {
//         throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
//       }
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       console.error('Error fetching account journal:', error);
//       throw new Error('Failed to fetch account journal data');
//     }
//   }, [apiUrl]);

//   return { fetchAccountJournal };
// };


