// src/components/admin/ClientData/UsersTable.tsx

// import react components
import React, { useState } from 'react';
//import hook file
import { useEnv } from '../../../context/EnvContext';  
import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';
import { countryOptions, assistantCoachesOptions } from '../../../hooks/Login/useFormValidation';
// import icon
import { AiOutlineSearch } from 'react-icons/ai'; 
import { BsPersonFillAdd, BsSendCheck, BsWhatsapp } from "react-icons/bs";
import { FaRedoAlt, FaWhatsapp, FaTags } from "react-icons/fa";
import { MdPersonRemoveAlt1, MdFilterListAlt, MdPlaylistAddCheckCircle } from "react-icons/md";
// import assets
import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';
// import components
import UsersTableEditModal from '../ClientData/UsersTableEdit';
import UsersTableFilter from '../ClientData/UsersTableFilter'; 
// import UserTableTradeStats from '../ClientData/UsersTableTradeStats';
import useDataTable from '../../../hooks/AdminDashboard/useDataTable';

interface UserTableProps {
  userData: ClientDataProps[];
  loading: boolean;
  error: string | null;
  fetchData: () => void;
  updateUser: (user: ClientDataProps & { receipt?: File | null }) => Promise<void>;
  addUser: (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => Promise<void>;
  deleteUsers: (ids: string[]) => void;
  handleSendTelegramLink: (user: ClientDataProps) => void; 
}

const UserTable: React.FC<UserTableProps> = ({ 
    userData, loading, error, 
    fetchData, addUser, updateUser, deleteUsers, handleSendTelegramLink
}) => {
  const { apiUrl } = useEnv();
  const { fetchUserVideoStats, fetchAlgoMatrixData, fetchTraderHubData } = useDataTable();
  const [searchQuery, setSearchQuery] = useState('');
  const [visibleRows, setVisibleRows] = useState(10); 
  const [selectedUser, setSelectedUser] = useState<ClientDataProps | null>(null); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showFilter, setShowFilter] = useState(false); 
  const [filterColumn, setFilterColumn] = useState<string>('');
  const [filterDirection, setFilterDirection] = useState<'asc' | 'desc'>('asc');
  // show user video stats and trading stats
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [userVideoData, setUserVideoData] = useState<any[]>([]);
  // show algomatrix stats and traderhub stats
  const [isAlgoMatrixModalOpen, setIsAlgoMatrixModalOpen] = useState(false);
  const [isTraderHubModalOpen, setIsTraderHubModalOpen] = useState(false);
  const [algoMatrixData, setAlgoMatrixData] = useState<any>(null);
  const [traderHubData, setTraderHubData] = useState<any>(null);
  // // show trading stat modal
  // const [isTradingStatsModalOpen, setIsTradingStatsModalOpen] = useState(false);
  // const [selectedEmailForTradingStats, setSelectedEmailForTradingStats] = useState<string | null>(null);
  // show WhatsApp message modal
  const [isWhatsAppModalOpen, setIsWhatsAppModalOpen] = useState(false);
  const [selectedUserForWhatsApp, setSelectedUserForWhatsApp] = useState<ClientDataProps | null>(null);
  const [customMessage, setCustomMessage] = useState('');
  const [selectedState, setSelectedState] = useState<keyof typeof assistantCoachesOptions | ''>('');
  const [selectedAssistant, setSelectedAssistant] = useState<{ name: string, contact: string } | null>(null);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [selectedReceiptImage, setSelectedReceiptImage] = useState<string | null>(null);
  
  // Handle selecting/deselecting individual rows
  const handleRowSelect = (id: string) => {
    if (selectedUserIds.includes(id)) {
      setSelectedUserIds(selectedUserIds.filter(userId => userId !== id));
    } else {
      setSelectedUserIds([...selectedUserIds, id]);
    }
  };

  // Handle selecting/deselecting all rows
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUserIds([]);
    } else {
      setSelectedUserIds(userData.map(user => user.id));
    }
    setSelectAll(!selectAll);
  };

  // Function to delete selected rows
  const handleDeleteSelectedUsers = () => {
    if (selectedUserIds.length > 0) {
      deleteUsers(selectedUserIds);
      setSelectedUserIds([]);
      setSelectAll(false); 
    }
  };

  // Function to load more rows
  const loadMoreRows = () => {
    setVisibleRows(prevVisibleRows => prevVisibleRows + 10);
  };

  // Function to reset and refetch the table data
  const resetTableData = () => {
    fetchData(); 
    setSearchQuery(''); 
  };

  // Handle double-click to open the modal with selected user data
  const handleRowDoubleClick = (user: ClientDataProps) => {
    setSelectedUser(user);
    setIsModalOpen(true); 
  };

  const handleAddUserClick = () => {
    setSelectedUser(null);
    setIsAddingNewUser(true); 
    setIsModalOpen(true); 
  };  

  // Handle save in modal
  const handleSave = async (user: ClientDataProps) => {
    try {
      if (isAddingNewUser) {
        const newUser = {
          ...user,
          password: user.password || generatePassword(),
        };
    
        await addUser(newUser);
      } else {
      const updatedUser = {
        ...user,
        receipt: user.receipt instanceof File ? user.receipt : null,
      };
        await updateUser(updatedUser); 
      }
      fetchData();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving user:', error); 
      
      if (error instanceof Error) {
        alert(error.message); 
      } else {
        alert('An unknown error occurred'); 
      }
    }
  };

  // Handle column filtering
  const handleFilterChange = (column: string, direction: 'asc' | 'desc') => {
    setFilterColumn(column);
    setFilterDirection(direction);
    setShowFilter(false); 
  };

  // Sort displayed users based on the filter column and direction
  const filteredUsers = userData
    .filter(user => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (user.name?.toLowerCase().includes(searchLower)) ||
        (user.email?.toLowerCase().includes(searchLower)) ||
        (user.phone?.toLowerCase().includes(searchLower)) ||
        (user.label?.toLowerCase().includes(searchLower)) ||
        (user.dateJoined &&
          new Date(user.dateJoined)
            .toDateString()
            .toLowerCase()
            .includes(searchLower)) ||
        (user.icNo?.toLowerCase().includes(searchLower)) ||
        (user.state?.toLowerCase().includes(searchLower)) ||
        (user.country?.toLowerCase().includes(searchLower))
      );
    })
    .sort((a, b) => {
      if (!filterColumn) return 0;
      const valueA = a[filterColumn as keyof ClientDataProps] ?? '';
      const valueB = b[filterColumn as keyof ClientDataProps] ?? '';
      if (valueA < valueB) return filterDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return filterDirection === 'asc' ? 1 : -1;
      return 0;
    });

  const displayedUsers = filteredUsers.slice(0, visibleRows);
  
  // Example password generation function
  const generatePassword = (): string => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const symbols = '#@$!';
    let password = '';
  
    // Generate 7 random alphanumeric characters
    for (let i = 0; i < 7; i++) {
      password += chars[Math.floor(Math.random() * chars.length)];
    }
  
    // Add a random symbol
    password += symbols[Math.floor(Math.random() * symbols.length)];
  
    return password;
  };

  const handleVideoStats = async (email: string) => {
    try {
      const videoData = await fetchUserVideoStats(email);
      setUserVideoData(videoData);
      setIsVideoModalOpen(true);
    } catch (error) {
      console.error('Error loading video stats:', error);
    }
  };  
    
  const handleAlgoMatrixStats = async (email: string) => {
    try {
      const data = await fetchAlgoMatrixData(email);
      setAlgoMatrixData(data || null); 
    } catch (error) {
      console.error('Error fetching AlgoMatrix stats:', error);
      setAlgoMatrixData(null); 
    } finally {
      setIsAlgoMatrixModalOpen(true);
    }
  };  
  
  const handleTraderHubStats = async (email: string) => {
    try {
      const data = await fetchTraderHubData(email);
      setTraderHubData(data || null); 
    } catch (error) {
      console.error('Error fetching TraderHub stats:', error);
      setTraderHubData(null); 
    } finally {
      setIsTraderHubModalOpen(true); 
    }
  };
  
  // // Handle showing the modal
  // const handleTradingStats = (email: string) => {
  //   setSelectedEmailForTradingStats(email);
  //   setIsTradingStatsModalOpen(true);
  // };

  const handleWhatsAppButtonClick = (user: ClientDataProps) => {
    setSelectedUserForWhatsApp(user);
    setCustomMessage(`
      Hello ${user.name}, here is the new member assignment details:\n
      - Full name: ${user.name}\n
      - Email: ${user.email}\n
      - Contact No: ${user.phone}\n
      - Password: abcd1234
    `);
    setIsWhatsAppModalOpen(true);
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const state = event.target.value as keyof typeof assistantCoachesOptions;
    setSelectedState(state);
    setSelectedAssistant(null);
  };

  const handleAssistantChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedName = event.target.value;
    const assistant = (assistantCoachesOptions[selectedState as keyof typeof assistantCoachesOptions] || [])
      .find((a: { name: string; contact: string }) => a.name === selectedName) || null;
    setSelectedAssistant(assistant);
  
    if (selectedUserForWhatsApp && assistant) {
      setCustomMessage(`
        Hello ${assistant.name}, here is the new member assignment details:\n
        - Full name: ${selectedUserForWhatsApp.name}\n
        - Email: ${selectedUserForWhatsApp.email}\n
        - Contact No: ${selectedUserForWhatsApp.phone}\n
        - Password: abcd1234
      `);
    }
  };

  const sendWhatsAppMessage = () => {
    if (selectedUserForWhatsApp && selectedAssistant) {
      // Ensure the contact number is in the correct format
      let phoneNumber = selectedAssistant.contact.replace(/\D/g, ''); 
      if (phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.slice(1); 
      }
      
      // Construct WhatsApp URL
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(customMessage)}`;
      window.open(url, '_blank');
      setIsWhatsAppModalOpen(false);
    }
  };  

  // Add this helper function at the top of the component
  const splitLabels = (labelString: string | undefined): string[] => {
    if (!labelString) return [];
    return labelString.split(',').map(label => label.trim()).filter(Boolean);
  };

  return (
    <div className="bg-white rounded-lg p-2">
      <div className="flex items-center justify-between mb-1">
        <div className="flex space-x-4">

          {/* Search Bar */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search users..."
              className="border px-4 py-1 rounded w-auto"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
            <AiOutlineSearch 
              className="absolute left-[200px] top-1/2 transform -translate-y-1/2 text-cyan-800" 
            />
          </div>
        </div>

        <div className="flex space-x-2">
          <button 
            onClick={resetTableData} 
            className="flex justify-normal items-center text-cyan-800 p-2 rounded"
          >
            <FaRedoAlt className="text-lg cursor-pointer" />
          </button>
          <button 
            onClick={() => setShowFilter(!showFilter)} 
            className="flex justify-normal items-center text-cyan-800 p-2 rounded"
          >
            <MdFilterListAlt className="text-xl cursor-pointer"/>
          </button>
          <button
            onClick={() => setShowCheckboxes(!showCheckboxes)}  
            className="flex justify-normal items-center text-cyan-800 p-2 rounded"
          >
            <MdPlaylistAddCheckCircle className="text-xl cursor-pointer"/>
          </button>
          <button 
            onClick={handleAddUserClick} 
            className="flex justify-normal items-center text-cyan-800 p-2 rounded"
          >
            <BsPersonFillAdd className="text-xl cursor-pointer" />
          </button>
          <button
            onClick={handleDeleteSelectedUsers}
            className="flex justify-normal items-center text-cyan-800 p-2 rounded"
          >
            <MdPersonRemoveAlt1 className="text-xl cursor-pointer"/>
          </button>

          {/* Show filter dropdown if toggled */}
          {showFilter && (
            <UsersTableFilter
              columns={['id', 'name', 'email', 'phone', 'label', 'dateJoined', 'dob', 'country', 'state', 'gender']}
              onFilterChange={handleFilterChange}
              onClose={() => setShowFilter(false)}
            />
          )}
        </div>
      </div>

      {/* Loading and Error States */}
      {loading ? (
        <div className="flex justify-center items-center bg-white border border-cyan-800 p-4 rounded">
          <p className="text-cyan-800">Loading...</p>
        </div>
      ) : error ? (
        <div className="text-center">
          <p className="text-red-500 mb-4">{error}</p>
          <button
            onClick={fetchData}
            className="bg-cyan-800 text-white py-2 px-4 rounded"
          >
            Retry
          </button>
        </div>
      ) : (
        <>
          {/* Table container with scrolling support */}
          <div className="w-full max-w-[1755px] h-full max-h-[680px] p-2 border rounded
                          overflow-scroll scrollbar scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack"
                        >
            <table className="table-auto border text-base">
              <thead>
                <tr className="text-center text-sm bg-gray-200">
                  {showCheckboxes && (
                    <th className="p-2 text-nowrap">
                      <input 
                        type="checkbox" 
                        checked={selectAll}
                        onChange={handleSelectAll} 
                      />
                    </th>
                  )}
                  <th className="p-2 text-nowrap">id</th>
                  <th className="p-2 text-nowrap">Member</th>
                  <th className="p-2 text-nowrap">Type</th>
                  <th className="p-2 text-nowrap">label</th>
                  <th className="p-2 text-nowrap">Date of Joined</th>
                  <th className="p-2 text-nowrap">Name</th>
                  <th className="p-2 text-nowrap">IC no.</th>
                  <th className="p-2 text-nowrap">Email</th>
                  <th className="p-2 text-nowrap">Gender</th>
                  <th className="p-2 text-nowrap">Phone</th>
                  <th className="p-2 text-nowrap">Date of Birth</th>
                  <th className="p-2 text-nowrap">Country</th>
                  <th className="p-2 text-nowrap">State</th>
                  <th className="p-2 text-nowrap">Address</th>
                  <th className="p-2 text-nowrap">Payment Amount</th>
                  <th className="p-2 text-nowrap">Payment Receipts</th>
                  <th className="p-2 text-nowrap">Telegram id</th>
                  <th className="p-2 text-nowrap">Discord id</th>
                  <th className="p-2 text-nowrap">AlgoMatrix Stats</th>
                  <th className="p-2 text-nowrap">Trader Hub Stats</th>
                  <th className="p-2 text-nowrap">Ranking</th>
                  <th className="p-2 text-nowrap">Program Stats</th>
                  <th className="p-2 text-nowrap">Video Stats</th>
                  {/* <th className="p-2 text-nowrap">Trading Stats</th> */}
                  <th className="p-2 text-nowrap">Send Telegram</th>
                  <th className="p-2 text-nowrap">Send Whatapp</th>
                </tr>
              </thead>
              <tbody>
                {displayedUsers.map(user => (
                  <tr 
                    key={user.id} 
                    className="text-center text-sm hover:bg-gray-50"
                    onDoubleClick={() => handleRowDoubleClick(user)}  
                  >
                    {showCheckboxes && (
                      <td className="p-2 border-b border-l text-nowrap">
                        <input 
                          type="checkbox" 
                          checked={selectedUserIds.includes(user.id)} 
                          onChange={() => handleRowSelect(user.id)} 
                          className="accent-cyan-800"
                        />
                      </td>
                    )}
                    <td className="p-2 border-b text-nowrap">{user.id}</td>
                    <td className="p-2 border-b text-nowrap">{user.stages}</td>
                    <td className="p-2 border-b text-nowrap">{user.memberType}</td>
                    <td className="p-2 border-b text-nowrap">
                      <div className="flex flex-row gap-1">
                        {splitLabels(user.label).map((label, index) => (
                          <div key={index} className="flex items-center text-xs text-cyan-800 font-bold">
                            <FaTags className="mr-1"/>
                            <span>{label}</span>
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="p-2 border-b text-nowrap">{user.dateJoined ? new Date(user.dateJoined).toISOString().split('T')[0] : ''}</td>
                    <td className="p-2 border-b text-nowrap">{user.name}</td>
                    <td className="p-2 border-b text-nowrap">{user.icNo}</td>
                    <td className="p-2 border-b text-nowrap">{user.email}</td>
                    <td className="p-2 border-b text-nowrap">
                      <img
                        src={(user.gender === 'male' ? maleAvatar : femaleAvatar)}
                        alt={user.name}
                        className={`w-8 h-8 rounded-full mx-auto border-2 ${user.gender === 'male' ? "bg-cyan-800 border-cyan-800" : "bg-pink-700 border-pink-700"}`}
                      />
                    </td>
                    <td className="p-2 border-b text-nowrap">{user.phone}</td>
                    <td className="p-2 border-b text-nowrap">
                        {new Date(user.dob).toISOString().split('T')[0]}
                    </td>
                    <td className="p-2 border-b text-nowrap">{countryOptions.find(option => option.value === user.country)?.label || user.country}</td>
                    <td className="p-2 border-b text-nowrap">{user.state}</td>
                    <td className="p-2 border-b text-nowrap">{user.address}</td>
                    <td className="p-2 border-b text-nowrap">{user.amount}</td>
                    <td className="py-2 px-4 border-b">
                      <button
                        className="text-cyan-800 hover:underline"
                        onClick={() => {
                          setSelectedReceiptImage(`${apiUrl}${user.receipt}`);
                          setIsReceiptModalOpen(true);
                        }}
                      >
                        View Receipts
                      </button>
                    </td>
                    <td className="p-2 border-b text-nowrap">{user.telegram_id}</td>
                    <td className="p-2 border-b text-nowrap">{user.discord_id}</td>

                    {/* AlgoMatrix Stats (clickable Yes/No) */}
                    <td className="p-2 border-b text-nowrap">
                      <button 
                        onClick={() => handleAlgoMatrixStats(user.email)}
                        className="text-cyan-800 hover:underline"
                      >
                        Stats
                      </button>
                    </td>
                    
                    {/* TraderHub Stats (clickable Yes/No) */}
                    <td className="p-2 border-b text-nowrap">
                      <button 
                        onClick={() => handleTraderHubStats(user.email)}
                        className="text-cyan-800 hover:underline"
                      >
                        Stats
                      </button>
                    </td>
                    
                    <td className="p-2 border-b text-nowrap">{user.badges}</td>
                    <td className="p-2 border-b text-nowrap">{user.programStats}</td>

                    {/* Video Stats */}
                    <td className="p-2 border-b text-nowrap">
                      <button onClick={() => handleVideoStats(user.email)} className="text-cyan-800 hover:underline">
                        Stats
                      </button>
                    </td>

                    {/* Trading Stats
                    <td className="p-2 border-b text-nowrap">
                      <button onClick={() => handleTradingStats(user.email)} className="text-cyan-800 hover:underline">
                        Stats
                      </button>
                    </td> */}
                    
                    {/* telegram button */}
                    <td className="p-2 border-b text-nowrap">
                      <button
                        className="text-cyan-800 cursor-pointer hover:text-rose-800"
                        onClick={() => handleSendTelegramLink(user)}
                      >
                        <BsSendCheck/>
                      </button>
                    </td>

                    {/* Whatapp button */}
                    <td className="p-2 border-b text-nowrap">
                      <button
                        className="text-cyan-800 cursor-pointer hover:text-rose-800"
                        onClick={() => handleWhatsAppButtonClick(user)}
                      >
                        <FaWhatsapp/>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Load More Button */}
          {visibleRows < filteredUsers.length && (
            <div className="flex justify-center mt-8">
              <button
                className="text-cyan-800 border-cyan-800 border rounded-full px-8 py-1 text-xs"
                onClick={loadMoreRows}
              >
                Load More Data
              </button>
            </div>
          )}

          {/* User video stats Modal */}
          {isVideoModalOpen && (
            <div className="fixed inset-0 z-[9999] flex items-center justify-center">
              {/* Darker background with animation */}
              <div 
                className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out" 
                onClick={() => setIsVideoModalOpen(false)}
              ></div>
              
              {/* Modal Container */}
              <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
                
                {/* Modal Title */}
                <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">User Video Stats</h2>
                
                {/* Content */}
                {userVideoData.length > 0 ? (
                  <div className="overflow-auto">
                    <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
                      <thead className="bg-gray-300 text-black text-sm">
                        <tr>
                          <th className="py-3 px-4 text-center">Email</th>
                          <th className="py-3 px-4 text-center">Video Title</th>
                          <th className="py-3 px-4 text-center">Category</th>
                          <th className="py-3 px-4 text-center">Views</th>
                          <th className="py-3 px-4 text-center">Last View</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-700 text-sm border-b">
                        {userVideoData.map((videoStat, index) => (
                          <tr 
                            key={index} 
                            className="hover:bg-gray-100"
                          >
                            <td className="py-3 px-4 text-center">{videoStat.userVideoEmail}</td>
                            <td className="py-3 px-4 text-center">{videoStat.userVideoTitle}</td>
                            <td className="py-3 px-4 text-center">{videoStat.userVideoCategory}</td>
                            <td className="py-3 px-4 text-center">{videoStat.userVideoViews}</td>
                            <td className="py-3 px-4 text-center">{new Date(videoStat.userVideoLastViews).toISOString().split('T')[0]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-gray-500 my-6">No video stats available for this user.</p>
                )}
                
                {/* Footer with Close Button */}
                <div className="flex justify-end mt-6">
                  <button 
                    onClick={() => setIsVideoModalOpen(false)} 
                    className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* algomatrix stats modal */}
          {isAlgoMatrixModalOpen && (
            <div className="fixed inset-0 z-[9999] flex items-center justify-center">
              <div
                className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
                onClick={() => setIsAlgoMatrixModalOpen(false)}
              ></div>

              <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
                <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">AlgoMatrix Stats</h2>

                {/* Check if algoMatrixData exists */}
                {algoMatrixData ? (
                  <div className="overflow-auto">
                    <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
                      <thead className="bg-gray-300 text-black text-sm">
                        <tr>
                          <th className="py-3 px-4 text-center">Title</th>
                          <th className="py-3 px-4 text-center">Data</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-700 text-sm border-b">
                        <tr>
                          <td className="py-3 px-4 text-center">User Name</td>
                          <td className="py-3 px-4 text-center">{algoMatrixData.subUserName}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 text-center">Email</td>
                          <td className="py-3 px-4 text-center">{algoMatrixData.subUserEmail}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 text-center">Plan Type</td>
                          <td className="py-3 px-4 text-center">{algoMatrixData.subPlanType}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 text-center">Plan Expiry Date</td>
                          <td className="py-3 px-4 text-center">
                            {algoMatrixData.subExpiredDate && !isNaN(Date.parse(algoMatrixData.subExpiredDate))
                              ? new Date(algoMatrixData.subExpiredDate).toISOString().split('T')[0]
                              : 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 text-center">Account Status</td>
                          <td
                            className={`p-2 text-center ${
                              traderHubData.subStatus === 'Active'
                                ? 'text-green-600 font-semibold'
                                : traderHubData.subStatus === 'Pending'
                                ? 'bg-yellow-600 font-semibold'
                                : traderHubData.subStatus === 'Expired'
                                ? 'bg-rose-600 font-semibold'
                                : ''
                            }`}
                          >
                            {traderHubData.subStatus}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-gray-500 my-6">No AlgoMatrix stats available for this user.</p>
                )}

                <div className="flex justify-end mt-6">
                  <button
                    onClick={() => setIsAlgoMatrixModalOpen(false)}
                    className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* trader hub stats modal */}
          {isTraderHubModalOpen && (
            <div className="fixed inset-0 z-[9999] flex items-center justify-center">
              <div
                className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
                onClick={() => setIsTraderHubModalOpen(false)}
              ></div>

              <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
                <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">TraderHub Stats</h2>

                {/* Check if traderHubData exists */}
                {traderHubData ? (
                  <div className="overflow-auto">
                    <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
                      <thead className="bg-gray-300 text-black text-sm">
                        <tr>
                          <th className="py-3 px-4 text-center">Column</th>
                          <th className="py-3 px-4 text-center">Data</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-700 text-sm border-b">
                        <tr>
                          <td className="py-3 px-4 text-center">Name</td>
                          <td className="py-3 px-4 text-center">{traderHubData.subUserName}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 text-center">Email</td>
                          <td className="py-3 px-4 text-center">{traderHubData.subUserEmail}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 text-center">Plan Type</td>
                          <td className="py-3 px-4 text-center">{traderHubData.subPlanType}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 text-center">Expiry Date</td>
                          <td className="py-3 px-4 text-center">
                            {traderHubData.subExpiredDate && !isNaN(Date.parse(traderHubData.subExpiredDate))
                              ? new Date(traderHubData.subExpiredDate).toISOString().split('T')[0]
                              : 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 text-center">Account Status</td>
                          <td
                            className={`py-3 px-4 text-center ${
                              algoMatrixData.subStatus === 'Active'
                                ? 'text-green-600 font-semibold'
                                : algoMatrixData.subStatus === 'Pending'
                                ? 'text-yellow-600 font-semibold'
                                : algoMatrixData.subStatus === 'Expired'
                                ? 'bg-rose-600 font-semibold'
                                : ''
                            }`}
                          >
                            {algoMatrixData.subStatus}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-gray-500 my-6">
                    No TraderHub stats available for this user
                  </p>
                )}

                <div className="flex justify-end mt-6">
                  <button
                    onClick={() => setIsTraderHubModalOpen(false)}
                    className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* WhatsApp Message Modal */}
          {isWhatsAppModalOpen && (
            <div className="fixed inset-0 z-[9999] flex items-center justify-center">
              <div 
                className="modal-overlay absolute inset-0 bg-black opacity-70" 
                onClick={() => setIsWhatsAppModalOpen(false)}
              >
              </div>
              
              <div className="modal relative bg-white rounded-md shadow-2xl p-6 w-auto max-w-2xl">
                <div className="flex flex-row items-center text-2xl space-x-4">
                <BsWhatsapp className="text-green-700"/>
                <h2 className="font-bold text-green-700 text-center">
                  Send WhatsApp Message
                </h2>
                </div>

                {/* State Dropdown */}
                <div className="my-4">
                  <label className="block mb-1 mx-2 font-semibold text-cyan-800">Assistant State</label>
                  <select
                    value={selectedState}
                    onChange={handleStateChange}
                    className="w-full border p-2 rounded"
                  >
                    <option value="">Select a state</option>
                    {Object.keys(assistantCoachesOptions).map(state => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>

                {/* Assistant Dropdown */}
                {selectedState && (
                  <div className="my-4">
                    <label className="block mb-1 mx-2 font-semibold text-cyan-800">Assistant Coach</label>
                    <select
                      value={selectedAssistant?.name || ''}
                      onChange={handleAssistantChange}
                      className="w-full border p-2 rounded"
                    >
                      <option value="">Select an assistant</option>
                      {assistantCoachesOptions[selectedState]?.map((assistant, index) => (
                        <option key={index} value={assistant.name}>
                          {assistant.name} ({assistant.contact})
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Message Input */}
                <div className="my-4">
                  <label className="block mb-1 mx-2 font-semibold text-cyan-800">Message Content</label>
                  <textarea
                    value={customMessage}
                    onChange={(e) => setCustomMessage(e.target.value)}
                    rows={5}
                    className="w-full border p-2 rounded"
                  ></textarea>
                </div>

                {/* Modal Footer */}
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setIsWhatsAppModalOpen(false)}
                    className="mr-2 text-gray-500 border border-gray-800 rounded-full px-4 py-1 font-semibold"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={sendWhatsAppMessage}
                    className="text-white border bg-green-600 rounded-full px-4 py-1 font-semibold"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* view receipt open modal */}
          {isReceiptModalOpen && selectedReceiptImage && (
            <div className="fixed inset-0 z-[9999] flex items-center justify-center">
              <div
                className="modal-overlay absolute inset-0 bg-black opacity-70"
                onClick={() => setIsReceiptModalOpen(false)}
              ></div>
              <div className="modal relative bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
                <h2 className="text-xl font-bold text-cyan-800 mb-4 text-center">Payment Receipt</h2>
                <div className="flex justify-center">
                  <img src={selectedReceiptImage} alt="Receipt" className="max-w-full h-auto rounded-md" />
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setIsReceiptModalOpen(false)}
                    className="text-white bg-cyan-800 px-4 py-2 rounded-full"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Show trading stats modal
          <UserTableTradeStats
            email={selectedEmailForTradingStats || ''}
            isOpen={isTradingStatsModalOpen}
            onClose={() => setIsTradingStatsModalOpen(false)}
          /> */}
            
          {/* Edit User Modal */}
          <UsersTableEditModal 
            user={selectedUser}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSave={handleSave} 
          />
        </>
      )}
    </div>
  );
};

export default UserTable;


// // udpate code --> 05 dec 2024
// // src/components/admin/ClientData/UsersTable.tsx

// // import react components
// import React, { useState } from 'react';
// //import hook file
// import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';
// import { countryOptions, assistantCoachesOptions } from '../../../hooks/Login/useFormValidation';
// // import icon
// import { AiOutlineSearch } from 'react-icons/ai'; 
// import { BsPersonFillAdd, BsSendCheck, BsWhatsapp } from "react-icons/bs";
// import { FaRedoAlt, FaWhatsapp } from "react-icons/fa";
// import { MdPersonRemoveAlt1, MdFilterListAlt, MdPlaylistAddCheckCircle } from "react-icons/md";
// // import assets
// import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
// import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';
// // import components
// import UsersTableEditModal from '../ClientData/UsersTableEdit';
// import UsersTableFilter from '../ClientData/UsersTableFilter'; 
// import UserTableTradeStats from '../ClientData/UsersTableTradeStats';
// import useDataTable from '../../../hooks/AdminDashboard/useDataTable';

// interface UserTableProps {
//   userData: ClientDataProps[];
//   loading: boolean;
//   error: string | null;
//   fetchData: () => void;
//   updateUser: (user: ClientDataProps) => void;
//   addUser: (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => Promise<void>;
//   deleteUsers: (ids: string[]) => void;
//   handleSendTelegramLink: (user: ClientDataProps) => void; 
// }

// const UserTable: React.FC<UserTableProps> = ({ 
//     userData, loading, error, 
//     fetchData, addUser, updateUser, deleteUsers, handleSendTelegramLink
// }) => {
//   const { fetchUserVideoStats, fetchAlgoMatrixData, fetchTraderHubData } = useDataTable();
//   const [searchQuery, setSearchQuery] = useState('');
//   const [visibleRows, setVisibleRows] = useState(10); 
//   const [selectedUser, setSelectedUser] = useState<ClientDataProps | null>(null); 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isAddingNewUser, setIsAddingNewUser] = useState(false);
//   const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
//   const [selectAll, setSelectAll] = useState(false);
//   const [showCheckboxes, setShowCheckboxes] = useState(false);
//   const [showFilter, setShowFilter] = useState(false); 
//   const [filterColumn, setFilterColumn] = useState<string>('');
//   const [filterDirection, setFilterDirection] = useState<'asc' | 'desc'>('asc');
//   // show user video stats and trading stats
//   const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
//   const [userVideoData, setUserVideoData] = useState<any[]>([]);
//   // show algomatrix stats and traderhub stats
//   const [isAlgoMatrixModalOpen, setIsAlgoMatrixModalOpen] = useState(false);
//   const [isTraderHubModalOpen, setIsTraderHubModalOpen] = useState(false);
//   const [algoMatrixData, setAlgoMatrixData] = useState<any>(null);
//   const [traderHubData, setTraderHubData] = useState<any>(null);
//   // show trading stat modal
//   const [isTradingStatsModalOpen, setIsTradingStatsModalOpen] = useState(false);
//   const [selectedEmailForTradingStats, setSelectedEmailForTradingStats] = useState<string | null>(null);
//   // show WhatsApp message modal
//   const [isWhatsAppModalOpen, setIsWhatsAppModalOpen] = useState(false);
//   const [selectedUserForWhatsApp, setSelectedUserForWhatsApp] = useState<ClientDataProps | null>(null);
//   const [customMessage, setCustomMessage] = useState('');
//   const [selectedState, setSelectedState] = useState<keyof typeof assistantCoachesOptions | ''>('');
//   const [selectedAssistant, setSelectedAssistant] = useState<{ name: string, contact: string } | null>(null);


//   // Handle selecting/deselecting individual rows
//   const handleRowSelect = (id: string) => {
//     if (selectedUserIds.includes(id)) {
//       setSelectedUserIds(selectedUserIds.filter(userId => userId !== id));
//     } else {
//       setSelectedUserIds([...selectedUserIds, id]);
//     }
//   };

//   // Handle selecting/deselecting all rows
//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedUserIds([]);
//     } else {
//       setSelectedUserIds(userData.map(user => user.id));
//     }
//     setSelectAll(!selectAll);
//   };

//   // Function to delete selected rows
//   const handleDeleteSelectedUsers = () => {
//     if (selectedUserIds.length > 0) {
//       deleteUsers(selectedUserIds);
//       setSelectedUserIds([]);
//       setSelectAll(false); 
//     }
//   };

//   // Function to load more rows
//   const loadMoreRows = () => {
//     setVisibleRows(prevVisibleRows => prevVisibleRows + 10);
//   };

//   // Function to reset and refetch the table data
//   const resetTableData = () => {
//     fetchData(); 
//     setSearchQuery(''); 
//   };

//   // Handle double-click to open the modal with selected user data
//   const handleRowDoubleClick = (user: ClientDataProps) => {
//     setSelectedUser(user);
//     setIsModalOpen(true); 
//   };

//   const handleAddUserClick = () => {
//     setSelectedUser(null);
//     setIsAddingNewUser(true); 
//     setIsModalOpen(true); 
//   };  

//   // Handle save in modal
//   const handleSave = async (user: ClientDataProps) => {
//     try {
//       if (isAddingNewUser) {
//         const newUser = {
//           ...user,
//           password: user.password || generatePassword(),
//         };
    
//         await addUser(newUser);
//       } else {
//         await updateUser(user); 
//       }
//       fetchData();
//       setIsModalOpen(false);
//     } catch (error) {
//       console.error('Error saving user:', error); 
      
//       if (error instanceof Error) {
//         alert(error.message); 
//       } else {
//         alert('An unknown error occurred'); 
//       }
//     }
//   };

//   // Handle column filtering
//   const handleFilterChange = (column: string, direction: 'asc' | 'desc') => {
//     setFilterColumn(column);
//     setFilterDirection(direction);
//     setShowFilter(false); 
//   };

//   // Sort displayed users based on the filter column and direction
//   const filteredUsers = userData
//     .filter(user =>
//       (user.name?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
//       (user.email?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
//       (user.icNo?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
//       (user.state?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
//       (user.country?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
//     )
//     .sort((a, b) => {
//       if (!filterColumn) return 0;
//       const valueA = a[filterColumn as keyof ClientDataProps] ?? ''; 
//       const valueB = b[filterColumn as keyof ClientDataProps] ?? '';
//       if (valueA < valueB) return filterDirection === 'asc' ? -1 : 1;
//       if (valueA > valueB) return filterDirection === 'asc' ? 1 : -1;
//       return 0;
//     });

//   const displayedUsers = filteredUsers.slice(0, visibleRows);
  
//   // Example password generation function
//   const generatePassword = (): string => {
//     const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     const symbols = '#@$!';
//     let password = '';
  
//     // Generate 7 random alphanumeric characters
//     for (let i = 0; i < 7; i++) {
//       password += chars[Math.floor(Math.random() * chars.length)];
//     }
  
//     // Add a random symbol
//     password += symbols[Math.floor(Math.random() * symbols.length)];
  
//     return password;
//   };

//   const handleVideoStats = async (email: string) => {
//     try {
//       const videoData = await fetchUserVideoStats(email);
//       setUserVideoData(videoData);
//       setIsVideoModalOpen(true);
//     } catch (error) {
//       console.error('Error loading video stats:', error);
//     }
//   };  
    
//   const handleAlgoMatrixStats = async (email: string) => {
//     try {
//       const data = await fetchAlgoMatrixData(email);
//       setAlgoMatrixData(data || null); 
//     } catch (error) {
//       console.error('Error fetching AlgoMatrix stats:', error);
//       setAlgoMatrixData(null); 
//     } finally {
//       setIsAlgoMatrixModalOpen(true);
//     }
//   };  
  
//   const handleTraderHubStats = async (email: string) => {
//     try {
//       const data = await fetchTraderHubData(email);
//       setTraderHubData(data || null); 
//     } catch (error) {
//       console.error('Error fetching TraderHub stats:', error);
//       setTraderHubData(null); 
//     } finally {
//       setIsTraderHubModalOpen(true); 
//     }
//   };
  
//   // Handle showing the modal
//   const handleTradingStats = (email: string) => {
//     setSelectedEmailForTradingStats(email);
//     setIsTradingStatsModalOpen(true);
//   };

//   const handleWhatsAppButtonClick = (user: ClientDataProps) => {
//     setSelectedUserForWhatsApp(user);
//     setCustomMessage(`
//       Hello ${user.name}, here is the new member assignment details:\n
//       - Full name: ${user.name}\n
//       - Email: ${user.email}\n
//       - Contact No: ${user.phone}\n
//       - Password: abcd1234
//     `);
//     setIsWhatsAppModalOpen(true);
//   };

//   const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const state = event.target.value as keyof typeof assistantCoachesOptions;
//     setSelectedState(state);
//     setSelectedAssistant(null);
//   };

//   const handleAssistantChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedName = event.target.value;
//     const assistant = (assistantCoachesOptions[selectedState as keyof typeof assistantCoachesOptions] || [])
//       .find((a: { name: string; contact: string }) => a.name === selectedName) || null;
//     setSelectedAssistant(assistant);
  
//     if (selectedUserForWhatsApp && assistant) {
//       setCustomMessage(`
//         Hello ${assistant.name}, here is the new member assignment details:\n
//         - Full name: ${selectedUserForWhatsApp.name}\n
//         - Email: ${selectedUserForWhatsApp.email}\n
//         - Contact No: ${selectedUserForWhatsApp.phone}\n
//         - Password: abcd@1234
//       `);
//     }
//   };

//   const sendWhatsAppMessage = () => {
//     if (selectedUserForWhatsApp && selectedAssistant) {
//       // Ensure the contact number is in the correct format
//       let phoneNumber = selectedAssistant.contact.replace(/\D/g, ''); 
//       if (phoneNumber.startsWith('0')) {
//         phoneNumber = phoneNumber.slice(1); 
//       }
      
//       // Construct WhatsApp URL
//       const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(customMessage)}`;
//       window.open(url, '_blank');
//       setIsWhatsAppModalOpen(false);
//     }
//   };  

//   return (
//     <div className="bg-white rounded-lg p-2">
//       <div className="flex items-center justify-between mb-1">
//         <div className="flex space-x-4">

//           {/* Search Bar */}
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search users..."
//               className="border px-4 py-1 rounded w-auto"
//               value={searchQuery}
//               onChange={e => setSearchQuery(e.target.value)}
//             />
//             <AiOutlineSearch 
//               className="absolute left-[200px] top-1/2 transform -translate-y-1/2 text-cyan-800" 
//             />
//           </div>
//         </div>

//         <div className="flex space-x-2">
//           <button 
//             onClick={resetTableData} 
//             className="flex justify-normal items-center text-cyan-800 p-2 rounded"
//           >
//             <FaRedoAlt className="text-lg cursor-pointer" />
//           </button>
//           <button 
//             onClick={() => setShowFilter(!showFilter)} 
//             className="flex justify-normal items-center text-cyan-800 p-2 rounded"
//           >
//             <MdFilterListAlt className="text-xl cursor-pointer"/>
//           </button>
//           <button
//             onClick={() => setShowCheckboxes(!showCheckboxes)}  
//             className="flex justify-normal items-center text-cyan-800 p-2 rounded"
//           >
//             <MdPlaylistAddCheckCircle className="text-xl cursor-pointer"/>
//           </button>
//           <button 
//             onClick={handleAddUserClick} 
//             className="flex justify-normal items-center text-cyan-800 p-2 rounded"
//           >
//             <BsPersonFillAdd className="text-xl cursor-pointer" />
//           </button>
//           <button
//             onClick={handleDeleteSelectedUsers}
//             className="flex justify-normal items-center text-cyan-800 p-2 rounded"
//           >
//             <MdPersonRemoveAlt1 className="text-xl cursor-pointer"/>
//           </button>

//           {/* Show filter dropdown if toggled */}
//           {showFilter && (
//             <UsersTableFilter
//               columns={['id', 'name', 'email', 'phone', 'dob', 'country', 'state']}
//               onFilterChange={handleFilterChange}
//               onClose={() => setShowFilter(false)}
//             />
//           )}
//         </div>
//       </div>

//       {/* Loading and Error States */}
//       {loading ? (
//         <div className="flex justify-center items-center bg-white border border-cyan-800 p-4 rounded">
//           <p className="text-cyan-800">Loading...</p>
//         </div>
//       ) : error ? (
//         <div className="text-center">
//           <p className="text-red-500 mb-4">{error}</p>
//           <button
//             onClick={fetchData}
//             className="bg-cyan-800 text-white py-2 px-4 rounded"
//           >
//             Retry
//           </button>
//         </div>
//       ) : (
//         <>
//           {/* Table container with scrolling support */}
//           <div className="overflow-auto w-auto max-w-[1100px] h-[340px] max-h-screen p-2">
//             <table className="w-auto table-auto border text-base">
//               <thead>
//                 <tr className="text-center text-sm bg-gray-200">
//                   {showCheckboxes && (
//                     <th className="p-2 text-nowrap">
//                       <input 
//                         type="checkbox" 
//                         checked={selectAll}
//                         onChange={handleSelectAll} 
//                       />
//                     </th>
//                   )}
//                   <th className="p-2 text-nowrap">id</th>
//                   <th className="p-2 text-nowrap">Member</th>
//                   <th className="p-2 text-nowrap">Type</th>
//                   <th className="p-2 text-nowrap">Date of Joined</th>
//                   <th className="p-2 text-nowrap">Name</th>
//                   <th className="p-2 text-nowrap">IC no.</th>
//                   <th className="p-2 text-nowrap">Email</th>
//                   <th className="p-2 text-nowrap">Gender</th>
//                   <th className="p-2 text-nowrap">Phone</th>
//                   <th className="p-2 text-nowrap">Date of Birth</th>
//                   <th className="p-2 text-nowrap">Country</th>
//                   <th className="p-2 text-nowrap">State</th>
//                   <th className="p-2 text-nowrap">Address</th>
//                   <th className="p-2 text-nowrap">Telegram id</th>
//                   <th className="p-2 text-nowrap">Discord id</th>
//                   <th className="p-2 text-nowrap">AlgoMatrix Stats</th>
//                   <th className="p-2 text-nowrap">Trader Hub Stats</th>
//                   <th className="p-2 text-nowrap">Ranking</th>
//                   <th className="p-2 text-nowrap">Program Stats</th>
//                   <th className="p-2 text-nowrap">Video Stats</th>
//                   <th className="p-2 text-nowrap">Trading Stats</th>
//                   <th className="p-2 text-nowrap">Send Telegram</th>
//                   <th className="p-2 text-nowrap">Send Whatapp</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {displayedUsers.map(user => (
//                   <tr 
//                     key={user.id} 
//                     className="text-center text-sm hover:bg-gray-50"
//                     onDoubleClick={() => handleRowDoubleClick(user)}  
//                   >
//                     {showCheckboxes && (
//                       <td className="p-2 border-b border-l text-nowrap">
//                         <input 
//                           type="checkbox" 
//                           checked={selectedUserIds.includes(user.id)} 
//                           onChange={() => handleRowSelect(user.id)} 
//                           className="accent-cyan-800"
//                         />
//                       </td>
//                     )}
//                     <td className="p-2 border-b text-nowrap">{user.id}</td>
//                     <td className="p-2 border-b text-nowrap">{user.stages}</td>
//                     <td className="p-2 border-b text-nowrap">{user.memberType}</td>
//                     <td className="p-2 border-b text-nowrap">{user.dateJoined ? new Date(user.dateJoined).toISOString().split('T')[0] : ''}</td>
//                     <td className="p-2 border-b text-nowrap">{user.name}</td>
//                     <td className="p-2 border-b text-nowrap">{user.icNo}</td>
//                     <td className="p-2 border-b text-nowrap">{user.email}</td>
//                     <td className="p-2 border-b text-nowrap">
//                         <img
//                           src={(user.gender === 'male' ? maleAvatar : femaleAvatar)}
//                           alt={user.name}
//                           className={`w-8 h-8 rounded-full mx-auto border-2 ${user.gender === 'male' ? "bg-cyan-800 border-cyan-800" : "bg-pink-700 border-pink-700"}`}
//                         />
//                     </td>
//                     <td className="p-2 border-b text-nowrap">{user.phone}</td>
//                     <td className="p-2 border-b text-nowrap">
//                         {new Date(user.dob).toISOString().split('T')[0]}
//                     </td>
//                     <td className="p-2 border-b text-nowrap">{countryOptions.find(option => option.value === user.country)?.label || user.country}</td>
//                     <td className="p-2 border-b text-nowrap">{user.state}</td>
//                     <td className="p-2 border-b text-nowrap">{user.address}</td>
//                     <td className="p-2 border-b text-nowrap">{user.telegram_id}</td>
//                     <td className="p-2 border-b text-nowrap">{user.discord_id}</td>

//                     {/* AlgoMatrix Stats (clickable Yes/No) */}
//                     <td className="p-2 border-b text-nowrap">
//                       <button 
//                         onClick={() => handleAlgoMatrixStats(user.email)}
//                         className="text-cyan-800 hover:underline"
//                       >
//                         Stats
//                       </button>
//                     </td>
                    
//                     {/* TraderHub Stats (clickable Yes/No) */}
//                     <td className="p-2 border-b text-nowrap">
//                       <button 
//                         onClick={() => handleTraderHubStats(user.email)}
//                         className="text-cyan-800 hover:underline"
//                       >
//                         Stats
//                       </button>
//                     </td>
                    
//                     <td className="p-2 border-b text-nowrap">{user.badges}</td>
//                     <td className="p-2 border-b text-nowrap">{user.programStats}</td>

//                     {/* Video Stats */}
//                     <td className="p-2 border-b text-nowrap">
//                       <button onClick={() => handleVideoStats(user.email)} className="text-cyan-800 hover:underline">
//                         Stats
//                       </button>
//                     </td>

//                     {/* Tradomg Stats */}
//                     <td className="p-2 border-b text-nowrap">
//                       <button onClick={() => handleTradingStats(user.email)} className="text-cyan-800 hover:underline">
//                         Stats
//                       </button>
//                     </td>
                    
//                     {/* telegram button */}
//                     <td className="p-2 border-b border-r text-nowrap">
//                       <button
//                         className="text-cyan-800 cursor-pointer hover:text-rose-800"
//                         onClick={() => handleSendTelegramLink(user)}
//                       >
//                         <BsSendCheck/>
//                       </button>
//                     </td>

//                     {/* Whatapp button */}
//                     <td className="p-2 border-b text-nowrap">
//                       <button
//                         className="text-cyan-800 cursor-pointer hover:text-rose-800"
//                         onClick={() => handleWhatsAppButtonClick(user)}
//                       >
//                         <FaWhatsapp/>
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           {/* Load More Button */}
//           {visibleRows < filteredUsers.length && (
//             <div className="flex justify-center mt-4">
//               <button
//                 className="text-cyan-800 border-cyan-800 border rounded-full px-8 py-1 text-xs"
//                 onClick={loadMoreRows}
//               >
//                 Load More Data
//               </button>
//             </div>
//           )}

//           {/* User video stats Modal */}
//           {isVideoModalOpen && (
//             <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//               {/* Darker background with animation */}
//               <div 
//                 className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out" 
//                 onClick={() => setIsVideoModalOpen(false)}
//               ></div>
              
//               {/* Modal Container */}
//               <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
                
//                 {/* Modal Title */}
//                 <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">User Video Stats</h2>
                
//                 {/* Content */}
//                 {userVideoData.length > 0 ? (
//                   <div className="overflow-auto">
//                     <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
//                       <thead className="bg-gray-300 text-black text-sm">
//                         <tr>
//                           <th className="py-3 px-4 text-center">Email</th>
//                           <th className="py-3 px-4 text-center">Video Title</th>
//                           <th className="py-3 px-4 text-center">Category</th>
//                           <th className="py-3 px-4 text-center">Views</th>
//                           <th className="py-3 px-4 text-center">Last View</th>
//                         </tr>
//                       </thead>
//                       <tbody className="text-gray-700 text-sm border-b">
//                         {userVideoData.map((videoStat, index) => (
//                           <tr 
//                             key={index} 
//                             className="hover:bg-gray-100"
//                           >
//                             <td className="py-3 px-4 text-center">{videoStat.userVideoEmail}</td>
//                             <td className="py-3 px-4 text-center">{videoStat.userVideoTitle}</td>
//                             <td className="py-3 px-4 text-center">{videoStat.userVideoCategory}</td>
//                             <td className="py-3 px-4 text-center">{videoStat.userVideoViews}</td>
//                             <td className="py-3 px-4 text-center">{new Date(videoStat.userVideoLastViews).toISOString().split('T')[0]}</td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                   </div>
//                 ) : (
//                   <p className="text-center text-gray-500 my-6">No video stats available for this user.</p>
//                 )}
                
//                 {/* Footer with Close Button */}
//                 <div className="flex justify-end mt-6">
//                   <button 
//                     onClick={() => setIsVideoModalOpen(false)} 
//                     className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
//                   >
//                     Close
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}

//           {/* algomatrix stats modal */}
//           {isAlgoMatrixModalOpen && (
//             <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//               <div
//                 className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
//                 onClick={() => setIsAlgoMatrixModalOpen(false)}
//               ></div>

//               <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
//                 <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">AlgoMatrix Stats</h2>

//                 {/* Check if algoMatrixData exists */}
//                 {algoMatrixData ? (
//                   <div className="overflow-auto">
//                     <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
//                       <thead className="bg-gray-300 text-black text-sm">
//                         <tr>
//                           <th className="py-3 px-4 text-center">Title</th>
//                           <th className="py-3 px-4 text-center">Data</th>
//                         </tr>
//                       </thead>
//                       <tbody className="text-gray-700 text-sm border-b">
//                         <tr>
//                           <td className="py-3 px-4 text-center">User Name</td>
//                           <td className="py-3 px-4 text-center">{algoMatrixData.userName}</td>
//                         </tr>
//                         <tr>
//                           <td className="py-3 px-4 text-center">Email</td>
//                           <td className="py-3 px-4 text-center">{algoMatrixData.email}</td>
//                         </tr>
//                         <tr>
//                           <td className="py-3 px-4 text-center">Plan Type</td>
//                           <td className="py-3 px-4 text-center">{algoMatrixData.plantype}</td>
//                         </tr>
//                         <tr>
//                           <td className="py-3 px-4 text-center">Plan Expiry Date</td>
//                           <td className="py-3 px-4 text-center">{new Date(algoMatrixData.planExpiredDate).toISOString().split('T')[0]}</td>
//                         </tr>
//                         <tr>
//                           <td className="py-3 px-4 text-center">Account Status</td>
//                           <td className="py-3 px-4 text-center">{algoMatrixData.accountStatus}</td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>
//                 ) : (
//                   <p className="text-center text-gray-500 my-6">No AlgoMatrix stats available for this user.</p>
//                 )}

//                 <div className="flex justify-end mt-6">
//                   <button
//                     onClick={() => setIsAlgoMatrixModalOpen(false)}
//                     className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
//                   >
//                     Close
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}

//           {/* trader hub stats modal */}
//           {isTraderHubModalOpen && (
//             <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//               <div
//                 className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
//                 onClick={() => setIsTraderHubModalOpen(false)}
//               ></div>

//               <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
//                 <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">TraderHub Stats</h2>

//                 {/* Check if traderHubData exists */}
//                 {traderHubData ? (
//                   <div className="overflow-auto">
//                     <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
//                       <thead className="bg-gray-300 text-black text-sm">
//                         <tr>
//                           <th className="py-3 px-4 text-center">Column</th>
//                           <th className="py-3 px-4 text-center">Data</th>
//                         </tr>
//                       </thead>
//                       <tbody className="text-gray-700 text-sm border-b">
//                         <tr>
//                           <td className="py-3 px-4 text-center">Name</td>
//                           <td className="py-3 px-4 text-center">{traderHubData.name}</td>
//                         </tr>
//                         <tr>
//                           <td className="py-3 px-4 text-center">Email</td>
//                           <td className="py-3 px-4 text-center">{traderHubData.email}</td>
//                         </tr>
//                         <tr>
//                           <td className="py-3 px-4 text-center">Plan Type</td>
//                           <td className="py-3 px-4 text-center">{traderHubData.planTypehub}</td>
//                         </tr>
//                         <tr>
//                           <td className="py-3 px-4 text-center">Expiry Date</td>
//                           <td className="py-3 px-4 text-center">{new Date(traderHubData.expiryDate).toISOString().split('T')[0]}</td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>
//                 ) : (
//                   <p className="text-center text-gray-500 my-6">No TraderHub stats available for this user.</p>
//                 )}

//                 <div className="flex justify-end mt-6">
//                   <button
//                     onClick={() => setIsTraderHubModalOpen(false)}
//                     className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
//                   >
//                     Close
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}

//           {/* WhatsApp Message Modal */}
//           {isWhatsAppModalOpen && (
//             <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//               <div 
//                 className="modal-overlay absolute inset-0 bg-black opacity-70" 
//                 onClick={() => setIsWhatsAppModalOpen(false)}
//               >
//               </div>
              
//               <div className="modal relative bg-white rounded-md shadow-2xl p-6 w-auto max-w-2xl">
//                 <div className="flex flex-row items-center text-2xl space-x-4">
//                 <BsWhatsapp className="text-green-700"/>
//                 <h2 className="font-bold text-green-700 text-center">
//                   Send WhatsApp Message
//                 </h2>
//                 </div>

//                 {/* State Dropdown */}
//                 <div className="my-4">
//                   <label className="block mb-1 mx-2 font-semibold text-cyan-800">Assistant State</label>
//                   <select
//                     value={selectedState}
//                     onChange={handleStateChange}
//                     className="w-full border p-2 rounded"
//                   >
//                     <option value="">Select a state</option>
//                     {Object.keys(assistantCoachesOptions).map(state => (
//                       <option key={state} value={state}>{state}</option>
//                     ))}
//                   </select>
//                 </div>

//                 {/* Assistant Dropdown */}
//                 {selectedState && (
//                   <div className="my-4">
//                     <label className="block mb-1 mx-2 font-semibold text-cyan-800">Assistant Coach</label>
//                     <select
//                       value={selectedAssistant?.name || ''}
//                       onChange={handleAssistantChange}
//                       className="w-full border p-2 rounded"
//                     >
//                       <option value="">Select an assistant</option>
//                       {assistantCoachesOptions[selectedState]?.map((assistant, index) => (
//                         <option key={index} value={assistant.name}>
//                           {assistant.name} ({assistant.contact})
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                 )}

//                 {/* Message Input */}
//                 <div className="my-4">
//                   <label className="block mb-1 mx-2 font-semibold text-cyan-800">Message Content</label>
//                   <textarea
//                     value={customMessage}
//                     onChange={(e) => setCustomMessage(e.target.value)}
//                     rows={5}
//                     className="w-full border p-2 rounded"
//                   ></textarea>
//                 </div>

//                 {/* Modal Footer */}
//                 <div className="flex justify-end mt-4">
//                   <button
//                     onClick={() => setIsWhatsAppModalOpen(false)}
//                     className="mr-2 text-gray-500 border border-gray-800 rounded-full px-4 py-1 font-semibold"
//                   >
//                     Cancel
//                   </button>
//                   <button
//                     onClick={sendWhatsAppMessage}
//                     className="text-white border bg-green-600 rounded-full px-4 py-1 font-semibold"
//                   >
//                     Send Message
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}

//           {/* Show trading stats modal */}
//           <UserTableTradeStats
//             email={selectedEmailForTradingStats || ''}
//             isOpen={isTradingStatsModalOpen}
//             onClose={() => setIsTradingStatsModalOpen(false)}
//           />
            
//             {/* Edit User Modal */}
//             <UsersTableEditModal 
//               user={selectedUser}
//               isOpen={isModalOpen}
//               onClose={() => setIsModalOpen(false)}
//               onSave={handleSave} 
//             />
//           </>
//         )}
//     </div>
//   );
// };

// export default UserTable;