// src/hooks/MiniApp/useFundedStatus.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext';
import { useAuth } from '../../context/AuthContext';

interface FundStatus {
  fundEvalProgress: string;
  fundStatus: string;
}

const useFundedStatus = () => {
  const [fundStatus, setFundStatus] = useState<FundStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();
  const { user } = useAuth();

  useEffect(() => {
    const fetchFundStatus = async () => {
      if (!user?.email) return;

      try {
        const response = await fetch(`${apiUrl}/api/fund/status/${user.email}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setFundStatus(data);
      } catch (err) {
        console.error('Error fetching fund status:', err);
        setError('Failed to load fund status');
      } finally {
        setLoading(false);
      }
    };

    fetchFundStatus();
  }, [apiUrl, user?.email]);

  return { fundStatus, loading, error };
};

export default useFundedStatus;