// src/components/client/MiniApp/App03_MySubRegistration.tsx

import React, { useState, useEffect } from 'react';
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// import custom file
import MyTicketAlert from '../MiniApp/App_AlertMessage';
import useSubValidation from '../../../validate/useSubValidation';
import { useEventRegTerms } from '../../../context/SubRegTermContext';
import { useSubData, Plan, Product, SubUser } from '../../../hooks/Subscription/useSubData';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import useCurrencyConversion from '../../../hooks/currencyAPI';

interface RegistrationProps {
  plan: Plan;
  selectedProduct: Product | null; 
  liveOrderId: string; 
  demoOrderId: string;
  renewalData?: Partial<SubUser> | null;
  isRenew?: boolean;  
  onBack: () => void;
}

const MySubRegistration: React.FC<RegistrationProps> = ({ 
  plan, selectedProduct, liveOrderId, demoOrderId, renewalData, isRenew = false, onBack
}) => {
  // import custom hook
  const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
  const { user } = useUserDashboard();
  const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
  // terms and conditions
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const termsAndConditions = useEventRegTerms();
  // default values to fill in field
  const [fullName, setFullName] = useState(user?.username || '');
  const [email, setEmail] = useState(user?.email || '');
  // referral 
  const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
  const [referralEnabled, setReferralEnabled] = useState(false);
  // voucher code
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherEnabled, setVoucherEnabled] = useState(false);
  const [isVoucherApplied, setIsVoucherApplied] = useState(false);
  const [planAmount, setPlanAmount] = useState(plan.planAmount); 
  // account info
  const [selectedAccountServer, setSelectedAccountServer] = useState('');
  const [liveAccountNumber, setLiveAccountNumber] = useState('');
  const [liveAccountPassword, setLiveAccountPassword] = useState('');
  const [demoAccountNumber, setDemoAccountNumber] = useState('');
  const [demoAccountPassword, setDemoAccountPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  // payment info
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
  const [notes, setNotes] = useState('');
  // Success and error messages for alerts
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successTitle, setSuccessTitle] = useState<string | null>(null);
  const [errorTitle, setErrorTitle] = useState<string | null>(null);
  // for trial
  const [planType, setPlanType] = useState(''); 
  const trialPeriod = getTrialPeriodByPlanId(plan.planId);
  // enable/disable section field
  const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
  const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
  const [isRenewDisabled, setIsRenewDisabled] = useState(false);
  // state to handle open and close modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  // state to handle currency conversion rate
  const { usdAmount, myrAmount } = useCurrencyConversion(
    plan.planCurrency === 'MYR' ? planAmount : 0,
    plan.planCurrency === 'USD' ? planAmount : undefined
  );

  // Update fields if `user` data changes
  useEffect(() => {
    if (user && !renewalData) {
      setFullName(user.username);
      setEmail(user.email);
    }
  }, [user, renewalData]);  

  useEffect(() => {
    // Enable or disable the trading section based on `planAccount` value
    setIsTradingSectionEnabled(plan.planAccount === 'Enable');
  }, [plan.planAccount]);
  
  // update renewal data field
  useEffect(() => {
    if (!renewalData) return;

    console.log('Renewal Data:', renewalData);
  
    setFullName(renewalData.subUserName || '');
    setEmail(renewalData.subUserEmail || '');
  
    // Handle Live Account Data
    if (renewalData.subAccountType?.toLowerCase() === 'live') {
      setLiveAccountNumber(renewalData.subAccountNumber || '');
      setLiveAccountPassword(renewalData.subAccountPassword || '');
      setSelectedAccountServer(renewalData.subAccountServer || '');
    }
  
    // Handle Demo Account Data
    if (renewalData.subAccountType?.toLowerCase() === 'demo') {
      setDemoAccountNumber(renewalData.subAccountNumber || '');
      setDemoAccountPassword(renewalData.subAccountPassword || '');
      setSelectedAccountServer(renewalData.subAccountServer || '');
    }
  
    setPlanType(renewalData.subPlanType || '');
    setPaymentMethod(renewalData.subPaymentMethod || '');
    setVoucherEnabled(renewalData.subVoucherCheck === 1);
  }, [renewalData]);        
        
  // handle submit subscription data component
  const handleSubscribe = async () => {
    const isRenewAction = !!renewalData;
  
    // Prevent double-click by checking the correct state
    if (isRenewAction && isRenewDisabled) return;
    if (!isRenewAction && isSubscribeDisabled) return;
  
    if (isRenewAction) {
      setIsRenewDisabled(true);
    } else {
      setIsSubscribeDisabled(true);
    }
  
    try {
      // Perform validation
      const missingFields = validateSubscriptionForm({
        isRenew,
        planType,
        liveAccountNumber,
        liveAccountPassword,
        demoAccountNumber,
        demoAccountPassword,
        paymentReceipt,
        termsAccepted,
        isTradingSectionEnabled,
      });
  
      // If validation fails, show error and re-enable the button
      if (missingFields.length > 0) {
        setErrorTitle("Missing Fields");
        setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
        setIsRenewDisabled(false);
        setIsSubscribeDisabled(false);
        return;
      }
  
      if (!selectedProduct) {
        setErrorTitle("Error");
        setErrorMessage("No product selected. Please select a product and try again.");
        setIsRenewDisabled(false);
        setIsSubscribeDisabled(false);
        return;
      }
  
      // Prepare subscription data
      setIsSubscribeDisabled(true);
      setIsRenewDisabled(true);
  
      const serverOptions: { [key: string]: { Demo: string; Live: string } } = {
        FortunePrimeGlobal: {
          Demo: "FortunePrimeGlobal-Demo",
          Live: "FortunePrimeGlobal-Live",
        },
        LirunexLimited: {
          Demo: "LirunexLimited-Demo",
          Live: "LirunexLimited-Live",
        },
      };
      
      const accountServer =
        selectedAccountServer === "FortunePrimeGlobal-Demo" ||
        selectedAccountServer === "FortunePrimeGlobal-Live"
          ? serverOptions.FortunePrimeGlobal
          : serverOptions.LirunexLimited;
           
  
      let paymentReceiptPath = "";
      if (paymentReceipt) {
        const fileExtension = paymentReceipt.name.split('.').pop();
        const currentDate = new Date().toISOString().split("T")[0];
        const baseDir = liveOrderId.startsWith("sub-ALG")
          ? "/uploads/forAlgoMatrixReceipts"
          : liveOrderId.startsWith("sub-TRA")
          ? "/uploads/forTraderHubReceipts"
          : "/uploads/otherReceipts";
  
        paymentReceiptPath = `${baseDir}/${liveOrderId}_${currentDate}.${fileExtension}`;
      }
  
      const baseSubscriptionData = {
        subOrderId: liveOrderId,
        subProductId: selectedProduct.productId,
        subPlanId: plan.planId,
        subUserName: fullName,
        subUserEmail: email,
        subPlanType: planType,
        subPlanAmount: planType === "Trial" ? 0 : plan.planAmount,
        subPlanPeriod: planType === "Trial" ? trialPeriod || 0 : plan.planPeriod,
        subDateSubmit: new Date(),
        subExpiredDate: new Date(),
        subExpiredDays: 0,
        subStatus: "Pending",
        subTrialCheck: planType === "Trial" ? 1 : 0,
        subVoucherCheck: voucherEnabled ? 1 : 0,
        subDemoCheck: isTradingSectionEnabled ? 1 : 0,
        subPaymentMethod: paymentMethod,
        subNotes: notes,
        subRemark: "",
        subTermAgreed: termsAccepted ? "Yes" : "No",
        subPaymentReceipts: paymentReceiptPath ? paymentReceiptPath : "",
      };
  
      let subscriptionData;
  
      if (isRenewAction) {
        // Renew action: Submit only one type of account (Demo or Live)
        const accountType = renewalData?.subAccountType?.toLowerCase();
        if (selectedProduct.productName === "TraderHub") {
          subscriptionData = {
            ...baseSubscriptionData,
            subOrderId: renewalData?.subOrderId || '',
            subAccountType: undefined,
            subAccountServer: undefined,
            subAccountNumber: undefined,
            subAccountPassword: undefined,
          };
        } else {
          subscriptionData = {
            ...baseSubscriptionData,
            subOrderId: renewalData?.subOrderId || '',
            subAccountType: accountType === "demo" ? "Demo" : "Live",
            subAccountServer: accountType === "demo" ? selectedAccountServer : selectedAccountServer,
            subAccountNumber: accountType === "demo" ? demoAccountNumber : liveAccountNumber,
            subAccountPassword: accountType === "demo" ? demoAccountPassword : liveAccountPassword,
          };
        }
      } else {
        // Subscribe action: Submit both Live and Demo accounts
        subscriptionData = isTradingSectionEnabled
          ? [
              {
                ...baseSubscriptionData,
                subOrderId: liveOrderId,
                subAccountType: "Live",
                subAccountServer: accountServer.Live,
                subAccountNumber: liveAccountNumber,
                subAccountPassword: liveAccountPassword,
              },
              {
                ...baseSubscriptionData,
                subOrderId: demoOrderId,
                subAccountType: "Demo",
                subAccountServer: accountServer.Demo,
                subAccountNumber: demoAccountNumber,
                subAccountPassword: demoAccountPassword,
              },
            ]
          : [baseSubscriptionData];
      }
  
      // Register the subscription(s)
      const responses = Array.isArray(subscriptionData)
        ? await Promise.all(
            subscriptionData.map((data) => registerSubscription(data as SubUser, paymentReceipt))
          )
        : [await registerSubscription(subscriptionData as SubUser, paymentReceipt)];
  
      const allSuccess = responses.every((response) => response?.success);
  
      if (allSuccess) {
        setSuccessTitle("Subscription Success");
        setSuccessMessage("Subscription saved successfully!");
        setErrorMessage(null);

        // Disable the button permanently after successful submission
        setIsSubscribeDisabled(true);
        setIsRenewDisabled(true);
      } else {
        setErrorTitle("Subscription Error");
        setErrorMessage("There was an issue saving your subscription. Please try again.");
        setIsSubscribeDisabled(false);
        setIsRenewDisabled(false);
      }
    } catch (error) {
      setErrorTitle("Subscription Error");
      setErrorMessage("An error occurred while processing your subscription. Please try again.");
      setIsSubscribeDisabled(false);
      setIsRenewDisabled(false);
    }
  };
  

  const closeAlert = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
    setSuccessTitle(null);
    setErrorTitle(null);
  };

  // --------------------------------------- Apply for Voucher ------------------------------------- //
  // add conde regex pattern
  const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

  // Function to apply the voucher code discount
  const applyVoucher = async () => {
    if (!voucherCodePattern.test(voucherCode)) {
      setErrorTitle("Invalid Voucher Code");
      setErrorMessage("Invalid voucher code format. Please enter a valid code.");
      return;
    }
  
    try {
      const entitlement = await validateVoucherEntitlement(voucherCode);
  
      if (!entitlement.entitled) {
        if (entitlement.message.includes("User not eligible for voucher entitlement")) {
          setErrorTitle("Voucher Ineligible");
          setErrorMessage("You are not eligible to redeem this voucher.");
        } else if (entitlement.message.includes("Voucher expired")) {
          setErrorTitle("Voucher Expired");
          setErrorMessage("This voucher has expired and can no longer be redeemed.");
        } else if (entitlement.message.includes("No remaining redeems")) {
          setErrorTitle("No Remaining Redeems");
          setErrorMessage("This voucher has no remaining redeems and cannot be used.");
        } else {
          setErrorTitle("Voucher Not Eligible");
          setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
        }
        return;
      }
  
      const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
      if (voucher) {
        let discountedAmount = plan.planAmount;
        if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
          discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
        } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
          discountedAmount -= voucher.voucherAmount;
        }
        setPlanAmount(Math.max(0, discountedAmount));
        setSuccessTitle("Voucher Applied");
        setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
        setErrorMessage(null);
        setIsVoucherApplied(true); 
      } else {
        setErrorTitle("Invalid Voucher Code");
        setErrorMessage("Invalid voucher code for the selected plan.");
      }
    } catch (error) {
      setErrorTitle("Voucher Validation Error");
      setErrorMessage("Error validating voucher entitlement.");
    } finally {
      setIsSubscribeDisabled(false); 
      setIsRenewDisabled(false);
    }
  };  

  // --------------------------------------- Apply for Trial ------------------------------------- //
  const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = event.target.value;
    setPlanType(selectedType);
  
    if (selectedType === 'Trial') {
      try {
        const entitlement = await validateTrialEntitlement(plan.planId);

        if (entitlement.subTrialCheck === 0) { 
          setErrorTitle("Trial Already Claimed");
          setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
          setPlanType('Subscribe'); 
          return;
        }

        if (entitlement.entitled) {
          setSuccessTitle("Trial Entitlement");
          setSuccessMessage("You are entitled to the trial for this plan!");
          setErrorMessage(null);
          setPlanType('Trial'); 
        } else {
          setErrorTitle("Trial Not Entitlement");
          setErrorMessage("Your email is not entitled to a trial for this plan.");
          setPlanType('Subscribe'); 
        }
      } catch (error) {
        setErrorTitle("Trial Validation Error");
        setErrorMessage("Error validating trial entitlement.");
        setPlanType('Subscribe'); 
      }
    } else {
      setPaymentMethod(''); 
      setVoucherCode('');
    }
  };  

  // --------------------------------------- Payment Receipts  ------------------------------------- //
  const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      const validTypes = ["image/png", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        setErrorTitle("Invalid File Type");
        setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
        setPaymentReceipt(null); 
        return;
      }
      setPaymentReceipt(file); 
    }
  };
  
  return (
    <div>
      {successMessage && (
        <MyTicketAlert
          visible={true}
          type="success"
          title={successTitle || ''}
          message={successMessage || ''}
          onClose={closeAlert}
        />
      )}

      {errorMessage && (
        <MyTicketAlert
          visible={true}
          type="error"
          title={errorTitle || ''}
          message={errorMessage || ''}
          onClose={closeAlert}
        />
      )}

      <div className="w-auto h-auto max-w-[600px] max-h-[650px] flex justify-self-center px-2
            overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack
            md:text-md md:h-[650px] 
            sm:h-[550px] sm:p-1 sm:text-sm 
            xs:h-[500px] xs:px-1 xs:text-xs"
      >
        {loading ? (
          <p>Loading data...</p>
        ) : (
          <form>
            <div className="flex justify-center my-4 
                  sm:my-0 
                  xs:my-0"
            >
              <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
                Basic Information
              </span>
            </div>

            <div className="my-4 mx-8
                  sm:text-xs
                  xs:text-xs"
            >
              <p className="mb-6 font-semibold">
                {plan.planDescription}
              </p>
            </div>

            {/* order id */}
            <div className="my-6">
              <label className="mx-2 font-semibold">Order ID:</label>
              <input
                className="w-full px-3 py-2 border rounded-md"
                name="subOrderId"
                type="text"
                value={liveOrderId}
                placeholder="Generated Order ID"
                disabled
              />
            </div>

            {/* user full name */}
            <div className="my-6">
              <label className="mx-2 font-semibold">Full Name:</label>
              <input
                className="w-full px-3 py-2 border rounded-md"
                name="subUserName"
                type="text"
                value={fullName}
                disabled
              />
            </div>

            {/* user email */}
            <div className="my-6">
              <label className="mx-2 font-semibold">Email:</label>
              <input
                className="w-full px-3 py-2 border rounded-md"
                name="subUserEmail"
                type="text"
                value={email}
                disabled
              />
            </div>

            {/* Referral Email Field */}
            <div className="mt-6 mb-6">
              <div className="flex items-center">
                <label className="mx-2 font-semibold mr-2">Referral Email</label>
                <div className="flex justify-between items-center">
                  <FaInfoCircle className="ml-0 
                                  sm:mr-1 
                                  xs:mr-1"
                  />
                  <div className="text-xs font-thin ml-2 text-rose-500 
                        sm:text-[11px]
                        xs:text-[10px]"
                  >
                    ( Enter your referrer's email )
                  </div>
                  <input
                    type="checkbox"
                    className="ml-2"
                    checked={referralEnabled}
                    onChange={() => setReferralEnabled(!referralEnabled)}
                  />
                </div>
              </div>
              <div className="mb-4 flex items-center">
                <input
                  className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-50 text-gray-300 cursor-not-allowed'}`}
                  name="referral-email"
                  type="email"
                  value={referralEmail}
                  onChange={(e) => setReferralEmail(e.target.value)}
                  disabled={!referralEnabled}
                  placeholder="Enter referral email"
                />
                <button
                  type="button"
                  className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white
                    sm:text-xs
                    xs:text-xs"
                  onClick={applyVoucher}
                  disabled={isVoucherApplied}
                >
                  Apply
                </button>
              </div>
            </div>

            {/* Plan information */}
            <div className="flex justify-center mb-4">
              <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
                Plan Selection
              </span>
            </div>

            {/* product name field */}
            <div className="my-6">
              <label className="mx-2 font-semibold">Product Name:</label>
              <input
                className="w-full px-3 py-2 border rounded-md"
                name="subProductId"
                type="text"
                value={selectedProduct?.productName || ""}
                disabled
              />
            </div>

            {/* plan name field */}
            <div className="my-6">
              <label className="mx-2 font-semibold">Plan Name:</label>
              <input
                className="w-full px-3 py-2 border rounded-md"
                name="subPlanName"
                type="text"
                value={plan.planName}
                disabled
              />
            </div>

            {/* plan period field */}
            <div className="my-6">
              <label className="mx-2 font-semibold">Plan Period:</label>
              <input
                className="w-full px-3 py-2 border rounded-md"
                name="subPlanPeriod"
                type="number"
                value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
                disabled
              />
            </div>

            {/* plan type field */}
            <div className="my-6">
              <div className="flex justify-normal items-center">
                <label className="mx-2 font-semibold">Plan Type:</label>
                <FaInfoCircle className="ml-0 
                  sm:mr-1 
                  xs:mr-1"
                />
                <div className="text-xs font-thin ml-2 text-rose-500
                      sm:text-[11px]
                      xs:text-[10px]"
                >
                  ( Select your plan type )
                </div>
              </div>
              <select 
                className={`w-full px-3 py-2 border rounded-md ${isRenew ? 'bg-gray-50' : '' }`}
                name="subPlanType"
                value={planType}
                onChange={handlePlanTypeChange}
                disabled={isRenew}
              >
                <option value="">Select a plan type</option>
                <option value="Subscribe">Subscribe</option>
                <option value="Trial">Trial</option>
              </select>
            </div>

            {/* Plan Amount Field */}
            <div className="my-6">
              <div className="flex justify-normal items-center mx-2">
                <label className="font-semibold">Plan Amount</label>
                <FaInfoCircle className="w-4 h-4 ml-2 
                                sm:ml-0 
                                xs:ml-0"
                />
                <div className="text-xs text-rose-600 ml-2
                      sm:text-[11px]
                      xs:text-[10px]"
                >
                  (Conversion based on today’s rate:
                  <span className="font-bold text-sm
                          sm:text-xs
                          xs:text-xs"
                  >
                    {plan.planCurrency === 'MYR' && ` USD ${usdAmount}`}
                    {plan.planCurrency === 'USD' && ` MYR ${myrAmount}`}
                  </span>)
                </div>
              </div>
              <div className="flex justify-between items-center">
                <input 
                  className="w-full px-3 py-2 border rounded-md" 
                  name="subPlanAmount"
                  type="number" 
                  value={planType === 'Trial' ? 0 : planAmount}
                  disabled 
                />
                <span className="ml-4">{plan.planCurrency}</span>
              </div>
            </div>

            {/* Apply Voucher Field */}
            <div className="my-6">
              <div className="mx-2 flex items-center">
                <label className={
                  `font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}
                        sm:mr-0
                        xs:mr-0
                  `
                  }>
                  Apply Voucher
                </label>
                <div className="flex justify-normal items-center">
                  <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
                  <span className={`
                      text-xs ml-2 text-nowrap ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}
                      sm:text-[11px] 
                      xs:text-[10px]`
                    }
                  >
                    ( Apply voucher for subscription.)
                  </span>
                  <input
                    type="checkbox"
                    className="ml-2 accent-cyan-800"
                    checked={voucherEnabled}
                    onChange={() => setVoucherEnabled(!voucherEnabled)}
                    disabled={planType === 'Trial'}
                  />
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="text"
                  name="subVoucherCode"
                  className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-50 text-gray-300 cursor-not-allowed'}`}
                  placeholder="Enter voucher code"
                  value={voucherCode}
                  onChange={(e) => setVoucherCode(e.target.value)}
                  disabled={!voucherEnabled}
                />
                <button
                  type="button"
                  className={`
                    ml-2 px-4 py-2 text-sm rounded-md 
                    sm:text-xs
                    xs:text-xs
                    ${
                      isVoucherApplied || planType === 'Trial'
                        ? "bg-gray-100 text-gray-300 cursor-not-allowed"
                        : "bg-cyan-800 text-white"
                    }`
                  }
                  onClick={applyVoucher}
                  disabled={isVoucherApplied || planType === 'Trial'}
                >
                  Apply
                </button>
              </div>
            </div>

            {/* fill in Account Section */}
            {/* Account Type Field (for Live) */}
            <div className="p-6 border rounded-xl 
              sm:p-4
              xs:p-4"
            >
              <div className="text-rose-600 mb-4
                sm:text-xs
                xs:text-xs"
              >
                {isTradingSectionEnabled ? (
                  <>
                    Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent 
                    AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, please contact 
                    <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a>or email us at 
                    <a href="mailto:general@r1trader.com" className="text-cyan-800 underline mx-2">general@r1trader.com</a>
                  </>
                ) : (
                  "No need to complete fill in trading account section and disabled for this plan."
                )}
              </div>

              <div className="flex items-center justify-center mb-4">
                <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
              </div>

              {/* account type live */}
              <div className="my-6">
                <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
                <input
                  className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
                  name="subAccountType"
                  value="Live"
                  disabled
                />
              </div>

              {/* account number live */}
              <div className="my-6">
                <div className="flex justify-normal items-center space-x-2">
                  <label className={
                    `ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }
                      sm:mr-0
                      xs:mr-0`
                    }
                  >
                    Account Number
                  </label>
                  <FaInfoCircle className={
                    `${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
                  <div className={
                    `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
                      sm:text-[11px] 
                      xs:text-[10px]`
                    } 
                  >
                    ( This is your live trading account number )
                  </div>
                </div>
                <input
                  className="w-full px-3 py-2 border rounded-md"
                  name="subAccountNumber"
                  type="number"
                  value={liveAccountNumber}
                  onChange={(e) => setLiveAccountNumber(e.target.value)}
                  placeholder="Enter trading account number..."
                  disabled={!isTradingSectionEnabled || isRenew}
                />
              </div>

              {/* account password live */}
              <div className="my-6 relative">
                <div className="flex justify-normal items-center space-x-2">
                  <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password</label>
                  <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
                  <div className={
                    `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
                      sm:text-[11px] 
                      xs:text-[10px]`
                    } 
                  >
                    ( This is your account "read-only" password. )
                  </div>
                </div>
                <div className="relative">
                  <input
                    className="w-full px-3 py-2 border rounded-md"
                    name="subAccountPassword"
                    type={showPassword ? "text" : "password"}
                    value={liveAccountPassword}
                    onChange={(e) => setLiveAccountPassword(e.target.value)}
                    placeholder="Enter trading account investor (read-only) password..."
                    disabled={!isTradingSectionEnabled || isRenew}
                  />
                  <button
                    type="button"
                    className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>

              {/* account server live */}
              <div className="my-6">
                <div className="flex justify-normal items-center space-x-2">
                  <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
                  <div className="flex justify-between items-center">
                    <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
                  </div>
                  <div className={
                    `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
                      sm:text-[11px] 
                      xs:text-[10px]`
                    } 
                  >
                    ( This is your live trading account login server )
                  </div>
                </div>
                <select
                  className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
                  name="subAccountServer"
                  value={selectedAccountServer}
                  onChange={(e) => setSelectedAccountServer(e.target.value)}
                  disabled={!isTradingSectionEnabled || isRenew}
                >
                  <option value="">Select server</option>
                  <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
                  <option value="LirunexLimited-Live">Lirunex Limited Live</option>
                </select>
              </div>

              <div className="flex items-center justify-center mb-4">
                <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
              </div>

              {/* account type demo */}
              <div className="my-6">
                <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
                <input
                  className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
                  name="subAccountType"
                  value="Demo"
                  disabled
                />
              </div>

              {/* account number demo */}
              <div className="my-6">
                <div className="flex justify-normal items-center space-x-2">
                  <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
                  <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
                  <div className={
                    `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
                        sm:text-[11px] 
                        xs:text-[10px]`
                    } 
                  >
                    ( This is your demo trading account number )
                  </div>
                </div>
                <input
                  className="w-full px-3 py-2 border rounded-md"
                  name="subAccountNumber"
                  type="number"
                  value={demoAccountNumber}
                  onChange={(e) => setDemoAccountNumber(e.target.value)}
                  placeholder="Enter demo account number..."
                  disabled={!isTradingSectionEnabled || isRenew}
                />
              </div>

              {/* account password demo */}
              <div className="my-6 relative">
                <div className="flex justify-normal items-center space-x-2">
                  <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password</label>
                  <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
                  <div className={
                    `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
                        sm:text-[11px] 
                        xs:text-[10px]`
                    }
                  >
                    ( This is your trading account "read-only" password. )
                  </div>
                </div>
                <div className="relative">
                  <input
                    className="w-full px-3 py-2 border rounded-md"
                    type={showPassword ? "text" : "password"}
                    name="subAccountPassword"
                    value={demoAccountPassword}
                    onChange={(e) => setDemoAccountPassword(e.target.value)}
                    placeholder="Enter demo trading account investor (read-only) password..."
                    disabled={!isTradingSectionEnabled || isRenew}
                  />
                  <button
                    type="button"
                    className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
                    onClick={() => setShowPassword(!showPassword)}
                    disabled={!isTradingSectionEnabled || isRenew}
                  >
                    {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>

              {/* account server demo */}
              <div className="my-6 ">
                <div className="flex justify-normal items-center space-x-2">
                  <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
                  <div className="flex justify-between items-center">
                    <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
                  </div>
                  <div className={
                    `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
                        sm:text-[11px] 
                        xs:text-[10px]`
                    }
                  >
                    ( This is your trading account login server. )
                  </div>
                </div>
                <select
                  className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
                  name="subAccountServer"
                  onChange={(e) => setSelectedAccountServer(e.target.value)}
                  disabled={!isTradingSectionEnabled || isRenew}
                >
                  <option value="">Select server</option>
                  <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
                  <option value="LirunexLimited-Demo">Lirunex Limited Demo</option>
                </select>
              </div>
            </div>

            <div className="flex justify-center my-8">
              <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
            </div>

            {/* Payment Method Field */}
            <div className="my-6">
              <div className="flex justify-normal items-center space-x-2">
                <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
                <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
                <div className={
                  `text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }
                      sm:text-[11px] 
                      xs:text-[10px]`
                  }
                >
                  ( Choose your payment method )
                </div>
              </div>
              {/* <div className="flex items-center mx-4">
                <input
                  type="radio"
                  name="subPaymentMethod"
                  value="Bank Transfer"
                  className="accent-cyan-800"
                  onChange={() => setPaymentMethod('Bank Transfer')}
                  disabled={planType === 'Trial'}
                />
                <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
              </div>
              {paymentMethod === 'Bank Transfer' && (
                <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
                  <span>Bank Account</span><br />
                  <div className="flex justify-between items-center">
                    <div>
                      <li>Please proceed with the payment through USDT (cryptocurrency).</li>
                    </div>
                    <FaClipboard 
                      className="ml-2 cursor-pointer w-6 h-6"
                      onClick={() => navigator.clipboard.writeText("8604820432")} 
                    />
                  </div>
                </div>
              )} */}
              <div className="flex items-center mx-4">
                <input
                  type="radio"
                  name="subPaymentMethod"
                  value="USDT"
                  className="accent-cyan-800"
                  onChange={() => setPaymentMethod('USDT')}
                  disabled={planType === 'Trial'}
                />
                <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>
                  USDT (Cryptocurrency)
                </label>
              </div>
              {paymentMethod === 'USDT' && (
                <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4
                      sm:text-xs sm:p-2
                      xs:text-[10px] xs:p-2"
                >
                  <span>Wallet Address:</span><br />
                  <div className="flex justify-between items-center">
                    <div>
                      <li>Tron (TRC20) Network</li>
                      <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
                    </div>
                    <FaClipboard 
                      className="ml-2 cursor-pointer w-6 h-6
                        sm:w-4 sm:h-4 sm:ml-0
                        xs:w-3 xs:h-3 xs:ml-0
                      "
                      onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Payment Receipts Field */}
            <div className="my-6">
              <div className="flex justify-normal items-center space-x-2">
                <label className="ml-2 font-semibold">Payment Receipts</label>
                <FaInfoCircle />
                <div className="text-xs text-rose-600
                      sm:text-[11px] 
                      xs:text-[10px]"
                >
                  ( Payment receipt upload only support "png" or "jpg" format. )
                </div>
              </div>
              <input
                className="w-full px-3 py-2 border rounded-md"
                type="file"
                name="subPaymentReceipts"
                onChange={handlePaymentReceiptChange}
              />
            </div>

            {/* Notes Field */}
            <div className="my-6">
              <label className="mx-2 font-semibold">Notes</label>
              <textarea 
                name="subNotes" 
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Leave any notes here (optional)..." 
                className="w-full px-3 py-2 border rounded-md" 
              />
            </div>

            {/* Terms and Conditions Field */}
            <div className="flex justify-normal items-center">
              <input
                type="checkbox"
                className="accent-cyan-800 mr-2"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
              <span 
                className="cursor-pointer text-cyan-800 underline
                  sm:text-xs
                  xs:text-xs" 
                onClick={openModal}
              >
                I have read and understand the terms and conditions
              </span>
            </div>

            {/* Submit and Back buttons */}
            <div className="flex justify-between items-center mt-6 space-x-4">
              <button 
                onClick={onBack} 
                className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200
                  sm:text-xs
                  xs:text-xs"
              >
                <FaArrowCircleLeft />
                <div className="ml-2">Back to Details</div>
              </button>
              <button 
                type="button" 
                className={
                  `px-4 py-2 rounded-md flex items-center justify-center
                    sm:text-xs
                    xs:text-xs
                  ${
                    isSubscribeDisabled || isRenewDisabled || !termsAccepted 
                      ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
                      : 'bg-cyan-800 text-white' 
                    }`
                  }
                onClick={handleSubscribe}
                disabled={isSubscribeDisabled || isRenewDisabled || !termsAccepted}
              >
                <BiSolidPurchaseTag />
                <div className="ml-2">{isRenew ? 'Renew' : 'Subscribe'}</div>
              </button>
            </div>
          </form>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
            <span
              className="border-2 p-10 overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              {termsAndConditions}
            </span>
            <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
              close
            </button>
          </div>
        </div>
      )}

      {/* Placeholder for Alerts */}
      <MyTicketAlert 
        visible={false} 
        type="check" 
        message="" 
        onClose={() => {}} 
        onConfirm={() => {}} 
        onCancel={() => {}} 
      />
    </div>
  );
};

export default MySubRegistration;


// // udpate code --> 03 dec 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product, SubUser } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useCurrencyConversion from '../../../hooks/currencyAPI';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product | null; 
//   liveOrderId: string; 
//   demoOrderId: string;
//   renewalData?: Partial<SubUser> | null;
//   isRenew?: boolean;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ 
//   plan, selectedProduct, liveOrderId, demoOrderId, renewalData, isRenew = false, onBack
// }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
//   const [isRenewDisabled, setIsRenewDisabled] = useState(false);
//   // state to handle open and close modal
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//   // state to handle currency conversion rate
//   const { usdAmount, myrAmount } = useCurrencyConversion(
//     plan.planCurrency === 'MYR' ? planAmount : 0,
//     plan.planCurrency === 'USD' ? planAmount : undefined
//   );

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user && !renewalData) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user, renewalData]);  

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);
  
//   // update renewal data field
//   useEffect(() => {
//     if (!renewalData) return;
  
//     setFullName(renewalData.subUserName || '');
//     setEmail(renewalData.subUserEmail || '');
  
//     // Handle Live Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'live') {
//       setLiveAccountNumber(renewalData.subAccountNumber || '');
//       setLiveAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     // Handle Demo Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'demo') {
//       setDemoAccountNumber(renewalData.subAccountNumber || '');
//       setDemoAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     setPlanType(renewalData.subPlanType || '');
//     setPaymentMethod(renewalData.subPaymentMethod || '');
//     setVoucherEnabled(renewalData.subVoucherCheck === 1);
//   }, [renewalData]);        
        
//   // handle submit subscription data component
//   const handleSubscribe = async () => {
//     const isRenewAction = !!renewalData;
  
//     // Prevent double-click by checking the correct state
//     if (isRenewAction && isRenewDisabled) return;
//     if (!isRenewAction && isSubscribeDisabled) return;
  
//     if (isRenewAction) {
//       setIsRenewDisabled(true);
//     } else {
//       setIsSubscribeDisabled(true);
//     }
  
//     try {
//       // Perform validation
//       const missingFields = validateSubscriptionForm({
//         isRenew,
//         planType,
//         liveAccountNumber,
//         liveAccountPassword,
//         demoAccountNumber,
//         demoAccountPassword,
//         paymentReceipt,
//         termsAccepted,
//         isTradingSectionEnabled,
//       });
  
//       // If validation fails, show error and re-enable the button
//       if (missingFields.length > 0) {
//         setErrorTitle("Missing Fields");
//         setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//         setIsRenewDisabled(false);
//         setIsSubscribeDisabled(false);
//         return;
//       }
  
//       if (!selectedProduct) {
//         setErrorTitle("Error");
//         setErrorMessage("No product selected. Please select a product and try again.");
//         setIsRenewDisabled(false);
//         setIsSubscribeDisabled(false);
//         return;
//       }
  
//       // Prepare subscription data
//       setIsSubscribeDisabled(true);
//       setIsRenewDisabled(true);
  
//       const serverOptions: { [key: string]: { Demo: string; Live: string } } = {
//         FortunePrimeGlobal: {
//           Demo: "FortunePrimeGlobal-Demo",
//           Live: "FortunePrimeGlobal-Live",
//         },
//         LirunexLimited: {
//           Demo: "LirunexLimited-Demo",
//           Live: "LirunexLimited-Live",
//         },
//       };      
  
//       let paymentReceiptPath = "";
//       if (paymentReceipt) {
//         const fileExtension = paymentReceipt.name.split('.').pop();
//         const currentDate = new Date().toISOString().split("T")[0];
//         const baseDir = liveOrderId.startsWith("sub-ALG")
//           ? "/uploads/forAlgoMatrixReceipts"
//           : liveOrderId.startsWith("sub-TRA")
//           ? "/uploads/forTraderHubReceipts"
//           : "/uploads/otherReceipts";
  
//         paymentReceiptPath = `${baseDir}/${liveOrderId}_${currentDate}.${fileExtension}`;
//       }
  
//       const baseSubscriptionData = {
//         subOrderId: liveOrderId,
//         subProductId: selectedProduct.productId,
//         subPlanId: plan.planId,
//         subUserName: fullName,
//         subUserEmail: email,
//         subPlanType: planType,
//         subPlanAmount: planType === "Trial" ? 0 : plan.planAmount,
//         subPlanPeriod: planType === "Trial" ? trialPeriod || 0 : plan.planPeriod,
//         subDateSubmit: new Date(),
//         subExpiredDate: new Date(),
//         subExpiredDays: 0,
//         subStatus: "Pending",
//         subTrialCheck: planType === "Trial" ? 1 : 0,
//         subVoucherCheck: voucherEnabled ? 1 : 0,
//         subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//         subPaymentMethod: paymentMethod,
//         subNotes: notes,
//         subRemark: "",
//         subTermAgreed: termsAccepted ? "Yes" : "No",
//         subPaymentReceipts: paymentReceiptPath ? paymentReceiptPath : "",
//       };
  
//       let subscriptionData;
  
//       if (isRenewAction) {
//         // Renew action: Submit only one type of account (Demo or Live)
//         const accountType = renewalData?.subAccountType?.toLowerCase();
//         if (selectedProduct.productName === "TraderHub") {
//           subscriptionData = {
//             ...baseSubscriptionData,
//             subOrderId: renewalData?.subOrderId || '',
//             subAccountType: undefined,
//             subAccountServer: undefined,
//             subAccountNumber: undefined,
//             subAccountPassword: undefined,
//           };
//         } else {
//           subscriptionData = {
//             ...baseSubscriptionData,
//             subOrderId: renewalData?.subOrderId || '',
//             subAccountType: accountType === "demo" ? "Demo" : "Live",
//             subAccountServer: serverOptions[accountType === "demo" ? "Demo" : "Live"],
//             subAccountNumber: accountType === "demo" ? demoAccountNumber : liveAccountNumber,
//             subAccountPassword: accountType === "demo" ? demoAccountPassword : liveAccountPassword,
//           };
//         }
//       } else {
//         // Subscribe action: Submit both Live and Demo accounts
//         subscriptionData = isTradingSectionEnabled
//           ? [
//               {
//                 ...baseSubscriptionData,
//                 subOrderId: liveOrderId,
//                 subAccountType: "Live",
//                 subAccountServer: serverOptions["Live"],
//                 subAccountNumber: liveAccountNumber,
//                 subAccountPassword: liveAccountPassword,
//               },
//               {
//                 ...baseSubscriptionData,
//                 subOrderId: demoOrderId,
//                 subAccountType: "Demo",
//                 subAccountServer: serverOptions["Demo"],
//                 subAccountNumber: demoAccountNumber,
//                 subAccountPassword: demoAccountPassword,
//               },
//             ]
//           : [baseSubscriptionData];
//       }
  
//       // Register the subscription(s)
//       const responses = Array.isArray(subscriptionData)
//         ? await Promise.all(
//             subscriptionData.map((data) => registerSubscription(data as SubUser, paymentReceipt))
//           )
//         : [await registerSubscription(subscriptionData as SubUser, paymentReceipt)];
  
//       const allSuccess = responses.every((response) => response?.success);
  
//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);

//         // Disable the button permanently after successful submission
//         setIsSubscribeDisabled(true);
//         setIsRenewDisabled(true);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//         setIsSubscribeDisabled(false);
//         setIsRenewDisabled(false);
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//       setIsSubscribeDisabled(false);
//       setIsRenewDisabled(false);
//     }
//   };
  

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//       setIsRenewDisabled(false);
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   // --------------------------------------- Payment Receipts  ------------------------------------- //
//   const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0] || null;
//     if (file) {
//       const validTypes = ["image/png", "image/jpeg"];
//       if (!validTypes.includes(file.type)) {
//         setErrorTitle("Invalid File Type");
//         setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
//         setPaymentReceipt(null); 
//         return;
//       }
//       setPaymentReceipt(file); 
//     }
//   };
  
//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-w-[600px] max-h-[650px] flex justify-self-center overflow-y-scroll px-4
//             md:text-md md:h-[650px] 
//             sm:h-[550px] sm:p-1 sm:text-sm 
//             xs:h-[500px] xs:px-1 xs:text-xs"
//       >
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 
//                   sm:my-0 
//                   xs:my-0"
//             >
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8
//                   sm:text-xs
//                   xs:text-xs"
//             >
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 
//                                   sm:mr-1 
//                                   xs:mr-1"
//                   />
//                   <div className="text-xs font-thin ml-2 text-rose-500 
//                         sm:text-[11px]
//                         xs:text-[10px]"
//                   >
//                     ( Enter your referrer's email )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-50 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white
//                     sm:text-xs
//                     xs:text-xs"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct?.productName || ""}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center">
//                 <label className="mx-2 font-semibold">Plan Type:</label>
//                 <FaInfoCircle className="ml-0 
//                   sm:mr-1 
//                   xs:mr-1"
//                 />
//                 <div className="text-xs font-thin ml-2 text-rose-500
//                       sm:text-[11px]
//                       xs:text-[10px]"
//                 >
//                   ( Select your plan type )
//                 </div>
//               </div>
//               <select 
//                 className={`w-full px-3 py-2 border rounded-md ${isRenew ? 'bg-gray-50' : '' }`}
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//                 disabled={isRenew}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2 
//                                 sm:ml-0 
//                                 xs:ml-0"
//                 />
//                 <div className="text-xs text-rose-600 ml-2
//                       sm:text-[11px]
//                       xs:text-[10px]"
//                 >
//                   (Conversion based on today’s rate:
//                   <span className="font-bold text-sm
//                           sm:text-xs
//                           xs:text-xs"
//                   >
//                     {plan.planCurrency === 'MYR' && ` USD ${usdAmount}`}
//                     {plan.planCurrency === 'USD' && ` MYR ${myrAmount}`}
//                   </span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={
//                   `font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}
//                         sm:mr-0
//                         xs:mr-0
//                   `
//                   }>
//                   Apply Voucher
//                 </label>
//                 <div className="flex justify-normal items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`
//                       text-xs ml-2 text-nowrap ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}
//                       sm:text-[11px] 
//                       xs:text-[10px]`
//                     }
//                   >
//                     ( Apply voucher for subscription.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-50 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`
//                     ml-2 px-4 py-2 text-sm rounded-md 
//                     sm:text-xs
//                     xs:text-xs
//                     ${
//                       isVoucherApplied || planType === 'Trial'
//                         ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                         : "bg-cyan-800 text-white"
//                     }`
//                   }
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-6 border rounded-xl 
//                   sm:p-4
//                   xs:p-4"
//             >
//               <div className="text-rose-600 mb-4
//                     sm:text-xs
//                     xs:text-xs"
//               >
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent 
//                     AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, please contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a>or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline mx-2">general@r1trader.com</a>
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={
//                     `ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }
//                           sm:mr-0
//                           xs:mr-0`
//                     }
//                   >
//                     Account Number
//                   </label>
//                   <FaInfoCircle className={
//                     `${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={
//                     `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
//                         sm:text-[11px] 
//                         xs:text-[10px]`
//                     } 
//                   >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={
//                     `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
//                         sm:text-[11px] 
//                         xs:text-[10px]`
//                     } 
//                   >
//                     ( This is your account "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={
//                     `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
//                         sm:text-[11px] 
//                         xs:text-[10px]`
//                     } 
//                   >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 >
//                   <option value="">Select server</option>
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                   <option value="LirunexLimited-Live">Lirunex Limited Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={
//                     `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
//                         sm:text-[11px] 
//                         xs:text-[10px]`
//                     } 
//                   >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={
//                     `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
//                         sm:text-[11px] 
//                         xs:text-[10px]`
//                     }
//                   >
//                     ( This is your trading account "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={
//                     `text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}
//                         sm:text-[11px] 
//                         xs:text-[10px]`
//                     }
//                   >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 >
//                   <option value="">Select server</option>
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                   <option value="LirunexLimited-Demo">Lirunex Limited Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={
//                   `text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }
//                       sm:text-[11px] 
//                       xs:text-[10px]`
//                   }
//                 >
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>
//                   USDT (Cryptocurrency)
//                 </label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4
//                       sm:text-xs sm:p-2
//                       xs:text-[10px] xs:p-2"
//                 >
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6
//                         sm:w-4 sm:h-4 sm:ml-0
//                         xs:w-3 xs:h-3 xs:ml-0
//                       "
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600
//                       sm:text-[11px] 
//                       xs:text-[10px]"
//                 >
//                   ( Payment receipt upload only support "png" or "jpg" format. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={handlePaymentReceiptChange}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span 
//                 className="cursor-pointer text-cyan-800 underline
//                   sm:text-xs
//                   xs:text-xs" 
//                 onClick={openModal}
//               >
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200
//                   sm:text-xs
//                   xs:text-xs"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={
//                   `px-4 py-2 rounded-md flex items-center justify-center
//                     sm:text-xs
//                     xs:text-xs
//                   ${
//                     isSubscribeDisabled || isRenewDisabled || !termsAccepted 
//                       ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                       : 'bg-cyan-800 text-white' 
//                     }`
//                   }
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || isRenewDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">{isRenew ? 'Renew' : 'Subscribe'}</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;


// // udpte code --> 23 nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product, SubUser } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product | null; 
//   liveOrderId: string; 
//   demoOrderId: string;
//   renewalData?: Partial<SubUser> | null;
//   isRenew?: boolean;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ 
//   plan, selectedProduct, liveOrderId, demoOrderId, renewalData, isRenew = false, onBack
// }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
//   const [isRenewDisabled, setIsRenewDisabled] = useState(false);

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user && !renewalData) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user, renewalData]);  

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);
  
//   // update renewal data field
//   useEffect(() => {
//     if (!renewalData) return;
  
//     setFullName(renewalData.subUserName || '');
//     setEmail(renewalData.subUserEmail || '');
  
//     // Handle Live Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'live') {
//       setLiveAccountNumber(renewalData.subAccountNumber || '');
//       setLiveAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     // Handle Demo Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'demo') {
//       setDemoAccountNumber(renewalData.subAccountNumber || '');
//       setDemoAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     setPlanType(renewalData.subPlanType || '');
//     setPaymentMethod(renewalData.subPaymentMethod || '');
//     setVoucherEnabled(renewalData.subVoucherCheck === 1);
//   }, [renewalData]);        
        
//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const isRenewAction = !!renewalData;

//     // Prevent double-click by checking the correct state
//     if (isRenewAction && isRenewDisabled) return;
//     if (!isRenewAction && isSubscribeDisabled) return;

//     if (isRenewAction) {
//       setIsRenewDisabled(true); 
//     } else {
//       setIsSubscribeDisabled(true); 
//     }

//     // Perform validation
//     const missingFields = validateSubscriptionForm({
//       isRenew,
//       planType,
//       liveAccountNumber,
//       liveAccountPassword,
//       demoAccountNumber,
//       demoAccountPassword,
//       paymentReceipt,
//       termsAccepted,
//       isTradingSectionEnabled,
//     });

//     // Check if there are any missing fields
//     if (missingFields.length > 0) {
//       setErrorTitle("Missing Fields");
//       setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//       return;
//     }

//     if (!selectedProduct) {
//       setErrorTitle("Error");
//       setErrorMessage("No product selected. Please select a product and try again.");
//       return;
//     }

//     setIsSubscribeDisabled(true); 
//     setIsRenewDisabled(true);

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     // Determine file path based on `subOrderId`
//     let paymentReceiptPath = "";

//     if (paymentReceipt) {
//       const fileExtension = paymentReceipt.name.split('.').pop();
//       const currentDate = new Date().toISOString().split("T")[0];
//       const baseDir = liveOrderId.startsWith("sub-ALG")
//         ? "/uploads/forAlgoMatrixReceipts"
//         : liveOrderId.startsWith("sub-TRA")
//         ? "/uploads/forTraderHubReceipts"
//         : "/uploads/otherReceipts";

//       paymentReceiptPath = `${baseDir}/${liveOrderId}_${currentDate}.${fileExtension}`;
//     }

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//       subPaymentReceipts: paymentReceiptPath ? paymentReceiptPath : "",
//     };

//     let subscriptionData;

//     if (isRenewAction) {
//       // Renew action: Submit only one type of account (Demo or Live)
//       const accountType = renewalData?.subAccountType?.toLowerCase();
  
//       // Handle TraderHub-specific logic
//       if (selectedProduct.productName === "TraderHub") {
//         subscriptionData = {
//           ...baseSubscriptionData,
//           subOrderId: renewalData?.subOrderId || '',
//           subAccountType: undefined, 
//           subAccountServer: undefined,
//           subAccountNumber: undefined,
//           subAccountPassword: undefined,
//         };
//       } else {
//         subscriptionData = {
//           ...baseSubscriptionData,
//           subOrderId: renewalData?.subOrderId || '',
//           subAccountType: accountType === 'demo' ? "Demo" : "Live",
//           subAccountServer: serverOptions[accountType === 'demo' ? "Demo" : "Live"],
//           subAccountNumber: accountType === 'demo' ? demoAccountNumber : liveAccountNumber,
//           subAccountPassword: accountType === 'demo' ? demoAccountPassword : liveAccountPassword,
//         };
//       }
//     } else {
//       // Subscribe action: Submit both Live and Demo accounts
//       subscriptionData = isTradingSectionEnabled
//         ? [
//             {
//               ...baseSubscriptionData,
//               subOrderId: liveOrderId,
//               subAccountType: "Live",
//               subAccountServer: serverOptions["Live"],
//               subAccountNumber: liveAccountNumber,
//               subAccountPassword: liveAccountPassword,
//             },
//             {
//               ...baseSubscriptionData,
//               subOrderId: demoOrderId,
//               subAccountType: "Demo",
//               subAccountServer: serverOptions["Demo"],
//               subAccountNumber: demoAccountNumber,
//               subAccountPassword: demoAccountPassword,
//             },
//           ]
//         : [baseSubscriptionData];
//     }
  
//     try {
//       const responses = Array.isArray(subscriptionData)
//         ? await Promise.all(
//             subscriptionData.map((data) =>
//               registerSubscription(data as SubUser, paymentReceipt)
//             )
//           )
//         : [await registerSubscription(subscriptionData as SubUser, paymentReceipt)];
  
//       const allSuccess = responses.every((response) => response?.success);
  
//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     } finally {
//       if (isRenewAction) {
//         setIsRenewDisabled(false);
//       } else {
//         setIsSubscribeDisabled(false);
//       }
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//       setIsRenewDisabled(false);
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   // --------------------------------------- Payment Receipts  ------------------------------------- //
//   const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0] || null;
//     if (file) {
//       const validTypes = ["image/png", "image/jpeg"];
//       if (!validTypes.includes(file.type)) {
//         setErrorTitle("Invalid File Type");
//         setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
//         setPaymentReceipt(null); 
//         return;
//       }
//       setPaymentReceipt(file); 
//     }
//   };
  

//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct?.productName || ""}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className={`w-full px-3 py-2 border rounded-md ${isRenew ? 'bg-gray-50' : '' }`}
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//                 disabled={isRenew}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-50 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={handlePaymentReceiptChange}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                   isSubscribeDisabled || isRenewDisabled || !termsAccepted 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-cyan-800 text-white' 
//                 }`}
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || isRenewDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">{isRenew ? 'Renew' : 'Subscribe'}</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;


// // updte code --> 22 nov 2024 v02
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product, SubUser } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product | null; 
//   liveOrderId: string; 
//   demoOrderId: string;
//   renewalData?: Partial<SubUser> | null;
//   isRenew?: boolean;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ 
//   plan, selectedProduct, liveOrderId, demoOrderId, renewalData, isRenew = false, onBack
// }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
//   const [isRenewDisabled, setIsRenewDisabled] = useState(false);

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user && !renewalData) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user, renewalData]);  

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);
  
//   // update renewal data field
//   useEffect(() => {
//     if (!renewalData) return;
  
//     setFullName(renewalData.subUserName || '');
//     setEmail(renewalData.subUserEmail || '');
  
//     // Handle Live Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'live') {
//       setLiveAccountNumber(renewalData.subAccountNumber || '');
//       setLiveAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     // Handle Demo Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'demo') {
//       setDemoAccountNumber(renewalData.subAccountNumber || '');
//       setDemoAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     setPlanType(renewalData.subPlanType || '');
//     setPaymentMethod(renewalData.subPaymentMethod || '');
//     setVoucherEnabled(renewalData.subVoucherCheck === 1);
//   }, [renewalData]);        
        
//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const isRenewAction = !!renewalData;

//     // Prevent double-click by checking the correct state
//     if (isRenewAction && isRenewDisabled) return;
//     if (!isRenewAction && isSubscribeDisabled) return;

//     if (isRenewAction) {
//       setIsRenewDisabled(true); 
//     } else {
//       setIsSubscribeDisabled(true); 
//     }

//     // Perform validation
//     const missingFields = validateSubscriptionForm({
//       isRenew,
//       planType,
//       liveAccountNumber,
//       liveAccountPassword,
//       demoAccountNumber,
//       demoAccountPassword,
//       paymentReceipt,
//       termsAccepted,
//       isTradingSectionEnabled,
//     });

//     // Check if there are any missing fields
//     if (missingFields.length > 0) {
//       setErrorTitle("Missing Fields");
//       setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//       return;
//     }

//     setIsSubscribeDisabled(true); 
//     setIsRenewDisabled(true);

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     if (!selectedProduct) {
//       setErrorTitle("Error");
//       setErrorMessage("No product selected. Please select a product and try again.");
//       return;
//     }

//     // Determine file path based on `subOrderId`
//     let paymentReceiptPath = "";

//     if (paymentReceipt) {
//       const fileExtension = paymentReceipt.name.split('.').pop();
//       const currentDate = new Date().toISOString().split("T")[0];
//       const baseDir = liveOrderId.startsWith("sub-ALG")
//         ? "/uploads/forAlgoMatrixReceipts"
//         : liveOrderId.startsWith("sub-TRA")
//         ? "/uploads/forTraderHubReceipts"
//         : "/uploads/otherReceipts";

//       paymentReceiptPath = `${baseDir}/${liveOrderId}_${currentDate}.${fileExtension}`;
//     }

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     let subscriptionData;

//     if (isRenewAction) {
//       // Renew action: Submit only one type of account (Demo or Live)
//       const accountType = renewalData?.subAccountType?.toLowerCase();
  
//       // Handle TraderHub-specific logic
//       if (selectedProduct.productName === "TraderHub") {
//         subscriptionData = {
//           ...baseSubscriptionData,
//           subOrderId: renewalData?.subOrderId || '',
//           subAccountType: undefined, 
//           subAccountServer: undefined,
//           subAccountNumber: undefined,
//           subAccountPassword: undefined,
//         };
//       } else {
//         subscriptionData = {
//           ...baseSubscriptionData,
//           subOrderId: renewalData?.subOrderId || '',
//           subAccountType: accountType === 'demo' ? "Demo" : "Live",
//           subAccountServer: serverOptions[accountType === 'demo' ? "Demo" : "Live"],
//           subAccountNumber: accountType === 'demo' ? demoAccountNumber : liveAccountNumber,
//           subAccountPassword: accountType === 'demo' ? demoAccountPassword : liveAccountPassword,
//         };
//       }
//     } else {
//       // Subscribe action: Submit both Live and Demo accounts
//       subscriptionData = isTradingSectionEnabled
//         ? [
//             {
//               ...baseSubscriptionData,
//               subOrderId: liveOrderId,
//               subAccountType: "Live",
//               subAccountServer: serverOptions["Live"],
//               subAccountNumber: liveAccountNumber,
//               subAccountPassword: liveAccountPassword,
//             },
//             {
//               ...baseSubscriptionData,
//               subOrderId: demoOrderId,
//               subAccountType: "Demo",
//               subAccountServer: serverOptions["Demo"],
//               subAccountNumber: demoAccountNumber,
//               subAccountPassword: demoAccountPassword,
//             },
//           ]
//         : [baseSubscriptionData];
//     }
  
//     try {
//       const responses = Array.isArray(subscriptionData)
//         ? await Promise.all(subscriptionData.map(data => registerSubscription(data)))
//         : [await registerSubscription(subscriptionData)];
  
//       const allSuccess = responses.every(response => response?.success);
  
//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     } finally {
//       if (isRenewAction) {
//         setIsRenewDisabled(false);
//       } else {
//         setIsSubscribeDisabled(false);
//       }
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//       setIsRenewDisabled(false);
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   // --------------------------------------- Payment Receipts  ------------------------------------- //
//   const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0] || null;
//     if (file) {
//       const validTypes = ["image/png", "image/jpeg"];
//       if (!validTypes.includes(file.type)) {
//         setErrorTitle("Invalid File Type");
//         setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
//         setPaymentReceipt(null); 
//         return;
//       }
//       setPaymentReceipt(file); 
//     }
//   };
  

//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct?.productName || ""}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className={`w-full px-3 py-2 border rounded-md ${isRenew ? 'bg-gray-50' : '' }`}
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//                 disabled={isRenew}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-50 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={handlePaymentReceiptChange}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                   isSubscribeDisabled || isRenewDisabled || !termsAccepted 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-cyan-800 text-white' 
//                 }`}
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || isRenewDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">{isRenew ? 'Renew' : 'Subscribe'}</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // udpate copde --> 22 nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product, SubUser } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product | null; 
//   liveOrderId: string; 
//   demoOrderId: string;
//   renewalData?: Partial<SubUser> | null;
//   isRenew?: boolean;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ 
//   plan, selectedProduct, liveOrderId, demoOrderId, renewalData, isRenew = false, onBack
// }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
//   const [isRenewDisabled, setIsRenewDisabled] = useState(false);

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user && !renewalData) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user, renewalData]);  

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);
  
//   // update renewal data field
//   useEffect(() => {
//     if (!renewalData) return;
  
//     setFullName(renewalData.subUserName || '');
//     setEmail(renewalData.subUserEmail || '');
  
//     // Handle Live Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'live') {
//       setLiveAccountNumber(renewalData.subAccountNumber || '');
//       setLiveAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     // Handle Demo Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'demo') {
//       setDemoAccountNumber(renewalData.subAccountNumber || '');
//       setDemoAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     setPlanType(renewalData.subPlanType || '');
//     setPaymentMethod(renewalData.subPaymentMethod || '');
//     setVoucherEnabled(renewalData.subVoucherCheck === 1);
//   }, [renewalData]);        
        
//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const isRenewAction = !!renewalData;

//     // Prevent double-click by checking the correct state
//     if (isRenewAction && isRenewDisabled) return;
//     if (!isRenewAction && isSubscribeDisabled) return;

//     if (isRenewAction) {
//       setIsRenewDisabled(true); // Disable the renew button
//     } else {
//       setIsSubscribeDisabled(true); // Disable the subscribe button
//     }

//     // Perform validation
//     const missingFields = validateSubscriptionForm({
//       isRenew,
//       planType,
//       liveAccountNumber,
//       liveAccountPassword,
//       demoAccountNumber,
//       demoAccountPassword,
//       paymentReceipt,
//       termsAccepted,
//       isTradingSectionEnabled,
//     });

//     // Check if there are any missing fields
//     if (missingFields.length > 0) {
//       setErrorTitle("Missing Fields");
//       setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//       return;
//     }

//     setIsSubscribeDisabled(true); 
//     setIsRenewDisabled(true);

//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     if (!selectedProduct) {
//       setErrorTitle("Error");
//       setErrorMessage("No product selected. Please select a product and try again.");
//       return;
//     }

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     let subscriptionData;

//     if (isRenewAction) {
//       // Renew action: Submit only one type of account (Demo or Live)
//       const accountType = renewalData?.subAccountType?.toLowerCase();
  
//       // Handle TraderHub-specific logic
//       if (selectedProduct.productName === "TraderHub") {
//         subscriptionData = {
//           ...baseSubscriptionData,
//           subOrderId: renewalData?.subOrderId || '',
//           subAccountType: undefined, 
//           subAccountServer: undefined,
//           subAccountNumber: undefined,
//           subAccountPassword: undefined,
//         };
//       } else {
//         subscriptionData = {
//           ...baseSubscriptionData,
//           subOrderId: renewalData?.subOrderId || '',
//           subAccountType: accountType === 'demo' ? "Demo" : "Live",
//           subAccountServer: serverOptions[accountType === 'demo' ? "Demo" : "Live"],
//           subAccountNumber: accountType === 'demo' ? demoAccountNumber : liveAccountNumber,
//           subAccountPassword: accountType === 'demo' ? demoAccountPassword : liveAccountPassword,
//         };
//       }
//     } else {
//       // Subscribe action: Submit both Live and Demo accounts
//       subscriptionData = isTradingSectionEnabled
//         ? [
//             {
//               ...baseSubscriptionData,
//               subOrderId: liveOrderId,
//               subAccountType: "Live",
//               subAccountServer: serverOptions["Live"],
//               subAccountNumber: liveAccountNumber,
//               subAccountPassword: liveAccountPassword,
//             },
//             {
//               ...baseSubscriptionData,
//               subOrderId: demoOrderId,
//               subAccountType: "Demo",
//               subAccountServer: serverOptions["Demo"],
//               subAccountNumber: demoAccountNumber,
//               subAccountPassword: demoAccountPassword,
//             },
//           ]
//         : [baseSubscriptionData];
//     }
  
//     try {
//       const responses = Array.isArray(subscriptionData)
//         ? await Promise.all(subscriptionData.map(data => registerSubscription(data)))
//         : [await registerSubscription(subscriptionData)];
  
//       const allSuccess = responses.every(response => response?.success);
  
//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     } finally {
//       if (isRenewAction) {
//         setIsRenewDisabled(false);
//       } else {
//         setIsSubscribeDisabled(false);
//       }
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//       setIsRenewDisabled(false);
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   // --------------------------------------- Payment Receipts  ------------------------------------- //
//   const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       const validTypes = ["image/png", "image/jpeg"];
//       if (!validTypes.includes(file.type)) {
//         setErrorTitle("Invalid File Type");
//         setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
//         setPaymentReceipt(null); 
//         return;
//       }
//       setPaymentReceipt(file); 
//     }
//   };
  

//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct?.productName || ""}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className={`w-full px-3 py-2 border rounded-md ${isRenew ? 'bg-gray-50' : '' }`}
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//                 disabled={isRenew}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-50 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled || isRenew}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled || isRenew ? "text-cyan-800 bg-gray-50" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled || isRenew}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={handlePaymentReceiptChange}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                   isSubscribeDisabled || isRenewDisabled || !termsAccepted 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-cyan-800 text-white' 
//                 }`}
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || isRenewDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">{isRenew ? 'Renew' : 'Subscribe'}</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // udpate code --> 19 nov 2024 v02
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product, SubUser } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product | null; 
//   liveOrderId: string; 
//   demoOrderId: string;
//   renewalData?: Partial<SubUser> | null;
//   isRenew?: boolean;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ 
//   plan, selectedProduct, liveOrderId, demoOrderId, renewalData, isRenew = false, onBack
// }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user && !renewalData) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user, renewalData]);  

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);
  
//   // update renewal data field
//   useEffect(() => {
//     if (!renewalData) return;
  
//     setFullName(renewalData.subUserName || '');
//     setEmail(renewalData.subUserEmail || '');
  
//     // Handle Live Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'live') {
//       setLiveAccountNumber(renewalData.subAccountNumber || '');
//       setLiveAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     // Handle Demo Account Data
//     if (renewalData.subAccountType?.toLowerCase() === 'demo') {
//       setDemoAccountNumber(renewalData.subAccountNumber || '');
//       setDemoAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     setPlanType(renewalData.subPlanType || '');
//     setPaymentMethod(renewalData.subPaymentMethod || '');
//     setVoucherEnabled(renewalData.subVoucherCheck === 1);
//   }, [renewalData]);        
        
//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//       // Perform validation
//     const missingFields = validateSubscriptionForm({
//       isRenew,
//       planType,
//       liveAccountNumber,
//       liveAccountPassword,
//       demoAccountNumber,
//       demoAccountPassword,
//       paymentReceipt,
//       termsAccepted,
//       isTradingSectionEnabled,
//     });

//     // Check if there are any missing fields
//     if (missingFields.length > 0) {
//       setErrorTitle("Missing Fields");
//       setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//       return;
//     }

//     setIsSubscribeDisabled(true); 

//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     if (!selectedProduct) {
//       setErrorTitle("Error");
//       setErrorMessage("No product selected. Please select a product and try again.");
//       return;
//     }

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subOrderId: liveOrderId,
//           subAccountType: "Live",
//           subAccountServer: serverOptions["Live"],
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: serverOptions["Demo"], 
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//     try {
//       const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));
//       const allSuccess = responses.every(response => response?.success);

//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   // --------------------------------------- Payment Receipts  ------------------------------------- //
//   const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       const validTypes = ["image/png", "image/jpeg"];
//       if (!validTypes.includes(file.type)) {
//         setErrorTitle("Invalid File Type");
//         setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
//         setPaymentReceipt(null); 
//         return;
//       }
//       setPaymentReceipt(file); 
//     }
//   };
  

//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct?.productName || ""}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={handlePaymentReceiptChange}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                   isSubscribeDisabled || !termsAccepted 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-cyan-800 text-white' 
//                 }`}
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">{isRenew ? 'Renew' : 'Subscribe'}</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // udpate code --> 19 nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product, SubUser } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product | null; 
//   liveOrderId: string; 
//   demoOrderId: string;
//   renewalData?: Partial<SubUser> | null;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, renewalData, onBack }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user && !renewalData) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user, renewalData]);  

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);
  
//   // update renewal data field
//   useEffect(() => {
//     if (!renewalData) return;
  
//     setFullName(renewalData.subUserName || '');
//     setEmail(renewalData.subUserEmail || '');
  
//     if (renewalData.subAccountType?.toLowerCase() === 'live') {
//       setLiveAccountNumber(renewalData.subAccountNumber || '');
//       setLiveAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     } else if (renewalData.subAccountType?.toLowerCase() === 'demo') {
//       setDemoAccountNumber(renewalData.subAccountNumber || '');
//       setDemoAccountPassword(renewalData.subAccountPassword || '');
//       setSelectedAccountServer(renewalData.subAccountServer || '');
//     }
  
//     setPlanType(renewalData.subPlanType || '');
//     setPaymentMethod(renewalData.subPaymentMethod || '');
//     setVoucherEnabled(renewalData.subVoucherCheck === 1);
//   }, [renewalData]);      
        
//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//       // Perform validation
//     const missingFields = validateSubscriptionForm({
//       planType,
//       liveAccountNumber,
//       liveAccountPassword,
//       demoAccountNumber,
//       demoAccountPassword,
//       paymentReceipt,
//       termsAccepted,
//       isTradingSectionEnabled,
//     });

//     // Check if there are any missing fields
//     if (missingFields.length > 0) {
//       setErrorTitle("Missing Fields");
//       setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//       return;
//     }

//     setIsSubscribeDisabled(true); 

//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     if (!selectedProduct) {
//       setErrorTitle("Error");
//       setErrorMessage("No product selected. Please select a product and try again.");
//       return;
//     }

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: serverOptions["Live"],
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: serverOptions["Demo"], 
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//     try {
//       const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));
//       const allSuccess = responses.every(response => response?.success);

//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   // --------------------------------------- Payment Receipts  ------------------------------------- //
//   const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       const validTypes = ["image/png", "image/jpeg"];
//       if (!validTypes.includes(file.type)) {
//         setErrorTitle("Invalid File Type");
//         setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
//         setPaymentReceipt(null); 
//         return;
//       }
//       setPaymentReceipt(file); 
//     }
//   };
  

//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct?.productName || ""}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={handlePaymentReceiptChange}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                   isSubscribeDisabled || !termsAccepted 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-cyan-800 text-white' 
//                 }`}
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // udpate code --> 18 nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product | null; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
  

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);
  

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//       // Perform validation
//     const missingFields = validateSubscriptionForm({
//       planType,
//       liveAccountNumber,
//       liveAccountPassword,
//       demoAccountNumber,
//       demoAccountPassword,
//       paymentReceipt,
//       termsAccepted,
//       isTradingSectionEnabled,
//     });

//     // Check if there are any missing fields
//     if (missingFields.length > 0) {
//       setErrorTitle("Missing Fields");
//       setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//       return;
//     }

//     setIsSubscribeDisabled(true); 

//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     if (!selectedProduct) {
//       setErrorTitle("Error");
//       setErrorMessage("No product selected. Please select a product and try again.");
//       return;
//     }

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: serverOptions["Live"],
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: serverOptions["Demo"], 
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//     try {
//       const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));
//       const allSuccess = responses.every(response => response?.success);

//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   // --------------------------------------- Payment Receipts  ------------------------------------- //
//   const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       const validTypes = ["image/png", "image/jpeg"];
//       if (!validTypes.includes(file.type)) {
//         setErrorTitle("Invalid File Type");
//         setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
//         setPaymentReceipt(null); 
//         return;
//       }
//       setPaymentReceipt(file); 
//     }
//   };
  

//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct?.productName || ""}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={handlePaymentReceiptChange}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                   isSubscribeDisabled || !termsAccepted 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-cyan-800 text-white' 
//                 }`}
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // udpate code -=-> 17 nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product | null; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
  

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);
  

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//       // Perform validation
//     const missingFields = validateSubscriptionForm({
//       planType,
//       liveAccountNumber,
//       liveAccountPassword,
//       demoAccountNumber,
//       demoAccountPassword,
//       paymentReceipt,
//       termsAccepted,
//       isTradingSectionEnabled,
//     });

//     // Check if there are any missing fields
//     if (missingFields.length > 0) {
//       setErrorTitle("Missing Fields");
//       setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//       return;
//     }

//     setIsSubscribeDisabled(true); 

//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     if (!selectedProduct) {
//       setErrorTitle("Error");
//       setErrorMessage("No product selected. Please select a product and try again.");
//       return;
//     }

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: serverOptions["Live"],
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: serverOptions["Demo"], 
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//     try {
//       const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));
//       const allSuccess = responses.every(response => response?.success);

//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   // --------------------------------------- Payment Receipts  ------------------------------------- //
//   const handlePaymentReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       const validTypes = ["image/png", "image/jpeg"];
//       if (!validTypes.includes(file.type)) {
//         setErrorTitle("Invalid File Type");
//         setErrorMessage("Only PNG and JPG file formats are supported for payment receipts.");
//         setPaymentReceipt(null); 
//         return;
//       }
//       setPaymentReceipt(file); 
//     }
//   };
  

//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct?.productName || ""}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={handlePaymentReceiptChange}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                   isSubscribeDisabled || !termsAccepted 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-cyan-800 text-white' 
//                 }`}
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // update code --> 15 nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
//   const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
  

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//       // Perform validation
//     const missingFields = validateSubscriptionForm({
//       planType,
//       liveAccountNumber,
//       liveAccountPassword,
//       demoAccountNumber,
//       demoAccountPassword,
//       paymentReceipt,
//       termsAccepted,
//       isTradingSectionEnabled,
//     });

//     // Check if there are any missing fields
//     if (missingFields.length > 0) {
//       setErrorTitle("Missing Fields");
//       setErrorMessage(`Please fill in the following fields: ${missingFields.join(", ")}`);
//       return;
//     }

//     setIsSubscribeDisabled(true); 

//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: serverOptions["Live"],
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: serverOptions["Demo"], 
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//     try {
//       const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));
//       const allSuccess = responses.every(response => response?.success);

//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         if (entitlement.message.includes("User not eligible for voucher entitlement")) {
//           setErrorTitle("Voucher Ineligible");
//           setErrorMessage("You are not eligible to redeem this voucher.");
//         } else if (entitlement.message.includes("Voucher expired")) {
//           setErrorTitle("Voucher Expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes("No remaining redeems")) {
//           setErrorTitle("No Remaining Redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     } finally {
//       setIsSubscribeDisabled(false); 
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);

//         if (entitlement.subTrialCheck === 0) { 
//           setErrorTitle("Trial Already Claimed");
//           setErrorMessage("You have already claimed a trial for this plan and are unable to claim it again.");
//           setPlanType('Subscribe'); 
//           return;
//         }

//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial'); 
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe'); 
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe'); 
//       }
//     } else {
//       setPaymentMethod(''); 
//       setVoucherCode('');
//     }
//   };  

//   return (
//     <div>
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct.productName}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={(e) => setPaymentReceipt(e.target.files?.[0] || null)}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                   isSubscribeDisabled || !termsAccepted 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-cyan-800 text-white' 
//                 }`}
//                 onClick={handleSubscribe}
//                 disabled={isSubscribeDisabled || !termsAccepted}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // udpate xode --> 14 nov 2024 v03
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement, validateTrialEntitlement } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
  

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: serverOptions["Live"],
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: serverOptions["Demo"], 
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//     try {
//       const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));
//       const allSuccess = responses.every(response => response?.success);
//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         // Check for specific entitlement messages from the backend response
//         if (entitlement.message.includes('Voucher expired')) {
//           setErrorTitle("Voucher expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes('No remaining redeems')) {
//           setErrorTitle("No remaining redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else if (entitlement.message.includes("User not entitled to claim this voucher")) {
//           setErrorTitle("Voucher Not Entitlement");
//           setErrorMessage("Your email is not entitled to redeem this voucher.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
  
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
  
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
  
//     if (selectedType === 'Trial') {
//       try {
//         const entitlement = await validateTrialEntitlement(plan.planId);
//         if (entitlement.entitled) {
//           setSuccessTitle("Trial Entitlement");
//           setSuccessMessage("You are entitled to the trial for this plan!");
//           setErrorMessage(null);
//           setPlanType('Trial');  // Ensure the Trial option is selected if entitled
//         } else {
//           setErrorTitle("Trial Not Entitlement");
//           setErrorMessage("Your email is not entitled to a trial for this plan.");
//           setPlanType('Subscribe');  // Switch back to Subscribe if not entitled
//         }
//       } catch (error) {
//         setErrorTitle("Trial Validation Error");
//         setErrorMessage("Error validating trial entitlement.");
//         setPlanType('Subscribe');  // Switch back to Subscribe if there's an error
//       }
//     } else {
//       setPaymentMethod('');  // Reset payment if switching to another type
//       setVoucherCode('');
//     }
//   };  

//   return (
//     <div>
//       {/* Success and Error Alert Messages */}
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct.productName}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={(e) => setPaymentReceipt(e.target.files?.[0] || null)}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//                 onClick={handleSubscribe}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // udpte code --> 14 nov 2024 v02
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import useSubValidation from '../../../validate/useSubValidation';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   // import custom hook
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   const { validateVoucherEntitlement } = useSubValidation();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [isVoucherApplied, setIsVoucherApplied] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const [successTitle, setSuccessTitle] = useState<string | null>(null);
//   const [errorTitle, setErrorTitle] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');
  

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: serverOptions["Live"],
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: serverOptions["Demo"], 
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//     try {
//       const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));
//       const allSuccess = responses.every(response => response?.success);
//       if (allSuccess) {
//         setSuccessTitle("Subscription Success");
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorTitle("Subscription Error");
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorTitle("Subscription Error");
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//     setSuccessTitle(null);
//     setErrorTitle(null);
//   };

//   // --------------------------------------- Apply for Voucher ------------------------------------- //
//   // add conde regex pattern
//   const voucherCodePattern = /^[a-zA-Z0-9!@#$%^&*()_+\-={};:'"\\|,.<>?/]*$/;

//   // Function to apply the voucher code discount
//   const applyVoucher = async () => {
//     if (!voucherCodePattern.test(voucherCode)) {
//       setErrorTitle("Invalid Voucher Code");
//       setErrorMessage("Invalid voucher code format. Please enter a valid code.");
//       return;
//     }
  
//     try {
//       const entitlement = await validateVoucherEntitlement(voucherCode);
  
//       if (!entitlement.entitled) {
//         // Check for specific entitlement messages from the backend response
//         if (entitlement.message.includes('Voucher expired')) {
//           setErrorTitle("Voucher expired");
//           setErrorMessage("This voucher has expired and can no longer be redeemed.");
//         } else if (entitlement.message.includes('No remaining redeems')) {
//           setErrorTitle("No remaining redeems");
//           setErrorMessage("This voucher has no remaining redeems and cannot be used.");
//         } else if (entitlement.message.includes("User not entitled to claim this voucher")) {
//           setErrorTitle("Voucher Not Entitlement");
//           setErrorMessage("Your email is not entitled to redeem this voucher.");
//         } else {
//           setErrorTitle("Voucher Not Eligible");
//           setErrorMessage(entitlement.message || 'You are not entitled to redeem this voucher.');
//         }
//         return;
//       }
  
//       const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);
  
//       if (voucher) {
//         let discountedAmount = plan.planAmount;
  
//         if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//           discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//         } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//           discountedAmount -= voucher.voucherAmount;
//         }
  
//         setPlanAmount(Math.max(0, discountedAmount));
//         setSuccessTitle("Voucher Applied");
//         setSuccessMessage("Voucher applied successfully! Your discount has been updated.");
//         setErrorMessage(null);
//         setIsVoucherApplied(true); 
//       } else {
//         setErrorTitle("Invalid Voucher Code");
//         setErrorMessage("Invalid voucher code for the selected plan.");
//       }
//     } catch (error) {
//       setErrorTitle("Voucher Validation Error");
//       setErrorMessage("Error validating voucher entitlement.");
//     }
//   };  

//   // --------------------------------------- Apply for Trial ------------------------------------- //
//   const handlePlanTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
    
//     if (selectedType === 'Trial') {
//       setPaymentMethod('');
//       setVoucherCode('');
//     }
//   };


//   return (
//     <div>
//       {/* Success and Error Alert Messages */}
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           title={successTitle || ''}
//           message={successMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           title={errorTitle || ''}
//           message={errorMessage || ''}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct.productName}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${
//                     isVoucherApplied || planType === 'Trial'
//                       ? "bg-gray-100 text-gray-300 cursor-not-allowed"
//                       : "bg-cyan-800 text-white"
//                   }`}
//                   onClick={applyVoucher}
//                   disabled={isVoucherApplied || planType === 'Trial'}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={(e) => setPaymentReceipt(e.target.files?.[0] || null)}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//                 onClick={handleSubscribe}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;


// // update code --> 14 Nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);

//   const handlePlanTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
    
//     if (selectedType === 'Trial') {
//       setPaymentMethod('');
//       setVoucherCode('');
//     }
//   };

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Server options mapping
//     const serverOptions: { [key: string]: string } = {
//       Demo: "FortunePrimeGlobal-Demo",
//       Live: "FortunePrimeGlobal-Live",
//     };

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subDemoCheck: isTradingSectionEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: serverOptions["Live"],
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: serverOptions["Demo"], 
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//     try {
//       // Register subscriptions based on condition
//       const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));
//       const allSuccess = responses.every(response => response?.success);
//       if (allSuccess) {
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//   };

//   // Function to apply the voucher code discount
//   const applyVoucher = () => {
//     // Retrieve the voucher based on the entered voucher code and plan ID
//     const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);

//     if (voucher) {
//       let discountedAmount = plan.planAmount;

//       // Apply discount based on voucher type
//       if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//         discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//       } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//         discountedAmount -= voucher.voucherAmount;
//       }

//       // Ensure discounted amount does not go below zero
//       setPlanAmount(Math.max(0, discountedAmount));
//       setErrorMessage(null); 
//     } else {
//       // Set an error message if the voucher code is invalid
//       setErrorMessage("Invalid voucher code for the selected plan.");
//     }
//   };

//   return (
//     <div>
//       {/* Success and Error Alert Messages */}
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           message={successMessage}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           message={errorMessage}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct.productName}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>( Apply voucher for subscription benefit.)</span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${planType === 'Trial' ? "border bg-gray-100 text-gray-300" : "bg-cyan-800 text-white"}`}
//                   onClick={applyVoucher}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={(e) => setPaymentReceipt(e.target.files?.[0] || null)}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//                 onClick={handleSubscribe}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // idpate code --> 10 nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);

//   const handlePlanTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
    
//     if (selectedType === 'Trial') {
//       setPaymentMethod('');
//       setVoucherCode('');
//     }
//   };

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     // Conditional data for live or demo based on isTradingSectionEnabled
//     const subscriptionData = isTradingSectionEnabled
//     ? [
//         {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: selectedAccountServer,
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         },
//         {
//           ...baseSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: selectedAccountServer,
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         },
//       ]
//     : [baseSubscriptionData];

//   try {
//     // Register subscriptions based on condition
//     const responses = await Promise.all(subscriptionData.map(data => registerSubscription(data)));

//     const allSuccess = responses.every(response => response?.success);
//     if (allSuccess) {
//       setSuccessMessage("Subscription saved successfully!");
//       setErrorMessage(null);
//     } else {
//       setErrorMessage("There was an issue saving your subscription. Please try again.");
//     }
//   } catch (error) {
//     setErrorMessage("An error occurred while processing your subscription. Please try again.");
//   }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//   };

//   // Function to apply the voucher code discount
//   const applyVoucher = () => {
//     // Retrieve the voucher based on the entered voucher code and plan ID
//     const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);

//     if (voucher) {
//       let discountedAmount = plan.planAmount;

//       // Apply discount based on voucher type
//       if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//         discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//       } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//         discountedAmount -= voucher.voucherAmount;
//       }

//       // Ensure discounted amount does not go below zero
//       setPlanAmount(Math.max(0, discountedAmount));
//       setErrorMessage(null); 
//     } else {
//       // Set an error message if the voucher code is invalid
//       setErrorMessage("Invalid voucher code for the selected plan.");
//     }
//   };

//   return (
//     <div>
//       {/* Success and Error Alert Messages */}
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           message={successMessage}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           message={errorMessage}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct.productName}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Subscribe">Subscribe</option>
//                 <option value="Trial">Trial</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>( Apply voucher for subscription benefit.)</span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${planType === 'Trial' ? "border bg-gray-100 text-gray-300" : "bg-cyan-800 text-white"}`}
//                   onClick={applyVoucher}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="">Select account server</option>
//                   <option value="FortunePrimeGlobal-Live">Fortune Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="">Select account server</option>
//                   <option value="FortunePrimeGlobal-Demo">Fortune Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               {/* <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )} */}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 mt-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={(e) => setPaymentReceipt(e.target.files?.[0] || null)}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//                 onClick={handleSubscribe}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;

// // updare code --> 08 Nov 2024
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);

//   const handlePlanTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
    
//     if (selectedType === 'Trial') {
//       setPaymentMethod('');
//       setVoucherCode('');
//     }
//   };

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     const baseSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };

//     const liveSubscriptionData = isTradingSectionEnabled
//       ? {
//           ...baseSubscriptionData,
//           subAccountType: "Live",
//           subAccountServer: "ForturePrimeGlobal-Live",
//           subAccountNumber: liveAccountNumber,
//           subAccountPassword: liveAccountPassword,
//         }
//       : baseSubscriptionData;

//     const demoSubscriptionData = isTradingSectionEnabled
//       ? {
//           ...liveSubscriptionData,
//           subOrderId: demoOrderId,
//           subAccountType: "Demo",
//           subAccountServer: "ForturePrimeGlobal-Demo",
//           subAccountNumber: demoAccountNumber,
//           subAccountPassword: demoAccountPassword,
//         }
//       : baseSubscriptionData;

//     try {
//       const liveResponse = await registerSubscription(liveSubscriptionData);
//       const demoResponse = await registerSubscription(demoSubscriptionData);

//       if (liveResponse?.success && demoResponse?.success) {
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//   };

//   // Function to apply the voucher code discount
//   const applyVoucher = () => {
//     // Retrieve the voucher based on the entered voucher code and plan ID
//     const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);

//     if (voucher) {
//       let discountedAmount = plan.planAmount;

//       // Apply discount based on voucher type
//       if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//         discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//       } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//         discountedAmount -= voucher.voucherAmount;
//       }

//       // Ensure discounted amount does not go below zero
//       setPlanAmount(Math.max(0, discountedAmount));
//       setErrorMessage(null); 
//     } else {
//       // Set an error message if the voucher code is invalid
//       setErrorMessage("Invalid voucher code for the selected plan.");
//     }
//   };

//   return (
//     <div>
//       {/* Success and Error Alert Messages */}
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           message={successMessage}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           message={errorMessage}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct.productName}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Trial">Trial</option>
//                 <option value="Subscribe">Subscribe</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>( Apply voucher for subscription benefit.)</span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${planType === 'Trial' ? "border bg-gray-100 text-gray-300" : "bg-cyan-800 text-white"}`}
//                   onClick={applyVoucher}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="">Select account server</option>
//                   <option value="ForturePrimeGlobal-Live">Forture Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="">Select account server</option>
//                   <option value="ForturePrimeGlobal-Demo">Forture Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={(e) => setPaymentReceipt(e.target.files?.[0] || null)}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//                 onClick={handleSubscribe}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;


// // udpate code --> 07 nov
// // src/components/client/MiniApp/App03_MySubRegistration.tsx

// import React, { useState, useEffect } from 'react';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaEyeSlash, FaEye, FaClipboard } from 'react-icons/fa';
// // import custom file
// import MyTicketAlert from '../MiniApp/App_AlertMessage';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// import { useSubData, Plan, Product } from '../../../hooks/Subscription/useSubData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface RegistrationProps {
//   plan: Plan;
//   selectedProduct: Product; 
//   liveOrderId: string; 
//   demoOrderId: string;  
//   onBack: () => void;
// }

// const MySubRegistration: React.FC<RegistrationProps> = ({ plan, selectedProduct, liveOrderId, demoOrderId, onBack }) => {
//   const { loading, registerSubscription, getTrialPeriodByPlanId, getVoucherByCodeAndPlanId  } = useSubData();
//   const { user } = useUserDashboard();
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const termsAndConditions = useEventRegTerms();
//   // default values to fill in field
//   const [fullName, setFullName] = useState(user?.username || '');
//   const [email, setEmail] = useState(user?.email || '');
//   // referral 
//   const [referralEmail, setReferralEmail] = useState("casper@r1trader.com");
//   const [referralEnabled, setReferralEnabled] = useState(false);
//   // voucher code
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [planAmount, setPlanAmount] = useState(plan.planAmount); 
//   // account info
//   const [selectedAccountServer, setSelectedAccountServer] = useState('');
//   const [liveAccountNumber, setLiveAccountNumber] = useState('');
//   const [liveAccountPassword, setLiveAccountPassword] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   // payment info
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null); 
//   const [notes, setNotes] = useState('');
//   // Success and error messages for alerts
//   const [successMessage, setSuccessMessage] = useState<string | null>(null);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   // for trial
//   const [planType, setPlanType] = useState(''); 
//   const trialPeriod = getTrialPeriodByPlanId(plan.planId);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(plan.planAccount === 'Enable');

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Update fields if `user` data changes
//   useEffect(() => {
//     if (user) {
//       setFullName(user.username);
//       setEmail(user.email);
//     }
//   }, [user]);

//   useEffect(() => {
//     // Enable or disable the trading section based on `planAccount` value
//     setIsTradingSectionEnabled(plan.planAccount === 'Enable');
//   }, [plan.planAccount]);

//   const handlePlanTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = event.target.value;
//     setPlanType(selectedType);
    
//     if (selectedType === 'Trial') {
//       setPaymentMethod('');
//       setVoucherCode('');
//     }
//   };

//   // save MySubRegistration component
//   const handleSubscribe = async () => {
//     const paymentReceiptPath = paymentReceipt ? `/uploads/forSubReceipts/${paymentReceipt.name}` : "";

//     // Prepare subscription data for Live account
//     const liveSubscriptionData = {
//       subOrderId: liveOrderId,
//       subProductId: selectedProduct.productId,
//       subPlanId: plan.planId,
//       subUserName: fullName,
//       subUserEmail: email,
//       subPlanType: planType,
//       subPlanAmount: planType === 'Trial' ? 0 : plan.planAmount,
//       subPlanPeriod: planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod,
//       subDateSubmit: new Date(),
//       subExpiredDate: new Date(),
//       subExpiredDays: 0,
//       subStatus: "Pending",
//       subAccountType: "Live",
//       subAccountServer: "ForturePrimeGlobal-Live", 
//       subAccountNumber: liveAccountNumber,  
//       subAccountPassword: liveAccountPassword, 
//       subTrialCheck: planType === 'Trial' ? 1 : 0,
//       subVoucherCheck: voucherEnabled ? 1 : 0,
//       subPaymentMethod: paymentMethod,
//       subPaymentReceipts: paymentReceiptPath,
//       subNotes: notes,
//       subRemark: "",
//       subTermAgreed: termsAccepted ? "Yes" : "No",
//     };
  
//     // Prepare subscription data for Demo account
//     const demoSubscriptionData = {
//       ...liveSubscriptionData,
//       subOrderId: demoOrderId,
//       subAccountType: "Demo",
//       subAccountServer: "ForturePrimeGlobal-Demo", 
//       subAccountNumber: demoAccountNumber,  
//       subAccountPassword: demoAccountPassword, 
//     };
  
//     try {
//       // Register both Live and Demo subscriptions
//       const liveResponse = await registerSubscription(liveSubscriptionData);
//       const demoResponse = await registerSubscription(demoSubscriptionData);
  
//       if (liveResponse && liveResponse.success && demoResponse && demoResponse.success) {
//         setSuccessMessage("Subscription saved successfully!");
//         setErrorMessage(null);
//       } else {
//         setErrorMessage("There was an issue saving your subscription. Please try again.");
//       }
//     } catch (error) {
//       setErrorMessage("An error occurred while processing your subscription. Please try again.");
//     }
//   };

//   const closeAlert = () => {
//     setSuccessMessage(null);
//     setErrorMessage(null);
//   };

//   // Function to apply the voucher code discount
//   const applyVoucher = () => {
//     // Retrieve the voucher based on the entered voucher code and plan ID
//     const voucher = getVoucherByCodeAndPlanId(voucherCode, plan.planId);

//     if (voucher) {
//       let discountedAmount = plan.planAmount;

//       // Apply discount based on voucher type
//       if (voucher.voucherType === 'By Percentage' && voucher.voucherPercentage) {
//         discountedAmount -= plan.planAmount * (voucher.voucherPercentage / 100);
//       } else if (voucher.voucherType === 'By Amount' && voucher.voucherAmount) {
//         discountedAmount -= voucher.voucherAmount;
//       }

//       // Ensure discounted amount does not go below zero
//       setPlanAmount(Math.max(0, discountedAmount));
//       setErrorMessage(null); // Clear any previous error
//     } else {
//       // Set an error message if the voucher code is invalid
//       setErrorMessage("Invalid voucher code for the selected plan.");
//     }
//   };

//   return (
//     <div>
//       {/* Success and Error Alert Messages */}
//       {successMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="success"
//           message={successMessage}
//           onClose={closeAlert}
//         />
//       )}

//       {errorMessage && (
//         <MyTicketAlert
//           visible={true}
//           type="error"
//           message={errorMessage}
//           onClose={closeAlert}
//         />
//       )}

//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         {loading ? (
//           <p>Loading data...</p>
//         ) : (
//           <form>
//             <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Basic Information
//               </span>
//             </div>

//             <div className="my-4 mx-8">
//               <p className="mb-6 font-semibold">
//                 {plan.planDescription}
//               </p>
//             </div>

//             {/* order id */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Order ID:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subOrderId"
//                 type="text"
//                 value={liveOrderId}
//                 placeholder="Generated Order ID"
//                 disabled
//               />
//             </div>

//             {/* user full name */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Full Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserName"
//                 type="text"
//                 value={fullName}
//                 disabled
//               />
//             </div>

//             {/* user email */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Email:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subUserEmail"
//                 type="text"
//                 value={email}
//                 disabled
//               />
//             </div>

//             {/* Referral Email Field */}
//             <div className="mt-6 mb-6">
//               <div className="flex items-center">
//                 <label className="mx-2 font-semibold mr-2">Referral Email</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//                   <div className="text-xs font-thin ml-2 text-rose-500">
//                     ( Enter your referrer's email or leave as default. )
//                   </div>
//                   <input
//                     type="checkbox"
//                     className="ml-2"
//                     checked={referralEnabled}
//                     onChange={() => setReferralEnabled(!referralEnabled)}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 flex items-center">
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${referralEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   name="referral-email"
//                   type="email"
//                   value={referralEmail}
//                   onChange={(e) => setReferralEmail(e.target.value)}
//                   disabled={!referralEnabled}
//                   placeholder="Enter referral email"
//                 />
//                 <button
//                   type="button"
//                   className="ml-2 px-4 py-2 text-sm rounded-md bg-cyan-800 text-white"
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* Plan information */}
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//                 Plan Selection
//               </span>
//             </div>

//             {/* product name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Product Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subProductId"
//                 type="text"
//                 value={selectedProduct.productName}
//                 disabled
//               />
//             </div>

//             {/* plan name field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Name:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanName"
//                 type="text"
//                 value={plan.planName}
//                 disabled
//               />
//             </div>

//             {/* plan period field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Period:</label>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanPeriod"
//                 type="number"
//                 value={planType === 'Trial' ? trialPeriod || 0 : plan.planPeriod}
//                 disabled
//               />
//             </div>

//             {/* plan type field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Plan Type:</label>
//               <select 
//                 className="w-full px-3 py-2 border rounded-md"
//                 name="subPlanType"
//                 value={planType}
//                 onChange={handlePlanTypeChange}
//               >
//                 <option value="">Select a plan type</option>
//                 <option value="Trial">Trial</option>
//                 <option value="Subscribe">Subscribe</option>
//               </select>
//             </div>

//             {/* Plan Amount Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center mx-2">
//                 <label className="font-semibold">Plan Amount</label>
//                 <FaInfoCircle className="w-4 h-4 ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   (Conversion based on today’s rate:<span className="font-bold text-sm"></span>)
//                 </div>
//               </div>
//               <div className="flex justify-between items-center">
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md" 
//                   name="subPlanAmount"
//                   type="number" 
//                   value={planType === 'Trial' ? 0 : planAmount}
//                   disabled 
//                 />
//                 <span className="ml-4">{plan.planCurrency}</span>
//               </div>
//             </div>

//             {/* Apply Voucher Field */}
//             <div className="my-6">
//               <div className="mx-2 flex items-center">
//                 <label className={`font-semibold mr-2 ${planType === 'Trial' ? "text-gray-300" : ""}`}>Apply Voucher</label>
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle className={`${planType === 'Trial' ? "text-gray-300" : ""}`}/>
//                   <span className={`text-xs ml-2 ${planType === 'Trial' ? "text-gray-300" : "text-rose-600"}`}>( Apply voucher for subscription benefit.)</span>
//                   <input
//                     type="checkbox"
//                     className="ml-2 accent-cyan-800"
//                     checked={voucherEnabled}
//                     onChange={() => setVoucherEnabled(!voucherEnabled)}
//                     disabled={planType === 'Trial'}
//                   />
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="text"
//                   name="subVoucherCode"
//                   className={`w-full px-3 py-2 border rounded-md ${voucherEnabled ? '' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
//                   placeholder="Enter voucher code"
//                   value={voucherCode}
//                   onChange={(e) => setVoucherCode(e.target.value)}
//                   disabled={!voucherEnabled}
//                 />
//                 <button
//                   type="button"
//                   className={`ml-2 px-4 py-2 text-sm rounded-md ${planType === 'Trial' ? "border bg-gray-100 text-gray-300" : "bg-cyan-800 text-white"}`}
//                   onClick={applyVoucher}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>

//             {/* fill in Account Section */}
//             {/* Account Type Field (for Live) */}
//             <div className="p-4 border rounded-xl">
//               <div className="text-rose-600 mb-4">
//                 {isTradingSectionEnabled ? (
//                   <>
//                     Must complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
//                     <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at 
//                     <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//                   </>
//                 ) : (
//                   "No need to complete fill in trading account section and disabled for this plan."
//                 )}
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400"}`}>Live Trading Account</span>
//               </div>

//               {/* account type live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "" : "bg-gray-50 text-gray-400"}`}
//                   name="subAccountType"
//                   value="Live"
//                   disabled
//                 />
//               </div>

//               {/* account number live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}>Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={liveAccountNumber}
//                   onChange={(e) => setLiveAccountNumber(e.target.value)}
//                   placeholder="Enter trading account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password live */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     name="subAccountPassword"
//                     type={showPassword ? "text" : "password"}
//                     value={liveAccountPassword}
//                     onChange={(e) => setLiveAccountPassword(e.target.value)}
//                     placeholder="Enter trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {!isTradingSectionEnabled || showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server live */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your live trading account login server )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   value={selectedAccountServer}
//                   onChange={(e) => setSelectedAccountServer(e.target.value)}
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="">Select account server</option>
//                   <option value="ForturePrimeGlobal-Live">Forture Prime Global Live</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-center mb-4">
//                 <span className={`text-md px-4 py-1 rounded-full ${isTradingSectionEnabled ? "bg-cyan-800 text-white" : "bg-gray-200 text-gray-400" }`}>Demo Trading Account</span>
//               </div>

//               {/* account type demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Type</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( Enable the checkbox to fill in the live trading account details )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountType"
//                   value="Demo"
//                   disabled
//                 />
//               </div>

//               {/* account number demo */}
//               <div className="my-6">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Number</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your demo trading account number )
//                   </div>
//                 </div>
//                 <input
//                   className="w-full px-3 py-2 border rounded-md"
//                   name="subAccountNumber"
//                   type="number"
//                   value={demoAccountNumber}
//                   onChange={(e) => setDemoAccountNumber(e.target.value)}
//                   placeholder="Enter demo account number..."
//                   disabled={!isTradingSectionEnabled}
//                 />
//               </div>

//               {/* account password demo */}
//               <div className="my-6 relative">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Password (Read-Only)</label>
//                   <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <div className="relative">
//                   <input
//                     className="w-full px-3 py-2 border rounded-md"
//                     type={showPassword ? "text" : "password"}
//                     name="subAccountPassword"
//                     value={demoAccountPassword}
//                     onChange={(e) => setDemoAccountPassword(e.target.value)}
//                     placeholder="Enter demo trading account investor (read-only) password..."
//                     disabled={!isTradingSectionEnabled}
//                   />
//                   <button
//                     type="button"
//                     className={`absolute inset-y-0 right-0 pr-3 flex items-center ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`}
//                     onClick={() => setShowPassword(!showPassword)}
//                     disabled={!isTradingSectionEnabled}
//                   >
//                     {showPassword || !isTradingSectionEnabled ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* account server demo */}
//               <div className="my-6 ">
//                 <div className="flex justify-normal items-center space-x-2">
//                   <label className={`ml-2 font-semibold ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`} >Account Server</label>
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle className={`${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400"}`} />
//                   </div>
//                   <div className={`text-xs ${isTradingSectionEnabled ? "text-rose-600" : "text-gray-400"}`} >
//                     ( This is your trading account login server. )
//                   </div>
//                 </div>
//                 <select
//                   className={`w-full px-3 py-2 border rounded-md ${isTradingSectionEnabled ? "text-cyan-800" : "text-gray-400" }`}
//                   name="subAccountServer"
//                   disabled={!isTradingSectionEnabled}
//                 >
//                   <option value="">Select account server</option>
//                   <option value="ForturePrimeGlobal-Demo">Forture Prime Global Demo</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex justify-center my-8">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//             </div>

//             {/* Payment Method Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className={`ml-2 font-semibold ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Payment Method</label>
//                 <FaInfoCircle className={`${planType === 'Trial' ? 'text-gray-300' : '' }`}/>
//                 <div className={`text-xs ml-2  ${planType === 'Trial' ? 'text-gray-300' : 'text-rose-600 ' }`}>
//                   ( Choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="Bank Transfer"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('Bank Transfer')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Please proceed with the payment through USDT (cryptocurrency).</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("8604820432")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center mx-4">
//                 <input
//                   type="radio"
//                   name="subPaymentMethod"
//                   value="USDT"
//                   className="accent-cyan-800"
//                   onChange={() => setPaymentMethod('USDT')}
//                   disabled={planType === 'Trial'}
//                 />
//                 <label className={`ml-2 ${planType === 'Trial' ? 'text-gray-300' : '' }`}>USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* Payment Receipts Field */}
//             <div className="my-6">
//               <div className="flex justify-normal items-center space-x-2">
//                 <label className="ml-2 font-semibold">Payment Receipts</label>
//                 <FaInfoCircle />
//                 <div className="text-xs text-rose-600">
//                   ( Please upload your payment receipt in "png" or "jpg" format only. )
//                 </div>
//               </div>
//               <input
//                 className="w-full px-3 py-2 border rounded-md"
//                 type="file"
//                 name="subPaymentReceipts"
//                 onChange={(e) => setPaymentReceipt(e.target.files?.[0] || null)}
//               />
//             </div>

//             {/* Notes Field */}
//             <div className="my-6">
//               <label className="mx-2 font-semibold">Notes</label>
//               <textarea 
//                 name="subNotes" 
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//                 placeholder="Leave any notes here (optional)..." 
//                 className="w-full px-3 py-2 border rounded-md" 
//               />
//             </div>

//             {/* Terms and Conditions Field */}
//             <div className="flex justify-normal items-center">
//               <input
//                 type="checkbox"
//                 className="accent-cyan-800 mr-2"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//               />
//               <span className="cursor-pointer text-cyan-800 underline" onClick={openModal}>
//                 I have read and understand the terms and conditions
//               </span>
//             </div>

//             {/* Submit and Back buttons */}
//             <div className="flex justify-between items-center mt-6 space-x-4">
//               <button 
//                 onClick={onBack} 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//               >
//                 <FaArrowCircleLeft />
//                 <div className="ml-2">Back to Details</div>
//               </button>
//               <button 
//                 type="button" 
//                 className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//                 onClick={handleSubscribe}
//               >
//                 <BiSolidPurchaseTag />
//                 <div className="ml-2">Subscribe</div>
//               </button>
//             </div>
//           </form>
//         )}
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto max-w-lg h-auto max-h-screen">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Placeholder for Alerts */}
//       <MyTicketAlert 
//         visible={false} 
//         type="check" 
//         message="" 
//         onClose={() => {}} 
//         onConfirm={() => {}} 
//         onCancel={() => {}} 
//       />
//     </div>
//   );
// };

// export default MySubRegistration;