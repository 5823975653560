// src/components/client/MiniApp/App06_MyVideo.tsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RiVideoOnFill } from "react-icons/ri";
import { 
  MdOutlineExpandMore, MdOutlineExpandLess, MdCloseFullscreen
} from "react-icons/md";
import { VscScreenFull } from "react-icons/vsc";
import useVideo from '../../../hooks/MiniApp/useVideo';
import useVideoValidation from '../../../validate/userVideoValidation'; 
import { useEnv } from '../../../context/EnvContext';

const MyVideo: React.FC = () => {
  // fetch hook
  const { apiUrl } = useEnv();
  const { videos, playlists, saveUserVideoData, videoCode } = useVideo();
  const { validateVideoPermission, validatePlaylistPermission, isUserLoaded } = useVideoValidation(); 
  // state
  const [expandedCategories, setExpandedCategories] = useState<Record<string, boolean>>({});
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [showRestrictedOverlay, setShowRestrictedOverlay] = useState(false);
  const [allowedPlaylists, setAllowedPlaylists] = useState<any[]>([]); 
  // player control
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  // video ref
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);

  // Toggle the expanded state of a category
  const toggleCategory = (category: string) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };  

  // Function to handle video selection from playlist
  const handleVideoSelect = async (video: any, videoCategory: string) => {
    const hasPermission = await validateVideoPermission(video.videoId);
    if (!hasPermission) {
      setShowRestrictedOverlay(true);
      setSelectedVideo(video);
      return;
    }

    setShowRestrictedOverlay(false);
    if (selectedVideo?.videoId !== video.videoId) {
      setSelectedVideo(video);
      await saveUserVideoData(video.videoTitle, videoCategory);
    }
  };

  const handleFullScreenChange = () => {
    const isFull = document.fullscreenElement !== null;
    setIsFullScreen(isFull);
  };

  // Add event listener for full screen
  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      videoContainerRef.current?.requestFullscreen();
      setIsFullScreen(true);
    } else {
      document.exitFullscreen();
      setIsFullScreen(false); 
    }
  };

  // Custom play/pause button handler
  const togglePlayPause = useCallback(() => {
    if (!videoRef.current) return;
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying]); 

  // Handle spacebar to toggle play/pause
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.code === 'Space') {
      e.preventDefault();
      togglePlayPause();
    }
  }, [togglePlayPause]);
  
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);


  const renderVideoCodeOverlay = () => {
    if (videoCode) {
      return (
        <div
          className={`absolute ${isFullScreen ? 'text-4xl md:text-4xl sm:text-xl xs:text-xl' : 'text-2xl md:text-sm sm:text-xs xs:text-xs'} top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-opacity-10 font-bold px-4 py-2 rounded-md z-50`}
        >
          {videoCode}
        </div>
      );
    }
    return null;
  };

  // Fetch and filter allowed playlists based on user permissions
  useEffect(() => {
    const fetchAllowedPlaylists = async () => {
      if (!isUserLoaded) {
        return;
      }
  
      const filteredPlaylists = await Promise.all(
        playlists.map(async (playlist) => {
          const hasPermission = await validatePlaylistPermission(playlist.videoPlaylistId);
          if (hasPermission) {
            return playlist;
          }
          return null;
        })
      );
      setAllowedPlaylists(filteredPlaylists.filter(Boolean));
    };
  
    if (playlists.length > 0) {
      fetchAllowedPlaylists();
    }
  }, [playlists, validatePlaylistPermission, isUserLoaded]);

  return (
    <div className="h-full w-full max-h-screen max-w-screen p-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack 
                    xl:max-h-[600px]
                    tablet-landscape-max:max-h-[700px]
                    tablet-landscape-min:max-h-[300px]">
      <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center md:text-xl sm:text-xl xs:text-lg tablet-landscape-min:text-base">
        <RiVideoOnFill className="mr-2 w-6 h-6" />
          R One Video Classes
      </h2>

      {/* Video Player section */}
      <div className="w-full h-full flex flex-row 
                      md:flex-col 
                      sm:flex-col sm:overflow-y-auto 
                      xs:flex-col
                      tablet-landscape-min:flex-row"
                    >
        <div className="space-y-2 mr-4 flex-1 max-h-[850px] px-2 
                        overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack 
                        md:overflow-y-scroll md:scrollbar-thin md:scrollbar-thumb-scrollbarThumb md:scrollbar-track-scrollbarTrack md:max-h-[600px] 
                        sm:overflow-hidden 
                        xs:max-h-[500px] xs:overflow-hidden
                        tablet-landscape-min:mr-1"
                      >
          <div className="relative p-4 rounded-lg bg-gray-100 w-full h-auto" ref={videoContainerRef}>
            {selectedVideo ? (
              <>
                {showRestrictedOverlay ? (
                  <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 h-[550px] md:h-[200px] sm:h-[200px] xs:h-[180px]">
                    <div className="text-white text-center p-4 sm:text-sm xs:text-xs">
                      <h2 className="text-2xl font-bold mb-4 sm:text-sm xs:text-sm">Restricted Video</h2>
                      <p>This video is private</p>
                    </div>
                  </div>
                ) : (
                  <div className="relative">
                    <video
                      ref={videoRef}
                      src={`${apiUrl}${selectedVideo.videoPath}`}
                      controls
                      width="100%"
                      height="auto"
                      preload="auto"
                      playsInline
                      autoPlay
                      style={{
                        objectFit: 'contain',
                        width: '100%', // Full width inside the container
                        height: 'auto', // Height adjusts based on width, keeping aspect ratio
                        maxHeight: isFullScreen ? '100vh' : 'auto', // Max height when full screen
                      }}
                      controlsList="nodownload nofullscreen noremoteplayback" 
                      onContextMenu={(e) => e.preventDefault()} 
                    >
                      Your browser does not support the video tag.
                    </video>

                    {/* Fullscreen icon positioned at top-right */}
                    <button
                      onClick={toggleFullScreen}
                      className="absolute bottom-9 right-5 text-white bg-black bg-opacity-20 px-2 py-1"
                    >
                      {isFullScreen ? <MdCloseFullscreen /> : <VscScreenFull className="text-xl md:text-sm sm:text-sm xs:text-xs" />}
                    </button>

                    {/* Play/Pause button positioned in the center */}
                    {!isPlaying && (
                      <button
                        onClick={togglePlayPause}
                        className="absolute "
                      >
                      </button>
                    )}

                    {/* Retain the renderVideoCodeOverlay */}
                    {renderVideoCodeOverlay()}
                  </div>
                )}
              </>
            ) : (
              <div className="w-full h-full">
                <div className="p-10 py-[400px] text-center text-gray-300 font-semibold border-4 border-dashed border-gray-300 rounded-lg
                                sm:py-[100px]
                                xs:py-[50px]
                                tablet-landscape-max:py-[200px]
                                tablet-landscape-min:py-[50px]">
                  No video selected
                </div>
              </div>
            )}
          </div>

          {/* Description and Title */}
          <div className="bg-gray-100 px-4 py-4 rounded-lg">
            <div className="flex justify-between items-center">
              <div className="text-2xl font-bold p-2 
                              md:text-xl 
                              sm:text-lg
                              xs:text-sm
                              tablet-landscape-max:text-lg
                              tablet-landscape-min:text-base"
                            >
                              {selectedVideo ? selectedVideo.videoTitle : 'No Video Selected'}
              </div>
              <div className="mt-2 text-lg text-right sm:text-lg xs:text-sm">{selectedVideo ? selectedVideo.videoLength : ''}</div>
            </div>
            <div className="border-2 p-4 rounded-lg md:text-sm sm:text-xs xs:text-xs tablet-landscape-min:text-xs">
              {selectedVideo ? selectedVideo.videoDescription : 'No description available'}
            </div>
          </div>
        </div>

        {/* Playlist section */}
        <div className="p-6 rounded-lg w-[350px] max-h-[850px] bg-gray-100 
                        md:max-h-[500px] md:w-[590px] md:mt-2 
                        sm:mt-2 sm:max-h-[500px] sm:max-w-[335px] 
                        xs:mt-2 xs:max-h-[250px] xs:max-w-[280px]
                        tablet-landscape-max:max-h-[600px]
                        tablet-landscape-min:max-w-[250px] tablet-landscape-min:mt-0 tablet-landscape-min:p-4"
                      >
          <h2 className="text-xl font-bold mb-4 
                         md:text-lg 
                         sm:text-lg 
                         xs:text-sm
                         tablet-landscape-min:text-base tablet-landscape-min:mb-2"
                        >R One Courses</h2>
          
          {allowedPlaylists.length === 0 ? (
            <div className="text-gray-300 font-semibold border-4 border-dashed border-gray-300 rounded-lg text-center py-4">
              No playlists available
            </div>
          ) : (
            <ul className="space-y-4 max-h-[800px] 
                           overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack px-2
                           lg:max-h-[465px] 
                           md:max-h-[200px] 
                           sm:max-h-[300px] 
                           xs:max-h-[100px]
                           tablet-landscape-min:max-h-[300px] tablet-landscape-min:space-y-2">
              {allowedPlaylists.map((playlist) => (
                <li key={playlist.videoPlaylistId}>
                  <div
                    className="flex flex-row justify-between items-center bg-white py-2 px-4 rounded-t-lg cursor-pointer"
                    onClick={() => toggleCategory(playlist.videoPlaylistId)}
                  >
                    <div className="flex flex-col justify-between items-left mr-4">
                      <h3 className="text-lg font-semibold md:text-base sm:text-sm xs:text-xs tablet-landscape-min:text-xs">{playlist.videoPlaylistTitle}</h3>
                      <p className="text-gray-500 text-xs mt-1">{playlist.videoPlaylistDescription}</p>
                    </div>
                    <button>
                      {expandedCategories[playlist.videoPlaylistId] ? (
                        <MdOutlineExpandLess className="text-2xl xs:text-lg" />
                      ) : (
                        <MdOutlineExpandMore className="text-2xl xs:text-lg" />
                      )}
                    </button>
                  </div>

                  {/* Display videos grouped by category */}
                  {expandedCategories[playlist.videoPlaylistId] && (
                    <div className="pl-4">
                      <ul className="space-y-2">
                        <div className="py-2 border-1 text-cyan-800 font-bold text-center text-xs underline">
                          {selectedVideo ? selectedVideo.videoCategory : 'No Category'}
                        </div>
                        {videos
                          .filter((video) => playlist.videoPlaylistContent.includes(video.videoId))
                          .sort((a, b) => a.videoCategoryArray - b.videoCategoryArray)
                          .map((video) => (
                            <li key={video.videoId} className="border-b border-gray-700 pb-2">
                              <button
                                className="w-full text-left text-sm flex justify-between items-center md:text-sm sm:text-xs xs:text-xs"
                                onClick={() => handleVideoSelect(video, playlist.videoPlaylistId)}
                              >
                                <div className="flex flex-row items-center">
                                  <img
                                    src={`${apiUrl}${video.videoThumbnail}`}
                                    alt={video.videoTitle}
                                    className="w-16 h-9 mr-4"
                                  />
                                  <span>{video.videoTitle}</span>
                                </div>
                                <span>{video.videoLength}</span>
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyVideo;

// // update code --> 22 Jan 2024
// // src/components/client/MiniApp/App06_MyVideo.tsx

// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { RiVideoOnFill } from "react-icons/ri";
// import { 
//   MdOutlineExpandMore, MdOutlineExpandLess, MdCloseFullscreen
// } from "react-icons/md";
// import { VscScreenFull } from "react-icons/vsc";
// import useVideo from '../../../hooks/MiniApp/useVideo';
// import useVideoValidation from '../../../validate/userVideoValidation'; 
// import { useEnv } from '../../../context/EnvContext';

// const MyVideo: React.FC = () => {
//   // fetch hook
//   const { apiUrl } = useEnv();
//   const { videos, playlists, saveUserVideoData, videoCode } = useVideo();
//   const { validateVideoPermission, validatePlaylistPermission  } = useVideoValidation(); 
//   // state
//   const [expandedCategories, setExpandedCategories] = useState<Record<string, boolean>>({});
//   const [selectedVideo, setSelectedVideo] = useState<any>(null);
//   const [showRestrictedOverlay, setShowRestrictedOverlay] = useState(false);
//   const [allowedPlaylists, setAllowedPlaylists] = useState<any[]>([]); 
//   // player control
//   const [isFullScreen, setIsFullScreen] = useState(false);
//   const [isPlaying, setIsPlaying] = useState(false);
//   // video ref
//   const videoRef = useRef<HTMLVideoElement>(null);
//   const videoContainerRef = useRef<HTMLDivElement>(null);

//   // Toggle the expanded state of a category
//   const toggleCategory = (category: string) => {
//     setExpandedCategories((prevState) => ({
//       ...prevState,
//       [category]: !prevState[category],
//     }));
//   };  

//   // Function to handle video selection from playlist
//   const handleVideoSelect = async (video: any, videoCategory: string) => {
//     const hasPermission = await validateVideoPermission(video.videoId);
//     if (!hasPermission) {
//       setShowRestrictedOverlay(true);
//       setSelectedVideo(video);
//       return;
//     }

//     setShowRestrictedOverlay(false);
//     if (selectedVideo?.videoId !== video.videoId) {
//       setSelectedVideo(video);
//       await saveUserVideoData(video.videoTitle, videoCategory);
//     }
//   };

//   const handleFullScreenChange = () => {
//     const isFull = document.fullscreenElement !== null;
//     setIsFullScreen(isFull);
//   };

//   // Add event listener for full screen
//   useEffect(() => {
//     document.addEventListener('fullscreenchange', handleFullScreenChange);
//     return () => {
//       document.removeEventListener('fullscreenchange', handleFullScreenChange);
//     };
//   }, []);

//   const toggleFullScreen = () => {
//     if (!document.fullscreenElement) {
//       videoContainerRef.current?.requestFullscreen();
//       setIsFullScreen(true);
//     } else {
//       document.exitFullscreen();
//       setIsFullScreen(false); 
//     }
//   };

//   // Custom play/pause button handler
//   const togglePlayPause = useCallback(() => {
//     if (!videoRef.current) return;
//     if (isPlaying) {
//       videoRef.current.pause();
//     } else {
//       videoRef.current.play();
//     }
//     setIsPlaying(!isPlaying);
//   }, [isPlaying]); 

//   // Handle spacebar to toggle play/pause
//   const handleKeyDown = useCallback((e: KeyboardEvent) => {
//     if (e.code === 'Space') {
//       e.preventDefault();
//       togglePlayPause();
//     }
//   }, [togglePlayPause]);
  
//   useEffect(() => {
//     document.addEventListener('keydown', handleKeyDown);
//     return () => {
//       document.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [handleKeyDown]);


//   const renderVideoCodeOverlay = () => {
//     if (videoCode) {
//       return (
//         <div
//           className={`absolute ${isFullScreen ? 'text-4xl md:text-4xl sm:text-xl xs:text-xl' : 'text-2xl md:text-sm sm:text-xs xs:text-xs'} top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-opacity-10 font-bold px-4 py-2 rounded-md z-50`}
//         >
//           {videoCode}
//         </div>
//       );
//     }
//     return null;
//   };

//   // Fetch and filter allowed playlists based on user permissions
//   useEffect(() => {
//     const fetchAllowedPlaylists = async () => {
//       const filteredPlaylists = await Promise.all(
//         playlists.map(async (playlist) => {
//           const hasPermission = await validatePlaylistPermission(playlist.videoPlaylistId);
//           if (hasPermission) {
//             return playlist;
//           }
//           return null;
//         })
//       );
//       setAllowedPlaylists(filteredPlaylists.filter(Boolean));
//     };

//     if (playlists.length > 0) {
//       fetchAllowedPlaylists();
//     }
//   }, [playlists, validatePlaylistPermission]);

//   return (
//     <div className="h-full w-full max-h-screen max-w-screen p-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack 
//                     xl:max-h-[600px]
//                     tablet-landscape-max:max-h-[700px]
//                     tablet-landscape-min:max-h-[300px]">
//       <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center md:text-xl sm:text-xl xs:text-lg tablet-landscape-min:text-base">
//         <RiVideoOnFill className="mr-2 w-6 h-6" />
//           R One Video Classes
//       </h2>

//       {/* Video Player section */}
//       <div className="w-full h-full flex flex-row 
//                       md:flex-col 
//                       sm:flex-col sm:overflow-y-auto 
//                       xs:flex-col
//                       tablet-landscape-min:flex-row"
//                     >
//         <div className="space-y-2 mr-4 flex-1 max-h-[850px] px-2 
//                         overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack 
//                         md:overflow-y-scroll md:scrollbar-thin md:scrollbar-thumb-scrollbarThumb md:scrollbar-track-scrollbarTrack md:max-h-[600px] 
//                         sm:overflow-hidden 
//                         xs:max-h-[500px] xs:overflow-hidden
//                         tablet-landscape-min:mr-1"
//                       >
//           <div className="relative p-4 rounded-lg bg-gray-100 w-full h-auto" ref={videoContainerRef}>
//             {selectedVideo ? (
//               <>
//                 {showRestrictedOverlay ? (
//                   <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 h-[550px] md:h-[200px] sm:h-[200px] xs:h-[180px]">
//                     <div className="text-white text-center p-4 sm:text-sm xs:text-xs">
//                       <h2 className="text-2xl font-bold mb-4 sm:text-sm xs:text-sm">Restricted Video</h2>
//                       <p>This video is private</p>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="relative">
//                     <video
//                       ref={videoRef}
//                       src={`${apiUrl}${selectedVideo.videoPath}`}
//                       controls
//                       width="100%"
//                       height="auto"
//                       style={{
//                         objectFit: 'contain',
//                         width: '100%', // Full width inside the container
//                         height: 'auto', // Height adjusts based on width, keeping aspect ratio
//                         maxHeight: isFullScreen ? '100vh' : 'auto', // Max height when full screen
//                       }}
//                       controlsList="nodownload nofullscreen noremoteplayback" 
//                       onContextMenu={(e) => e.preventDefault()} 
//                     >
//                       Your browser does not support the video tag.
//                     </video>

//                     {/* Fullscreen icon positioned at top-right */}
//                     <button
//                       onClick={toggleFullScreen}
//                       className="absolute bottom-9 right-5 text-white bg-black bg-opacity-20 px-2 py-1"
//                     >
//                       {isFullScreen ? <MdCloseFullscreen /> : <VscScreenFull className="text-xl md:text-sm sm:text-sm xs:text-xs" />}
//                     </button>

//                     {/* Play/Pause button positioned in the center */}
//                     {!isPlaying && (
//                       <button
//                         onClick={togglePlayPause}
//                         className="absolute "
//                       >
//                       </button>
//                     )}

//                     {/* Retain the renderVideoCodeOverlay */}
//                     {renderVideoCodeOverlay()}
//                   </div>
//                 )}
//               </>
//             ) : (
//               <div className="w-full h-full">
//                 <div className="p-10 py-[400px] text-center text-gray-300 font-semibold border-4 border-dashed border-gray-300 rounded-lg
//                                 sm:py-[100px]
//                                 xs:py-[50px]
//                                 tablet-landscape-max:py-[200px]
//                                 tablet-landscape-min:py-[50px]">
//                   No video selected
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* Description and Title */}
//           <div className="bg-gray-100 px-4 py-4 rounded-lg">
//             <div className="flex justify-between items-center">
//               <div className="text-2xl font-bold p-2 
//                               md:text-xl 
//                               sm:text-lg
//                               xs:text-sm
//                               tablet-landscape-max:text-lg
//                               tablet-landscape-min:text-base"
//                             >
//                               {selectedVideo ? selectedVideo.videoTitle : 'No Video Selected'}
//               </div>
//               <div className="mt-2 text-lg text-right sm:text-lg xs:text-sm">{selectedVideo ? selectedVideo.videoLength : ''}</div>
//             </div>
//             <div className="border-2 p-4 rounded-lg md:text-sm sm:text-xs xs:text-xs tablet-landscape-min:text-xs">
//               {selectedVideo ? selectedVideo.videoDescription : 'No description available'}
//             </div>
//           </div>
//         </div>

//         {/* Playlist section */}
//         <div className="p-6 rounded-lg w-[350px] max-h-[850px] bg-gray-100 
//                         md:max-h-[500px] md:w-[590px] md:mt-2 
//                         sm:mt-2 sm:max-h-[500px] sm:max-w-[335px] 
//                         xs:mt-2 xs:max-h-[250px] xs:max-w-[280px]
//                         tablet-landscape-max:max-h-[600px]
//                         tablet-landscape-min:max-w-[250px] tablet-landscape-min:mt-0 tablet-landscape-min:p-4"
//                       >
//           <h2 className="text-xl font-bold mb-4 
//                          md:text-lg 
//                          sm:text-lg 
//                          xs:text-sm
//                          tablet-landscape-min:text-base tablet-landscape-min:mb-2"
//                         >R One Courses</h2>
          
//           {allowedPlaylists.length === 0 ? (
//             <div className="text-gray-300 font-semibold border-4 border-dashed border-gray-300 rounded-lg text-center py-4">
//               No playlists available
//             </div>
//           ) : (
//             <ul className="space-y-4 max-h-[800px] 
//                            overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack px-2
//                            lg:max-h-[465px] 
//                            md:max-h-[200px] 
//                            sm:max-h-[300px] 
//                            xs:max-h-[100px]
//                            tablet-landscape-min:max-h-[300px] tablet-landscape-min:space-y-2">
//               {allowedPlaylists.map((playlist) => (
//                 <li key={playlist.videoPlaylistId}>
//                   <div
//                     className="flex flex-row justify-between items-center bg-white py-2 px-4 rounded-t-lg cursor-pointer"
//                     onClick={() => toggleCategory(playlist.videoPlaylistId)}
//                   >
//                     <div className="flex flex-col justify-between items-left mr-4">
//                       <h3 className="text-lg font-semibold md:text-base sm:text-sm xs:text-xs tablet-landscape-min:text-xs">{playlist.videoPlaylistTitle}</h3>
//                       <p className="text-gray-500 text-xs mt-1">{playlist.videoPlaylistDescription}</p>
//                     </div>
//                     <button>
//                       {expandedCategories[playlist.videoPlaylistId] ? (
//                         <MdOutlineExpandLess className="text-2xl xs:text-lg" />
//                       ) : (
//                         <MdOutlineExpandMore className="text-2xl xs:text-lg" />
//                       )}
//                     </button>
//                   </div>

//                   {/* Display videos grouped by category */}
//                   {expandedCategories[playlist.videoPlaylistId] && (
//                     <div className="pl-4">
//                       <ul className="space-y-2">
//                         <div className="py-2 border-1 text-cyan-800 font-bold text-center text-xs underline">
//                           {selectedVideo ? selectedVideo.videoCategory : 'No Category'}
//                         </div>
//                         {videos
//                           .filter((video) => playlist.videoPlaylistContent.includes(video.videoId))
//                           .sort((a, b) => a.videoCategoryArray - b.videoCategoryArray)
//                           .map((video) => (
//                             <li key={video.videoId} className="border-b border-gray-700 pb-2">
//                               <button
//                                 className="w-full text-left text-sm flex justify-between items-center md:text-sm sm:text-xs xs:text-xs"
//                                 onClick={() => handleVideoSelect(video, playlist.videoPlaylistId)}
//                               >
//                                 <div className="flex flex-row items-center">
//                                   <img
//                                     src={`${apiUrl}${video.videoThumbnail}`}
//                                     alt={video.videoTitle}
//                                     className="w-16 h-9 mr-4"
//                                   />
//                                   <span>{video.videoTitle}</span>
//                                 </div>
//                                 <span>{video.videoLength}</span>
//                               </button>
//                             </li>
//                           ))}
//                       </ul>
//                     </div>
//                   )}
//                 </li>
//               ))}
//             </ul>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyVideo;