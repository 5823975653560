// src/components/admin/admin08_video.tsx

import React, { useState } from 'react';
// import react icon
import { AiFillEye, AiOutlineLock} from 'react-icons/ai';
import { MdPlaylistAdd } from "react-icons/md";
import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
import { BsUpload, BsFilterLeft } from 'react-icons/bs';
import { HiOutlineFolderRemove } from "react-icons/hi";
// import component
import VideoUpload from '../admin/VideoSetting/VideoUpload';
import VideoPlaylistModal from '../admin/VideoSetting/VideoPlaylistModal';
import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
import VideoPlayerModal from '../admin/VideoSetting/VideoPlayerModal';
import GroupListModal from '../admin/VideoSetting/VideoGroupListModal';
// import hook file
import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
import { useRoleAccess } from '../../hooks/AdminDashboard/useRoleAccess'; 
// import context file
import { useEnv } from '../../context/EnvContext';
import { useAuth } from '../../context/AuthContext';   

interface PlaylistData {
  videoPlaylistTitle: string;
  videoPlaylistDescription: string;
  videoPlaylistArray: number;
  videoPlaylistContent: string[];
  videoPlaylistPermission: string;
  videoPlaylistGroup: string;
  videoPlaylistGroupCustom:string; 
  videoPlaylistDate: string;
}

const Video: React.FC = () => {
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');
  const { 
    uploadVideo, removeVideos, uploadProgress, 
    videos, setVideos, 
    playlists, createPlaylist, fetchPlaylists, removePlaylists,
    loading 
  } = useVideoUpload();
  const { apiUrl } = useEnv();

  // modal open
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false); 
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  // video content table stats
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null); 
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [selectedVideoPath, setSelectedVideoPath] = useState<string | null>(null);
  const [selectedVideoDuration, setSelectedVideoDuration] = useState<string>('');
  const [selectedVideoTitle, setSelectedVideoTitle] = useState<string>('');
  // selected row
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedPlaylistRows, setSelectedPlaylistRows] = useState<string[]>([]);
    // Sorting config for videos and playlists
  const [isPlaylistToggleChecked, setIsPlaylistToggleChecked] = useState(false);
  const [isToggleChecked, setIsToggleChecked] = useState(false);
  // filtering and search term
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Filtering videos based on search term
  const filteredVideos = videos.filter((video) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      (video.videoTitle?.toLowerCase() || '').includes(lowerSearchTerm) ||
      (video.videoCategory?.toLowerCase() || '').includes(lowerSearchTerm) ||
      (video.videoPermission?.toLowerCase() || '').includes(lowerSearchTerm) ||
      video.videoViews?.toString().includes(lowerSearchTerm)
    );
  });  

  // Sorting function
  const handleSort = (key: string) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedVideos = [...filteredVideos].sort((a, b) => {
    if (!sortConfig) return 0;
  
    const key = sortConfig.key as keyof typeof a;
    const direction = sortConfig.direction === 'asc' ? 1 : -1;
  
    // Explicitly cast the values to string or number to avoid 'possibly undefined' error
    const aValue = a[key] !== undefined && a[key] !== null ? (a[key] as string | number) : '';
    const bValue = b[key] !== undefined && b[key] !== null ? (b[key] as string | number) : '';
  
    if (aValue < bValue) return -1 * direction;
    if (aValue > bValue) return 1 * direction;
    return 0;
  });  
  
  // Sorting playlist function
  const handleSortPlaylists = (key: string) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  
  const sortedPlaylists = [...playlists].sort((a, b) => {
    if (!sortConfig) return 0;
  
    const key = sortConfig.key as keyof typeof a;
    const direction = sortConfig.direction === 'asc' ? 1 : -1;
  
    const aValue = a[key] !== undefined && a[key] !== null ? (a[key] as string | number) : '';
    const bValue = b[key] !== undefined && b[key] !== null ? (b[key] as string | number) : '';
  
    if (aValue < bValue) return -1 * direction;
    if (aValue > bValue) return 1 * direction;
    return 0;
  });
  
  // Function to handle form submission and video upload
  const handleVideoUpload = async (formData: FormData) => {
    setIsProgressModalOpen(true);
    try {
      const response = await uploadVideo(formData);
      
      if (response) {
        // Correctly format the createdAt (videoUploadDate) immediately after upload
        const updatedVideo = {
          ...response.videoData,
          videoUploadDate: new Date().toISOString().split('T')[0], 
        };
        
        // Add the new video to the list only if it doesn't already exist
        setVideos((prevVideos: any[]) => {
          const videoExists = prevVideos.some(video => video.videoId === updatedVideo.videoId);
          if (!videoExists) {
            return [updatedVideo, ...prevVideos];
          }
          return prevVideos;  
        });
  
        setIsModalOpen(false); 
        setIsProgressModalOpen(false);  
      }
    } catch (error) {
      console.error("Error during video upload:", error);
    }
  };
  
  // Handle playlist creation
  const handleCreatePlaylist = async (playlistData: Omit<PlaylistData, 'videoPlaylistId'>) => {
    try {
      await createPlaylist(playlistData);
  
      // Refetch playlists after creating a new one
      await fetchPlaylists();
  
    } catch (error) {
      console.error('Error creating playlist:', error);
    }
  };
  
  // Toggle handler for the checkbox and button
  const handleToggleClick = () => {
    setIsToggleChecked(!isToggleChecked);
    setSelectedRows([]); 
  };

  // Handle toggling playlist selection
  const handleTogglePlaylistClick = () => {
    setIsPlaylistToggleChecked(!isPlaylistToggleChecked);
    setSelectedPlaylistRows([]);
  };

  // Handle selecting/deselecting rows
  const handleSelectRow = (id: string) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  // Handle "select all" checkbox
  const handleSelectAll = () => {
    if (selectedRows.length === videos.length) {
      setSelectedRows([]); 
    } else {
      setSelectedRows(videos.map(video => video.videoId)); 
    }
  };

  // Handle removing selected videos
  const handleRemoveVideos = async () => {
    if (selectedRows.length > 0) {
      await removeVideos(selectedRows);
      setSelectedRows([]);
    } else {
      alert("Please select at least one video to remove.");
    }
  };

  const handleSelectPlaylistRow = (id: string) => {
    if (selectedPlaylistRows.includes(id)) {
      setSelectedPlaylistRows(selectedPlaylistRows.filter(rowId => rowId !== id));
    } else {
      setSelectedPlaylistRows([...selectedPlaylistRows, id]);
    }
  };
  
  const handleSelectAllPlaylists = () => {
    if (selectedPlaylistRows.length === playlists.length) {
      setSelectedPlaylistRows([]);
    } else {
      setSelectedPlaylistRows(playlists.map(playlist => playlist.videoPlaylistId));
    }
  };
  
  const handleRemovePlaylists = async () => {
    if (selectedPlaylistRows.length > 0) {
      await removePlaylists(selectedPlaylistRows);
      setSelectedPlaylistRows([]);
    } else {
      alert("Please select at least one playlist to remove.");
    }
  };

  // Double-click handler for editing the selected video
  const handleDoubleClick = (video: any) => {
    setSelectedVideo(video);  
    setIsModalOpen(true);   
  };

  // Open video player modal when title is clicked and extract video duration
  const handlePlayVideo = (videoPath: string, videoTitle: string) => {
    setSelectedVideoPath(`${apiUrl}${videoPath}`);
  
    // Create a video element to get the duration
    const videoElement = document.createElement('video');
    videoElement.src = `${apiUrl}${videoPath}`;
  
    videoElement.onloadedmetadata = () => {
      const duration = videoElement.duration; 
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = Math.floor(duration % 60);
  
      const formattedDuration = `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      setSelectedVideoDuration(formattedDuration); 
    };
  
    setSelectedVideoTitle(videoTitle); 
    setIsPlayerModalOpen(true);
  };

  // Function to handle clicking on "Private" or "Custom Group"
  const handleGroupClick = (video: any) => {
    setSelectedVideoId(video.videoId);  // Set the selected video ID
    setIsGroupModalOpen(true);  // Open the group modal
  };

  if (loading) {
    return <div>Loading videos...</div>;
  }

  return (
    <div className="p-6">
      {/* Header Section with Search Bar and Upload Button */}
      <div className="flex justify-start items-center mb-2">
        <h1 className="text-2xl font-bold">Video Manage Center</h1>
      </div>

      <div className="flex justify-end items-center mb-4">
        <div className="flex items-center space-x-4">
          {/* toggle selection */}
          <button
            onClick={handleToggleClick}
            className={`mr-4 rounded-lg flex items-center space-x-2
              ${permissions?.videoManage?.editVideo !== 'enable'
                ? 'text-gray-400 cursor-not-allowed' 
                : 'text-cyan-800'
              }`}
            disabled={permissions?.videoManage?.editVideo !== 'enable'}
          >
            {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
            <span className="font-semibold text-sm">Toggle</span>
          </button>

          {/* Search Bar */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search your video"
              onChange={handleSearchChange}
              className="px-4 py-2 border rounded-lg w-auto"
            />
            <FaSearch className="absolute right-3 top-3 text-gray-400" />
          </div>

          {/* Upload Video Button */}
          <button
            onClick={() => setIsModalOpen(true)}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 
              ${permissions?.videoManage?.uploadVideo !== 'enable'
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                : 'bg-cyan-800 text-white'
              }`}
            disabled={permissions?.videoManage?.uploadVideo !== 'enable'}
          >
            <BsUpload className="text-sm" />
            <span className="text-sm">Upload</span>
          </button>

          {/* Remove Video Button */}
          <button
            onClick={handleRemoveVideos}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 
              ${permissions?.videoManage?.removeVideo !== 'enable'
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                : 'bg-red-600 text-white'
              }`}
            disabled={permissions?.videoManage?.removeVideo !== 'enable'}
          >
            <HiOutlineFolderRemove className="text-lg" />
            <span className="text-sm">Remove</span>
          </button>
        </div>
      </div>

      {/* Video Table */}
      <div className="h-auto max-h-[480px] overflow-auto">
        <table className="min-w-full bg-white border rounded-lg">
          <thead>
            <tr className="border-b text-center text-cyan-800 bg-gray-100">
              {isToggleChecked && (
                <th className="px-4 py-2">
                  <input
                    type="checkbox"
                    className="accent-cyan-800"
                    checked={selectedRows.length === videos.length}
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              <th 
                className="px-4 py-2" 
              >
                id
              </th>
              <th 
                className="px-4 py-2"
              >
                Thumbnail
              </th>
              <th className="px-4 py-2" onClick={() => handleSort('videoTitle')}>
                <div className="flex justify-center items-center">
                  Title<BsFilterLeft className="ml-2" />
                </div>
              </th>
              <th className="px-4 py-2" onClick={() => handleSort('videoCategory')}>
                <div className="flex justify-center items-center">
                  Category<BsFilterLeft className="ml-2" />
                </div>
              </th>
              <th className="px-4 py-2" onClick={() => handleSort('videoCategoryArray')}>
                <div className="flex justify-center items-center">
                  Array<BsFilterLeft className="ml-2" />
                </div>
              </th>
              <th className="px-4 py-2" onClick={() => handleSort('videoPermission')}>
                <div className="flex justify-center items-center">
                  Permission<BsFilterLeft className="ml-2" />
                </div>
              </th>
              <th className="px-4 py-2" onClick={() => handleSort('videoUploadDate')}>
                <div className="flex justify-center items-center">
                  Upload Date<BsFilterLeft className="ml-2" />
                </div>
              </th>
              <th className="px-4 py-2" onClick={() => handleSort('videoViews')}>
                <div className="flex justify-center items-center">
                  Views<BsFilterLeft className="ml-2" />
                </div>
              </th>
            </tr>
          </thead>

          {videos.length > 0 ? (
            <tbody>
              {sortedVideos
                .filter(video => video.videoId && !video.videoPlaylistId) 
                .map((video) => (
                  <tr 
                      key={video.videoId} 
                      className={`border-b text-center text-sm hover:bg-gray-100 ${
                        permissions?.productSubscription?.editSub === 'enable' ? '' : 'cursor-not-allowed pointer-events-none'
                      }`}
                      onDoubleClick={() => {
                        if (permissions?.videoManage?.editVideo === 'enable') {
                          handleDoubleClick(video);
                        }
                      }}
                  >
                    {isToggleChecked && (
                      <td className="px-4 py-4">
                        <input
                          type="checkbox"
                          className="accent-cyan-800"
                          checked={selectedRows.includes(video.videoId)}
                          onChange={() => handleSelectRow(video.videoId)}
                        />
                      </td>
                    )}
                    {/* id column */}
                    <td className="px-4 py-2">{video.videoId}</td>

                    {/* thumbnail column */}
                    <td className="px-4 py-2 flex justify-center items-center">
                      <img 
                          src={`${apiUrl}${video.videoThumbnail}`} 
                          alt={video.videoTitle} 
                          className="w-24 h-14 rounded-lg mr-4" 
                      />
                    </td>

                    {/* title column */}
                    <td>
                      <div className="text-center">
                        <h3 
                          className="font-bold text-cyan-800 hover:underline cursor-pointer"
                          onClick={() => handlePlayVideo(video.videoPath, video.videoTitle)}   
                        >
                          {video.videoTitle}
                        </h3>
                        <p className="text-sm text-cyan-800">{video.videoLength}</p>
                      </div>
                    </td>

                    {/* category column */}
                    <td className="px-4 py-2">{video.videoCategory}</td>

                    {/* category array column */}
                    <td className="px-4 py-2">{video.videoCategoryArray}</td>

                    {/* permission column */}
                    <td className="px-4 py-4">
                      {video.videoPermission === 'Private' || video.videoPermission === 'Custom Group' ? (
                        <div 
                          className="flex justify-center items-center hover:underline cursor-pointer"
                          onClick={() => handleGroupClick(video)}
                        >
                          <AiOutlineLock className="text-gray-500 mr-2" />
                          <span>{video.videoPermission}</span>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center">
                          <AiFillEye className="text-cyan-800 mr-2" />
                          <span>{video.videoPermission}</span>
                        </div>
                      )}
                    </td>

                    {/* upload date column */}
                    <td className="px-4 py-2">{new Date(video.videoUploadDate).toLocaleDateString('en-CA')}</td>

                    {/* video view stats column */}
                    <td className="px-4 py-4">{video.videoViews}</td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={9} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
                  <p className="text-gray-00 font-semi px-4">No playlists available</p>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      {/* Playlist Table */}
      <div className="relative mt-6">
        <div className="flex justify-end items-center mb-4">
          <div className="flex items-center space-x-4">
            {/* toggle selection */}
            <button
              onClick={handleTogglePlaylistClick}
              className={`mr-4 rounded-lg flex items-center space-x-2
                ${permissions?.videoManage?.editPlaylist !== 'enable'
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-cyan-800'
                }`}
              disabled={permissions?.videoManage?.editPlaylist !== 'enable'}
            >
              {isPlaylistToggleChecked  ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
              <span className="font-semibold text-sm">Toggle</span>
            </button>

            {/* Search Bar */}
            <div className="relative">
              <input
                type="text"
                placeholder="Search your playlist"
                onChange={handleSearchChange}
                className="px-4 py-2 border rounded-lg w-auto"
              />
              <FaSearch className="absolute right-3 top-3 text-gray-400" />
            </div>

            {/* Add Playlist Button */}
            <button
              onClick={() => setIsPlaylistModalOpen(true)}
              className={`px-4 py-2 rounded-lg flex items-center space-x-2 
                ${permissions?.videoManage?.addPlaylist !== 'enable'
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                  : 'bg-cyan-800 text-white'
                }`}
              disabled={permissions?.videoManage?.addPlaylist !== 'enable'}
            >
              <MdPlaylistAdd className="text-sm" />
              <span className="text-sm">Add Playlist</span>
            </button>

            {/* Remove Playlist Button */}
            <button
              onClick={handleRemovePlaylists}
              className={`px-4 py-2 rounded-lg flex items-center space-x-2 
                ${permissions?.videoManage?.removePlaylist !== 'enable'
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                  : 'bg-red-600 text-white'
                }`}
              disabled={permissions?.videoManage?.removePlaylist !== 'enable'}
            >
              <HiOutlineFolderRemove className="text-lg" />
              <span className="text-sm">Remove Playlist</span>
            </button>
          </div>
        </div>
        
        <div className="h-auto max-h-[300px] overflow-auto border">
          <table className="min-w-full bg-white border rounded-lg">
            <thead>
              <tr className="border-b text-center text-cyan-800 bg-gray-100">
                {isPlaylistToggleChecked  && (
                  <th className="px-4 py-2">
                    <input
                      type="checkbox"
                      className="accent-cyan-800"
                      checked={selectedPlaylistRows.length === playlists.length}
                      onChange={handleSelectAllPlaylists}
                    />
                  </th>
                )}
                <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistId')}>id</th>
                <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistTitle')}>Title</th>
                <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistDescription')}>Description</th>
                <th className="px-4 py-2">Content</th>
                <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistArray')}>Array</th>
                <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistDate')}>Created Date</th>
              </tr>
            </thead>

            <tbody>
            {sortedPlaylists.length > 0 ? (
              sortedPlaylists.map((playlist) => (
                  <tr 
                    key={playlist.videoPlaylistId} 
                    className="border-b text-center text-sm"
                  >
                    {isPlaylistToggleChecked && (
                      <td className="px-4 py-4">
                        <input
                          type="checkbox"
                          className="accent-cyan-800"
                          checked={selectedPlaylistRows.includes(playlist.videoPlaylistId)}
                          onChange={() => handleSelectPlaylistRow(playlist.videoPlaylistId)}
                        />
                      </td>
                    )}

                    {/* Playlist ID */}
                    <td className="px-4 py-2">{playlist.videoPlaylistId}</td>

                    {/* Playlist Title */}
                    <td className="px-4 py-2">{playlist.videoPlaylistTitle}</td>

                    {/* Playlist Description */}
                    <td className="px-4 py-2">{playlist.videoPlaylistDescription}</td>

                    {/* Playlist Content */}
                    <td className="px-4 py-2">
                      {playlist.videoPlaylistContent && Object.keys(playlist.videoPlaylistContent).length > 0 ? (
                        <div className="relative flex flex-col items-center">
                          {(() => {
                            // Extract video IDs from the playlist content
                            const matchingVideos = Object.keys(playlist.videoPlaylistContent)
                              .map((playlistVideoId) => {
                                // Try to find the video by videoId in the list of videos
                                const video = videos.find((video) => video.videoId === playlistVideoId);
                                if (video) {}
                                return video;
                              })
                              .filter((video) => video); 

                            if (matchingVideos.length > 0) {
                              // Randomly pick one matching video
                              const randomVideo = matchingVideos[Math.floor(Math.random() * matchingVideos.length)];

                              if (randomVideo) {
                                return (
                                  <div key={randomVideo.videoId} className="relative">
                                    <img
                                      src={`${apiUrl}${randomVideo.videoThumbnail}`}
                                      alt={randomVideo.videoTitle}
                                      className="w-16 h-10 rounded-lg mr-2"
                                    />
                                    {/* Overlay with the total number of videos in the playlist */}
                                    <span className="absolute bottom-0 right-0 bg-gray-800 text-white text-xs px-2 py-1 rounded-full">
                                      {matchingVideos.length} videos
                                    </span>
                                  </div>
                                );
                              }
                            }

                            return <p>No matching video found</p>;
                          })()}
                        </div>
                      ) : (
                        'No content available'
                      )}
                    </td>

                    {/* Playlist Array */}
                    <td className="px-4 py-2">{playlist.videoPlaylistArray}</td>

                    {/* Created Date */}
                    <td className="px-4 py-2">{new Date(playlist.videoPlaylistDate).toLocaleDateString('en-CA')}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
                    <p className="text-gray-00 font-semi px-4">No playlists available</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Import the modal component */}
      <VideoUpload
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        handleSubmit={handleVideoUpload}
        initialData={selectedVideo} 
      />

      {/* Playlist Modal */}
      <VideoPlaylistModal
        isOpen={isPlaylistModalOpen}
        onClose={() => setIsPlaylistModalOpen(false)}
        handleCreatePlaylist={handleCreatePlaylist}
      />

      {/* Progress Modal */}
      <ProgressModal
        isOpen={isProgressModalOpen}
        progress={uploadProgress}
        onClose={() => setIsProgressModalOpen(false)}
      />

      {/* Group List Modal */}
      <GroupListModal
        isOpen={isGroupModalOpen}
        onClose={() => setIsGroupModalOpen(false)}
        videoId={selectedVideoId || ''} 
      />

      {/* Video Player Modal */}
      {selectedVideoPath && (
        <VideoPlayerModal
          isOpen={isPlayerModalOpen}
          onClose={() => setIsPlayerModalOpen(false)}
          videoSrc={selectedVideoPath} 
          videoDuration={selectedVideoDuration}
          videoTitle={selectedVideoTitle}
        />
      )}
    </div>
  );
};

export default Video;


// // update code --> 11 Oct 2024
// // src/components/admin/admin08_video.tsx

// import React, { useState } from 'react';
// // import react icon
// import { AiFillEye, AiOutlineLock} from 'react-icons/ai';
// import { MdPlaylistAdd } from "react-icons/md";
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload, BsFilterLeft } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import component
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// import VideoPlaylistModal from '../admin/VideoSetting/VideoPlaylistModal';
// import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
// import VideoPlayerModal from '../admin/VideoSetting/VideoPlayerModal';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
// // import context file
// import { useEnv } from '../../context/EnvContext'; 

// interface PlaylistData {
//   videoPlaylistTitle: string;
//   videoPlaylistDescription: string;
//   videoPlaylistArray: number;
//   videoPlaylistContent: string[];
//   videoPlaylistPermission: string;
//   videoPlaylistGroup: string;
//   videoPlaylistGroupCustom:string; 
//   videoPlaylistDate: string;
// }

// const Video: React.FC = () => {
//   const { 
//     uploadVideo, removeVideos, uploadProgress, 
//     videos, setVideos, 
//     playlists, createPlaylist, fetchPlaylists, removePlaylists,
//     loading 
//   } = useVideoUpload();
//   const { apiUrl } = useEnv();

//   // modal open
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false); 
//   const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
//   const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
//   // video content table stats
//   const [selectedVideo, setSelectedVideo] = useState<any>(null);
//   const [selectedVideoPath, setSelectedVideoPath] = useState<string | null>(null);
//   const [selectedVideoDuration, setSelectedVideoDuration] = useState<string>('');
//   const [selectedVideoTitle, setSelectedVideoTitle] = useState<string>('');
//   // selected row
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [selectedPlaylistRows, setSelectedPlaylistRows] = useState<string[]>([]);
//     // Sorting config for videos and playlists
//   const [isPlaylistToggleChecked, setIsPlaylistToggleChecked] = useState(false);
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   // filtering and search term
//   const [searchTerm, setSearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filtering videos based on search term
//   const filteredVideos = videos.filter((video) => {
//     const lowerSearchTerm = searchTerm.toLowerCase();
//     return (
//       (video.videoTitle?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       (video.videoCategory?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       (video.videoPermission?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       video.videoViews?.toString().includes(lowerSearchTerm)
//     );
//   });  

//   // Sorting function
//   const handleSort = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedVideos = [...filteredVideos].sort((a, b) => {
//     if (!sortConfig) return 0;
  
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;
  
//     // Explicitly cast the values to string or number to avoid 'possibly undefined' error
//     const aValue = a[key] !== undefined && a[key] !== null ? (a[key] as string | number) : '';
//     const bValue = b[key] !== undefined && b[key] !== null ? (b[key] as string | number) : '';
  
//     if (aValue < bValue) return -1 * direction;
//     if (aValue > bValue) return 1 * direction;
//     return 0;
//   });  
  
//   // Sorting playlist function
//   const handleSortPlaylists = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };
  
//   const sortedPlaylists = [...playlists].sort((a, b) => {
//     if (!sortConfig) return 0;
  
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;
  
//     const aValue = a[key] !== undefined && a[key] !== null ? (a[key] as string | number) : '';
//     const bValue = b[key] !== undefined && b[key] !== null ? (b[key] as string | number) : '';
  
//     if (aValue < bValue) return -1 * direction;
//     if (aValue > bValue) return 1 * direction;
//     return 0;
//   });
  
//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     setIsProgressModalOpen(true);
//     try {
//       const response = await uploadVideo(formData);
      
//       if (response) {
//         // Correctly format the createdAt (videoUploadDate) immediately after upload
//         const updatedVideo = {
//           ...response.videoData,
//           videoUploadDate: new Date().toISOString().split('T')[0], 
//         };
        
//         // Add the new video to the list only if it doesn't already exist
//         setVideos((prevVideos: any[]) => {
//           const videoExists = prevVideos.some(video => video.videoId === updatedVideo.videoId);
//           if (!videoExists) {
//             return [updatedVideo, ...prevVideos];
//           }
//           return prevVideos;  
//         });
  
//         setIsModalOpen(false); 
//         setIsProgressModalOpen(false);  
//       }
//     } catch (error) {
//       console.error("Error during video upload:", error);
//     }
//   };
  
//   // Handle playlist creation
//   const handleCreatePlaylist = async (playlistData: Omit<PlaylistData, 'videoPlaylistId'>) => {
//     try {
//       await createPlaylist(playlistData);
  
//       // Refetch playlists after creating a new one
//       await fetchPlaylists();
  
//     } catch (error) {
//       console.error('Error creating playlist:', error);
//     }
//   };
  
//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); 
//   };

//   // Handle toggling playlist selection
//   const handleTogglePlaylistClick = () => {
//     setIsPlaylistToggleChecked(!isPlaylistToggleChecked);
//     setSelectedPlaylistRows([]);
//   };

//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: string) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); 
//     } else {
//       setSelectedRows(videos.map(video => video.videoId)); 
//     }
//   };

//   // Handle removing selected videos
//   const handleRemoveVideos = async () => {
//     if (selectedRows.length > 0) {
//       await removeVideos(selectedRows);
//       setSelectedRows([]);
//     } else {
//       alert("Please select at least one video to remove.");
//     }
//   };

//   const handleSelectPlaylistRow = (id: string) => {
//     if (selectedPlaylistRows.includes(id)) {
//       setSelectedPlaylistRows(selectedPlaylistRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedPlaylistRows([...selectedPlaylistRows, id]);
//     }
//   };
  
//   const handleSelectAllPlaylists = () => {
//     if (selectedPlaylistRows.length === playlists.length) {
//       setSelectedPlaylistRows([]);
//     } else {
//       setSelectedPlaylistRows(playlists.map(playlist => playlist.videoPlaylistId));
//     }
//   };
  
//   const handleRemovePlaylists = async () => {
//     if (selectedPlaylistRows.length > 0) {
//       await removePlaylists(selectedPlaylistRows);
//       setSelectedPlaylistRows([]);
//     } else {
//       alert("Please select at least one playlist to remove.");
//     }
//   };

//   // Double-click handler for editing the selected video
//   const handleDoubleClick = (video: any) => {
//     setSelectedVideo(video);  
//     setIsModalOpen(true);   
//   };

//   // Open video player modal when title is clicked and extract video duration
//   const handlePlayVideo = (videoPath: string, videoTitle: string) => {
//     setSelectedVideoPath(`${apiUrl}${videoPath}`);
  
//     // Create a video element to get the duration
//     const videoElement = document.createElement('video');
//     videoElement.src = `${apiUrl}${videoPath}`;
  
//     videoElement.onloadedmetadata = () => {
//       const duration = videoElement.duration; 
//       const hours = Math.floor(duration / 3600);
//       const minutes = Math.floor((duration % 3600) / 60);
//       const seconds = Math.floor(duration % 60);
  
//       const formattedDuration = `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//       setSelectedVideoDuration(formattedDuration); 
//     };
  
//     setSelectedVideoTitle(videoTitle); 
//     setIsPlayerModalOpen(true);
//   };

//   if (loading) {
//     return <div>Loading videos...</div>;
//   }

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         <div className="flex items-center space-x-4">
//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           {/* Search Bar */}
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               onChange={handleSearchChange}
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span className="text-sm">Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={handleRemoveVideos}
//             className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span className="text-sm">Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center text-cyan-800 bg-gray-100">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   className="accent-cyan-800"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th 
//               className="px-4 py-2" 
//             >
//               id
//             </th>
//             <th 
//               className="px-4 py-2"
//             >
//               Thumbnail
//             </th>
//             <th className="px-4 py-2" onClick={() => handleSort('videoTitle')}>
//               <div className="flex justify-center items-center">
//                 Title<BsFilterLeft className="ml-2" />
//               </div>
//             </th>
//             <th className="px-4 py-2" onClick={() => handleSort('videoCategory')}>
//               <div className="flex justify-center items-center">
//                 Category<BsFilterLeft className="ml-2" />
//               </div>
//             </th>
//             <th className="px-4 py-2" onClick={() => handleSort('videoCategoryArray')}>
//               <div className="flex justify-center items-center">
//                 Array<BsFilterLeft className="ml-2" />
//               </div>
//             </th>
//             <th className="px-4 py-2" onClick={() => handleSort('videoPermission')}>
//               <div className="flex justify-center items-center">
//                 Permission<BsFilterLeft className="ml-2" />
//               </div>
//             </th>
//             <th className="px-4 py-2" onClick={() => handleSort('videoUploadDate')}>
//               <div className="flex justify-center items-center">
//                 Upload Date<BsFilterLeft className="ml-2" />
//               </div>
//             </th>
//             <th className="px-4 py-2" onClick={() => handleSort('videoViews')}>
//               <div className="flex justify-center items-center">
//                 Views<BsFilterLeft className="ml-2" />
//               </div>
//             </th>
//           </tr>
//         </thead>

//         {videos.length > 0 ? (
//           <tbody>
//             {sortedVideos
//               .filter(video => video.videoId && !video.videoPlaylistId) 
//               .map((video) => (
//                 <tr 
//                     key={video.videoId} 
//                     className="border-b text-center text-sm"
//                     onDoubleClick={() => handleDoubleClick(video)}
//                 >
//                   {isToggleChecked && (
//                     <td className="px-4 py-4">
//                       <input
//                         type="checkbox"
//                         className="accent-cyan-800"
//                         checked={selectedRows.includes(video.videoId)}
//                         onChange={() => handleSelectRow(video.videoId)}
//                       />
//                     </td>
//                   )}
//                   {/* id column */}
//                   <td className="px-4 py-2">{video.videoId}</td>

//                   {/* thumbnail column */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <img 
//                         src={`${apiUrl}${video.videoThumbnail}`} 
//                         alt={video.videoTitle} 
//                         className="w-24 h-14 rounded-lg mr-4" 
//                     />
//                   </td>

//                   {/* title column */}
//                   <td>
//                     <div className="text-center">
//                       <h3 
//                         className="font-bold text-cyan-800 hover:underline cursor-pointer"
//                         onClick={() => handlePlayVideo(video.videoPath, video.videoTitle)}   
//                       >
//                         {video.videoTitle}
//                       </h3>
//                       <p className="text-sm text-cyan-800">{video.videoLength}</p>
//                     </div>
//                   </td>

//                   {/* category column */}
//                   <td className="px-4 py-2">{video.videoCategory}</td>

//                   {/* category array column */}
//                   <td className="px-4 py-2">{video.videoCategoryArray}</td>

//                   {/* permission column */}
//                   <td className="px-4 py-4">
//                     {video.videoPermission === 'Private' ? (
//                       <div className="flex justify-center items-center">
//                         <AiOutlineLock className="text-gray-500 mr-2" />
//                         <span>{video.videoPermission}</span>
//                       </div>
//                     ) : (
//                       <div className="flex justify-center items-center">
//                         <AiFillEye className="text-cyan-800 mr-2" />
//                         <span>{video.videoPermission}</span>
//                       </div>
//                     )}
//                   </td>

//                   {/* upload date column */}
//                   <td className="px-4 py-2">{new Date(video.videoUploadDate).toLocaleDateString('en-CA')}</td>

//                   {/* video view stats column */}
//                   <td className="px-4 py-4">{video.videoViews}</td>
//                 </tr>
//               ))}
//           </tbody>
//         ) : (
//           <tbody>
//             <tr>
//               <td colSpan={9} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
//                 <p className="text-gray-00 font-semi px-4">No playlists available</p>
//               </td>
//             </tr>
//           </tbody>
//         )}
//       </table>

//       {/* Playlist Table */}
//       <div className="relative mt-6">
//         <div className="flex justify-end items-center mb-4">
//           <div className="flex items-center space-x-4">
//             {/* toggle selection */}
//             <button
//               onClick={handleTogglePlaylistClick}
//               className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//             >
//               {isPlaylistToggleChecked  ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//               <span className="font-semibold text-sm">Toggle</span>
//             </button>

//             {/* Search Bar */}
//             <div className="relative">
//               <input
//                 type="text"
//                 placeholder="Search your playlist"
//                 onChange={handleSearchChange}
//                 className="px-4 py-2 border rounded-lg w-auto"
//               />
//               <FaSearch className="absolute right-3 top-3 text-gray-500" />
//             </div>

//             {/* Add Playlist Button */}
//             <button
//               onClick={() => setIsPlaylistModalOpen(true)}
//               className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//               <MdPlaylistAdd className="text-sm" />
//               <span className="text-sm">Add Playlist</span>
//             </button>

//             {/* Remove Playlist Button */}
//             <button
//                onClick={handleRemovePlaylists}
//               className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//               <HiOutlineFolderRemove className="text-lg" />
//               <span className="text-sm">Remove</span>
//             </button>
//           </div>
//         </div>

//         <table className="min-w-full bg-white border rounded-lg">
//           <thead>
//             <tr className="border-b text-center text-cyan-800 bg-gray-100">
//               {isPlaylistToggleChecked  && (
//                 <th className="px-4 py-2">
//                   <input
//                     type="checkbox"
//                     className="accent-cyan-800"
//                     checked={selectedPlaylistRows.length === playlists.length}
//                     onChange={handleSelectAllPlaylists}
//                   />
//                 </th>
//               )}
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistId')}>id</th>
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistTitle')}>Title</th>
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistDescription')}>Description</th>
//               <th className="px-4 py-2">Content</th>
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistArray')}>Array</th>
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistDate')}>Created Date</th>
//             </tr>
//           </thead>

//           <tbody>
//           {sortedPlaylists.length > 0 ? (
//             sortedPlaylists.map((playlist) => (
//                 <tr 
//                   key={playlist.videoPlaylistId} 
//                   className="border-b text-center text-sm"
//                 >
//                   {isPlaylistToggleChecked && (
//                     <td className="px-4 py-4">
//                       <input
//                         type="checkbox"
//                         className="accent-cyan-800"
//                         checked={selectedPlaylistRows.includes(playlist.videoPlaylistId)}
//                         onChange={() => handleSelectPlaylistRow(playlist.videoPlaylistId)}
//                       />
//                     </td>
//                   )}

//                   {/* Playlist ID */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistId}</td>

//                   {/* Playlist Title */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistTitle}</td>

//                   {/* Playlist Description */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistDescription}</td>

//                   {/* Playlist Content */}
//                   <td className="px-4 py-2">
//                     {playlist.videoPlaylistContent && Object.keys(playlist.videoPlaylistContent).length > 0 ? (
//                       <div className="relative flex flex-col items-center">
//                         {(() => {
//                           // Extract video IDs from the playlist content
//                           const matchingVideos = Object.keys(playlist.videoPlaylistContent)
//                             .map((playlistVideoId) => {
//                               // Try to find the video by videoId in the list of videos
//                               const video = videos.find((video) => video.videoId === playlistVideoId);
//                               if (video) {}
//                               return video;
//                             })
//                             .filter((video) => video); 

//                           if (matchingVideos.length > 0) {
//                             // Randomly pick one matching video
//                             const randomVideo = matchingVideos[Math.floor(Math.random() * matchingVideos.length)];

//                             if (randomVideo) {
//                               return (
//                                 <div key={randomVideo.videoId} className="relative">
//                                   <img
//                                     src={`${apiUrl}${randomVideo.videoThumbnail}`}
//                                     alt={randomVideo.videoTitle}
//                                     className="w-16 h-10 rounded-lg mr-2"
//                                   />
//                                   {/* Overlay with the total number of videos in the playlist */}
//                                   <span className="absolute bottom-0 right-0 bg-gray-800 text-white text-xs px-2 py-1 rounded-full">
//                                     {matchingVideos.length} videos
//                                   </span>
//                                 </div>
//                               );
//                             }
//                           }

//                           return <p>No matching video found</p>;
//                         })()}
//                       </div>
//                     ) : (
//                       'No content available'
//                     )}
//                   </td>

//                   {/* Playlist Array */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistArray}</td>

//                   {/* Created Date */}
//                   <td className="px-4 py-2">{new Date(playlist.videoPlaylistDate).toLocaleDateString('en-CA')}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={7} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
//                   <p className="text-gray-00 font-semi px-4">No playlists available</p>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleSubmit={handleVideoUpload}
//         initialData={selectedVideo} 
//       />

//       {/* Playlist Modal */}
//       <VideoPlaylistModal
//         isOpen={isPlaylistModalOpen}
//         onClose={() => setIsPlaylistModalOpen(false)}
//         handleCreatePlaylist={handleCreatePlaylist}
//       />

//       {/* Progress Modal */}
//       <ProgressModal
//         isOpen={isProgressModalOpen}
//         progress={uploadProgress}
//         onClose={() => setIsProgressModalOpen(false)}
//       />

//       {/* Video Player Modal */}
//       {selectedVideoPath && (
//         <VideoPlayerModal
//           isOpen={isPlayerModalOpen}
//           onClose={() => setIsPlayerModalOpen(false)}
//           videoSrc={selectedVideoPath} 
//           videoDuration={selectedVideoDuration}
//           videoTitle={selectedVideoTitle}
//         />
//       )}
//     </div>
//   );
// };

// export default Video;


// // udpate copde --> 08 oct 2024
// // src/components/admin/admin08_video.tsx

// import React, { useState } from 'react';
// // import react icon
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { MdPlaylistAdd } from "react-icons/md";
// import { LuFilter, LuFilterX } from "react-icons/lu";
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import component
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// import VideoPlaylistModal from '../admin/VideoSetting/VideoPlaylistModal';
// import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
// import VideoPlayerModal from '../admin/VideoSetting/VideoPlayerModal';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
// // import context file
// import { useEnv } from '../../context/EnvContext'; 

// interface PlaylistData {
//   videoPlaylistTitle: string;
//   videoPlaylistDescription: string;
//   videoPlaylistArray: number;
//   videoPlaylistContent: Record<string, { videoId: string; videoTitle: string; videoCategory: string }>;
//   videoPlaylistDate: string;
// }

// const Video: React.FC = () => {
//   const { 
//     uploadVideo, removeVideos, uploadProgress, 
//     videos, setVideos, 
//     playlists, createPlaylist, fetchPlaylists, removePlaylists,
//     loading 
//   } = useVideoUpload();
//   const { apiUrl } = useEnv();
//   // modal open
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false); 
//   const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
//   // video content table stats
//   const [selectedVideo, setSelectedVideo] = useState<any>(null);
//   const [selectedVideoPath, setSelectedVideoPath] = useState<string | null>(null);
//   const [selectedVideoDuration, setSelectedVideoDuration] = useState<string>('');
//   const [selectedVideoTitle, setSelectedVideoTitle] = useState<string>('');
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   const [isToggleFilterChecked, setIsToggleFilterChecked] = useState(false);
//   // selected row
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [selectedPlaylistRows, setSelectedPlaylistRows] = useState<string[]>([]);
//   // video upload progressing
//   const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
//   // filtering and search term
//   const [searchTerm, setSearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filtering videos based on search term
//   const filteredVideos = videos.filter((video) => {
//     const lowerSearchTerm = searchTerm.toLowerCase();
//     return (
//       (video.videoTitle?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       (video.videoCategory?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       (video.videoPermission?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       video.videoViews?.toString().includes(lowerSearchTerm)
//     );
//   });  

//   // Sorting function
//   const handleSort = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedVideos = [...filteredVideos].sort((a, b) => {
//     if (!sortConfig) return 0;
  
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;
  
//     // Explicitly cast the values to string or number to avoid 'possibly undefined' error
//     const aValue = a[key] !== undefined && a[key] !== null ? (a[key] as string | number) : '';
//     const bValue = b[key] !== undefined && b[key] !== null ? (b[key] as string | number) : '';
  
//     if (aValue < bValue) return -1 * direction;
//     if (aValue > bValue) return 1 * direction;
//     return 0;
//   });  
  
//   // Sorting playlist function
//   const handleSortPlaylists = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };
  
//   const sortedPlaylists = [...playlists].sort((a, b) => {
//     if (!sortConfig) return 0;
  
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;
  
//     const aValue = a[key] !== undefined && a[key] !== null ? (a[key] as string | number) : '';
//     const bValue = b[key] !== undefined && b[key] !== null ? (b[key] as string | number) : '';
  
//     if (aValue < bValue) return -1 * direction;
//     if (aValue > bValue) return 1 * direction;
//     return 0;
//   });
  

//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     setIsProgressModalOpen(true);
//     try {
//       const response = await uploadVideo(formData);
      
//       if (response) {
//         // Correctly format the createdAt (videoUploadDate) immediately after upload
//         const updatedVideo = {
//           ...response.videoData,
//           videoUploadDate: new Date().toISOString().split('T')[0], 
//         };
        
//         // Add the new video to the list only if it doesn't already exist
//         setVideos((prevVideos: any[]) => {
//           const videoExists = prevVideos.some(video => video.videoId === updatedVideo.videoId);
//           if (!videoExists) {
//             return [updatedVideo, ...prevVideos];
//           }
//           return prevVideos;  
//         });
  
//         setIsModalOpen(false); 
//         setIsProgressModalOpen(false);  
//       }
//     } catch (error) {
//       console.error("Error during video upload:", error);
//     }
//   };
  
//   // Handle playlist creation
//   const handleCreatePlaylist = async (playlistData: Omit<PlaylistData, 'videoPlaylistId'>) => {
//     try {
//       await createPlaylist(playlistData);
  
//       // Refetch playlists after creating a new one
//       await fetchPlaylists();
  
//     } catch (error) {
//       console.error('Error creating playlist:', error);
//     }
//   };
  
//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); 
//   };

//   // Toggle handler for filter and button
//   const handleToggleFilterClick = () => {
//     setIsToggleFilterChecked(!isToggleFilterChecked);
//     handleSort('videoTitle');
//   };


//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: string) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); 
//     } else {
//       setSelectedRows(videos.map(video => video.videoId)); 
//     }
//   };

//   // Handle removing selected videos
//   const handleRemoveVideos = async () => {
//     if (selectedRows.length > 0) {
//       await removeVideos(selectedRows);
//       setSelectedRows([]);
//     } else {
//       alert("Please select at least one video to remove.");
//     }
//   };

//   const handleSelectPlaylistRow = (id: string) => {
//     if (selectedPlaylistRows.includes(id)) {
//       setSelectedPlaylistRows(selectedPlaylistRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedPlaylistRows([...selectedPlaylistRows, id]);
//     }
//   };
  
//   const handleSelectAllPlaylists = () => {
//     if (selectedPlaylistRows.length === playlists.length) {
//       setSelectedPlaylistRows([]);
//     } else {
//       setSelectedPlaylistRows(playlists.map(playlist => playlist.videoPlaylistId));
//     }
//   };
  
//   const handleRemovePlaylists = async () => {
//     if (selectedPlaylistRows.length > 0) {
//       await removePlaylists(selectedPlaylistRows);
//       setSelectedPlaylistRows([]);
//     } else {
//       alert("Please select at least one playlist to remove.");
//     }
//   };

//   // Double-click handler for editing the selected video
//   const handleDoubleClick = (video: any) => {
//     setSelectedVideo(video);  
//     setIsModalOpen(true);   
//   };

//   // Open video player modal when title is clicked and extract video duration
//   const handlePlayVideo = (videoPath: string, videoTitle: string) => {
//     setSelectedVideoPath(`${apiUrl}${videoPath}`);
  
//     // Create a video element to get the duration
//     const videoElement = document.createElement('video');
//     videoElement.src = `${apiUrl}${videoPath}`;
  
//     videoElement.onloadedmetadata = () => {
//       const duration = videoElement.duration; 
//       const hours = Math.floor(duration / 3600);
//       const minutes = Math.floor((duration % 3600) / 60);
//       const seconds = Math.floor(duration % 60);
  
//       const formattedDuration = `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//       setSelectedVideoDuration(formattedDuration); 
//     };
  
//     setSelectedVideoTitle(videoTitle); 
//     setIsPlayerModalOpen(true);
//   };

//   if (loading) {
//     return <div>Loading videos...</div>;
//   }

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         <div className="flex items-center space-x-4">

//           {/* filter asc & dsc button */}
//           <button 
//             onClick={handleToggleFilterClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleFilterChecked ? <LuFilter className="text-sm" /> : <LuFilterX className="text-sm" />}
//             <span className="font-semibold text-sm">Filter</span>
//           </button>


//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           {/* Search Bar */}
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               onChange={handleSearchChange}
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span className="text-sm">Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={handleRemoveVideos}
//             className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span className="text-sm">Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center text-cyan-800 bg-gray-100">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   className="accent-cyan-800"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th className="px-4 py-2">id</th>
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Category</th>
//             <th className="px-4 py-2">Array</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Upload Date</th>
//             <th className="px-4 py-2">Views</th>
//           </tr>
//         </thead>

//         {videos.length > 0 ? (
//           <tbody>
//             {sortedVideos
//               .filter(video => video.videoId && !video.videoPlaylistId) 
//               .map((video) => (
//                 <tr 
//                     key={video.videoId} 
//                     className="border-b text-center text-sm"
//                     onDoubleClick={() => handleDoubleClick(video)}
//                 >
//                   {isToggleChecked && (
//                     <td className="px-4 py-4">
//                       <input
//                         type="checkbox"
//                         className="accent-cyan-800"
//                         checked={selectedRows.includes(video.videoId)}
//                         onChange={() => handleSelectRow(video.videoId)}
//                       />
//                     </td>
//                   )}
//                   {/* id column */}
//                   <td className="px-4 py-2">{video.videoId}</td>

//                   {/* thumbnail column */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <img 
//                         src={`${apiUrl}${video.videoThumbnail}`} 
//                         alt={video.videoTitle} 
//                         className="w-24 h-14 rounded-lg mr-4" 
//                     />
//                   </td>

//                   {/* title column */}
//                   <td>
//                     <div className="text-center">
//                       <h3 
//                         className="font-bold text-cyan-800"
//                         onClick={() => handlePlayVideo(video.videoPath, video.videoTitle)}   
//                       >
//                         {video.videoTitle}
//                       </h3>
//                       <p className="text-sm text-cyan-800">{video.videoLength}</p>
//                     </div>
//                   </td>

//                   {/* category column */}
//                   <td className="px-4 py-2">{video.videoCategory}</td>

//                   {/* category array column */}
//                   <td className="px-4 py-2">{video.videoCategoryArray}</td>

//                   {/* permission column */}
//                   <td className="px-4 py-4">
//                     {video.videoPermission === 'Private' ? (
//                       <div className="flex justify-center items-center">
//                         <AiOutlineLock className="text-gray-500 mr-2" />
//                         <span>{video.videoPermission}</span>
//                       </div>
//                     ) : (
//                       <div className="flex justify-center items-center">
//                         <AiFillEye className="text-cyan-800 mr-2" />
//                         <span>{video.videoPermission}</span>
//                       </div>
//                     )}
//                   </td>

//                   {/* upload date column */}
//                   <td className="px-4 py-4">{new Date(video.videoUploadDate).toLocaleDateString('en-CA')}</td>

//                   {/* video view stats column */}
//                   <td className="px-4 py-4">{video.videoViews}</td>
//                 </tr>
//               ))}
//           </tbody>
//         ) : (
//           <tbody>
//             <tr>
//               <td colSpan={9} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
//                 <p className="text-gray-00 font-semi px-4">No playlists available</p>
//               </td>
//             </tr>
//           </tbody>
//         )}
//       </table>

//       {/* Playlist Table */}
//       <div className="relative mt-6">
//         <div className="flex justify-end items-center mb-4">
//           <div className="flex items-center space-x-4">
//             {/* filter asc & dsc button */}
//             <button 
//               onClick={() => handleSortPlaylists('videoPlaylistTitle')}
//               className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//             >
//               {isToggleFilterChecked ? <LuFilter className="text-sm" /> : <LuFilterX className="text-sm" />}
//               <span className="font-semibold text-sm">Filter</span>
//             </button>

//             {/* toggle selection */}
//             <button
//               onClick={handleToggleClick}
//               className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//             >
//               {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//               <span className="font-semibold text-sm">Toggle</span>
//             </button>

//             {/* Search Bar */}
//             <div className="relative">
//               <input
//                 type="text"
//                 placeholder="Search your playlist"
//                 onChange={handleSearchChange}
//                 className="px-4 py-2 border rounded-lg w-auto"
//               />
//               <FaSearch className="absolute right-3 top-3 text-gray-500" />
//             </div>

//             {/* Add Playlist Button */}
//             <button
//               onClick={() => setIsPlaylistModalOpen(true)}
//               className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//               <MdPlaylistAdd className="text-sm" />
//               <span className="text-sm">Add Playlist</span>
//             </button>

//             {/* Remove Playlist Button */}
//             <button
//                onClick={handleRemovePlaylists}
//               className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//               <HiOutlineFolderRemove className="text-lg" />
//               <span className="text-sm">Remove</span>
//             </button>
//           </div>
//         </div>

//         <table className="min-w-full bg-white border rounded-lg">
//           <thead>
//             <tr className="border-b text-center text-cyan-800 bg-gray-100">
//               {isToggleChecked && (
//                 <th className="px-4 py-2">
//                   <input
//                     type="checkbox"
//                     className="accent-cyan-800"
//                     checked={selectedPlaylistRows.length === playlists.length}
//                     onChange={handleSelectAllPlaylists}
//                   />
//                 </th>
//               )}
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistId')}>ID</th>
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistTitle')}>Title</th>
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistDescription')}>Description</th>
//               <th className="px-4 py-2">Content</th>
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistArray')}>Array</th>
//               <th className="px-4 py-2" onClick={() => handleSortPlaylists('videoPlaylistDate')}>Created Date</th>
//             </tr>
//           </thead>

//           <tbody>
//           {sortedPlaylists.length > 0 ? (
//             sortedPlaylists.map((playlist) => (
//                 <tr 
//                   key={playlist.videoPlaylistId} 
//                   className="border-b text-center text-sm"
//                 >
//                   {isToggleChecked && (
//                     <td className="px-4 py-4">
//                       <input
//                         type="checkbox"
//                         className="accent-cyan-800"
//                         checked={selectedPlaylistRows.includes(playlist.videoPlaylistId)}
//                         onChange={() => handleSelectPlaylistRow(playlist.videoPlaylistId)}
//                       />
//                     </td>
//                   )}

//                   {/* Playlist ID */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistId}</td>

//                   {/* Playlist Title */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistTitle}</td>

//                   {/* Playlist Description */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistDescription}</td>

//                   {/* Playlist Content */}
//                   <td className="px-4 py-2">
//                     {playlist.videoPlaylistContent && Object.keys(playlist.videoPlaylistContent).length > 0 ? (
//                       <div className="relative flex flex-col items-center">
//                         {(() => {
//                           // Debugging: Log playlist content and videos
//                           console.log('Playlist Content:', playlist.videoPlaylistContent);
//                           console.log('All Videos:', videos);

//                           // Extract video IDs from the playlist content
//                           const matchingVideos = Object.keys(playlist.videoPlaylistContent)
//                             .map((playlistVideoId) => {
//                               // Try to find the video by videoId in the list of videos
//                               const video = videos.find((video) => video.videoId === playlistVideoId);
//                               if (video) {
//                                 console.log(`Matching video found: ${video.videoId}`);
//                               }
//                               return video; // Return the video object if found, else return undefined
//                             })
//                             .filter((video) => video); // Filter out undefined values

//                           console.log('Matching Videos:', matchingVideos);

//                           if (matchingVideos.length > 0) {
//                             // Randomly pick one matching video
//                             const randomVideo = matchingVideos[Math.floor(Math.random() * matchingVideos.length)];

//                             if (randomVideo) {
//                               return (
//                                 <div key={randomVideo.videoId} className="relative">
//                                   <img
//                                     src={`${apiUrl}${randomVideo.videoThumbnail}`}
//                                     alt={randomVideo.videoTitle}
//                                     className="w-16 h-10 rounded-lg mr-2"
//                                   />
//                                   {/* Overlay with the total number of videos in the playlist */}
//                                   <span className="absolute bottom-0 right-0 bg-gray-800 text-white text-xs px-2 py-1 rounded-full">
//                                     {matchingVideos.length} videos
//                                   </span>
//                                 </div>
//                               );
//                             }
//                           }

//                           return <p>No matching video found</p>;
//                         })()}
//                       </div>
//                     ) : (
//                       'No content available'
//                     )}
//                   </td>

//                   {/* Playlist Array */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistArray}</td>

//                   {/* Created Date */}
//                   <td className="px-4 py-2">{new Date(playlist.videoPlaylistDate).toLocaleDateString('en-CA')}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={7} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
//                   <p className="text-gray-00 font-semi px-4">No playlists available</p>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleSubmit={handleVideoUpload}
//         initialData={selectedVideo} 
//       />

//       {/* Playlist Modal */}
//       <VideoPlaylistModal
//         isOpen={isPlaylistModalOpen}
//         onClose={() => setIsPlaylistModalOpen(false)}
//         handleCreatePlaylist={handleCreatePlaylist}
//       />

//       {/* Progress Modal */}
//       <ProgressModal
//         isOpen={isProgressModalOpen}
//         progress={uploadProgress}
//         onClose={() => setIsProgressModalOpen(false)}
//       />

//       {/* Video Player Modal */}
//       {selectedVideoPath && (
//         <VideoPlayerModal
//           isOpen={isPlayerModalOpen}
//           onClose={() => setIsPlayerModalOpen(false)}
//           videoSrc={selectedVideoPath} 
//           videoDuration={selectedVideoDuration}
//           videoTitle={selectedVideoTitle}
//         />
//       )}
//     </div>
//   );
// };

// export default Video;


// // update code --> 06 oct 2024 v03
// // src/components/admin/admin08_video.tsx

// import React, { useState } from 'react';
// // import react icon
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { MdPlaylistAdd } from "react-icons/md";
// import { LuFilter, LuFilterX } from "react-icons/lu";
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import component
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// import VideoPlaylistModal from '../admin/VideoSetting/VideoPlaylistModal';
// import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
// import VideoPlayerModal from '../admin/VideoSetting/VideoPlayerModal';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
// // import context file
// import { useEnv } from '../../context/EnvContext'; 

// interface PlaylistData {
//   videoPlaylistTitle: string;
//   videoPlaylistDescription: string;
//   videoPlaylistArray: number;
//   videoPlaylistContent: Record<string, string>;
//   videoPlaylistDate: string;
// }

// const Video: React.FC = () => {
//   const { 
//     uploadVideo, removeVideos, uploadProgress, 
//     videos, setVideos, 
//     playlists, createPlaylist, fetchPlaylists,
//     loading 
//   } = useVideoUpload();
//   const { apiUrl } = useEnv();
//   // modal open
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false); 
//   const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
//   // video content table stats
//   const [selectedVideo, setSelectedVideo] = useState<any>(null);
//   const [selectedVideoPath, setSelectedVideoPath] = useState<string | null>(null);
//   const [selectedVideoDuration, setSelectedVideoDuration] = useState<string>('');
//   const [selectedVideoTitle, setSelectedVideoTitle] = useState<string>('');
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   const [isToggleFilterChecked, setIsToggleFilterChecked] = useState(false);
//   // selected row
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   // video upload progressing
//   const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
//   // filtering and search term
//   const [searchTerm, setSearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filtering videos based on search term
//   const filteredVideos = videos.filter((video) => {
//     const lowerSearchTerm = searchTerm.toLowerCase();
//     return (
//       (video.videoTitle?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       (video.videoCategory?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       (video.videoPermission?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       video.videoViews?.toString().includes(lowerSearchTerm)
//     );
//   });  

//   // Sorting function
//   const handleSort = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedVideos = [...filteredVideos].sort((a, b) => {
//     if (!sortConfig) return 0;
  
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;
  
//     // Explicitly cast the values to string or number to avoid 'possibly undefined' error
//     const aValue = a[key] !== undefined && a[key] !== null ? (a[key] as string | number) : '';
//     const bValue = b[key] !== undefined && b[key] !== null ? (b[key] as string | number) : '';
  
//     if (aValue < bValue) return -1 * direction;
//     if (aValue > bValue) return 1 * direction;
//     return 0;
//   });   

//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     setIsProgressModalOpen(true);
//     try {
//       const response = await uploadVideo(formData);
      
//       if (response) {
//         // Correctly format the createdAt (videoUploadDate) immediately after upload
//         const updatedVideo = {
//           ...response.videoData,
//           videoUploadDate: new Date().toISOString().split('T')[0], 
//         };
        
//         // Add the new video to the list only if it doesn't already exist
//         setVideos((prevVideos: any[]) => {
//           const videoExists = prevVideos.some(video => video.videoId === updatedVideo.videoId);
//           if (!videoExists) {
//             return [updatedVideo, ...prevVideos];
//           }
//           return prevVideos;  
//         });
  
//         setIsModalOpen(false); 
//         setIsProgressModalOpen(false);  
//       }
//     } catch (error) {
//       console.error("Error during video upload:", error);
//     }
//   };
  
//   // Handle playlist creation
//   const handleCreatePlaylist = async (playlistData: Omit<PlaylistData, 'videoPlaylistId'>) => {
//     try {
//       await createPlaylist(playlistData);
  
//       // Refetch playlists after creating a new one
//       await fetchPlaylists();
  
//     } catch (error) {
//       console.error('Error creating playlist:', error);
//     }
//   };
  
//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); 
//   };

//   // Toggle handler for filter and button
//   const handleToggleFilterClick = () => {
//     setIsToggleFilterChecked(!isToggleFilterChecked);
//     handleSort('videoTitle');
//   };


//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: string) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); 
//     } else {
//       setSelectedRows(videos.map(video => video.videoId)); 
//     }
//   };

//   // Handle removing selected videos
//   const handleRemoveVideos = async () => {
//     if (selectedRows.length > 0) {
//       await removeVideos(selectedRows);
//       setSelectedRows([]);
//     } else {
//       alert("Please select at least one video to remove.");
//     }
//   };

//   // Double-click handler for editing the selected video
//   const handleDoubleClick = (video: any) => {
//     setSelectedVideo(video);  
//     setIsModalOpen(true);   
//   };

//   // Open video player modal when title is clicked and extract video duration
//   const handlePlayVideo = (videoPath: string, videoTitle: string) => {
//     setSelectedVideoPath(`${apiUrl}${videoPath}`);
  
//     // Create a video element to get the duration
//     const videoElement = document.createElement('video');
//     videoElement.src = `${apiUrl}${videoPath}`;
  
//     videoElement.onloadedmetadata = () => {
//       const duration = videoElement.duration; 
//       const hours = Math.floor(duration / 3600);
//       const minutes = Math.floor((duration % 3600) / 60);
//       const seconds = Math.floor(duration % 60);
  
//       const formattedDuration = `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//       setSelectedVideoDuration(formattedDuration); 
//     };
  
//     setSelectedVideoTitle(videoTitle); 
//     setIsPlayerModalOpen(true);
//   };

//   if (loading) {
//     return <div>Loading videos...</div>;
//   }

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         <div className="flex items-center space-x-4">

//           {/* filter asc & dsc button */}
//           <button 
//             onClick={handleToggleFilterClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleFilterChecked ? <LuFilter className="text-sm" /> : <LuFilterX className="text-sm" />}
//             <span className="font-semibold text-sm">Filter</span>
//           </button>


//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           {/* Search Bar */}
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               onChange={handleSearchChange}
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span className="text-sm">Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={handleRemoveVideos}
//             className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span className="text-sm">Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center text-cyan-800 bg-gray-100">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   className="accent-cyan-800"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th className="px-4 py-2">id</th>
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Category</th>
//             <th className="px-4 py-2">Array</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Upload Date</th>
//             <th className="px-4 py-2">Views</th>
//           </tr>
//         </thead>

//         {videos.length > 0 ? (
//           <tbody>
//             {sortedVideos
//               .filter(video => video.videoId && !video.videoPlaylistId) 
//               .map((video) => (
//                 <tr 
//                     key={video.videoId} 
//                     className="border-b text-center text-sm"
//                     onDoubleClick={() => handleDoubleClick(video)}
//                 >
//                   {isToggleChecked && (
//                     <td className="px-4 py-4">
//                       <input
//                         type="checkbox"
//                         className="accent-cyan-800"
//                         checked={selectedRows.includes(video.videoId)}
//                         onChange={() => handleSelectRow(video.videoId)}
//                       />
//                     </td>
//                   )}
//                   {/* id column */}
//                   <td className="px-4 py-2">{video.videoId}</td>

//                   {/* thumbnail column */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <img 
//                         src={`${apiUrl}${video.videoThumbnail}`} 
//                         alt={video.videoTitle} 
//                         className="w-24 h-14 rounded-lg mr-4" 
//                     />
//                   </td>

//                   {/* title column */}
//                   <td>
//                     <div className="text-center">
//                       <h3 
//                         className="font-bold text-cyan-800"
//                         onClick={() => handlePlayVideo(video.videoPath, video.videoTitle)}   
//                       >
//                         {video.videoTitle}
//                       </h3>
//                       <p className="text-sm text-cyan-800">{video.videoLength}</p>
//                     </div>
//                   </td>

//                   {/* category column */}
//                   <td className="px-4 py-2">{video.videoCategory}</td>

//                   {/* category array column */}
//                   <td className="px-4 py-2">{video.videoCategoryArray}</td>

//                   {/* permission column */}
//                   <td className="px-4 py-4">
//                     {video.videoPermission === 'Private' ? (
//                       <div className="flex justify-center items-center">
//                         <AiOutlineLock className="text-gray-500 mr-2" />
//                         <span>{video.videoPermission}</span>
//                       </div>
//                     ) : (
//                       <div className="flex justify-center items-center">
//                         <AiFillEye className="text-cyan-800 mr-2" />
//                         <span>{video.videoPermission}</span>
//                       </div>
//                     )}
//                   </td>

//                   {/* upload date column */}
//                   <td className="px-4 py-4">{new Date(video.videoUploadDate).toLocaleDateString('en-CA')}</td>

//                   {/* video view stats column */}
//                   <td className="px-4 py-4">{video.videoViews}</td>
//                 </tr>
//               ))}
//           </tbody>
//         ) : (
//           <tbody>
//             <tr>
//               <td colSpan={9} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
//                 <p className="text-gray-00 font-semi px-4">No playlists available</p>
//               </td>
//             </tr>
//           </tbody>
//         )}
//       </table>

//       {/* Playlist Table */}
//       <div className="relative mt-6">
//         <div className="flex justify-end items-center mb-4">
//           <div className="flex items-center space-x-4">
//             {/* filter asc & dsc button */}
//             <button 
//               onClick={handleToggleFilterClick}
//               className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//             >
//               {isToggleFilterChecked ? <LuFilter className="text-sm" /> : <LuFilterX className="text-sm" />}
//               <span className="font-semibold text-sm">Filter</span>
//             </button>

//             {/* toggle selection */}
//             <button
//               onClick={handleToggleClick}
//               className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//             >
//               {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//               <span className="font-semibold text-sm">Toggle</span>
//             </button>

//             {/* Search Bar */}
//             <div className="relative">
//               <input
//                 type="text"
//                 placeholder="Search your playlist"
//                 onChange={handleSearchChange}
//                 className="px-4 py-2 border rounded-lg w-auto"
//               />
//               <FaSearch className="absolute right-3 top-3 text-gray-500" />
//             </div>

//             {/* Add Playlist Button */}
//             <button
//               onClick={() => setIsPlaylistModalOpen(true)}
//               className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//               <MdPlaylistAdd className="text-sm" />
//               <span className="text-sm">Add Playlist</span>
//             </button>

//             {/* Remove Playlist Button */}
//             <button
//               onClick={handleRemoveVideos}
//               className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//               <HiOutlineFolderRemove className="text-lg" />
//               <span className="text-sm">Remove</span>
//             </button>
//           </div>
//         </div>

//         <table className="min-w-full bg-white border rounded-lg">
//           <thead>
//             <tr className="border-b text-center text-cyan-800 bg-gray-100">
//               <th className="px-4 py-2">ID</th>
//               <th className="px-4 py-2">Title</th>
//               <th className="px-4 py-2">Description</th>
//               <th className="px-4 py-2">Content</th>
//               <th className="px-4 py-2">Array</th>
//               <th className="px-4 py-2">Created Date</th>
//             </tr>
//           </thead>

//           <tbody>
//             {playlists.length > 0 ? (
//               playlists.map((playlist) => (
//                 <tr key={playlist.videoPlaylistId} className="border-b text-center text-sm">
//                   {/* Playlist ID */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistId}</td>

//                   {/* Playlist Title */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistTitle}</td>

//                   {/* Playlist Description */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistDescription}</td>

//                   {/* Playlist Content */}
//                   <td className="px-4 py-2">
//                     {playlist.videoPlaylistContent && Object.keys(playlist.videoPlaylistContent).length > 0 
//                       ? `${Object.keys(playlist.videoPlaylistContent).length} videos`
//                       : 'No content available'}
//                   </td>

//                   {/* Playlist Array */}
//                   <td className="px-4 py-2">{playlist.videoPlaylistArray}</td>

//                   {/* Created Date */}
//                   <td className="px-4 py-2">{new Date(playlist.videoPlaylistDate).toLocaleDateString('en-CA')}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={7} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
//                   <p className="text-gray-00 font-semi px-4">No playlists available</p>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleSubmit={handleVideoUpload}
//         initialData={selectedVideo} 
//       />

//       {/* Playlist Modal */}
//       <VideoPlaylistModal
//         isOpen={isPlaylistModalOpen}
//         onClose={() => setIsPlaylistModalOpen(false)}
//         handleCreatePlaylist={handleCreatePlaylist}
//       />

//       {/* Progress Modal */}
//       <ProgressModal
//         isOpen={isProgressModalOpen}
//         progress={uploadProgress}
//         onClose={() => setIsProgressModalOpen(false)}
//       />

//       {/* Video Player Modal */}
//       {selectedVideoPath && (
//         <VideoPlayerModal
//           isOpen={isPlayerModalOpen}
//           onClose={() => setIsPlayerModalOpen(false)}
//           videoSrc={selectedVideoPath} 
//           videoDuration={selectedVideoDuration}
//           videoTitle={selectedVideoTitle}
//         />
//       )}
//     </div>
//   );
// };

// export default Video;

// // update code --> 06 oct 2024 v02
// // src/components/admin/admin08_video.tsx

// import React, { useState } from 'react';
// // import react icon
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { MdPlaylistAdd } from "react-icons/md";
// import { LuFilter, LuFilterX } from "react-icons/lu";
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import component
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// import VideoPlaylistModal from '../admin/VideoSetting/VideoPlaylistModal';
// import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
// import VideoPlayerModal from '../admin/VideoSetting/VideoPlayerModal';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
// // import context file
// import { useEnv } from '../../context/EnvContext'; 

// interface PlaylistData {
//   videoPlaylistTitle: string;
//   videoPlaylistDescription: string;
//   videoPlaylistArray: number;
//   videoPlaylistContent: Record<string, string>;
//   videoPlaylistDate: string;
// }

// const Video: React.FC = () => {
//   const { 
//     uploadVideo, removeVideos, uploadProgress, 
//     videos, setVideos, 
//     playlists, createPlaylist,
//     loading 
//   } = useVideoUpload();
//   const { apiUrl } = useEnv();
//   // modal open
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false); 
//   const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
//   // video content table stats
//   const [selectedVideo, setSelectedVideo] = useState<any>(null);
//   const [selectedVideoPath, setSelectedVideoPath] = useState<string | null>(null);
//   const [selectedVideoDuration, setSelectedVideoDuration] = useState<string>('');
//   const [selectedVideoTitle, setSelectedVideoTitle] = useState<string>('');
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   const [isToggleFilterChecked, setIsToggleFilterChecked] = useState(false);
//   // selected row
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   // video upload progressing
//   const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
//   // filtering and search term
//   const [searchTerm, setSearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filtering videos based on search term
//   const filteredVideos = videos.filter((video) => {
//     const lowerSearchTerm = searchTerm.toLowerCase();
//     return (
//       (video.videoTitle?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       (video.videoCategory?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       (video.videoPermission?.toLowerCase() || '').includes(lowerSearchTerm) ||
//       video.videoViews?.toString().includes(lowerSearchTerm)
//     );
//   });  

//   // Sorting function
//   const handleSort = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedVideos = [...filteredVideos].sort((a, b) => {
//     if (!sortConfig) return 0;
  
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;
  
//     // Explicitly cast the values to string or number to avoid 'possibly undefined' error
//     const aValue = a[key] !== undefined && a[key] !== null ? (a[key] as string | number) : '';
//     const bValue = b[key] !== undefined && b[key] !== null ? (b[key] as string | number) : '';
  
//     if (aValue < bValue) return -1 * direction;
//     if (aValue > bValue) return 1 * direction;
//     return 0;
//   });   

//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     setIsProgressModalOpen(true);
//     try {
//       const response = await uploadVideo(formData);
      
//       if (response) {
//         // Correctly format the createdAt (videoUploadDate) immediately after upload
//         const updatedVideo = {
//           ...response.videoData,
//           videoUploadDate: new Date().toISOString().split('T')[0], 
//         };
        
//         // Add the new video to the list only if it doesn't already exist
//         setVideos((prevVideos: any[]) => {
//           const videoExists = prevVideos.some(video => video.videoId === updatedVideo.videoId);
//           if (!videoExists) {
//             return [updatedVideo, ...prevVideos];
//           }
//           return prevVideos;  
//         });
  
//         setIsModalOpen(false); 
//         setIsProgressModalOpen(false);  
//       }
//     } catch (error) {
//       console.error("Error during video upload:", error);
//     }
//   };
  
//   // Handle playlist creation
//   const handleCreatePlaylist = (playlistData: Omit<PlaylistData, 'videoPlaylistId'>) => {
//     createPlaylist(playlistData); 
//   };  

//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); 
//   };

//   // Toggle handler for filter and button
//   const handleToggleFilterClick = () => {
//     setIsToggleFilterChecked(!isToggleFilterChecked);
//     handleSort('videoTitle');
//   };


//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: string) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); 
//     } else {
//       setSelectedRows(videos.map(video => video.videoId)); 
//     }
//   };

//   // Handle removing selected videos
//   const handleRemoveVideos = async () => {
//     if (selectedRows.length > 0) {
//       await removeVideos(selectedRows);
//       setSelectedRows([]);
//     } else {
//       alert("Please select at least one video to remove.");
//     }
//   };

//   // Double-click handler for editing the selected video
//   const handleDoubleClick = (video: any) => {
//     setSelectedVideo(video);  
//     setIsModalOpen(true);   
//   };

//   // Open video player modal when title is clicked and extract video duration
//   const handlePlayVideo = (videoPath: string, videoTitle: string) => {
//     setSelectedVideoPath(`${apiUrl}${videoPath}`);
  
//     // Create a video element to get the duration
//     const videoElement = document.createElement('video');
//     videoElement.src = `${apiUrl}${videoPath}`;
  
//     videoElement.onloadedmetadata = () => {
//       const duration = videoElement.duration; 
//       const hours = Math.floor(duration / 3600);
//       const minutes = Math.floor((duration % 3600) / 60);
//       const seconds = Math.floor(duration % 60);
  
//       const formattedDuration = `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//       setSelectedVideoDuration(formattedDuration); 
//     };
  
//     setSelectedVideoTitle(videoTitle); 
//     setIsPlayerModalOpen(true);
//   };

//   if (loading) {
//     return <div>Loading videos...</div>;
//   }

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         <div className="flex items-center space-x-4">

//           {/* filter asc & dsc button */}
//           <button 
//             onClick={handleToggleFilterClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleFilterChecked ? <LuFilter className="text-sm" /> : <LuFilterX className="text-sm" />}
//             <span className="font-semibold text-sm">Filter</span>
//           </button>


//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           {/* Search Bar */}
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               onChange={handleSearchChange}
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span className="text-sm">Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={handleRemoveVideos}
//             className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span className="text-sm">Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center text-cyan-800 bg-gray-100">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   className="accent-cyan-800"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th className="px-4 py-2">id</th>
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Category</th>
//             <th className="px-4 py-2">Array</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Upload Date</th>
//             <th className="px-4 py-2">Views</th>
//           </tr>
//         </thead>

//         {videos.length > 0 ? (
//           <tbody>
//             {sortedVideos
//               .filter(video => video.videoId && !video.videoPlaylistId) 
//               .map((video) => (
//                 <tr 
//                     key={video.videoId} 
//                     className="border-b text-center text-sm"
//                     onDoubleClick={() => handleDoubleClick(video)}
//                 >
//                   {isToggleChecked && (
//                     <td className="px-4 py-4">
//                       <input
//                         type="checkbox"
//                         className="accent-cyan-800"
//                         checked={selectedRows.includes(video.videoId)}
//                         onChange={() => handleSelectRow(video.videoId)}
//                       />
//                     </td>
//                   )}
//                   {/* id column */}
//                   <td className="px-4 py-2">{video.videoId}</td>

//                   {/* thumbnail column */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <img 
//                         src={`${apiUrl}${video.videoThumbnail}`} 
//                         alt={video.videoTitle} 
//                         className="w-24 h-14 rounded-lg mr-4" 
//                     />
//                   </td>

//                   {/* title column */}
//                   <td>
//                     <div className="text-center">
//                       <h3 
//                         className="font-bold text-cyan-800"
//                         onClick={() => handlePlayVideo(video.videoPath, video.videoTitle)}   
//                       >
//                         {video.videoTitle}
//                       </h3>
//                       <p className="text-sm text-cyan-800">{video.videoLength}</p>
//                     </div>
//                   </td>

//                   {/* category column */}
//                   <td className="px-4 py-2">{video.videoCategory}</td>

//                   {/* category array column */}
//                   <td className="px-4 py-2">{video.videoCategoryArray}</td>

//                   {/* permission column */}
//                   <td className="px-4 py-4">
//                     {video.videoPermission === 'Private' ? (
//                       <div className="flex justify-center items-center">
//                         <AiOutlineLock className="text-gray-500 mr-2" />
//                         <span>{video.videoPermission}</span>
//                       </div>
//                     ) : (
//                       <div className="flex justify-center items-center">
//                         <AiFillEye className="text-cyan-800 mr-2" />
//                         <span>{video.videoPermission}</span>
//                       </div>
//                     )}
//                   </td>

//                   {/* upload date column */}
//                   <td className="px-4 py-4">{new Date(video.videoUploadDate).toLocaleDateString('en-CA')}</td>

//                   {/* video view stats column */}
//                   <td className="px-4 py-4">{video.videoViews}</td>
//                 </tr>
//               ))}
//           </tbody>
//         ) : (
//           <tbody>
//             <tr>
//               <td colSpan={9} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
//                 <p className="text-gray-00 font-semi px-4">No playlists available</p>
//               </td>
//             </tr>
//           </tbody>
//         )}
//       </table>

//       {/* Playlist Table */}
//       <div className="relative mt-6">
//         <div className="flex justify-end items-center mb-4">
//           <div className="flex items-center space-x-4">
//             {/* filter asc & dsc button */}
//             <button 
//               onClick={handleToggleFilterClick}
//               className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//             >
//               {isToggleFilterChecked ? <LuFilter className="text-sm" /> : <LuFilterX className="text-sm" />}
//               <span className="font-semibold text-sm">Filter</span>
//             </button>

//             {/* toggle selection */}
//             <button
//               onClick={handleToggleClick}
//               className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//             >
//               {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//               <span className="font-semibold text-sm">Toggle</span>
//             </button>

//             {/* Search Bar */}
//             <div className="relative">
//               <input
//                 type="text"
//                 placeholder="Search your video"
//                 onChange={handleSearchChange}
//                 className="px-4 py-2 border rounded-lg w-auto"
//               />
//               <FaSearch className="absolute right-3 top-3 text-gray-500" />
//             </div>

//             {/* Upload Video Button */}
//             <button
//               onClick={() => setIsPlaylistModalOpen(true)}
//               className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//               <MdPlaylistAdd className="text-sm" />
//               <span className="text-sm">Add Playlist</span>
//             </button>

//             {/* Remove Video Button */}
//             <button
//               onClick={handleRemoveVideos}
//               className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//               <HiOutlineFolderRemove className="text-lg" />
//               <span className="text-sm">Remove</span>
//             </button>
//           </div>
//         </div>
    
//         <table className="min-w-full bg-white border rounded-lg">
//           <thead>
//             <tr className="border-b text-center text-cyan-800 bg-gray-100">
//               <th className="px-4 py-2">id</th>
//               <th className="px-4 py-2">Playlist Title</th>
//               <th className="px-4 py-2">Playlist Content</th>
//               <th className="px-4 py-2">Playlist Array</th>
//               <th className="px-4 py-2">Created Date</th>
//               <th className="px-4 py-2">Content Count</th>
//             </tr>
//           </thead>
//         </table>

//         {playlists.length > 0 ? (
//           <div className="h-[500px] overflow-y-auto">
//             <table className="min-w-full bg-white border-t">
//               <tbody>
//                 {playlists
//                   .filter(playlist => playlist.videoPlaylistId && !playlist.videoId) 
//                   .map((playlist) => (
//                     <tr key={playlist.videoPlaylistId} className="border-b text-center text-sm">
//                       <td className="px-4 py-2">{playlist.videoPlaylistId}</td>
//                       <td className="px-4 py-2">
//                         <div className="flex flex-col items-center ">
//                           {playlist.videoPlaylistTitle} 
//                           {playlist.videoPlaylistDescription}
//                         </div>
//                       </td>
//                       <td className="px-4 py-2">{playlist.videoPlaylistContent}</td>
//                       <td className="px-4 py-2">{playlist.videoPlaylistArray}</td>
//                       <td className="px-4 py-2">{new Date(playlist.videoPlaylistDate).toLocaleDateString('en-CA')}</td>
//                       <td className="px-4 py-2">{playlist.videoPlaylistCount}</td>
//                     </tr>
//                   ))}
//               </tbody>
//             </table>
//           </div>
//         ) : (
//           <div className="h-[200px] flex justify-center items-center border-4 border-dashed border-gray-200 rounded-lg">
//             <p className="text-gray-400 font-semi px-4">No playlists available</p>
//           </div>
//         )}
//       </div>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleSubmit={handleVideoUpload}
//         initialData={selectedVideo} 
//       />

//       {/* Playlist Modal */}
//       <VideoPlaylistModal
//         isOpen={isPlaylistModalOpen}
//         onClose={() => setIsPlaylistModalOpen(false)}
//         handleCreatePlaylist={handleCreatePlaylist}
//       />

//       {/* Progress Modal */}
//       <ProgressModal
//         isOpen={isProgressModalOpen}
//         progress={uploadProgress}
//         onClose={() => setIsProgressModalOpen(false)}
//       />

//       {/* Video Player Modal */}
//       {selectedVideoPath && (
//         <VideoPlayerModal
//           isOpen={isPlayerModalOpen}
//           onClose={() => setIsPlayerModalOpen(false)}
//           videoSrc={selectedVideoPath} 
//           videoDuration={selectedVideoDuration}
//           videoTitle={selectedVideoTitle}
//         />
//       )}
//     </div>
//   );
// };

// export default Video;

// // udpate code --> 06 oct 2024
// // src/components/admin/admin08_video.tsx

// import React, { useState } from 'react';
// // import react icon
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { MdPlaylistAdd } from "react-icons/md";
// import { LuFilter, LuFilterX } from "react-icons/lu";
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import component
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
// import VideoPlayerModal from '../admin/VideoSetting/VideoPlayerModal';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
// // import context file
// import { useEnv } from '../../context/EnvContext'; 

// const Video: React.FC = () => {
//   const { uploadVideo, uploadProgress, videos, setVideos, playlists, removeVideos, loading } = useVideoUpload();
//   const { apiUrl } = useEnv(); 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
//   const [selectedVideo, setSelectedVideo] = useState<any>(null);
//   const [selectedVideoPath, setSelectedVideoPath] = useState<string | null>(null);
//   const [selectedVideoDuration, setSelectedVideoDuration] = useState<string>('');
//   const [selectedVideoTitle, setSelectedVideoTitle] = useState<string>('');
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   const [isToggleFilterChecked, setIsToggleFilterChecked] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filtering videos based on search term
//   const filteredVideos = videos.filter((video) => {
//     const lowerSearchTerm = searchTerm.toLowerCase();
//     return (
//       video.videoTitle.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoCategory.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoPermission.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoViews.toString().includes(lowerSearchTerm)
//     );
//   });

//   // Sorting function
//   const handleSort = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedVideos = [...filteredVideos].sort((a, b) => {
//     if (!sortConfig) return 0;
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;

//     if (a[key] < b[key]) return -1 * direction;
//     if (a[key] > b[key]) return 1 * direction;
//     return 0;
//   });

//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     setIsProgressModalOpen(true);
//     const response = await uploadVideo(formData);
    
//     if (response) {
//       // Correctly format the createdAt date immediately after upload
//       const updatedVideo = {
//         ...response.videoData,
//         createdAt: new Date().toISOString(),
//       };
      
//       // Add the new video to the list only if it doesn't already exist
//       setVideos((prevVideos: any[]) => {
//         const videoExists = prevVideos.some(video => video.videoId === updatedVideo.videoId);
//         if (!videoExists) {
//           return [updatedVideo, ...prevVideos];
//         }
//         return prevVideos;  // If the video already exists, return the original array
//       });

//       setIsModalOpen(false); 
//       setIsProgressModalOpen(false);  
//     }
//   };

//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); 
//   };

//   // Toggle handler for filter and button
//   const handleToggleFilterClick = () => {
//     setIsToggleFilterChecked(!isToggleFilterChecked);
//     handleSort('videoTitle');
//   };


//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: string) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); 
//     } else {
//       setSelectedRows(videos.map(video => video.videoId)); 
//     }
//   };

//   // Handle removing selected videos
//   const handleRemoveVideos = async () => {
//     if (selectedRows.length > 0) {
//       await removeVideos(selectedRows);
//       setSelectedRows([]);
//     } else {
//       alert("Please select at least one video to remove.");
//     }
//   };

//   // Double-click handler for editing the selected video
//   const handleDoubleClick = (video: any) => {
//     setSelectedVideo(video);  
//     setIsModalOpen(true);   
//   };

//   // Open video player modal when title is clicked and extract video duration
//   const handlePlayVideo = (videoPath: string, videoTitle: string) => {
//     setSelectedVideoPath(`${apiUrl}${videoPath}`);
  
//     // Create a video element to get the duration
//     const videoElement = document.createElement('video');
//     videoElement.src = `${apiUrl}${videoPath}`;
  
//     videoElement.onloadedmetadata = () => {
//       const duration = videoElement.duration; 
//       const hours = Math.floor(duration / 3600);
//       const minutes = Math.floor((duration % 3600) / 60);
//       const seconds = Math.floor(duration % 60);
  
//       const formattedDuration = `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//       setSelectedVideoDuration(formattedDuration); 
//     };
  
//     setSelectedVideoTitle(videoTitle); 
//     setIsPlayerModalOpen(true);
//   };

//   if (loading) {
//     return <div>Loading videos...</div>;
//   }

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         <div className="flex items-center space-x-4">
//           <div className="flex justify-start item-center">
//             {/* add playlist */}
//             <div className="flex flex-row justify-normal items-center text-cyan-800">
//               <button>
//                 <MdPlaylistAdd className="mr-2 cursor-pointer text-xl" />
//               </button>
//               <span className="font-semibold text-sm">Add Playlist</span>
//             </div>
//           </div>
      
//           {/* filter asc & dsc button */}
//           <button 
//             onClick={handleToggleFilterClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleFilterChecked ? <LuFilter className="text-sm" /> : <LuFilterX className="text-sm" />}
//             <span className="font-semibold text-sm">Filter</span>
//           </button>


//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 mr-4 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           {/* Search Bar */}
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               onChange={handleSearchChange}
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span className="text-sm">Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={handleRemoveVideos}
//             className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span className="text-sm">Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center text-cyan-800 bg-gray-100">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   className="accent-cyan-800"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th className="px-4 py-2">id</th>
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Category</th>
//             <th className="px-4 py-2">Array</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Upload Date</th>
//             <th className="px-4 py-2">Views</th>
//           </tr>
//         </thead>

//         {videos.length > 0 ? (
//           <tbody>
//             {sortedVideos.map((video) => (
//               <tr 
//                   key={video.videoId} 
//                   className="border-b text-center text-sm"
//                   onDoubleClick={() => handleDoubleClick(video)}
//               >
//                 {isToggleChecked && (
//                   <td className="px-4 py-4">
//                     <input
//                       type="checkbox"
//                       className="accent-cyan-800"
//                       checked={selectedRows.includes(video.videoId)}
//                       onChange={() => handleSelectRow(video.videoId)}
//                     />
//                   </td>
//                 )}
//                 {/* id column */}
//                 <td className="px-4 py-2">{video.videoId}</td>

//                 {/* thumbnail column */}
//                 <td className="px-4 py-2 flex justify-center items-center">
//                   <img 
//                       src={`${apiUrl}${video.videoThumbnail}`} 
//                       alt={video.videoTitle} 
//                       className="w-24 h-14 rounded-lg mr-4" 
//                   />
//                 </td>

//                 {/* title column */}
//                 <td>
//                   <div className="text-center">
//                     <h3 
//                       className="font-bold text-cyan-800"
//                       onClick={() => handlePlayVideo(video.videoPath, video.videoTitle)}   
//                     >
//                       {video.videoTitle}
//                     </h3>
//                     <p className="text-sm text-cyan-800">{video.videoLength}</p>
//                   </div>
//                 </td>

//                 {/* category column */}
//                 <td className="px-4 py-2">{video.videoCategory}</td>

//                 {/* category array column */}
//                 <td className="px-4 py-2">{video.videoCategoryArray}</td>

//                 {/* permission column */}
//                 <td className="px-4 py-4">
//                   {video.videoPermission === 'Private' ? (
//                     <div className="flex justify-center items-center">
//                       <AiOutlineLock className="text-gray-500 mr-2" />
//                       <span>{video.videoPermission}</span>
//                     </div>
//                   ) : (
//                     <div className="flex justify-center items-center">
//                       <AiFillEye className="text-cyan-800 mr-2" />
//                       <span>{video.videoPermission}</span>
//                     </div>
//                   )}
//                 </td>

//                 {/* upload date column */}
//                 <td className="px-4 py-4">{new Date(video.createdAt).toLocaleDateString('en-CA')}</td>

//                 {/* video view stats column */}
//                 <td className="px-4 py-4">{video.videoViews}</td>
//               </tr>
//             ))}
//           </tbody>
//         ) : (
//           <tbody>
//             <tr>
//               <td colSpan={9} className="h-[200px] text-center py-10 border-4 border-dashed border-gray-200">
//                 <p className="text-gray-00 font-semi px-4">No playlists available</p>
//               </td>
//             </tr>
//           </tbody>
//         )}
//       </table>

//       {/* Playlist Table */}
//       <div className="relative mt-6">
//         <table className="min-w-full bg-white border rounded-lg">
//           <thead>
//             <tr className="border-b text-center text-cyan-800 bg-gray-100">
//               <th className="px-4 py-2">ID</th>
//               <th className="px-4 py-2">Playlist Title</th>
//               <th className="px-4 py-2">Playlist Content</th>
//               <th className="px-4 py-2">Playlist Array</th>
//               <th className="px-4 py-2">Created Date</th>
//               <th className="px-4 py-2">Content Count</th>
//             </tr>
//           </thead>
//         </table>

//         {playlists.length > 0 ? (
//           <div className="h-[500px] overflow-y-auto">
//             <table className="min-w-full bg-white border-t">
//               <tbody>
//                 {playlists.map((playlist) => (
//                   <tr key={playlist.videoPlaylistId} className="border-b text-center text-sm">
//                     <td className="px-4 py-2">{playlist.videoPlaylistId}</td>
//                     <td className="px-4 py-2">{playlist.videoPlaylistTitle}</td>
//                     <td className="px-4 py-2">{playlist.videoPlaylistContent}</td>
//                     <td className="px-4 py-2">{playlist.videoPlaylistArray}</td>
//                     <td className="px-4 py-2">{new Date(playlist.videoPlaylistDate).toLocaleDateString('en-CA')}</td>
//                     <td className="px-4 py-2">{playlist.videoPlaylistCount}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         ) : (
//           <div className="h-[200px] flex justify-center items-center border-4 border-dashed border-gray-200 rounded-lg">
//             <p className="text-gray-400 font-semi px-4">No playlists available</p>
//           </div>
//         )}
//       </div>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleSubmit={handleVideoUpload}
//         initialData={selectedVideo} 
//       />

//       {/* Progress Modal */}
//       <ProgressModal
//         isOpen={isProgressModalOpen}
//         progress={uploadProgress}
//         onClose={() => setIsProgressModalOpen(false)}
//       />

//       {/* Video Player Modal */}
//       {selectedVideoPath && (
//         <VideoPlayerModal
//           isOpen={isPlayerModalOpen}
//           onClose={() => setIsPlayerModalOpen(false)}
//           videoSrc={selectedVideoPath} 
//           videoDuration={selectedVideoDuration}
//           videoTitle={selectedVideoTitle}
//         />
//       )}
//     </div>
//   );
// };

// export default Video;

// // update code --> 05 oct 2024
// // src/components/admin/admin08_video.tsx

// import React, { useState } from 'react';
// // import react icon
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { LuListFilter } from "react-icons/lu";
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import component
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
// import VideoPlayerModal from '../admin/VideoSetting/VideoPlayerModal';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
// // import context file
// import { useEnv } from '../../context/EnvContext'; 

// const Video: React.FC = () => {
//   const { uploadVideo, uploadProgress, videos, setVideos, removeVideos, loading } = useVideoUpload();
//   const { apiUrl } = useEnv(); 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
//   const [selectedVideo, setSelectedVideo] = useState<any>(null);
//   const [selectedVideoPath, setSelectedVideoPath] = useState<string | null>(null);
//   const [selectedVideoDuration, setSelectedVideoDuration] = useState<string>('');
//   const [selectedVideoTitle, setSelectedVideoTitle] = useState<string>('');
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filtering videos based on search term
//   const filteredVideos = videos.filter((video) => {
//     const lowerSearchTerm = searchTerm.toLowerCase();
//     return (
//       video.videoTitle.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoCategory.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoPermission.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoViews.toString().includes(lowerSearchTerm)
//     );
//   });

//   // Sorting function
//   const handleSort = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedVideos = [...filteredVideos].sort((a, b) => {
//     if (!sortConfig) return 0;
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;

//     if (a[key] < b[key]) return -1 * direction;
//     if (a[key] > b[key]) return 1 * direction;
//     return 0;
//   });

//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     setIsProgressModalOpen(true); 
//     const response = await uploadVideo(formData);
  
//     if (response) {
//       // Correctly format the createdAt date immediately after upload
//       const updatedVideo = {
//         ...response.videoData,
//         createdAt: new Date().toISOString(), // Format date correctly
//       };
  
//       // Add the new video to the list and update state
//       setVideos((prevVideos: any[]) => [updatedVideo, ...prevVideos]);
  
//       setIsModalOpen(false); 
//       setIsProgressModalOpen(false);  
//     }
//   };

//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); 
//   };

//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: string) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); 
//     } else {
//       setSelectedRows(videos.map(video => video.videoId)); 
//     }
//   };

//   // Handle removing selected videos
//   const handleRemoveVideos = async () => {
//     if (selectedRows.length > 0) {
//       await removeVideos(selectedRows);
//       setSelectedRows([]);
//     } else {
//       alert("Please select at least one video to remove.");
//     }
//   };

//   // Double-click handler for editing the selected video
//   const handleDoubleClick = (video: any) => {
//     setSelectedVideo(video);  // Set the selected video data
//     setIsModalOpen(true);     // Open modal for editing
//   };

//   // Open video player modal when title is clicked and extract video duration
//   const handlePlayVideo = (videoPath: string, videoTitle: string) => {
//     setSelectedVideoPath(`${apiUrl}${videoPath}`);
  
//     // Create a video element to get the duration
//     const videoElement = document.createElement('video');
//     videoElement.src = `${apiUrl}${videoPath}`;
  
//     videoElement.onloadedmetadata = () => {
//       const duration = videoElement.duration; // Get video duration in seconds
//       const hours = Math.floor(duration / 3600);
//       const minutes = Math.floor((duration % 3600) / 60);
//       const seconds = Math.floor(duration % 60);
  
//       const formattedDuration = `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//       setSelectedVideoDuration(formattedDuration); 
//     };
  
//     setSelectedVideoTitle(videoTitle); 
//     setIsPlayerModalOpen(true);
//   };

//   if (loading) {
//     return <div>Loading videos...</div>;
//   }

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         {/* Search Bar */}
//         <div className="flex items-center space-x-4">
//           <div className="flex flex-row justify-normal items-center text-cyan-800">
//             <button 
//                 onClick={() => handleSort('videoTitle')}
//             >
//               <LuListFilter className="mr-2 cursor-pointer" />
//             </button>
//             <span className="font-semibold text-sm">Filter</span>
//           </div>

//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               onChange={handleSearchChange}
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span className="text-sm">Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={handleRemoveVideos}
//             className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span className="text-sm">Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center text-cyan-800 bg-gray-100">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   className="accent-cyan-800"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th className="px-4 py-2">id</th>
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Category</th>
//             <th className="px-4 py-2">Array</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Upload Date</th>
//             <th className="px-4 py-2">Views</th>
//           </tr>
//         </thead>
//         <tbody>
//           {sortedVideos.map((video) => (
//             <tr 
//                 key={video.videoId} 
//                 className="border-b text-center text-sm"
//                 onDoubleClick={() => handleDoubleClick(video)}
//             >
//               {isToggleChecked && (
//                 <td className="px-4 py-4">
//                   <input
//                     type="checkbox"
//                     className="accent-cyan-800"
//                     checked={selectedRows.includes(video.videoId)}
//                     onChange={() => handleSelectRow(video.videoId)}
//                   />
//                 </td>
//               )}
//               {/* id column */}
//               <td className="px-4 py-2">{video.videoId}</td>

//               {/* thumbnail column */}
//               <td className="px-4 py-2 flex justify-center items-center">
//                 <img 
//                     src={`${apiUrl}${video.videoThumbnail}`} 
//                     alt={video.videoTitle} 
//                     className="w-24 h-14 rounded-lg mr-4" 
//                 />
//               </td>

//               {/* title column */}
//               <td>
//                 <div className="text-center">
//                   <h3 
//                     className="font-bold text-cyan-800"
//                     onClick={() => handlePlayVideo(video.videoPath, video.videoTitle)}   
//                   >
//                     {video.videoTitle}
//                   </h3>
//                   <p className="text-sm text-cyan-800">{video.videoLength}</p>
//                 </div>
//               </td>

//               {/* category column */}
//               <td className="px-4 py-2">{video.videoCategory}</td>

//               {/* category array column */}
//               <td className="px-4 py-2">{video.videoCategoryArray}</td>

//               {/* permission column */}
//               <td className="px-4 py-4">
//                 {video.videoPermission === 'Private' ? (
//                   <div className="flex justify-center items-center">
//                     <AiOutlineLock className="text-gray-500 mr-2" />
//                     <span>{video.videoPermission}</span>
//                   </div>
//                 ) : (
//                   <div className="flex justify-center items-center">
//                     <AiFillEye className="text-cyan-800 mr-2" />
//                     <span>{video.videoPermission}</span>
//                   </div>
//                 )}
//               </td>

//               {/* upload date column */}
//               <td className="px-4 py-4">{new Date(video.createdAt).toLocaleDateString('en-CA')}</td>

//               {/* video view stats column */}
//               <td className="px-4 py-4">{video.videoViews}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleSubmit={handleVideoUpload}
//         initialData={selectedVideo} 
//       />

//       {/* Progress Modal */}
//       <ProgressModal
//         isOpen={isProgressModalOpen}
//         progress={uploadProgress}
//         onClose={() => setIsProgressModalOpen(false)}
//       />

//       {/* Video Player Modal */}
//       {selectedVideoPath && (
//         <VideoPlayerModal
//           isOpen={isPlayerModalOpen}
//           onClose={() => setIsPlayerModalOpen(false)}
//           videoSrc={selectedVideoPath} 
//           videoDuration={selectedVideoDuration}
//           videoTitle={selectedVideoTitle}
//         />
//       )}
//     </div>
//   );
// };

// export default Video;

// // 04 oct 2024 v02
// // src/components/admin/admin08_video.tsx

// import React, { useState } from 'react';
// // import react icon
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { LuListFilter } from "react-icons/lu";
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import component
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
// import VideoPlayerModal from '../admin/VideoSetting/VideoPlayerModal';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
// // import context file
// import { useEnv } from '../../context/EnvContext'; 

// const Video: React.FC = () => {
//   const { uploadVideo, uploadProgress, removeVideos, videos, loading } = useVideoUpload();
//   const { apiUrl } = useEnv(); 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
//   const [selectedVideoPath, setSelectedVideoPath] = useState<string | null>(null);
//   const [selectedVideoDuration, setSelectedVideoDuration] = useState<string>('');
//   const [selectedVideoTitle, setSelectedVideoTitle] = useState<string>('');
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filtering videos based on search term
//   const filteredVideos = videos.filter((video) => {
//     const lowerSearchTerm = searchTerm.toLowerCase();
//     return (
//       video.videoTitle.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoCategory.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoPermission.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoViews.toString().includes(lowerSearchTerm)
//     );
//   });

//   // Sorting function
//   const handleSort = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedVideos = [...filteredVideos].sort((a, b) => {
//     if (!sortConfig) return 0;
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;

//     if (a[key] < b[key]) return -1 * direction;
//     if (a[key] > b[key]) return 1 * direction;
//     return 0;
//   });

//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     setIsProgressModalOpen(true); 
//     const response = await uploadVideo(formData);

//     if (response) {
//       setIsModalOpen(false); 
//       setIsProgressModalOpen(false);  
//     }
//   };

//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); 
//   };

//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: string) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); 
//     } else {
//       setSelectedRows(videos.map(video => video.videoId)); 
//     }
//   };

//   // Handle removing selected videos
//   const handleRemoveVideos = async () => {
//     if (selectedRows.length > 0) {
//       await removeVideos(selectedRows);
//       setSelectedRows([]);
//     } else {
//       alert("Please select at least one video to remove.");
//     }
//   };

//   // Open video player modal when title is clicked and extract video duration
//   const handlePlayVideo = (videoPath: string, videoTitle: string) => {
//     setSelectedVideoPath(`${apiUrl}${videoPath}`);
  
//     // Create a video element to get the duration
//     const videoElement = document.createElement('video');
//     videoElement.src = `${apiUrl}${videoPath}`;
  
//     videoElement.onloadedmetadata = () => {
//       const duration = videoElement.duration; // Get video duration in seconds
//       const hours = Math.floor(duration / 3600);
//       const minutes = Math.floor((duration % 3600) / 60);
//       const seconds = Math.floor(duration % 60);
  
//       const formattedDuration = `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//       setSelectedVideoDuration(formattedDuration); 
//     };
  
//     setSelectedVideoTitle(videoTitle); 
//     setIsPlayerModalOpen(true);
//   };

//   if (loading) {
//     return <div>Loading videos...</div>;
//   }

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         {/* Search Bar */}
//         <div className="flex items-center space-x-4">
//           <div className="flex flex-row justify-normal items-center text-cyan-800">
//             <button 
//                 onClick={() => handleSort('videoTitle')}
//             >
//               <LuListFilter className="mr-2 cursor-pointer" />
//             </button>
//             <span className="font-semibold text-sm">Filter</span>
//           </div>

//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               onChange={handleSearchChange}
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span className="text-sm">Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={handleRemoveVideos}
//             className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span className="text-sm">Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center text-cyan-800 bg-gray-100">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   className="accent-cyan-800"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th className="px-4 py-2">id</th>
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Category</th>
//             <th className="px-4 py-2">Array</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Upload Date</th>
//             <th className="px-4 py-2">Views</th>
//           </tr>
//         </thead>
//         <tbody>
//           {sortedVideos.map((video) => (
//             <tr key={video.videoId} className="border-b text-center text-sm">
//               {isToggleChecked && (
//                 <td className="px-4 py-4">
//                   <input
//                     type="checkbox"
//                     className="accent-cyan-800"
//                     checked={selectedRows.includes(video.videoId)}
//                     onChange={() => handleSelectRow(video.videoId)}
//                   />
//                 </td>
//               )}
//               {/* id column */}
//               <td className="px-4 py-2">{video.videoId}</td>

//               {/* thumbnail column */}
//               <td className="px-4 py-2 flex justify-center items-center">
//                 <img 
//                     src={`${apiUrl}${video.videoThumbnail}`} 
//                     alt={video.videoTitle} 
//                     className="w-24 h-14 rounded-lg mr-4" 
//                 />
//               </td>

//               {/* title column */}
//               <td>
//                 <div className="text-center">
//                   <h3 
//                     className="font-bold text-cyan-800"
//                     onClick={() => handlePlayVideo(video.videoPath, video.videoTitle)}   
//                   >
//                     {video.videoTitle}
//                   </h3>
//                   <p className="text-sm text-cyan-800">{video.videoLength}</p>
//                 </div>
//               </td>

//               {/* category column */}
//               <td className="px-4 py-2">{video.videoCategory}</td>

//               {/* category array column */}
//               <td className="px-4 py-2">{video.videoCategoryArray}</td>

//               {/* permission column */}
//               <td className="px-4 py-4">
//                 {video.videoPermission === 'Private' ? (
//                   <div className="flex justify-center items-center">
//                     <AiOutlineLock className="text-gray-500 mr-2" />
//                     <span>{video.videoPermission}</span>
//                   </div>
//                 ) : (
//                   <div className="flex justify-center items-center">
//                     <AiFillEye className="text-cyan-800 mr-2" />
//                     <span>{video.videoPermission}</span>
//                   </div>
//                 )}
//               </td>

//               {/* upload date column */}
//               <td className="px-4 py-4">{new Date(video.createdAt).toLocaleDateString('en-CA')}</td>

//               {/* video view stats column */}
//               <td className="px-4 py-4">{video.videoViews}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleSubmit={handleVideoUpload}
//       />

//       {/* Progress Modal */}
//       <ProgressModal
//         isOpen={isProgressModalOpen}
//         progress={uploadProgress}
//         onClose={() => setIsProgressModalOpen(false)}
//       />

//       {/* Video Player Modal */}
//       {selectedVideoPath && (
//         <VideoPlayerModal
//           isOpen={isPlayerModalOpen}
//           onClose={() => setIsPlayerModalOpen(false)}
//           videoSrc={selectedVideoPath} 
//           videoDuration={selectedVideoDuration}
//           videoTitle={selectedVideoTitle}
//         />
//       )}
//     </div>
//   );
// };

// export default Video;


// // udpate code --> 04 Oct 2024
// // src/components/admin/admin08_video.tsx

// import React, { useState } from 'react';
// // import react icon
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { LuListFilter } from "react-icons/lu";
// import { FiEdit } from 'react-icons/fi';
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import component
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// import ProgressModal from '../admin/VideoSetting/VideoUploadProgress';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';
// // import context file
// import { useEnv } from '../../context/EnvContext'; 

// const Video: React.FC = () => {
//   const { uploadVideo, uploadProgress, removeVideos, videos, loading } = useVideoUpload();
//   const { apiUrl } = useEnv(); 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState(''); // State for search term
//   const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filtering videos based on search term
//   const filteredVideos = videos.filter((video) => {
//     const lowerSearchTerm = searchTerm.toLowerCase();
//     return (
//       video.videoTitle.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoCategory.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoPermission.toLowerCase().includes(lowerSearchTerm) ||
//       video.videoViews.toString().includes(lowerSearchTerm)
//     );
//   });

//   // Sorting function
//   const handleSort = (key: string) => {
//     let direction = 'asc';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedVideos = [...filteredVideos].sort((a, b) => {
//     if (!sortConfig) return 0;
//     const key = sortConfig.key as keyof typeof a;
//     const direction = sortConfig.direction === 'asc' ? 1 : -1;

//     if (a[key] < b[key]) return -1 * direction;
//     if (a[key] > b[key]) return 1 * direction;
//     return 0;
//   });

//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     setIsProgressModalOpen(true); 
//     const response = await uploadVideo(formData);

//     if (response) {
//       setIsModalOpen(false); 
//       setIsProgressModalOpen(false);  
//     }
//   };

//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); 
//   };

//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: string) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); 
//     } else {
//       setSelectedRows(videos.map(video => video.videoId)); 
//     }
//   };

//   // Handle removing selected videos
//   const handleRemoveVideos = async () => {
//     if (selectedRows.length > 0) {
//       await removeVideos(selectedRows);
//       setSelectedRows([]);
//     } else {
//       alert("Please select at least one video to remove.");
//     }
//   };

//   if (loading) {
//     return <div>Loading videos...</div>;
//   }

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         {/* Search Bar */}
//         <div className="flex items-center space-x-4">
//           <div className="flex flex-row justify-normal items-center text-cyan-800">
//             <button 
//                 onClick={() => handleSort('videoTitle')}
//             >
//               <LuListFilter className="mr-2 cursor-pointer" />
//             </button>
//             <span className="font-semibold text-sm">Filter</span>
//           </div>

//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               onChange={handleSearchChange}
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span className="text-sm">Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={handleRemoveVideos}
//             className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span className="text-sm">Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center text-cyan-800 bg-gray-100">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   className="accent-cyan-800"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th className="px-4 py-2">id</th>
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Category</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Upload Date</th>
//             <th className="px-4 py-2">Views</th>
//             <th className="px-4 py-2">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {sortedVideos.map((video) => (
//             <tr key={video.videoId} className="border-b text-center text-sm">
//               {isToggleChecked && (
//                 <td className="px-4 py-4">
//                   <input
//                     type="checkbox"
//                     className="accent-cyan-800"
//                     checked={selectedRows.includes(video.videoId)}
//                     onChange={() => handleSelectRow(video.videoId)}
//                   />
//                 </td>
//               )}
//               <td className="px-4 py-2">{video.videoId}</td>
//               <td className="px-4 py-2 flex justify-center items-center">
//                 <img 
//                     src={`${apiUrl}${video.videoThumbnail}`} 
//                     alt={video.videoTitle} 
//                     className="w-24 h-14 rounded-lg mr-4" 
//                 />
//               </td>
//               <td>
//                 <div className="text-center">
//                   <h3 className="font-bold text-cyan-800">{video.videoTitle}</h3>
//                   <p className="text-sm text-cyan-800">{video.videoLength}</p>
//                 </div>
//               </td>
//               <td className="px-4 py-2">{video.videoCategory}</td>
//               <td className="px-4 py-4">
//                 {video.videoPermission === 'Private' ? (
//                   <div className="flex justify-center items-center">
//                     <AiOutlineLock className="text-gray-500 mr-2" />
//                     <span>{video.videoPermission}</span>
//                   </div>
//                 ) : (
//                   <div className="flex justify-center items-center">
//                     <AiFillEye className="text-cyan-800 mr-2" />
//                     <span>{video.videoPermission}</span>
//                   </div>
//                 )}
//               </td>
//               <td className="px-4 py-4">{new Date(video.createdAt).toLocaleDateString('en-CA')}</td>
//               <td className="px-4 py-4">{video.videoViews}</td>
//               <td className="px-4 py-4">
//                 <div className="flex justify-center">
//                   <FiEdit className="text-cyan-800 cursor-pointer" />
//                 </div>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleSubmit={handleVideoUpload}
//       />

//       {/* Progress Modal */}
//       <ProgressModal
//         isOpen={isProgressModalOpen}
//         progress={uploadProgress}
//         onClose={() => setIsProgressModalOpen(false)}
//       />
//     </div>
//   );
// };

// export default Video;




// // udpate code --> 03 Oct 2024
// // src/components/admin/admin08_video.tsx

// // import react components
// import React, { useState } from 'react';
// // import icon
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { LuListFilter } from "react-icons/lu";
// import { FiEdit } from 'react-icons/fi';
// import { FaSearch, FaSquare, FaRegSquare } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';
// import { HiOutlineFolderRemove } from "react-icons/hi";
// // import components
// import VideoUpload from '../admin/VideoSetting/VideoUpload';
// // import hook file
// import useVideoUpload from '../../hooks/AdminDashboard/useVideoUpload';

// const Video: React.FC = () => {
//   const { uploadVideo } = useVideoUpload();
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isToggleChecked, setIsToggleChecked] = useState(false); 
//   const [selectedRows, setSelectedRows] = useState<number[]>([]);

//   const [videos, setVideos] = useState([
//     {
//       id: 1,
//       title: '2024-0623 Go4Funds Ultimate Tools Enhanced',
//       duration: '2:23:55',
//       visibility: 'Private',
//       date: '2024-06-24',
//       category: 'Getting Start',
//       views: 613,
//       thumbnail: '/path/to/thumbnail1.jpg',
//     },
//     {
//       id: 2,
//       title: '2024-0603 Go4Funds Ultimate Tools',
//       duration: '1:24:04',
//       visibility: 'Private',
//       date: '2024-06-05',
//       category: 'Getting Start',
//       views: 216,
//       thumbnail: '/path/to/thumbnail2.jpg',
//     },
//     {
//       id: 3,
//       title: '2024-0524 (男团) 工具Update',
//       duration: '1:16:47',
//       visibility: 'Private',
//       date: '2024-05-24',
//       category: 'Introduction',
//       views: 11,
//       thumbnail: '/path/to/thumbnail3.jpg',
//     },
//   ]);

//   // Handle file upload (for demo purposes)
//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (event.target.files && event.target.files.length > 0) {
//       const file = event.target.files[0];
//       console.log('File uploaded:', file.name);
//       setIsModalOpen(false); // Close modal after selecting file
//     }
//   };

//   // Function to handle form submission and video upload
//   const handleVideoUpload = async (formData: FormData) => {
//     const response = await uploadVideo(formData);

//     if (response) {
//       // After a successful upload, you can add the new video to the videos state
//       const newVideo = {
//         id: videos.length + 1, // Assuming a simple ID increment for demo purposes
//         title: response.videoData.videoTitle,
//         duration: response.videoData.videoLength,
//         visibility: response.videoData.videoPermission,
//         date: new Date().toISOString().slice(0, 10),
//         category: response.videoData.videoCategory,
//         views: 0,
//         thumbnail: response.videoData.videoThumbnail,
//       };

//       setVideos([...videos, newVideo]);
//       setIsModalOpen(false); // Close modal after upload
//     }
//   };

//   // Toggle handler for the checkbox and button
//   const handleToggleClick = () => {
//     setIsToggleChecked(!isToggleChecked);
//     setSelectedRows([]); // Reset selection when toggling
//   };

//   // Handle selecting/deselecting rows
//   const handleSelectRow = (id: number) => {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter(rowId => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   };

//   // Handle "select all" checkbox
//   const handleSelectAll = () => {
//     if (selectedRows.length === videos.length) {
//       setSelectedRows([]); // Deselect all if all rows are selected
//     } else {
//       setSelectedRows(videos.map(video => video.id)); // Select all
//     }
//   };

//   return (
//     <div className="p-6">
//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         {/* Search Bar */}
//         <div className="flex items-center space-x-4">
//           <div className="flex flex-row justify-normal items-center text-cyan-800">
//             <button>
//               <LuListFilter className="mr-2" />
//             </button>
//             <span className="font-semibold text-sm">Filter</span>
//           </div>

//           {/* toggle selection */}
//           <button
//             onClick={handleToggleClick}
//             className="text-cyan-800 px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             {isToggleChecked ? <FaSquare className="text-sm" /> : <FaRegSquare className="text-sm" />}
//             <span className="font-semibold text-sm">Toggle</span>
//           </button>

//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search your video"
//               className="px-4 py-2 border rounded-lg w-auto"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>

//           {/* Upload Video Button */}
//           <button
//             onClick={() => setIsModalOpen(true)}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//           >
//             <BsUpload className="text-sm" />
//             <span>Upload</span>
//           </button>

//           {/* Remove Video Button */}
//           <button
//             onClick={() => {
//                 const updatedVideos = videos.filter((video) => !selectedRows.includes(video.id));
//                 setSelectedRows([]); // Clear selection
//                 setVideos(updatedVideos); // Update the video list
//             }}
//             className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
//             >
//             <HiOutlineFolderRemove className="text-lg" />
//             <span>Remove</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center">
//             {isToggleChecked && (
//               <th className="px-4 py-2">
//                 <input
//                   type="checkbox"
//                   checked={selectedRows.length === videos.length}
//                   onChange={handleSelectAll}
//                 />
//               </th>
//             )}
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Category</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Upload Date</th>
//             <th className="px-4 py-2">Views</th>
//             <th className="px-4 py-2">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {videos.map((video) => (
//             <tr key={video.id} className="border-b text-center">
//               {isToggleChecked && (
//                 <td className="px-4 py-4">
//                   <input
//                     type="checkbox"
//                     checked={selectedRows.includes(video.id)}
//                     onChange={() => handleSelectRow(video.id)}
//                   />
//                 </td>
//               )}
//               <td className="px-4 py-4 flex items-center">
//                 <img src={video.thumbnail} alt={video.title} className="w-24 h-14 rounded-lg mr-4" />
//               </td>
//               <td>
//                 <div className="text-left">
//                   <h3 className="font-bold">{video.title}</h3>
//                   <p className="text-sm text-gray-500">{video.duration}</p>
//                 </div>
//               </td>
//               <td className="px-4 py-2">{video.category}</td>
//               <td className="px-4 py-4">
//                 {video.visibility === 'Private' ? (
//                   <div className="flex justify-center items-center">
//                     <AiOutlineLock className="text-gray-500 mr-2" />
//                     <span>{video.visibility}</span>
//                   </div>
//                 ) : (
//                   <div className="flex items-center">
//                     <AiFillEye className="text-green-500 mr-2" />
//                     <span>{video.visibility}</span>
//                   </div>
//                 )}
//               </td>
//               <td className="px-4 py-4">{new Date(video.date).toLocaleDateString('en-CA')}</td>
//               <td className="px-4 py-4">{video.views}</td>
//               <td className="px-4 py-4">
//                 <div className="flex justify-center">
//                   <FiEdit className="text-cyan-800 cursor-pointer" />
//                 </div>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Import the modal component */}
//       <VideoUpload
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         onFileChange={handleFileChange}
//         handleSubmit={handleVideoUpload}
//       />
//     </div>
//   );
// };

// export default Video;



// // update code --> 03 Oct 2024
// // src/components/admin/admin08_video.tsx

// import React from 'react';
// import { AiFillEye, AiOutlineLock } from 'react-icons/ai';
// import { LuListFilter } from "react-icons/lu";
// import { FiEdit, FiTrash } from 'react-icons/fi';
// import { FaSearch } from 'react-icons/fa';
// import { BsUpload } from 'react-icons/bs';

// const videos = [
//   {
//     id: 1,
//     title: '2024-0623 Go4Funds Ultimate Tools Enhanced',
//     duration: '2:23:55',
//     visibility: 'Private',
//     date: '24 Jun 2024',
//     views: 613,
//     likes: 4,
//     comments: 0,
//     thumbnail: '/path/to/thumbnail1.jpg',
//   },
//   {
//     id: 2,
//     title: '2024-0603 Go4Funds Ultimate Tools',
//     duration: '1:24:04',
//     visibility: 'Private',
//     date: '5 Jun 2024',
//     views: 216,
//     likes: 1,
//     comments: 0,
//     thumbnail: '/path/to/thumbnail2.jpg',
//   },
//   {
//     id: 3,
//     title: '2024-0524 (男团) 工具Update',
//     duration: '1:16:47',
//     visibility: 'Private',
//     date: '24 May 2024',
//     views: 11,
//     likes: 0,
//     comments: 0,
//     thumbnail: '/path/to/thumbnail3.jpg',
//   },
// ];

// const Video: React.FC = () => {
//   return (
//     <div className="p-6">

//       {/* Header Section with Search Bar and Upload Button */}
//       <div className="flex justify-start items-center mb-2">
//         <h1 className="text-2xl font-bold">Video Manage Center</h1>
//       </div>

//       <div className="flex justify-end items-center mb-4">
//         {/* Search Bar */}
//         <div className="flex items-center space-x-4">
//           <div className="flex flex-row justify-normal items-center">
//             <button>
//                 <LuListFilter className="mr-2"/>
//             </button>
//             <span>Filter</span>
//           </div>
          
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search across your channel"
//               className="px-4 py-2 border rounded-lg w-96"
//             />
//             <FaSearch className="absolute right-3 top-3 text-gray-500" />
//           </div>
          
//           {/* Upload Video Button */}
//           <button className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2">
//             <BsUpload className="w-5 h-5" />
//             <span>Upload</span>
//           </button>
//         </div>
//       </div>

//       {/* Video Table */}
//       <table className="min-w-full bg-white border rounded-lg">
//         <thead>
//           <tr className="border-b text-center">
//             <th className="px-4 py-2">Thumbnail</th>
//             <th className="px-4 py-2">Title</th>
//             <th className="px-4 py-2">Permission</th>
//             <th className="px-4 py-2">Date</th>
//             <th className="px-4 py-2">Views</th>
//             <th className="px-4 py-2">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {videos.map((video) => (
//             <tr key={video.id} className="border-b text-center">
//               <td className="px-4 py-4 flex items-center">
//                 <img src={video.thumbnail} alt={video.title} className="w-24 h-14 rounded-lg mr-4" />
//               </td>
//               <td>
//                 <div className="text-left">
//                   <h3 className="font-bold">{video.title}</h3>
//                   <p className="text-sm text-gray-500">{video.duration}</p>
//                 </div>
//               </td>
//               <td className="px-4 py-4">
//                 {video.visibility === 'Private' ? (
//                   <div className="flex items-center">
//                     <AiOutlineLock className="text-gray-500 mr-2" />
//                     <span>{video.visibility}</span>
//                   </div>
//                 ) : (
//                   <div className="flex items-center">
//                     <AiFillEye className="text-green-500 mr-2" />
//                     <span>{video.visibility}</span>
//                   </div>
//                 )}
//               </td>
//               <td className="px-4 py-4">{video.date}</td>
//               <td className="px-4 py-4">{video.views}</td>
//               <td className="px-4 py-4">
//                 <div className="flex space-x-4">
//                   <FiEdit className="text-cyan-800 cursor-pointer" />
//                   <FiTrash className="text-cyan-800 cursor-pointer" />
//                 </div>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Video;
