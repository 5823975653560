// src/hooks/AdminDashboard/useDataTable.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';
import { useAuth } from '../../context/AuthContext'; 
export interface ClientDataProps {
  id: string;
  name: string;
  icNo: string;
  email: string;
  gender: string;
  countryCode?: string;
  phone: string;
  dob: string;
  country: string;
  state: string;
  address: string;
  telegram_id?: string; 
  discord_id?: string;
  profileImage?: string;
  stages?: string;
  badges?: string;
  programStats?: string;
  password?: string;
  memberType: string;
  label?: string;
  dateJoined: Date | null; 
  amount: number;
  receipt?: File[] | string | null;
  assignMember?: string;
  assignGroup?: string;
  algoMatrixData?: boolean;
  traderHubData?: boolean;
}
export interface UserVideoDataProps {
  userVideoEmail: string;
  userVideoName: string;
  userVideoTitle: string;
  userVideoCategory: string;
  userVideoViews: string;
  userVideoLastViews: string;
}

export interface AlgoMatrixDataProps {
  subStatus: string;
  subUserName: string;
  subUserEmail: string;
  subPlanType: string;
  subExpiredDate: Date;
}

export interface TraderHubDataProps {
  subStatus: string;
  subUserName: string;
  subUserEmail: string;
  subPlanType: string;
  subExpiredDate: string;
}

const useDataTable = () => {
  const [data, setData] = useState<ClientDataProps[]>([]);
  const [filterData, setFilterData] = useState<ClientDataProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();
  const { user } = useAuth();

  // Fetch all unique labels
  const fetchLabels = async (): Promise<string[]> => {
    try {
      console.log('Fetching labels from:', `${apiUrl}/api/labels`);
      
      const response = await fetch(`${apiUrl}/api/labels`);
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        if (response.status === 404) {
          console.error('Labels endpoint not found');
          return [];
        }
        try {
          const errorData = await response.json();
          console.error('Labels fetch error:', errorData);
        } catch (e) {
          console.error('Could not parse error response');
        }
        return [];
      }
  
      const labels = await response.json();
      console.log('Received labels:', labels);
      
      if (!Array.isArray(labels)) {
        console.warn('Invalid labels response format:', labels);
        return [];
      }
  
      return labels;
    } catch (error) {
      console.error('Error fetching labels:', error);
      return [];
    }
  };    

  // Add or update a label for a user by email
  const addOrUpdateLabel = async (email: string, label: string): Promise<void> => {
    try {
      // Clean up the label format before sending
      const cleanedLabel = label
        .split(',')
        .map(l => l.trim().replace('$', ''))  // Remove $ prefix
        .filter(l => l)  // Remove empty strings
        .join(', ');  // Join with comma and space

      const response = await fetch(`${apiUrl}/api/data/User/addLabel`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, label: cleanedLabel }),
      });

      if (!response.ok) {
        throw new Error('Failed to add or update label');
      }
    } catch (error) {
      console.error('Error adding/updating label:', error);
      throw error;
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/data/User`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result);
    } catch (err) {
      setError('Error fetching data');
      console.error('Error details:', err);
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  const handleSendTelegramLink = async (user: ClientDataProps) => {

    try {
      const response = await fetch(`${apiUrl}/api/sendTelegramLink`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email }),  
      });
  
      if (response.ok) {
        await fetchData();
        alert('Telegram link sent via email.');
      } else {
        const errorDetails = await response.json();
        console.error("Failed to send Telegram link, response details:", errorDetails);
        alert('Failed to send Telegram link');
      }
    } catch (error) {
      console.error('Error sending Telegram link via email:', error);
    }
  };
    
  const deleteUsers = async (ids: string[]) => {
    try {
      const response = await fetch(`${apiUrl}/api/data/User`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      await fetchData();
    } catch (err) {
      setError('Error deleting users');
      console.error('Error details:', err);
    }
  };

  const addUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
    try {
      const response = await fetch(`${apiUrl}/api/data/User`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser),
      });
  
      if (response.status === 409) {
        const errorResponse = await response.json();
        console.error("Duplicate user error from server:", errorResponse); 
        throw new Error('Duplicate entry: Email already exists'); 
      }

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Failed to add user - Error from server:", errorResponse); 
        throw new Error('Failed to add new user');
      }
  
      const addedUser = await response.json();

      setData(prevData => [...prevData, addedUser]);
      return addedUser;
    } catch (err) {
      console.error("Error occurred during addUser:", err); 
      setError('Error adding new user');
      console.error('Error details:', err);
      throw err;
    }
  };

  const updateUser = async (updatedUser: ClientDataProps & { receipt?: File | null }) => {
    try {
      const formData = new FormData();
  
      // Add user fields to FormData
      Object.entries(updatedUser).forEach(([key, value]) => {
        if (key === 'receipt' && value instanceof File) {
          // console.log("Appending receipt file:", value.name); 
          formData.append(key, value); 
        } else if (value !== undefined && value !== null) {
          formData.append(key, String(value));
        }
      });

      // console.log("FormData for update:", Array.from(formData.entries()));
  
      const response = await fetch(`${apiUrl}/api/data/User/${updatedUser.id}`, {
        method: 'PUT',
        body: formData,
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Failed to update user - Error from server:", errorResponse);
        throw new Error('Failed to update user');
      }
  
      const result = await response.json();
      // console.log("User updated successfully:", result);

      setData((prevData) => prevData.map((user) => (user.id === updatedUser.id ? result : user)));
      return result;
    } catch (err) {
      console.error("Error occurred during updateUser:", err);
      setError('Error updating user');
      throw err;
    }
  };  

  // filtering data by assignmember and assign group
  const fetchAssignedUsers = useCallback(async () => {
    setLoading(true);
    try {
      if (!user?.email) {
        throw new Error("User email not found");
      }

      const response = await fetch(`${apiUrl}/api/filterData?email=${user.email}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setFilterData(result);
    } catch (err) {
      setError('Error fetching assigned users');
      console.error('Error details:', err);
    } finally {
      setLoading(false);
    }
  }, [apiUrl, user?.email]);

  const fetchUserVideoStats = async (email: string): Promise<UserVideoDataProps[]> => {
    try {
      const response = await fetch(`${apiUrl}/api/userVideo/${email}`);
      const data = await response.json();
  
      // Check if data is already an array or needs to be wrapped in an array
      if (Array.isArray(data)) {
        return data.map((video: any) => ({
          userVideoEmail: video.userVideoEmail,
          userVideoName: video.userVideoName,
          userVideoTitle: video.userVideoTitle,
          userVideoCategory: video.userVideoCategory,
          userVideoViews: video.userVideoViews,
          userVideoLastViews: video.userVideoLastViews,
        }));
      } else {
        // If the response is not an array, we wrap it in an array
        return [{
          userVideoEmail: data.userVideoEmail,
          userVideoName: data.userVideoName,
          userVideoTitle: data.userVideoTitle,
          userVideoCategory: data.userVideoCategory,
          userVideoViews: data.userVideoViews,
          userVideoLastViews: data.userVideoLastViews,
        }];
      }
    } catch (error) {
      console.error('Error fetching video stats:', error);
      throw error;
    }
  };      

  const fetchTradingStatus = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/algoMatrix/${email}/tradingStatus`);
      if (!response.ok) {
        console.warn(`No trading status found for email: ${email}`);
        return '';
      }

      const data = await response.json();
      return data.tradingStatus;
    } catch (error) {
      console.error('Error fetching trading status:', error);
      return '';
    }
  };

  const fetchAlgoMatrixData = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/algoMatrix/${email}`);

      if (!response.ok) {
        console.warn(`No AlgoMatrix data found for email: ${email}`);
        return null;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching AlgoMatrix data:', error);
      return null;
    }
  };
  
  const fetchTraderHubData = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/traderHub/${email}`);

      if (!response.ok) {
        console.warn(`No TraderHub data found for email: ${email}`);
        return null; 
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching TraderHub data:', error);
      return null;
    }
  };  
  
  useEffect(() => {
    if (user) {
      fetchAssignedUsers();
    }
  }, [fetchAssignedUsers, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

 return { 
    loading, error, 
    data, setData, filterData, setFilterData,
    fetchData, fetchAssignedUsers, fetchUserVideoStats, fetchTradingStatus, fetchAlgoMatrixData, fetchTraderHubData,
    addUser, updateUser, deleteUsers,
    fetchLabels, addOrUpdateLabel,
    handleSendTelegramLink, 
  };
};

export default useDataTable;

// // update code --> 12 jan 2024
// // src/hooks/AdminDashboard/useDataTable.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import { useAuth } from '../../context/AuthContext'; 
// export interface ClientDataProps {
//   id: string;
//   name: string;
//   icNo: string;
//   email: string;
//   gender: string;
//   countryCode?: string;
//   phone: string;
//   dob: string;
//   country: string;
//   state: string;
//   address: string;
//   telegram_id?: string; 
//   discord_id?: string;
//   profileImage?: string;
//   stages?: string;
//   badges?: string;
//   programStats?: string;
//   password?: string;
//   memberType: string;
//   dateJoined: Date | null; 
//   amount: number;
//   receipt?: File[] | string | null;
//   assignMember?: string;
//   assignGroup?: string;
//   algoMatrixData?: boolean;
//   traderHubData?: boolean;
// }
// export interface UserVideoDataProps {
//   userVideoEmail: string;
//   userVideoName: string;
//   userVideoTitle: string;
//   userVideoCategory: string;
//   userVideoViews: string;
//   userVideoLastViews: string;
// }

// export interface AlgoMatrixDataProps {
//   subStatus: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subExpiredDate: Date;
// }

// export interface TraderHubDataProps {
//   subStatus: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subExpiredDate: string;
// }

// const useDataTable = () => {
//   const [data, setData] = useState<ClientDataProps[]>([]);
//   const [filterData, setFilterData] = useState<ClientDataProps[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const result = await response.json();
//       setData(result);
//     } catch (err) {
//       setError('Error fetching data');
//       console.error('Error details:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const handleSendTelegramLink = async (user: ClientDataProps) => {

//     try {
//       const response = await fetch(`${apiUrl}/api/sendTelegramLink`, { 
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email: user.email }),  
//       });
  
//       if (response.ok) {
//         await fetchData();
//         alert('Telegram link sent via email.');
//       } else {
//         const errorDetails = await response.json();
//         console.error("Failed to send Telegram link, response details:", errorDetails);
//         alert('Failed to send Telegram link');
//       }
//     } catch (error) {
//       console.error('Error sending Telegram link via email:', error);
//     }
//   };
    
//   const deleteUsers = async (ids: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ ids }),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       await fetchData();
//     } catch (err) {
//       setError('Error deleting users');
//       console.error('Error details:', err);
//     }
//   };

//   const addUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newUser),
//       });
  
//       if (response.status === 409) {
//         const errorResponse = await response.json();
//         console.error("Duplicate user error from server:", errorResponse); 
//         throw new Error('Duplicate entry: Email already exists'); 
//       }

//       if (!response.ok) {
//         const errorResponse = await response.json();
//         console.error("Failed to add user - Error from server:", errorResponse); 
//         throw new Error('Failed to add new user');
//       }
  
//       const addedUser = await response.json();

//       setData(prevData => [...prevData, addedUser]);
//       return addedUser;
//     } catch (err) {
//       console.error("Error occurred during addUser:", err); 
//       setError('Error adding new user');
//       console.error('Error details:', err);
//       throw err;
//     }
//   };

//   const updateUser = async (updatedUser: ClientDataProps & { receipt?: File | null }) => {
//     try {
//       const formData = new FormData();
  
//       // Add user fields to FormData
//       Object.entries(updatedUser).forEach(([key, value]) => {
//         if (key === 'receipt' && value instanceof File) {
//           // console.log("Appending receipt file:", value.name); 
//           formData.append(key, value); 
//         } else if (value !== undefined && value !== null) {
//           formData.append(key, String(value));
//         }
//       });

//       // console.log("FormData for update:", Array.from(formData.entries()));
  
//       const response = await fetch(`${apiUrl}/api/data/User/${updatedUser.id}`, {
//         method: 'PUT',
//         body: formData,
//       });
  
//       if (!response.ok) {
//         const errorResponse = await response.json();
//         console.error("Failed to update user - Error from server:", errorResponse);
//         throw new Error('Failed to update user');
//       }
  
//       const result = await response.json();
//       // console.log("User updated successfully:", result);

//       setData((prevData) => prevData.map((user) => (user.id === updatedUser.id ? result : user)));
//       return result;
//     } catch (err) {
//       console.error("Error occurred during updateUser:", err);
//       setError('Error updating user');
//       throw err;
//     }
//   };  

//   // filtering data by assignmember and assign group
//   const fetchAssignedUsers = useCallback(async () => {
//     setLoading(true);
//     try {
//       if (!user?.email) {
//         throw new Error("User email not found");
//       }

//       const response = await fetch(`${apiUrl}/api/filterData?email=${user.email}`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const result = await response.json();
//       setFilterData(result);
//     } catch (err) {
//       setError('Error fetching assigned users');
//       console.error('Error details:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl, user?.email]);

//   const fetchUserVideoStats = async (email: string): Promise<UserVideoDataProps[]> => {
//     try {
//       const response = await fetch(`${apiUrl}/api/userVideo/${email}`);
//       const data = await response.json();
  
//       // Check if data is already an array or needs to be wrapped in an array
//       if (Array.isArray(data)) {
//         return data.map((video: any) => ({
//           userVideoEmail: video.userVideoEmail,
//           userVideoName: video.userVideoName,
//           userVideoTitle: video.userVideoTitle,
//           userVideoCategory: video.userVideoCategory,
//           userVideoViews: video.userVideoViews,
//           userVideoLastViews: video.userVideoLastViews,
//         }));
//       } else {
//         // If the response is not an array, we wrap it in an array
//         return [{
//           userVideoEmail: data.userVideoEmail,
//           userVideoName: data.userVideoName,
//           userVideoTitle: data.userVideoTitle,
//           userVideoCategory: data.userVideoCategory,
//           userVideoViews: data.userVideoViews,
//           userVideoLastViews: data.userVideoLastViews,
//         }];
//       }
//     } catch (error) {
//       console.error('Error fetching video stats:', error);
//       throw error;
//     }
//   };      

//   const fetchTradingStatus = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/algoMatrix/${email}/tradingStatus`);
//       if (!response.ok) {
//         console.warn(`No trading status found for email: ${email}`);
//         return '';
//       }

//       const data = await response.json();
//       return data.tradingStatus;
//     } catch (error) {
//       console.error('Error fetching trading status:', error);
//       return '';
//     }
//   };

//   const fetchAlgoMatrixData = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/algoMatrix/${email}`);

//       if (!response.ok) {
//         console.warn(`No AlgoMatrix data found for email: ${email}`);
//         return null;
//       }

//       const data = await response.json();
//       return data;
//     } catch (error) {
//       console.error('Error fetching AlgoMatrix data:', error);
//       return null;
//     }
//   };
  
//   const fetchTraderHubData = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/traderHub/${email}`);

//       if (!response.ok) {
//         console.warn(`No TraderHub data found for email: ${email}`);
//         return null; 
//       }

//       const data = await response.json();
//       return data;
//     } catch (error) {
//       console.error('Error fetching TraderHub data:', error);
//       return null;
//     }
//   };  
  
//   useEffect(() => {
//     if (user) {
//       fetchAssignedUsers();
//     }
//   }, [fetchAssignedUsers, user]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//  return { 
//     loading, error, 
//     data, setData, filterData, setFilterData,
//     fetchData, fetchAssignedUsers, fetchUserVideoStats, fetchTradingStatus, fetchAlgoMatrixData, fetchTraderHubData,
//     addUser, updateUser, deleteUsers, 
//     handleSendTelegramLink,
//   };
// };

// export default useDataTable;
