// // src/components/admin/FundProgramData/FundProgramData.tsx

import React, { useState, useMemo } from 'react';
import { RiEditBoxLine } from "react-icons/ri";
import { GoTrash } from "react-icons/go";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import useFundUserData, { FundUser } from '../../../hooks/AdminDashboard/useFundProgramData';
import useFundProgramSetting from '../../../hooks/AdminDashboard/useFundProgramSetting';

// sort config interface
interface SortConfig {
  key: string;
  direction: 'asc' | 'desc';
}

// Update EditFormData to be a Partial of FundUser
type EditFormData = Partial<FundUser>;

const FundProgramData: React.FC = () => {
  // handle import hook state
  const { data, loading, error, updateFundProgress, updateUser, deleteUser } = useFundUserData();
  const { programs } = useFundProgramSetting();
  // handle expanded row index state
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editFormData, setEditFormData] = useState<EditFormData | null>(null);
  // handle filtering state
  const [searchTerm, setSearchTerm] = useState('');
  const [progressFilter, setProgressFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });

  // Add this sorting function
  const handleSort = (key: string) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Add this filtering and sorting logic
  const filteredAndSortedData = useMemo(() => {
    let filtered = [...data];

    // Apply search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.fundUserName.toLowerCase().includes(searchLower) ||
          item.fundUserEmail.toLowerCase().includes(searchLower)
      );
    }

    // Apply progress filter
    if (progressFilter) {
      filtered = filtered.filter((item) => item.fundEvalProgress === progressFilter);
    }

    // Apply status filter
    if (statusFilter) {
      filtered = filtered.filter((item) => item.fundStatus === statusFilter);
    }

    // Apply sorting
    if (sortConfig.key) {
      filtered.sort((a, b) => {
        const aValue = a[sortConfig.key as keyof typeof a];
        const bValue = b[sortConfig.key as keyof typeof b];
        
        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }

    return filtered;
  }, [data, searchTerm, progressFilter, statusFilter, sortConfig]);

  // Handle delete
  const handleDelete = async (fundOrderId: string) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(fundOrderId);
        setExpandedRowIndex(null);
      } catch (error) {
        // Handle error (show error message to user)
        console.error('Error deleting user:', error);
      }
    }
  };

  // Handle edit
  // Add edit handlers
  const handleEditClick = (item: FundUser) => {
    // Format the date to YYYY-MM-DD for the date input
    const formattedDate = item.fundEvalExpiredDate 
      ? new Date(item.fundEvalExpiredDate).toISOString().split('T')[0]
      : '';
  
    setEditingId(item.fundOrderId);
    setEditFormData({
      // Basic Information
      fundOrderId: item.fundOrderId,
      fundLinkEvalId: item.fundLinkEvalId,
      fundUserName: item.fundUserName,
      fundUserEmail: item.fundUserEmail,
      
      // Evaluation Info
      fundStatus: item.fundStatus,
      fundEvalSize: item.fundEvalSize,
      fundEvalExpiredDate: formattedDate,
      fundEvalExpiredDays: item.fundEvalExpiredDays,
      
      // Account Info
      fundAccountNumber: item.fundAccountNumber,
      fundAccountPassword: item.fundAccountPassword,
      fundAccountServer: item.fundAccountServer,
      fundRuleAgreed: item.fundRuleAgreed,
      
      // Payment Info
      fundPaymentMethod: item.fundPaymentMethod,
      fundPaymentReceipts: item.fundPaymentReceipts,
      fundEvalBeforeProgram: item.fundEvalBeforeProgram,
      fundEvalBeforeStage: item.fundEvalBeforeStage,
      
      // Notes
      fundRegNotes: item.fundRegNotes,
      fundRemark: item.fundRemark
    });
  };

  // Add function to calculate days between dates
  const calculateDays = (selectedDate: string): number => {
    const today = new Date();
    const expireDate = new Date(selectedDate);
    const diffTime = expireDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  // Add this function to calculate current days remaining
  const calculateCurrentDays = (expiredDate: string): number => {
    const today = new Date();
    const expireDate = new Date(expiredDate);
    const diffTime = expireDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditFormData(null);
  };

  const handleSaveEdit = async (fundOrderId: string) => {
    try {
      if (!editFormData) return;
      
      await updateUser(fundOrderId, editFormData);
      setEditingId(null);
      setEditFormData(null);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  // Modify handleEditFormChange to handle date changes and fund program selection
  const handleEditFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    
    if (name === 'fundEvalExpiredDate') {
      // Calculate days when date changes
      const days = calculateDays(value);
      setEditFormData((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          [name]: value,
          fundEvalExpiredDays: days.toString()
        };
      });
    } else if (name === 'fundLinkEvalId') {
      // Find selected program and update eval size
      const selectedProgram = programs.find(prog => prog.fundNameSet === value);
      setEditFormData((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          [name]: value,
          // Convert string to number or use 0 as fallback
          fundEvalSize: selectedProgram?.fundSizeSet ? parseFloat(selectedProgram.fundSizeSet) : 0
        };
      });
    } else {
      setEditFormData((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          [name]: value
        };
      });
    }
  };

  // Modify the handleRowClick function to update days
  const handleRowClick = (index: number) => {
    if (expandedRowIndex === index) {
      setExpandedRowIndex(null);
    } else {
      setExpandedRowIndex(index);
      // Update the expired days when expanding the row
      const item = data[index];
      if (item && item.fundEvalExpiredDate) {
        const currentDays = calculateCurrentDays(item.fundEvalExpiredDate);
        // Update the data with new days count
        const updatedData = [...data];
        updatedData[index] = {
          ...item,
          fundEvalExpiredDays: currentDays.toString()
        };
        // You might need to update this in your backend as well
        updateUser(item.fundOrderId, { fundEvalExpiredDays: currentDays.toString() });
      }
    }
  };

  const handlePassClick = async (index: number) => {
    try {
      const item = data[index];
      if (!item) return;
  
      // Call the updateFundProgress hook function
      await updateFundProgress(item.fundOrderId, 'pass');
  
      // Update local state after successful API call
      const updatedData = [...data];
      if (updatedData[index].fundEvalProgress === 'Stage 01') {
        updatedData[index].fundEvalProgress = 'Stage 02';
      } else if (updatedData[index].fundEvalProgress === 'Stage 02') {
        updatedData[index].fundEvalProgress = 'Stage 03';
      } else if (updatedData[index].fundEvalProgress === 'Stage 03') {
        updatedData[index].fundEvalProgress = 'Live Funds';
      }
  
      const newDate = new Date();
      updatedData[index].fundEvalExpiredDate = new Date(newDate.setDate(newDate.getDate() + 30))
        .toISOString()
        .split('T')[0];
      updatedData[index].fundEvalExpiredDays = '30';
  
      // Update state
      setExpandedRowIndex(null);
    } catch (error) {
      console.error('Error updating fund progress:', error);
    }
  };  

  const handleFailedClick = async (index: number) => {
    try {
      const item = data[index];
      if (!item) return;

      // Call the updateFundProgress hook function
      await updateFundProgress(item.fundOrderId, 'fail');

      // Reset progress to "Stage 01" and expiration details
      const updatedData = [...data];
      updatedData[index].fundEvalProgress = 'Stage 01';

      const newDate = new Date();
      updatedData[index].fundEvalExpiredDate = new Date(newDate.setDate(newDate.getDate() + 30))
        .toISOString()
        .split('T')[0];
      updatedData[index].fundEvalExpiredDays = '30';

      setExpandedRowIndex(null);
    } catch (error) {
      console.error('Error resetting fund progress:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="p-4 border rounded">

      {/* Filters Section */}
      <div className="mb-4 space-y-4">
        <div className="flex justify-end space-x-6">

          {/* Progress Filter */}
          <select
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500"
            value={progressFilter}
            onChange={(e) => setProgressFilter(e.target.value)}
          >
            <option value="">All Progress</option>
            <option value="Stage 01">Stage 01</option>
            <option value="Stage 02">Stage 02</option>
            <option value="Stage 03">Stage 03</option>
            <option value="Live Funds">Live Funds</option>
          </select>

          {/* Status Filter */}
          <div className="flex items-center space-x-4">
            <label className="flex items-center text-center space-x-2">
              <input
                type="radio"
                name="status"
                value=""
                checked={statusFilter === ''}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="accent-cyan-800"
              />
              <span>All</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="status"
                value="Active"
                checked={statusFilter === 'Active'}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="accent-cyan-800"
              />
              <span>Active</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="status"
                value="Expire"
                checked={statusFilter === 'Expire'}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="accent-cyan-800"
              />
              <span>Expire</span>
            </label>

            <div className="border-r border-cyan-800"></div>

            {/* Search Bar */}
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Search by name or email..."
                className="w-[300px] px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Fund Participants table */}
      <div className="
            overflow-y-scroll scrollbar scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack 
            h-auto max-h-[450px]"
      >
        <table className="min-w-full bg-white border text-sm text-center">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold top-0 sticky border-gray-200">
              <th 
                className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
                onClick={() => handleSort('fundOrderId')}
              >
                Participant ID {sortConfig.key === 'fundOrderId' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
                onClick={() => handleSort('fundUserName')}
              >
                Participant Name {sortConfig.key === 'fundUserName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
                onClick={() => handleSort('fundUserEmail')}
              >
                Participant Email {sortConfig.key === 'fundUserEmail' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
                onClick={() => handleSort('fundEvalSize')}
              >
                Funded Size {sortConfig.key === 'fundEvalSize' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
                onClick={() => handleSort('fundEvalProgress')}
              >
                Evaluation Progress {sortConfig.key === 'fundEvalProgress' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
                onClick={() => handleSort('fundEvalExpiredDate')}
              >
                Evaluation Expired Date {sortConfig.key === 'fundEvalExpiredDate' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
                onClick={() => handleSort('fundStatus')}
              >
                Evaluation Status {sortConfig.key === 'fundStatus' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedData.length > 0 ? (
              filteredAndSortedData.map((item, index) => (
                <React.Fragment key={item.fundOrderId}>
                  {/* Main Row */}
                  <tr
                    onClick={() => handleRowClick(index)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border-b">{item.fundOrderId}</td>
                    <td className="py-2 px-4 border-b">{item.fundUserName}</td>
                    <td className="py-2 px-4 border-b">{item.fundUserEmail}</td>
                    <td className="py-2 px-4 border-b">{item.fundEvalSize}</td>
                    <td className="py-2 px-4 border-b">{item.fundEvalProgress}</td>
                    <td className="py-2 px-4 border-b">
                      {item.fundEvalExpiredDate
                        ? new Date(item.fundEvalExpiredDate).toISOString().split('T')[0]
                        : 'N/A'}
                    </td>
                    <td className="py-2 px-4 border-b">{item.fundStatus}</td>
                  </tr>

                  {/* Expanded Row */}
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan={7} className="bg-gray-50 border-b px-8 py-4">
                      <div className="flex justify-center items-center">
                        <div className="flex flex-col">
                          <div className="flex justify-center items-center">
                            <strong>Evaluation Progress:</strong> 
                            <span className="px-6 py-1 ml-2 bg-cyan-800 text-white rounded-full">
                              {item.fundEvalProgress} ( {calculateCurrentDays(item.fundEvalExpiredDate)} Days )
                            </span>
                            <div className="flex justify-normal text-xl text-cyan-900 ml-4">
                              {editingId === item.fundOrderId ? (
                                <>
                                  <button
                                    onClick={() => handleSaveEdit(item.fundOrderId)}
                                    className="text-cyan-800 font-bold mr-2 text-base"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelEdit}
                                    className="text-cyan-800 font-bold text-base"
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button onClick={() => handleEditClick(item)}>
                                    <RiEditBoxLine />
                                  </button>
                                  <button onClick={() => handleDelete(item.fundOrderId)}>
                                    <GoTrash className="mx-2"/>
                                  </button>
                                </>
                              )}
                            </div>
                          </div>

                          {/* Fund Participants Info */}
                          <div className="flex flex-row justify-center text-center space-x-10 p-6 text-sm">
                            {/* user information */}
                            <div className="flex flex-col">
                              <label className="underline text-left font-semibold">Detailed Information</label>
                              <div className="flex flex-col text-left">
                                {/* Sub Order Id */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">Order Id:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundOrderId"
                                      value={item.fundOrderId}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                      disabled
                                    />
                                  ) : (
                                    <span>{item.fundOrderId}</span>
                                  )}
                                </div>
                                {/* Fund Name */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">Fund Name:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundLinkEvalId"
                                      value={editFormData?.fundLinkEvalId || ''}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    >
                                      <option value="">Select a program</option>
                                      {programs.map((program) => (
                                        <option key={program.fundSetId} value={program.fundNameSet}>
                                          {program.fundNameSet}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    <span>{item.fundLinkEvalId}</span>
                                  )}
                                </div>
                                {/* user name */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">User Name:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundUserName"
                                      value={editFormData?.fundUserName}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    />
                                  ) : (
                                    <span>{item.fundUserName}</span>
                                  )}
                                </div>
                                {/* user email */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">User Email:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundUserEmail"
                                      value={editFormData?.fundUserEmail}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    />
                                  ) : (
                                    <span>{item.fundUserEmail}</span>
                                  )}
                                </div>
                              </div>
                            </div>


                            {/* Fund Evaluations Info */}
                            <div className="flex flex-col">
                              <label className="underline text-left font-semibold">
                                Evaluation Info
                              </label>
                              <div className="flex flex-col text-left">
                                {/* user status */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">Status:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundStatus"
                                      value={editFormData?.fundStatus || ''}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    >
                                      <option value="">Select a status</option>
                                      <option value="Active">Active</option>
                                      <option value="Expire">Expire</option>
                                    </select>
                                  ) : (
                                    <span 
                                      className={`font-bold 
                                        ${item.fundStatus === "Active" 
                                          ? "text-green-600" : "text-rose-600" }
                                        `}
                                    >
                                      {item.fundStatus}
                                    </span>
                                  )}
                                </div>
                                {/* user eval fund size */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">Eval Size:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundEvalSize"
                                      value={editFormData?.fundEvalSize || 0}
                                      className="border rounded px-2 py-1"
                                      disabled
                                    />
                                  ) : (
                                    <span>{item.fundEvalSize}</span>
                                  )}
                                </div>
                                {/* Expired Date */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">Expire Date:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="date"
                                      name="fundEvalExpiredDate"
                                      value={editFormData?.fundEvalExpiredDate || ''}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    />
                                  ) : (
                                    <span>{new Date(item.fundEvalExpiredDate).toISOString().split('T')[0]}</span>
                                  )}
                                </div>
                                {/* Expired Day with auto-calculated days */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">Expire Days:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundEvalExpiredDays"
                                      value={editFormData?.fundEvalExpiredDays || ''}
                                      className="border rounded px-2 py-1 w-20"
                                      disabled
                                    />
                                  ) : (
                                    <span className={`${
                                      calculateCurrentDays(item.fundEvalExpiredDate) <= 0 ? 'text-red-500' : ''
                                    }`}>
                                      {calculateCurrentDays(item.fundEvalExpiredDate)} Days
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Fund Account Info */}
                            <div className="flex flex-col">
                              <label className="underline text-left font-semibold">
                                Account Information
                              </label>
                              <div className="flex flex-col text-left mr-2">
                                {/* user account number */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold">Number:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="number"
                                      name="fundAccountNumber"
                                      value={editFormData?.fundAccountNumber}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    />
                                  ) : (
                                    <span>{item.fundAccountNumber}</span>
                                  )}
                                </div>
                                {/* user account password */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold">Password:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundAccountPassword"
                                      value={editFormData?.fundAccountPassword}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    />
                                  ) : (
                                    <span>{item.fundAccountPassword}</span>
                                  )}
                                </div>
                                {/* user account server */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold">Server:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundAccountServer"
                                      value={editFormData?.fundAccountServer || ''}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    >
                                      <option value="">Select a option</option>
                                      <option value="FortunePrimeGlobal-Live">FPG Live</option>
                                      <option value="FortunePrimeGlobal-Demo">FPG Demo</option>
                                      <option value="LirunexLimited-Live">Lirunex Live</option>
                                      <option value="LirunexLimited-Demo">Lirunex Demo</option>
                                    </select>
                                  ) : (
                                    <span>{item.fundAccountServer}</span>
                                  )}
                                </div>
                                {/* user rule and term acceptence */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold">Rule/Term:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundRuleAgreed"
                                      value={editFormData?.fundRuleAgreed || ''}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    >
                                      <option value="">Select a option</option>
                                      <option value="Agreed">Agreed</option>
                                      <option value="Not Agreed">Not Agreed</option>
                                    </select>
                                  ) : (
                                    <span>{item.fundRuleAgreed}</span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Fund Payment Info */}
                            <div className="flex flex-col">
                              <label className="underline text-left font-semibold">
                                Payment Information
                              </label>
                              <div className="flex flex-col text-left mr-2">
                                {/* Pay method */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">Pay Method:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundPaymentMethod"
                                      value={editFormData?.fundPaymentMethod}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    >
                                      <option value="">Select a option</option>
                                      <option value="Bank Transfer">Bank Transfer</option>
                                      <option value="USDT">USDT</option>
                                      <option value="Credit Card">Credit Card</option>
                                    </select>
                                  ) : (
                                    <span>{item.fundPaymentMethod}</span>
                                  )}
                                </div>
                                {/* Pay Receipts */}
                                <div className="flex items-center">
                                  <strong className="w-24 font-semibold mr-2">Pay Receipts:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type='text'
                                      name="fundPaymentReceipts"
                                      value={editFormData?.fundPaymentReceipts || ''}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1"
                                    />
                                  ) : (
                                    <a 
                                      href={item.fundPaymentReceipts} 
                                      className="underline text-cyan-800"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.fundPaymentReceipts ? 'View Receipts' : 'No Receipts'}
                                    </a>
                                  )}
                                </div>
                                {/* Before program */}
                                <div className="flex items-center">
                                  <strong className="w-28 font-semibold mr-2">Before Program:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundEvalBeforeProgram"
                                      value={editFormData?.fundEvalBeforeProgram || ''}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1 underline text-cyan-800"
                                    >
                                      <option value="">Select a option</option>
                                      <option value="180">180</option>
                                      <option value="Go4Funds">Go4Funds</option>
                                      <option value="New">New</option>
                                    </select>
                                  ) : (
                                    <span>{item.fundEvalBeforeProgram}</span>
                                  )}
                                </div>
                                {/* Before stage */}
                                <div className="flex items-center">
                                  <strong className="w-28 font-semibold mr-2">Before Stage:</strong>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundEvalBeforeStage"
                                      value={editFormData?.fundEvalBeforeStage || ''}
                                      onChange={handleEditFormChange}
                                      className="border rounded px-2 py-1 underline text-cyan-800"
                                    >
                                      <option value="">Select a option</option>
                                      <option value="Stage 01">Stage 01</option>
                                      <option value="Stage 02">Stage 02</option>
                                      <option value="Stage 03">Stage 03</option>
                                      <option value="Live Funds">Live Funds</option>
                                      <option value="New">New</option>
                                    </select>
                                  ) : (
                                    <span>{item.fundEvalBeforeStage}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          {/* user and admin noted */}
                          <div className="flex flex-row justify-center space-x-10">
                            <div className="text-left mb-2">
                              {/* user notes */}
                              <div className="flex flex-col">
                                <strong className="w-32 font-semibold mr-2">User Notes:</strong>
                                {editingId === item.fundOrderId ? (
                                  <textarea
                                    name="fundRegNotes"
                                    value={editFormData?.fundRegNotes}
                                    onChange={handleEditFormChange}
                                    className="border rounded px-2 py-1"
                                  />
                                ) : (
                                  <span>{item.fundRegNotes || 'No additional users notes available.'}</span>
                                )}
                              </div>
                            </div>
                            <div className="text-left mb-2">
                              {/* user notes */}
                              <div className="flex flex-col">
                                <strong className="w-32 font-semibold mr-2">Additional Notes:</strong>
                                {editingId === item.fundOrderId ? (
                                  <textarea
                                    name="fundRemark"
                                    value={editFormData?.fundRemark}
                                    onChange={handleEditFormChange}
                                    className="border rounded px-2 py-1"
                                  />
                                ) : (
                                  <span>{item.fundRemark || 'No additional users notes available.'}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                          {/* Fund Performance Chart */}
                          <div className="p-4 rounded-lg">
                            <div className="flex flex-col items-center w-auto min-w-[400px]">
                              <strong>Overview Chart</strong>
                              <ResponsiveContainer width="100%" height={200}>
                                <AreaChart
                                  data={[
                                    { name: 'Day 1', profit: 1000 },
                                    { name: 'Day 2', profit: 1200 },
                                    { name: 'Day 3', profit: 1500 },
                                    { name: 'Day 4', profit: 1800 },
                                    { name: 'Day 5', profit: 2000 },
                                  ]}
                                >
                                  <defs>
                                    <linearGradient id="colorProfit" x1="0" y1="0" x2="0" y2="1">
                                      <stop offset="5%" stopColor="#0f5469" stopOpacity={0.8} />
                                      <stop offset="95%" stopColor="#43bae6" stopOpacity={0} />
                                    </linearGradient>
                                  </defs>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <Tooltip />
                                  <Area
                                    type="monotone"
                                    dataKey="profit"
                                    stroke="#0f5469"
                                    fill="url(#colorProfit)"
                                  />
                                </AreaChart>
                              </ResponsiveContainer>
                            </div>

                            {/* Pass or Fail evalution button */}
                            <div className="flex justify-center items-center space-x-4">
                              <button
                                className={`rounded-full px-8 py-1 ${
                                  item.fundEvalProgress === 'Live Funds'
                                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                    : 'bg-gray-200 text-gray-400 hover:bg-gray-400 hover:text-white'
                                }`}
                                onClick={() => handleFailedClick(index)}
                                disabled={item.fundEvalProgress === 'Live Funds'}
                              >
                                Failed
                              </button>
                              <button
                                className={`rounded-full px-8 py-1 ${
                                  item.fundEvalProgress === 'Live Funds'
                                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                    : 'bg-cyan-800 text-white hover:bg-cyan-900'
                                }`}
                                onClick={() => handlePassClick(index)}
                                disabled={item.fundEvalProgress === 'Live Funds'}
                              >
                                Pass
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="text-center py-8">
                  No participants available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FundProgramData;

// // update code --> 02 jan 2025
// // src/components/admin/FundProgramData/FundProgramData.tsx

// import React, { useState, useMemo } from 'react';
// import { RiEditBoxLine } from "react-icons/ri";
// import { GoTrash } from "react-icons/go";
// import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
// import useFundUserData, { FundUser } from '../../../hooks/AdminDashboard/useFundProgramData';

// // sort config interface
// interface SortConfig {
//   key: string;
//   direction: 'asc' | 'desc';
// }

// // Update EditFormData to be a Partial of FundUser
// type EditFormData = Partial<FundUser>;

// const FundProgramData: React.FC = () => {
//   // handle import hook state
//   const { data, loading, error, updateFundProgress, updateUser, deleteUser } = useFundUserData();
//   // handle expanded row index state
//   const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
//   const [editingId, setEditingId] = useState<string | null>(null);
//   const [editFormData, setEditFormData] = useState<EditFormData | null>(null);
//   // handle filtering state
//   const [searchTerm, setSearchTerm] = useState('');
//   const [progressFilter, setProgressFilter] = useState('');
//   const [statusFilter, setStatusFilter] = useState('');
//   const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });

//   // Add this sorting function
//   const handleSort = (key: string) => {
//     setSortConfig((prevConfig) => ({
//       key,
//       direction:
//         prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
//     }));
//   };

//   // Add this filtering and sorting logic
//   const filteredAndSortedData = useMemo(() => {
//     let filtered = [...data];

//     // Apply search filter
//     if (searchTerm) {
//       const searchLower = searchTerm.toLowerCase();
//       filtered = filtered.filter(
//         (item) =>
//           item.fundUserName.toLowerCase().includes(searchLower) ||
//           item.fundUserEmail.toLowerCase().includes(searchLower)
//       );
//     }

//     // Apply progress filter
//     if (progressFilter) {
//       filtered = filtered.filter((item) => item.fundEvalProgress === progressFilter);
//     }

//     // Apply status filter
//     if (statusFilter) {
//       filtered = filtered.filter((item) => item.fundStatus === statusFilter);
//     }

//     // Apply sorting
//     if (sortConfig.key) {
//       filtered.sort((a, b) => {
//         const aValue = a[sortConfig.key as keyof typeof a];
//         const bValue = b[sortConfig.key as keyof typeof b];
        
//         if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
//         if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
//         return 0;
//       });
//     }

//     return filtered;
//   }, [data, searchTerm, progressFilter, statusFilter, sortConfig]);

//   // Handle delete
//   const handleDelete = async (fundOrderId: string) => {
//     if (window.confirm('Are you sure you want to delete this user?')) {
//       try {
//         await deleteUser(fundOrderId);
//         setExpandedRowIndex(null);
//       } catch (error) {
//         // Handle error (show error message to user)
//         console.error('Error deleting user:', error);
//       }
//     }
//   };

//   // Handle edit
//   // Add edit handlers
//   const handleEditClick = (item: FundUser) => {
//     // Format the date to YYYY-MM-DD for the date input
//     const formattedDate = item.fundEvalExpiredDate 
//       ? new Date(item.fundEvalExpiredDate).toISOString().split('T')[0]
//       : '';
  
//     setEditingId(item.fundOrderId);
//     setEditFormData({
//       // Basic Information
//       fundOrderId: item.fundOrderId,
//       fundLinkEvalId: item.fundLinkEvalId,
//       fundUserName: item.fundUserName,
//       fundUserEmail: item.fundUserEmail,
      
//       // Evaluation Info
//       fundStatus: item.fundStatus,
//       fundEvalSize: item.fundEvalSize,
//       fundEvalExpiredDate: formattedDate,
//       fundEvalExpiredDays: item.fundEvalExpiredDays,
      
//       // Account Info
//       fundAccountNumber: item.fundAccountNumber,
//       fundAccountPassword: item.fundAccountPassword,
//       fundAccountServer: item.fundAccountServer,
//       fundRuleAgreed: item.fundRuleAgreed,
      
//       // Payment Info
//       fundPaymentMethod: item.fundPaymentMethod,
//       fundPaymentReceipts: item.fundPaymentReceipts,
//       fundEvalBeforeProgram: item.fundEvalBeforeProgram,
//       fundEvalBeforeStage: item.fundEvalBeforeStage,
      
//       // Notes
//       fundRegNotes: item.fundRegNotes,
//       fundRemark: item.fundRemark
//     });
//   };

//   const handleCancelEdit = () => {
//     setEditingId(null);
//     setEditFormData(null);
//   };

//   const handleSaveEdit = async (fundOrderId: string) => {
//     try {
//       if (!editFormData) return;
      
//       await updateUser(fundOrderId, editFormData);
//       setEditingId(null);
//       setEditFormData(null);
//     } catch (error) {
//       console.error('Error updating user:', error);
//     }
//   };

//   const handleEditFormChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
//   ) => {
//     const { name, value } = e.target;
//     setEditFormData((prev) => {
//       if (!prev) return null;
//       return {
//         ...prev,
//         [name]: value
//       };
//     });
//   };

//   const handleRowClick = (index: number) => {
//     setExpandedRowIndex(expandedRowIndex === index ? null : index);
//   };

//   const handlePassClick = async (index: number) => {
//     try {
//       const item = data[index];
//       if (!item) return;
  
//       // Call the updateFundProgress hook function
//       await updateFundProgress(item.fundOrderId, 'pass');
  
//       // Update local state after successful API call
//       const updatedData = [...data];
//       if (updatedData[index].fundEvalProgress === 'Stage 01') {
//         updatedData[index].fundEvalProgress = 'Stage 02';
//       } else if (updatedData[index].fundEvalProgress === 'Stage 02') {
//         updatedData[index].fundEvalProgress = 'Stage 03';
//       } else if (updatedData[index].fundEvalProgress === 'Stage 03') {
//         updatedData[index].fundEvalProgress = 'Live Funds';
//       }
  
//       const newDate = new Date();
//       updatedData[index].fundEvalExpiredDate = new Date(newDate.setDate(newDate.getDate() + 30))
//         .toISOString()
//         .split('T')[0];
//       updatedData[index].fundEvalExpiredDays = '30';
  
//       // Update state
//       setExpandedRowIndex(null);
//     } catch (error) {
//       console.error('Error updating fund progress:', error);
//     }
//   };  

//   const handleFailedClick = async (index: number) => {
//     try {
//       const item = data[index];
//       if (!item) return;

//       // Call the updateFundProgress hook function
//       await updateFundProgress(item.fundOrderId, 'fail');

//       // Reset progress to "Stage 01" and expiration details
//       const updatedData = [...data];
//       updatedData[index].fundEvalProgress = 'Stage 01';

//       const newDate = new Date();
//       updatedData[index].fundEvalExpiredDate = new Date(newDate.setDate(newDate.getDate() + 30))
//         .toISOString()
//         .split('T')[0];
//       updatedData[index].fundEvalExpiredDays = '30';

//       setExpandedRowIndex(null);
//     } catch (error) {
//       console.error('Error resetting fund progress:', error);
//     }
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div className="text-red-500">{error}</div>;

//   return (
//     <div className="p-4 border rounded">

//       {/* Filters Section */}
//       <div className="mb-4 space-y-4">
//         <div className="flex justify-end space-x-6">

//           {/* Progress Filter */}
//           <select
//             className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500"
//             value={progressFilter}
//             onChange={(e) => setProgressFilter(e.target.value)}
//           >
//             <option value="">All Progress</option>
//             <option value="Stage 01">Stage 01</option>
//             <option value="Stage 02">Stage 02</option>
//             <option value="Stage 03">Stage 03</option>
//             <option value="Live Funds">Live Funds</option>
//           </select>

//           {/* Status Filter */}
//           <div className="flex items-center space-x-4">
//             <label className="flex items-center text-center space-x-2">
//               <input
//                 type="radio"
//                 name="status"
//                 value=""
//                 checked={statusFilter === ''}
//                 onChange={(e) => setStatusFilter(e.target.value)}
//                 className="accent-cyan-800"
//               />
//               <span>All</span>
//             </label>
//             <label className="flex items-center space-x-2">
//               <input
//                 type="radio"
//                 name="status"
//                 value="Active"
//                 checked={statusFilter === 'Active'}
//                 onChange={(e) => setStatusFilter(e.target.value)}
//                 className="accent-cyan-800"
//               />
//               <span>Active</span>
//             </label>
//             <label className="flex items-center space-x-2">
//               <input
//                 type="radio"
//                 name="status"
//                 value="Expire"
//                 checked={statusFilter === 'Expire'}
//                 onChange={(e) => setStatusFilter(e.target.value)}
//                 className="accent-cyan-800"
//               />
//               <span>Expire</span>
//             </label>

//             <div className="border-r border-cyan-800"></div>

//             {/* Search Bar */}
//             <div className="flex items-center">
//               <input
//                 type="text"
//                 placeholder="Search by name or email..."
//                 className="w-[300px] px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Fund Participants table */}
//       <div className="
//             overflow-y-scroll scrollbar scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack 
//             h-auto max-h-[450px]"
//       >
//         <table className="min-w-full bg-white border text-sm text-center">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold top-0 sticky border-gray-200">
//               <th 
//                 className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
//                 onClick={() => handleSort('fundOrderId')}
//               >
//                 Participant ID {sortConfig.key === 'fundOrderId' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
//               </th>
//               <th 
//                 className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
//                 onClick={() => handleSort('fundUserName')}
//               >
//                 Participant Name {sortConfig.key === 'fundUserName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
//               </th>
//               <th 
//                 className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
//                 onClick={() => handleSort('fundUserEmail')}
//               >
//                 Participant Email {sortConfig.key === 'fundUserEmail' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
//               </th>
//               <th 
//                 className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
//                 onClick={() => handleSort('fundEvalSize')}
//               >
//                 Funded Size {sortConfig.key === 'fundEvalSize' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
//               </th>
//               <th 
//                 className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
//                 onClick={() => handleSort('fundEvalProgress')}
//               >
//                 Evaluation Progress {sortConfig.key === 'fundEvalProgress' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
//               </th>
//               <th 
//                 className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
//                 onClick={() => handleSort('fundEvalExpiredDate')}
//               >
//                 Evaluation Expired Date {sortConfig.key === 'fundEvalExpiredDate' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
//               </th>
//               <th 
//                 className="py-2 px-4 border-b cursor-pointer hover:bg-gray-300"
//                 onClick={() => handleSort('fundStatus')}
//               >
//                 Evaluation Status {sortConfig.key === 'fundStatus' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredAndSortedData.length > 0 ? (
//               filteredAndSortedData.map((item, index) => (
//                 <React.Fragment key={item.fundOrderId}>
//                   {/* Main Row */}
//                   <tr
//                     onClick={() => handleRowClick(index)}
//                     className="cursor-pointer hover:bg-gray-100"
//                   >
//                     <td className="py-2 px-4 border-b">{item.fundOrderId}</td>
//                     <td className="py-2 px-4 border-b">{item.fundUserName}</td>
//                     <td className="py-2 px-4 border-b">{item.fundUserEmail}</td>
//                     <td className="py-2 px-4 border-b">{item.fundEvalSize}</td>
//                     <td className="py-2 px-4 border-b">{item.fundEvalProgress}</td>
//                     <td className="py-2 px-4 border-b">
//                       {item.fundEvalExpiredDate
//                         ? new Date(item.fundEvalExpiredDate).toISOString().split('T')[0]
//                         : 'N/A'}
//                     </td>
//                     <td className="py-2 px-4 border-b">{item.fundStatus}</td>
//                   </tr>

//                   {/* Expanded Row */}
//                   {expandedRowIndex === index && (
//                     <tr>
//                       <td colSpan={7} className="bg-gray-50 border-b px-8 py-4">
//                       <div className="flex justify-center items-center">
//                         <div className="flex flex-col">
//                           <div className="flex justify-center items-center">
//                             <strong>Evaluation Progress:</strong> 
//                             <span className="px-6 py-1 ml-2 bg-cyan-800 text-white rounded-full">
//                               {item.fundEvalProgress}
//                             </span>
//                             <div className="flex justify-normal text-xl text-cyan-900 ml-4">
//                               {editingId === item.fundOrderId ? (
//                                 <>
//                                   <button
//                                     onClick={() => handleSaveEdit(item.fundOrderId)}
//                                     className="text-cyan-800 font-bold mr-2 text-base"
//                                   >
//                                     Save
//                                   </button>
//                                   <button
//                                     onClick={handleCancelEdit}
//                                     className="text-cyan-800 font-bold text-base"
//                                   >
//                                     Cancel
//                                   </button>
//                                 </>
//                               ) : (
//                                 <>
//                                   <button onClick={() => handleEditClick(item)}>
//                                     <RiEditBoxLine />
//                                   </button>
//                                   <button onClick={() => handleDelete(item.fundOrderId)}>
//                                     <GoTrash className="mx-2"/>
//                                   </button>
//                                 </>
//                               )}
//                             </div>
//                           </div>

//                           {/* Fund Participants Info */}
//                           <div className="flex flex-row justify-center text-center space-x-10 p-6 text-sm">
//                             {/* user information */}
//                             <div className="flex flex-col">
//                               <label className="underline text-left font-semibold">Detailed Information</label>
//                               <div className="flex flex-col text-left">
//                                 {/* Sub Order Id */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">Order Id:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="text"
//                                       name="fundOrderId"
//                                       value={item.fundOrderId}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                       disabled
//                                     />
//                                   ) : (
//                                     <span>{item.fundOrderId}</span>
//                                   )}
//                                 </div>
//                                 {/* fund name */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">Fund Name:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="text"
//                                       name="fundLinkEvalId"
//                                       value={item.fundLinkEvalId}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                       disabled
//                                     />
//                                   ) : (
//                                     <span>{item.fundLinkEvalId}</span>
//                                   )}
//                                 </div>
//                                 {/* user name */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">User Name:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="text"
//                                       name="fundUserName"
//                                       value={editFormData?.fundUserName}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     />
//                                   ) : (
//                                     <span>{item.fundUserName}</span>
//                                   )}
//                                 </div>
//                                 {/* user email */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">User Email:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="text"
//                                       name="fundUserEmail"
//                                       value={editFormData?.fundUserEmail}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     />
//                                   ) : (
//                                     <span>{item.fundUserEmail}</span>
//                                   )}
//                                 </div>
//                               </div>
//                             </div>


//                             {/* Fund Evaluations Info */}
//                             <div className="flex flex-col">
//                               <label className="underline text-left font-semibold">
//                                 Evaluation Info
//                               </label>
//                               <div className="flex flex-col text-left">
//                                 {/* user status */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">Status:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <select
//                                       name="fundStatus"
//                                       value={editFormData?.fundStatus || ''}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     >
//                                       <option value="">Select a status</option>
//                                       <option value="Active">Active</option>
//                                       <option value="Expire">Expire</option>
//                                     </select>
//                                   ) : (
//                                     <span 
//                                       className={`font-bold 
//                                         ${item.fundStatus === "Active" 
//                                           ? "text-green-600" : "text-rose-600" }
//                                         `}
//                                     >
//                                       {item.fundStatus}
//                                     </span>
//                                   )}
//                                 </div>
//                                 {/* user eval fund size */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">Size:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="number"
//                                       name="fundEvalSize"
//                                       value={editFormData?.fundEvalSize || ''}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     />
//                                   ) : (
//                                     <span>{item.fundEvalSize}</span>
//                                   )}
//                                 </div>
//                                 {/* user eval fund expired date */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">Expire Date:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="date"
//                                       name="fundEvalExpiredDate"
//                                       value={editFormData?.fundEvalExpiredDate}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     />
//                                   ) : (
//                                     <span>{new Date(item.fundEvalExpiredDate).toISOString().split('T')[0]}</span>
//                                   )}
//                                 </div>
//                                 {/* user eval fund expire days */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">Expire Days:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="number"
//                                       name="fundEvalExpiredDays"
//                                       value={editFormData?.fundEvalExpiredDays}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                       disabled
//                                     />
//                                   ) : (
//                                     <span>{item.fundEvalExpiredDays}</span>
//                                   )}
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Fund Account Info */}
//                             <div className="flex flex-col">
//                               <label className="underline text-left font-semibold">
//                                 Account Information
//                               </label>
//                               <div className="flex flex-col text-left mr-2">
//                                 {/* user account number */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold">Number:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="number"
//                                       name="fundAccountNumber"
//                                       value={editFormData?.fundAccountNumber}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     />
//                                   ) : (
//                                     <span>{item.fundAccountNumber}</span>
//                                   )}
//                                 </div>
//                                 {/* user account password */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold">Password:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type="text"
//                                       name="fundAccountPassword"
//                                       value={editFormData?.fundAccountPassword}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     />
//                                   ) : (
//                                     <span>{item.fundAccountPassword}</span>
//                                   )}
//                                 </div>
//                                 {/* user account server */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold">Server:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <select
//                                       name="fundAccountServer"
//                                       value={editFormData?.fundAccountServer || ''}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     >
//                                       <option value="">Select a option</option>
//                                       <option value="FortunePrimeGlobal-Live">FPG Live</option>
//                                       <option value="FortunePrimeGlobal-Demo">FPG Demo</option>
//                                       <option value="LirunexLimited-Live">Lirunex Live</option>
//                                       <option value="LirunexLimited-Demo">Lirunex Demo</option>
//                                     </select>
//                                   ) : (
//                                     <span>{item.fundAccountServer}</span>
//                                   )}
//                                 </div>
//                                 {/* user rule and term acceptence */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold">Rule/Term:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <select
//                                       name="fundRuleAgreed"
//                                       value={editFormData?.fundRuleAgreed || ''}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     >
//                                       <option value="">Select a option</option>
//                                       <option value="Agreed">Agreed</option>
//                                       <option value="Not Agreed">Not Agreed</option>
//                                     </select>
//                                   ) : (
//                                     <span>{item.fundRuleAgreed}</span>
//                                   )}
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Fund Payment Info */}
//                             <div className="flex flex-col">
//                               <label className="underline text-left font-semibold">
//                                 Payment Information
//                               </label>
//                               <div className="flex flex-col text-left mr-2">
//                                 {/* Pay method */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">Pay Method:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <select
//                                       name="fundPaymentMethod"
//                                       value={editFormData?.fundPaymentMethod}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     >
//                                       <option value="">Select a option</option>
//                                       <option value="Bank Transfer">Bank Transfer</option>
//                                       <option value="USDT">USDT</option>
//                                       <option value="Credit Card">Credit Card</option>
//                                     </select>
//                                   ) : (
//                                     <span>{item.fundPaymentMethod}</span>
//                                   )}
//                                 </div>
//                                 {/* Pay Receipts */}
//                                 <div className="flex items-center">
//                                   <strong className="w-24 font-semibold mr-2">Pay Receipts:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <input
//                                       type='text'
//                                       name="fundPaymentReceipts"
//                                       value={editFormData?.fundPaymentReceipts || ''}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1"
//                                     />
//                                   ) : (
//                                     <a 
//                                       href={item.fundPaymentReceipts} 
//                                       className="underline text-cyan-800"
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       {item.fundPaymentReceipts ? 'View Receipts' : 'No Receipts'}
//                                     </a>
//                                   )}
//                                 </div>
//                                 {/* Before program */}
//                                 <div className="flex items-center">
//                                   <strong className="w-28 font-semibold mr-2">Before Program:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <select
//                                       name="fundEvalBeforeProgram"
//                                       value={editFormData?.fundEvalBeforeProgram || ''}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1 underline text-cyan-800"
//                                     >
//                                       <option value="">Select a option</option>
//                                       <option value="180">180</option>
//                                       <option value="Go4Funds">Go4Funds</option>
//                                       <option value="New">New</option>
//                                     </select>
//                                   ) : (
//                                     <span>{item.fundEvalBeforeProgram}</span>
//                                   )}
//                                 </div>
//                                 {/* Before stage */}
//                                 <div className="flex items-center">
//                                   <strong className="w-28 font-semibold mr-2">Before Stage:</strong>
//                                   {editingId === item.fundOrderId ? (
//                                     <select
//                                       name="fundEvalBeforeStage"
//                                       value={editFormData?.fundEvalBeforeStage || ''}
//                                       onChange={handleEditFormChange}
//                                       className="border rounded px-2 py-1 underline text-cyan-800"
//                                     >
//                                       <option value="">Select a option</option>
//                                       <option value="Stage 01">Stage 01</option>
//                                       <option value="Stage 02">Stage 02</option>
//                                       <option value="Stage 03">Stage 03</option>
//                                       <option value="Live Funds">Live Funds</option>
//                                       <option value="New">New</option>
//                                     </select>
//                                   ) : (
//                                     <span>{item.fundEvalBeforeStage}</span>
//                                   )}
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
                          
//                           {/* user and admin noted */}
//                           <div className="flex flex-row justify-center space-x-10">
//                             <div className="text-left mb-2">
//                               {/* user notes */}
//                               <div className="flex flex-col">
//                                 <strong className="w-24 font-semibold mr-2">User Notes:</strong>
//                                 {editingId === item.fundOrderId ? (
//                                   <textarea
//                                     name="fundRegNotes"
//                                     value={editFormData?.fundRegNotes}
//                                     onChange={handleEditFormChange}
//                                     className="border rounded px-2 py-1"
//                                   />
//                                 ) : (
//                                   <span>{item.fundRegNotes || 'No additional users notes available.'}</span>
//                                 )}
//                               </div>
//                             </div>
//                             <div className="text-left mb-2">
//                               {/* user notes */}
//                               <div className="flex flex-col">
//                                 <strong className="w-24 font-semibold mr-2">Additional Notes:</strong>
//                                 {editingId === item.fundOrderId ? (
//                                   <textarea
//                                     name="fundRemark"
//                                     value={editFormData?.fundRemark}
//                                     onChange={handleEditFormChange}
//                                     className="border rounded px-2 py-1"
//                                   />
//                                 ) : (
//                                   <span>{item.fundRemark || 'No additional users notes available.'}</span>
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         </div>

//                           {/* Fund Performance Chart */}
//                           <div className="p-4 rounded-lg">
//                             <div className="flex flex-col items-center w-auto min-w-[400px]">
//                               <strong>Overview Chart</strong>
//                               <ResponsiveContainer width="100%" height={200}>
//                                 <AreaChart
//                                   data={[
//                                     { name: 'Day 1', profit: 1000 },
//                                     { name: 'Day 2', profit: 1200 },
//                                     { name: 'Day 3', profit: 1500 },
//                                     { name: 'Day 4', profit: 1800 },
//                                     { name: 'Day 5', profit: 2000 },
//                                   ]}
//                                 >
//                                   <defs>
//                                     <linearGradient id="colorProfit" x1="0" y1="0" x2="0" y2="1">
//                                       <stop offset="5%" stopColor="#0f5469" stopOpacity={0.8} />
//                                       <stop offset="95%" stopColor="#43bae6" stopOpacity={0} />
//                                     </linearGradient>
//                                   </defs>
//                                   <CartesianGrid strokeDasharray="3 3" />
//                                   <XAxis dataKey="name" />
//                                   <YAxis />
//                                   <Tooltip />
//                                   <Area
//                                     type="monotone"
//                                     dataKey="profit"
//                                     stroke="#0f5469"
//                                     fill="url(#colorProfit)"
//                                   />
//                                 </AreaChart>
//                               </ResponsiveContainer>
//                             </div>

//                             {/* Pass or Fail evalution button */}
//                             <div className="flex justify-center items-center space-x-4">
//                               <button
//                                 className={`rounded-full px-8 py-1 ${
//                                   item.fundEvalProgress === 'Live Funds'
//                                     ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
//                                     : 'bg-gray-200 text-gray-400 hover:bg-gray-400 hover:text-white'
//                                 }`}
//                                 onClick={() => handleFailedClick(index)}
//                                 disabled={item.fundEvalProgress === 'Live Funds'}
//                               >
//                                 Failed
//                               </button>
//                               <button
//                                 className={`rounded-full px-8 py-1 ${
//                                   item.fundEvalProgress === 'Live Funds'
//                                     ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
//                                     : 'bg-cyan-800 text-white hover:bg-cyan-900'
//                                 }`}
//                                 onClick={() => handlePassClick(index)}
//                                 disabled={item.fundEvalProgress === 'Live Funds'}
//                               >
//                                 Pass
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                   )}
//                 </React.Fragment>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={7} className="text-center py-8">
//                   No participants available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default FundProgramData;


// // update code --> 01 jan 2025
// // src/components/admin/FundProgramData/FundProgramData.tsx

// import React, { useState } from 'react';
// import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
// import useFundUserData from '../../../hooks/AdminDashboard/useFundProgramData';

// const FundProgramData: React.FC = () => {
//   const { data, loading, error, updateFundProgress } = useFundUserData();
//   const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

//   const handleRowClick = (index: number) => {
//     setExpandedRowIndex(expandedRowIndex === index ? null : index);
//   };

//   const handlePassClick = async (index: number) => {
//     try {
//       const item = data[index];
//       if (!item) return;
  
//       // Call the updateFundProgress hook function
//       await updateFundProgress(item.fundOrderId, 'pass');
  
//       // Update local state after successful API call
//       const updatedData = [...data];
//       if (updatedData[index].fundEvalProgress === 'Stage 01') {
//         updatedData[index].fundEvalProgress = 'Stage 02';
//       } else if (updatedData[index].fundEvalProgress === 'Stage 02') {
//         updatedData[index].fundEvalProgress = 'Stage 03';
//       } else if (updatedData[index].fundEvalProgress === 'Stage 03') {
//         updatedData[index].fundEvalProgress = 'Live Funds';
//       }
  
//       const newDate = new Date();
//       updatedData[index].fundEvalExpiredDate = new Date(newDate.setDate(newDate.getDate() + 30))
//         .toISOString()
//         .split('T')[0];
//       updatedData[index].fundEvalExpiredDays = '30';
  
//       // Update state
//       setExpandedRowIndex(null);
//     } catch (error) {
//       console.error('Error updating fund progress:', error);
//     }
//   };  

//   const handleFailedClick = async (index: number) => {
//     try {
//       const item = data[index];
//       if (!item) return;

//       // Call the updateFundProgress hook function
//       await updateFundProgress(item.fundOrderId, 'fail');

//       // Reset progress to "Stage 01" and expiration details
//       const updatedData = [...data];
//       updatedData[index].fundEvalProgress = 'Stage 01';

//       const newDate = new Date();
//       updatedData[index].fundEvalExpiredDate = new Date(newDate.setDate(newDate.getDate() + 30))
//         .toISOString()
//         .split('T')[0];
//       updatedData[index].fundEvalExpiredDays = '30';

//       setExpandedRowIndex(null);
//     } catch (error) {
//       console.error('Error resetting fund progress:', error);
//     }
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div className="text-red-500">{error}</div>;

//   return (
//     <div className="p-4 border rounded">
//       <div className="
//             overflow-y-scroll scrollbar scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack 
//             h-auto max-h-[450px]"
//       >
//         <table className="min-w-full bg-white border text-sm text-center">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold top-0 sticky border-gray-200">
//               <th className="py-2 px-4 border-b">Participant ID</th>
//               <th className="py-2 px-4 border-b">Participant Name</th>
//               <th className="py-2 px-4 border-b">Participant Email</th>
//               <th className="py-2 px-4 border-b">Funded Size</th>
//               <th className="py-2 px-4 border-b">Evaluation Progress</th>
//               <th className="py-2 px-4 border-b">Evaluation Expired Date</th>
//               <th className="py-2 px-4 border-b">Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.length > 0 ? (
//               data.map((item, index) => (
//                 <React.Fragment key={item.fundOrderId}>
//                   {/* Main Row */}
//                   <tr
//                     onClick={() => handleRowClick(index)}
//                     className="cursor-pointer hover:bg-gray-100"
//                   >
//                     <td className="py-2 px-4 border-b">{item.fundOrderId}</td>
//                     <td className="py-2 px-4 border-b">{item.fundUserName}</td>
//                     <td className="py-2 px-4 border-b">{item.fundUserEmail}</td>
//                     <td className="py-2 px-4 border-b">{item.fundEvalSize}</td>
//                     <td className="py-2 px-4 border-b">{item.fundEvalProgress}</td>
//                     <td className="py-2 px-4 border-b">
//                       {item.fundEvalExpiredDate
//                         ? new Date(item.fundEvalExpiredDate).toISOString().split('T')[0]
//                         : 'N/A'}
//                     </td>
//                     <td className="py-2 px-4 border-b">{item.fundStatus}</td>
//                   </tr>

//                   {/* Expanded Row */}
//                   {expandedRowIndex === index && (
//                     <tr>
//                       <td colSpan={7} className="bg-gray-50 border-b px-8 py-4">
//                       <div className="flex justify-center items-center">
//                         <div className="flex flex-col">
//                           <div className="space-x-4">
//                             <strong>Evaluation Progress:</strong> 
//                             <span className="px-6 py-2 bg-cyan-800 text-white rounded-full">
//                               {item.fundEvalProgress}
//                             </span>
//                           </div>

//                           {/* Fund Participants Info */}
//                           <div className="flex flex-row justify-center text-center space-x-10 p-6 text-sm">
//                             <div className="flex flex-row">
//                               <div className="flex flex-col mr-4">
//                                 <label className="underline text-left font-semibold">
//                                   Detailed Information
//                                 </label>
//                                 <div className="flex flex-row">
//                                   <div className="flex flex-col text-left mr-2">
//                                     <strong>Order ID:</strong>
//                                     <strong>Program:</strong>
//                                     <strong>Name:</strong>
//                                     <strong>Email:</strong>
//                                   </div>
//                                   <div className="flex flex-col text-left">
//                                     <span>{item.fundOrderId}</span>
//                                     <span>{item.fundLinkEvalId}</span>
//                                     <span>{item.fundUserName}</span>
//                                     <span>{item.fundUserEmail}</span>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Fund Evaluations Info */}
//                             <div className="flex flex-row">
//                               <div className="flex flex-col mr-4">
//                                 <label className="underline text-left font-semibold">
//                                   Evaluation Info
//                                 </label>
//                                 <div className="flex flex-row">
//                                   <div className="flex flex-col text-left mr-2">
//                                     <strong>Status:</strong>
//                                     <strong>Size:</strong>
//                                     <strong>Expire Date:</strong>
//                                     <strong>Expire Days:</strong>
//                                   </div>
//                                   <div className="flex flex-col text-left">
//                                     <span 
//                                       className={`font-bold 
//                                         ${item.fundStatus === "Active" 
//                                           ? "text-green-600" : "text-rose-600" }
//                                         `}
//                                     >
//                                       {item.fundStatus}
//                                     </span>
//                                     <span>{item.fundEvalSize}</span>
//                                     <span>{new Date(item.fundEvalExpiredDate).toISOString().split('T')[0]}</span>
//                                     <span>{item.fundEvalExpiredDays}</span>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Fund Account Info */}
//                             <div className="flex flex-row">
//                               <div className="flex flex-col mr-4">
//                                 <label className="underline text-left font-semibold">
//                                   Account Information
//                                 </label>
//                                 <div className="flex flex-row">
//                                   <div className="flex flex-col text-left mr-2">
//                                     <strong>Number:</strong>
//                                     <strong>Password:</strong>
//                                     <strong>Server:</strong>
//                                     <strong>Rule/Term:</strong>
//                                   </div>
//                                   <div className="flex flex-col text-left">
//                                     <span>{item.fundAccountNumber}</span>
//                                     <span>{item.fundAccountPassword}</span>
//                                     <span>{item.fundAccountServer}</span>
//                                     <span>{item.fundRuleAgreed}</span>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Fund Payment Info */}
//                             <div className="flex flex-row">
//                               <div className="flex flex-col mr-4">
//                                 <label className="underline text-left font-semibold">
//                                   Payment Information
//                                 </label>
//                                 <div className="flex flex-row">
//                                   <div className="flex flex-col text-left mr-2">
//                                     <strong>Pay Method:</strong>
//                                     <strong>Pay Receipts:</strong>
//                                     <strong>Before Program:</strong>
//                                     <strong>Before Stage:</strong>
//                                   </div>
//                                   <div className="flex flex-col text-left">
//                                     <span>{item.fundPaymentMethod}</span>
//                                     <span>{item.fundPaymentReceipts || 'No payment receipts.' }</span>
//                                     <span>{item.fundEvalBeforeProgram}</span>
//                                     <span>{item.fundEvalBeforeStage}</span>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
                          
//                           {/* user and admin noted */}
//                           <div className="flex flex-row justify-center space-x-8">
//                             <div className="text-left mb-2">
//                               <strong>User Notes:</strong>
//                               <p>{item.fundRegNotes || 'No additional users notes available.'}</p>
//                             </div>
//                             <div className="text-left">
//                               <strong>Additional Notes:</strong>
//                               <p>{item.fundRemark || 'No additional notes available.'}</p>
//                             </div>
//                           </div>
//                         </div>

//                           {/* Fund Performance Chart */}
//                           <div className="p-4 rounded-lg">
//                             <div className="flex flex-col items-center w-auto min-w-[400px]">
//                               <strong>Overview Chart</strong>
//                               <ResponsiveContainer width="100%" height={200}>
//                                 <AreaChart
//                                   data={[
//                                     { name: 'Day 1', profit: 1000 },
//                                     { name: 'Day 2', profit: 1200 },
//                                     { name: 'Day 3', profit: 1500 },
//                                     { name: 'Day 4', profit: 1800 },
//                                     { name: 'Day 5', profit: 2000 },
//                                   ]}
//                                 >
//                                   <defs>
//                                     <linearGradient id="colorProfit" x1="0" y1="0" x2="0" y2="1">
//                                       <stop offset="5%" stopColor="#0f5469" stopOpacity={0.8} />
//                                       <stop offset="95%" stopColor="#43bae6" stopOpacity={0} />
//                                     </linearGradient>
//                                   </defs>
//                                   <CartesianGrid strokeDasharray="3 3" />
//                                   <XAxis dataKey="name" />
//                                   <YAxis />
//                                   <Tooltip />
//                                   <Area
//                                     type="monotone"
//                                     dataKey="profit"
//                                     stroke="#0f5469"
//                                     fill="url(#colorProfit)"
//                                   />
//                                 </AreaChart>
//                               </ResponsiveContainer>
//                             </div>

//                             {/* Pass or Fail evalution button */}
//                             <div className="flex justify-center items-center space-x-4">
//                               <button
//                                 className={`rounded-full px-8 py-1 ${
//                                   item.fundEvalProgress === 'Live Funds'
//                                     ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
//                                     : 'bg-gray-200 text-gray-400 hover:bg-gray-400 hover:text-white'
//                                 }`}
//                                 onClick={() => handleFailedClick(index)}
//                                 disabled={item.fundEvalProgress === 'Live Funds'}
//                               >
//                                 Failed
//                               </button>
//                               <button
//                                 className={`rounded-full px-8 py-1 ${
//                                   item.fundEvalProgress === 'Live Funds'
//                                     ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
//                                     : 'bg-cyan-800 text-white hover:bg-cyan-900'
//                                 }`}
//                                 onClick={() => handlePassClick(index)}
//                                 disabled={item.fundEvalProgress === 'Live Funds'}
//                               >
//                                 Pass
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                   )}
//                 </React.Fragment>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={7} className="text-center py-8">
//                   No participants available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default FundProgramData;