// src/context/LangContext.tsx

import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import useGeoIPCheck from '../hooks/useGeoIP';

interface LanguageContextType {
  language: string;
  toggleLanguage: () => void;
  setLanguageDirectly: (lang: string) => void;
  geoData: { country_code: string; ip?: string } | null;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<string>('en');
  const [isLanguageManuallyChanged, setIsLanguageManuallyChanged] = useState<boolean>(false);

  const { geoData, isLoading } = useGeoIPCheck('live'); // Fetch GeoIP data using your hook

  useEffect(() => {
    // Automatically set the language based on geoData if not manually changed
    if (!isLoading && geoData && !isLanguageManuallyChanged) {
      const detectedLanguage = geoData.country_code === 'TW' ? 'zh-TW' : 'zh-CN';
      if (language !== detectedLanguage) {
        setLanguage(detectedLanguage);
      }
    }
  }, [geoData, isLoading, isLanguageManuallyChanged, language]);

  // Toggle logic based on the detected country
  const toggleLanguage = () => {
    setIsLanguageManuallyChanged(true); // Mark as manually changed
    setLanguage((prev) => {
      if (geoData?.country_code === 'TW') {
        // Toggle between en and zh-TW for Taiwan users
        return prev === 'en' ? 'zh-TW' : 'en';
      }
      // Toggle between en and zh-CN for non-Taiwan users
      return prev === 'en' ? 'zh-CN' : 'en';
    });
  };

  const setLanguageDirectly = (lang: string) => {
    if (['en', 'zh-TW', 'zh-CN'].includes(lang)) {
      setIsLanguageManuallyChanged(true); // Mark as manually changed
      setLanguage(lang);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, setLanguageDirectly, geoData }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

