// src/components/TradingJournal.tsx

import React, { useEffect, useState, useMemo } from 'react';
import { useAccountJournal } from '../hooks/useAccountJournal';
import { RiRefreshFill, RiArrowUpDownLine } from 'react-icons/ri';
import AccountDetailsModal from './TradingJournalModal';

const TradingJournal: React.FC = () => {
  const { fetchAccountJournal, fetchAndInsertHistory, fetchAndInsertPosition } = useAccountJournal();
  const [journalData, setJournalData] = useState<any[]>([]);
  const [accountType, setAccountType] = useState<'All' | 'LIVE' | 'DEMO'>('All');
  const [sortField, setSortField] = useState<'balance' | 'margin_free' | 'credit' | 'equity'>('balance');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Modal State for user account detail journal
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchAccountJournal();
        setJournalData(data);
      } catch (err: any) {
        setError(err.message || 'Error fetching trading journal data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchAccountJournal]);

  const openModal = async (accountNumber: string) => {
    console.log('Opening modal for account:', accountNumber);
      try {
        setLoading(true);
        // Trigger fetch-and-insert endpoints
        await Promise.all([
          fetchAndInsertHistory(accountNumber),
          fetchAndInsertPosition(accountNumber),
        ]);
      } catch (error) {
          console.error('Error preparing account data:', error);
      } finally {
          setLoading(false);
          setSelectedAccount(accountNumber);
          setModalOpen(true);
      }
    };  

  const closeModal = () => {
    setSelectedAccount(null);
    setModalOpen(false);
  };

  // Filtered and sorted data
  const filteredData = useMemo(() => {
    let filtered = journalData.filter((entry) => {
      const matchesSearch =
        entry.subUserName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.subUserEmail?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.subAccountType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.subAccountNumber?.toLowerCase().includes(searchQuery.toLowerCase());

      const matchesAccountType =
        accountType === 'All' || entry.subAccountType === accountType;

      return matchesSearch && matchesAccountType;
    });

    // Sort the filtered data
    filtered.sort((a, b) => {
      const valA = parseFloat(a[sortField]) || 0;
      const valB = parseFloat(b[sortField]) || 0;

      return sortOrder === 'asc' ? valA - valB : valB - valA;
    });

    return filtered.slice(0, rowsPerPage);
  }, [journalData, searchQuery, accountType, sortField, sortOrder, rowsPerPage]);

  // Function to load more rows
  const handleLoadMore = () => {
    setRowsPerPage((prev) => prev + 20);
  };

  // Toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  if (loading) {
    return (
      <div className="p-8 border-dashed border-4 border-cyan-800 border-opacity-50 rounded-lg">
        <span className="text-center font-semibold text-cyan-800">
          Loading trading journal data...
        </span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 border rounded">
        <p className="border-dashed border-4 border-cyan-800 text-rose-600 text-center">
          Error: {error}
        </p>
      </div>
    );
  }

  return (
    <div className="p-4 border rounded">
      {/* Header Section */}
      <div className="flex justify-end py-2 space-x-4">
        {/* Radio Buttons for Account Type Filter */}
        <div className="flex items-center space-x-2">
          <label>
            <input
              type="radio"
              name="accountType"
              value="All"
              checked={accountType === 'All'}
              onChange={() => setAccountType('All')}
              className="mr-1 accent-cyan-800"
            />
            All
          </label>
          <label>
            <input
              type="radio"
              name="accountType"
              value="LIVE"
              checked={accountType === 'LIVE'}
              onChange={() => setAccountType('LIVE')}
              className="mr-1 accent-cyan-800"
            />
            Live
          </label>
          <label>
            <input
              type="radio"
              name="accountType"
              value="DEMO"
              checked={accountType === 'DEMO'}
              onChange={() => setAccountType('DEMO')}
              className="mr-1 accent-cyan-800"
            />
            Demo
          </label>
        </div>

        <div className="border-r border-cyan-800 border-opacity-80"></div>

        {/* Dropdown and Sorting Button */}
        <div className="flex items-center space-x-2">
          <select
            value={sortField}
            onChange={(e) => setSortField(e.target.value as any)}
            className="border border-gray-300 rounded px-2 py-1 text-sm"
          >
            <option value="balance">Balance</option>
            <option value="margin_free">Margin</option>
            <option value="credit">Credit</option>
            <option value="equity">Equity</option>
          </select>
          <button onClick={toggleSortOrder} className="text-lg text-cyan-800">
            <RiArrowUpDownLine />
          </button>
        </div>

        <div className="border-r border-cyan-800 border-opacity-80"></div>

        {/* Search Bar */}
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border border-gray-300 rounded px-2 py-1 text-sm w-[250px]"
        />
        
        {/* Refresh Button */}
        <button
          className="text-lg text-cyan-800"
          onClick={() => {
            setAccountType('All');
            setSortField('balance');
            setSortOrder('asc'); 
            setSearchQuery(''); 
            setRowsPerPage(20); 
          }}
        >
          <RiRefreshFill />
        </button>
      </div>

      {/* Journal Table */}
      <div className="overflow-x-auto max-h-[380px] overflow-y-scroll">
        <table className="min-w-full bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              <th className="py-2 px-4 border-b text-nowrap">ID</th>
              <th className="py-2 px-4 border-b text-nowrap">Username</th>
              <th className="py-2 px-4 border-b text-nowrap">Email</th>
              <th className="py-2 px-4 border-b text-nowrap">Account Type</th>
              <th className="py-2 px-4 border-b text-nowrap">Account Number</th>
              <th className="py-2 px-4 border-b text-nowrap">Balance</th>
              <th className="py-2 px-4 border-b text-nowrap">Margin</th>
              <th className="py-2 px-4 border-b text-nowrap">Credit</th>
              <th className="py-2 px-4 border-b text-nowrap">Equity</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((entry, index) => (
                <tr key={index} className="cursor-pointer hover:bg-gray-100">
                  <td className="py-2 px-4 border-b text-center">{entry.id}</td>
                  <td className="py-2 px-4 border-b text-center">{entry.subUserName}</td>
                  <td className="py-2 px-4 border-b text-center">{entry.subUserEmail}</td>
                  <td className="py-2 px-4 border-b text-center">{entry.subAccountType}</td>
                  <td
                    className="py-2 px-4 border-b text-center text-cyan-800 cursor-pointer"
                    onClick={() => openModal(entry.subAccountNumber)}
                  >
                    {entry.subAccountNumber}
                  </td>
                  <td className="py-2 px-4 border-b text-center">{entry.balance}</td>
                  <td className="py-2 px-4 border-b text-center">{entry.margin_free}</td>
                  <td className="py-2 px-4 border-b text-center">{entry.credit}</td>
                  <td className="py-2 px-4 border-b text-center">{entry.equity}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center py-8">
                  No trading journal data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Load More Button */}
      {filteredData.length < journalData.length && (
        <div className="flex justify-center mt-2">
          <button
            onClick={handleLoadMore}
            className="px-4 py-1 bg-cyan-800 text-white rounded hover:bg-cyan-900"
          >
            Load More
          </button>
        </div>
      )}

      {/* Modal open for detail journal account detail */}
      <AccountDetailsModal
        accountNumber={selectedAccount}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default TradingJournal;


// // udpate code --> 24 dec 2024
// // src/components/TradingJournal.tsx

// import React, { useEffect, useState, useMemo } from 'react';
// import { useAccountJournal } from '../hooks/useAccountJournal';
// import { RiRefreshFill, RiArrowUpDownLine } from 'react-icons/ri';

// const TradingJournal: React.FC = () => {
//   const { fetchAccountJournal } = useAccountJournal();
//   const [journalData, setJournalData] = useState<any[]>([]);
//   const [accountType, setAccountType] = useState<'All' | 'LIVE' | 'DEMO'>('All');
//   const [sortField, setSortField] = useState<'balance' | 'margin_free' | 'credit' | 'equity'>('balance');
//   const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
//   const [searchQuery, setSearchQuery] = useState('');
//   const [rowsPerPage, setRowsPerPage] = useState(20);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const data = await fetchAccountJournal();
//         setJournalData(data);
//       } catch (err: any) {
//         setError(err.message || 'Error fetching trading journal data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [fetchAccountJournal]);

//   // Filtered and sorted data
//   const filteredData = useMemo(() => {
//     let filtered = journalData.filter((entry) => {
//       const matchesSearch =
//         entry.subUserName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         entry.subUserEmail?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         entry.subAccountType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         entry.subAccountNumber?.toLowerCase().includes(searchQuery.toLowerCase());

//       const matchesAccountType =
//         accountType === 'All' || entry.subAccountType === accountType;

//       return matchesSearch && matchesAccountType;
//     });

//     // Sort the filtered data
//     filtered.sort((a, b) => {
//       const valA = parseFloat(a[sortField]) || 0;
//       const valB = parseFloat(b[sortField]) || 0;

//       return sortOrder === 'asc' ? valA - valB : valB - valA;
//     });

//     return filtered.slice(0, rowsPerPage);
//   }, [journalData, searchQuery, accountType, sortField, sortOrder, rowsPerPage]);

//   // Function to load more rows
//   const handleLoadMore = () => {
//     setRowsPerPage((prev) => prev + 20);
//   };

//   // Toggle sorting order
//   const toggleSortOrder = () => {
//     setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
//   };

//   if (loading) return <p>Loading trading journal data...</p>;
//   if (error) return <p>Error: {error}</p>;

//   return (
//     <div className="p-4 border rounded">
//       {/* Header Section */}
//       <div className="flex justify-end py-2 space-x-4">
//         {/* Radio Buttons for Account Type Filter */}
//         <div className="flex items-center space-x-2">
//           <label>
//             <input
//               type="radio"
//               name="accountType"
//               value="All"
//               checked={accountType === 'All'}
//               onChange={() => setAccountType('All')}
//               className="mr-1 accent-cyan-800"
//             />
//             All
//           </label>
//           <label>
//             <input
//               type="radio"
//               name="accountType"
//               value="LIVE"
//               checked={accountType === 'LIVE'}
//               onChange={() => setAccountType('LIVE')}
//               className="mr-1 accent-cyan-800"
//             />
//             Live
//           </label>
//           <label>
//             <input
//               type="radio"
//               name="accountType"
//               value="DEMO"
//               checked={accountType === 'DEMO'}
//               onChange={() => setAccountType('DEMO')}
//               className="mr-1 accent-cyan-800"
//             />
//             Demo
//           </label>
//         </div>

//         <div className="border-r border-cyan-800 border-opacity-80"></div>

//         {/* Dropdown and Sorting Button */}
//         <div className="flex items-center space-x-2">
//           <select
//             value={sortField}
//             onChange={(e) => setSortField(e.target.value as any)}
//             className="border border-gray-300 rounded px-2 py-1 text-sm"
//           >
//             <option value="balance">Balance</option>
//             <option value="margin_free">Margin</option>
//             <option value="credit">Credit</option>
//             <option value="equity">Equity</option>
//           </select>
//           <button onClick={toggleSortOrder} className="text-lg text-cyan-800">
//             <RiArrowUpDownLine />
//           </button>
//         </div>

//         <div className="border-r border-cyan-800 border-opacity-80"></div>

//         {/* Search Bar */}
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//           className="border border-gray-300 rounded px-2 py-1 text-sm w-[250px]"
//         />

//         {/* Refresh Button */}
//         <button
//           className="text-lg text-cyan-800"
//           onClick={() => {
//             setAccountType('All');
//             setSortField('balance');
//             setSortOrder('asc'); 
//             setSearchQuery(''); 
//             setRowsPerPage(20); 
//           }}
//         >
//           <RiRefreshFill />
//         </button>
//       </div>

//       {/* Journal Table */}
//       <div className="overflow-x-auto max-h-[380px] overflow-y-scroll">
//         <table className="min-w-full bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               <th className="py-2 px-4 border-b text-nowrap">ID</th>
//               <th className="py-2 px-4 border-b text-nowrap">Username</th>
//               <th className="py-2 px-4 border-b text-nowrap">Email</th>
//               <th className="py-2 px-4 border-b text-nowrap">Account Type</th>
//               <th className="py-2 px-4 border-b text-nowrap">Account Number</th>
//               <th className="py-2 px-4 border-b text-nowrap">Balance</th>
//               <th className="py-2 px-4 border-b text-nowrap">Margin</th>
//               <th className="py-2 px-4 border-b text-nowrap">Credit</th>
//               <th className="py-2 px-4 border-b text-nowrap">Equity</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.length > 0 ? (
//               filteredData.map((entry, index) => (
//                 <tr key={index} className="cursor-pointer hover:bg-gray-100">
//                   <td className="py-2 px-4 border-b text-center">{entry.id}</td>
//                   <td className="py-2 px-4 border-b text-center">{entry.subUserName}</td>
//                   <td className="py-2 px-4 border-b text-center">{entry.subUserEmail}</td>
//                   <td className="py-2 px-4 border-b text-center">{entry.subAccountType}</td>
//                   <td className="py-2 px-4 border-b text-center">{entry.subAccountNumber}</td>
//                   <td className="py-2 px-4 border-b text-center">{entry.balance}</td>
//                   <td className="py-2 px-4 border-b text-center">{entry.margin_free}</td>
//                   <td className="py-2 px-4 border-b text-center">{entry.credit}</td>
//                   <td className="py-2 px-4 border-b text-center">{entry.equity}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={9} className="text-center py-8">
//                   No trading journal data available.
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Load More Button */}
//       {filteredData.length < journalData.length && (
//         <div className="flex justify-center mt-2">
//           <button
//             onClick={handleLoadMore}
//             className="px-4 py-1 bg-cyan-800 text-white rounded hover:bg-cyan-900"
//           >
//             Load More
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TradingJournal;


