// src/contexts/TermsAndConditions.tsx

import React from 'react';

interface TermsAndConditionsProps {
  onClose: () => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ onClose }) => {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="bg-white rounded-lg p-20 relative w-auto max-w-[800px] max-h-screen">
        <h2 className="text-2xl font-bold mb-4" id="modal-title">
          Terms and Conditions
        </h2>
        <div className="overflow-y-auto h-auto max-h-[800px] flex-grow">
          {/* Section 1: Introduction */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">1. Introduction</h3>
            <p className="mx-2">
              <strong>1.1 Program Overview:</strong><br />
              The [Your Program Name] Funded Trading Program is designed to provide qualified traders with access to trading capital under specific terms and conditions. The Program aims to support traders in developing their skills and achieving consistent trading performance.
            </p>
            <p className="mx-2">
              <strong>1.2 Acceptance of Terms:</strong><br />
              By registering for the Program, you ("Participant" or "You") agree to abide by these Terms, as well as any additional rules and guidelines provided by [Your Company Name] ("Provider," "We," "Us," or "Our").
            </p>
          </section>

          {/* Section 2: Eligibility */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">2. Eligibility</h3>
            <p className="mx-2">
              <strong>2.1 Age Requirement:</strong><br />
              Participants must be at least [insert age, e.g., 18] years old.
            </p>
            <p className="mx-2">
              <strong>2.2 Legal Capacity:</strong><br />
              Participants must have the legal capacity to enter into these Terms and engage in trading activities within their jurisdiction.
            </p>
            <p className="mx-2">
              <strong>2.3 Geographical Restrictions:</strong><br />
              The Program is available to residents of [specify allowed countries]. Participants from restricted jurisdictions are not eligible to apply.
            </p>
          </section>

          {/* Section 3: Application and Registration */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">3. Application and Registration</h3>
            <p className="mx-2">
              <strong>3.1 Application Process:</strong><br />
              Participants must complete the online registration form, providing accurate and complete information. Incomplete or misleading applications may be rejected.
            </p>
            <p className="mx-2">
              <strong>3.2 Verification:</strong><br />
              Participants may be required to undergo identity verification and provide additional documentation as part of the registration process.
            </p>
            <p className="mx-2">
              <strong>3.3 Acceptance:</strong><br />
              Submission of an application does not guarantee acceptance into the Program. Acceptance is at the sole discretion of the Provider.
            </p>
          </section>

          {/* Section 4: Fees and Payments */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">4. Fees and Payments</h3>
            <p className="mx-2">
              <strong>4.1 Registration Fees:</strong><br />
              Participation in the Program may require payment of registration fees as outlined on our website. All fees are non-refundable unless explicitly stated otherwise.
            </p>
            <p className="mx-2">
              <strong>4.2 Payment Methods:</strong><br />
              Fees must be paid using the approved payment methods specified during the registration process.
            </p>
            <p className="mx-2">
              <strong>4.3 Additional Costs:</strong><br />
              Participants are responsible for any additional costs incurred, such as internet fees or software subscriptions, unless otherwise stated.
            </p>
          </section>

          {/* Section 5: Evaluation Process */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">5. Evaluation Process</h3>
            <p className="mx-2">
              <strong>5.1 Evaluation Phase:</strong><br />
              Participants must undergo an evaluation phase to assess their trading skills and adherence to Program rules. Details of the evaluation criteria are provided in the Program guidelines.
            </p>
            <p className="mx-2">
              <strong>5.2 Performance Metrics:</strong><br />
              Evaluation may include achieving specific profit targets, maintaining drawdown limits, and following risk management protocols.
            </p>
            <p className="mx-2">
              <strong>5.3 Duration:</strong><br />
              The evaluation period lasts for [insert duration, e.g., 30 days], during which participants must meet the required performance standards.
            </p>
            <p className="mx-2">
              <strong>5.4 Outcome:</strong><br />
              Successful completion of the evaluation phase may lead to allocation of trading capital. Failure to meet the criteria may result in disqualification.
            </p>
          </section>

          {/* Section 6: Trading Rules and Guidelines */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">6. Trading Rules and Guidelines</h3>
            <p className="mx-2">
              <strong>6.1 Allowed Instruments:</strong><br />
              Participants may trade the following financial instruments: [list allowed instruments, e.g., Forex, Stocks, Commodities, Cryptocurrencies].
            </p>
            <p className="mx-2">
              <strong>6.2 Leverage and Position Sizing:</strong><br />
              Trading leverage and position sizes must comply with the Program’s risk management policies as detailed in the guidelines.
            </p>
            <p className="mx-2">
              <strong>6.3 Profit Targets:</strong><br />
              Participants must achieve a minimum profit target of [insert percentage, e.g., 10%] during the evaluation phase to qualify for funding.
            </p>
            <p className="mx-2">
              <strong>6.4 Drawdown Limits:</strong><br />
              Maximum allowable drawdown is set at [insert percentage, e.g., 5%]. Exceeding this limit may lead to immediate disqualification.
            </p>
            <p className="mx-2">
              <strong>6.5 Trading Hours:</strong><br />
              Participants must conduct trades within the designated trading hours: [specify if applicable].
            </p>
            <p className="mx-2">
              <strong>6.6 Prohibited Activities:</strong><br />
              Any form of market manipulation, use of automated trading systems without prior approval, or violation of trading rules will result in disqualification.
            </p>
          </section>

          {/* Section 7: Funding and Capital Allocation */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">7. Funding and Capital Allocation</h3>
            <p className="mx-2">
              <strong>7.1 Allocated Capital:</strong><br />
              Upon successful evaluation, participants will receive trading capital of [insert amount, e.g., $50,000].
            </p>
            <p className="mx-2">
              <strong>7.2 Use of Funds:</strong><br />
              Allocated funds must be used solely for trading within the Program’s permitted instruments and according to the established guidelines.
            </p>
            <p className="mx-2">
              <strong>7.3 Profit Sharing:</strong><br />
              Profits generated from trading will be shared as follows: [insert profit split, e.g., 80% to Participant, 20% to Provider].
            </p>
            <p className="mx-2">
              <strong>7.4 Loss Responsibility:</strong><br />
              Participants are responsible for all trading losses. The Provider does not cover any losses incurred.
            </p>
          </section>

          {/* Section 8: Profit Withdrawal and Payouts */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">8. Profit Withdrawal and Payouts</h3>
            <p className="mx-2">
              <strong>8.1 Withdrawal Requests:</strong><br />
              Participants may request withdrawal of profits once a minimum profit threshold of [insert amount or percentage] is achieved.
            </p>
            <p className="mx-2">
              <strong>8.2 Processing Time:</strong><br />
              Withdrawal requests will be processed within [insert time frame, e.g., 7 business days].
            </p>
            <p className="mx-2">
              <strong>8.3 Payment Methods:</strong><br />
              Payouts will be made via [specify payment methods, e.g., bank transfer, PayPal].
            </p>
            <p className="mx-2">
              <strong>8.4 Withdrawal Limits:</strong><br />
              There may be limits on the frequency and amount of withdrawals, as detailed in the Program guidelines.
            </p>
          </section>

          {/* Section 9: Risk Management */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">9. Risk Management</h3>
            <p className="mx-2">
              <strong>9.1 Understanding Risks:</strong><br />
              Trading in financial markets involves significant risk. Participants may incur losses exceeding their initial investment.
            </p>
            <p className="mx-2">
              <strong>9.2 Responsibility:</strong><br />
              Participants must fully understand and accept the risks associated with trading before participating in the Program.
            </p>
            <p className="mx-2">
              <strong>9.3 Risk Mitigation:</strong><br />
              Adherence to the Program’s risk management rules is mandatory to maintain eligibility for funding.
            </p>
          </section>

          {/* Section 10: Compliance with Rules */}
          <section className="mb-4">
            <h3 className="text-xl font-semibold">10. Compliance with Rules</h3>
            <p className="mx-2">
              <strong>10.1 Adherence to Guidelines:</strong><br />
              Participants must comply with all Program rules, including profit targets, drawdown limits, and trading conditions.
            </p>
            <p className="mx-2">
              <strong>10.2 Monitoring:</strong><br />
              The Provider reserves the right to monitor all trading activities to ensure compliance.
            </p>
            <p className="mx-2">
              <strong>10.3 Disciplinary Actions:</strong><br />
              Non-compliance may result in warnings, suspension, or permanent disqualification from the Program.
            </p>
          </section>
        </div>

        {/* Close Button */}
        <div className="flex justify-end mt-4">
          <button
            className="bg-cyan-800 text-white text-xl font-bold rounded-full px-8 py-2"
            onClick={onClose}
            aria-label="Close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
