// src/hooks/useFormValidation.tsx

import { useFormik } from 'formik';
import * as Yup from 'yup';

const stateOptions = [
  { label: 'Johor', value:'Johor' },
  { label: 'Kedah', value:'Kedah' },
  { label: 'Kelantan', value:'Kelantan' },
  { label: 'Melacca', value:'Melacca' },
  { label: 'Negeri Sembilan', value:'Negeri Sembilan' },
  { label: 'Pahang', value:'Pahang' },
  { label: 'Penang', value:'Penang' },
  { label: 'Perak', value:'Perak' },
  { label: 'Perlis', value:'Perlis' }, 
  { label: 'Selangor', value:'Selangor' },
  { label: 'Sabah', value:'Sabah' },
  { label: 'Sarawak', value:'Sarawak' },
  { label: 'Terengganu', value:'Terengganu' },
  { label: 'Federal Territory of Kuala Lumpur', value:'Kuala Lumpur' },
  { label: 'Federal Territory of Labuan', value:'Labuan' },
  { label: 'Federal Territory of Putrajaya', value:'Putrajaya' },
  { label: 'Out of Malaysia', value:'other' },
];

const countryOptions = [
  { label: 'Malaysia', value: 'MY' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Japan', value: 'JP' },
  { label: 'South Korea', value: 'KR' },
  { label: 'China', value: 'CN' },
  { label: 'India', value: 'IN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'CA' },
  { label: 'United Kingdom', value: 'UK' },
  { label: 'Australia', value: 'AU' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Germany', value: 'DE' },
  { label: 'France', value: 'FR' },
  { label: 'Italy', value: 'IT' },
  { label: 'Spain', value: 'ES' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Austria', value: 'AT' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Norway', value: 'NO' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Finland', value: 'FI' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Greece', value: 'GR' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Argentina', value: 'AR' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'Russia', value: 'RU' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'United Arab Emirates', value: 'AE' },
  // Add more countries as needed
];

const phoneOptions = [
  { label: '+60 (Malaysia)', value: '+60' },
  { label: '+65 (Singapore)', value: '+65' },
  { label: '+81 (Japan)', value: '+81' },
  { label: '+82 (South Korea)', value: '+82' },
  { label: '+86 (China)', value: '+86' },
  { label: '+852 (Hong Kong)', value: '+852' },
  { label: '+886 (Taiwan)', value: '+886' },
  { label: '+66 (Thailand)', value: '+66' },
  { label: '+63 (Philippines)', value: '+63' },
  { label: '+62 (Indonesia)', value: '+62' },
  { label: '+84 (Vietnam)', value: '+84' },
  { label: '+91 (India)', value: '+91' },
  { label: '+1 (USA & Canada)', value: '+1' },
  { label: '+44 (UK)', value: '+44' },
  { label: '+61 (Australia)', value: '+61' },
  { label: '+64 (New Zealand)', value: '+64' },
  { label: '+49 (Germany)', value: '+49' },
  { label: '+33 (France)', value: '+33' },
  { label: '+39 (Italy)', value: '+39' },
  { label: '+34 (Spain)', value: '+34' },
  { label: '+31 (Netherlands)', value: '+31' },
  { label: '+32 (Belgium)', value: '+32' },
  { label: '+41 (Switzerland)', value: '+41' },
  { label: '+43 (Austria)', value: '+43' },
  { label: '+46 (Sweden)', value: '+46' },
  { label: '+47 (Norway)', value: '+47' },
  { label: '+45 (Denmark)', value: '+45' },
  { label: '+358 (Finland)', value: '+358' },
  { label: '+353 (Ireland)', value: '+353' },
  { label: '+30 (Greece)', value: '+30' },
  { label: '+351 (Portugal)', value: '+351' },
  { label: '+55 (Brazil)', value: '+55' },
  { label: '+52 (Mexico)', value: '+52' },
  { label: '+54 (Argentina)', value: '+54' },
  { label: '+27 (South Africa)', value: '+27' },
  { label: '+7 (Russia)', value: '+7' },
  { label: '+90 (Turkey)', value: '+90' },
  { label: '+966 (Saudi Arabia)', value: '+966' },
  { label: '+971 (United Arab Emirates)', value: '+971' },
  // Add more options as needed
];

const assistantCoachesOptions = {
  KL: [
    { name: 'Jordan', contact: '+60125874931' },
    { name: 'Hunter', contact: '+60163378098' },
    { name: 'Denise', contact: '+601139348278' },
    { name: 'Zeric', contact: '+60173505884' },
  ],
  Pg: [
    { name: 'Luc', contact: '+60187889587' },
    { name: 'Richard', contact: '+60175410383' },
  ],
  Sabah: [
    { name: 'Mien', contact: '+60168320532' },
    { name: 'Kenny', contact: '+60149513775' },
  ],
};

export const useFormValidation = (onClose: () => void) => {
  return useFormik({
    initialValues: {
      memberType: '',
      email: '',
      gender: '', 
      name: '',
      icNo: '',
      contactNumber: '',
      countryCode: '',
      country: '',
      state:'',
      address: '',
      password: '',
      confirmPassword: '',
      telegramId: '',
      discordId: '',
      day: '',
      month: '',
      year: '',
      dob: '',
    },
    validationSchema: Yup.object({
      memberType: Yup.string().required('Please select your member type'),
      email: Yup.string().email('Invalid email address').required('Please enter your email'),
      gender: Yup.string().required('Please select your gender'), 
      name: Yup.string().required('Please enter your name'),
      icNo: Yup.string()
        .required('Please enter your identity no.'),
      contactNumber: Yup.string().required('Please enter your contact number'),
      countryCode: Yup.string()
        .oneOf(phoneOptions.map(option => option.value), 'Must select a country code')
        .required('Please enter your country code'),
      country: Yup.string().required('Please select your country').notOneOf([''], 'Must select a country'), 
      state: Yup.string().required('Please select your state').notOneOf([''], 'Must select a state'), 
      address: Yup.string().required('Please enter your address'),
      password: Yup.string()
        .matches(/[A-Za-z]/, 'Password must contain at least one letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[~!@#$%^&*()\-_={}[\];:'",<.>/?]/, 'Password must contain at least one symbol')
        .min(8, 'Password must be at least 8 characters')
        .required('Please enter your password'),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), undefined], 'Passwords must match').required('Please confirm your password'),
      telegramId: Yup.string().nullable(),
      discordId: Yup.string().nullable(),
      day: Yup.number().required('Please select a valid day'),
      month: Yup.number().required('Please select a valid month'),
      year: Yup.number().required('Please select a valid year'),
    }),
    onSubmit: (values) => {
      const dob = `${values.year}-${String(values.month).padStart(2, '0')}-${String(values.day).padStart(2, '0')}`;
      values.dob = dob; 
      console.log('Submitted Values:', values); 
      alert('Registration Successful!');
      onClose();
    },
  });
};

export { countryOptions, phoneOptions, stateOptions, assistantCoachesOptions };

// // udpate code --> 09 dec 2024
// // src/hooks/useFormValidation.tsx

// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// const stateOptions = [
//   { label: 'Johor', value:'Johor' },
//   { label: 'Kedah', value:'Kedah' },
//   { label: 'Kelantan', value:'Kelantan' },
//   { label: 'Melacca', value:'Melacca' },
//   { label: 'Negeri Sembilan', value:'Negeri Sembilan' },
//   { label: 'Pahang', value:'Pahang' },
//   { label: 'Penang', value:'Penang' },
//   { label: 'Perak', value:'Perak' },
//   { label: 'Perlis', value:'Perlis' }, 
//   { label: 'Selangor', value:'Selangor' },
//   { label: 'Sabah', value:'Sabah' },
//   { label: 'Sarawak', value:'Sarawak' },
//   { label: 'Terengganu', value:'Terengganu' },
//   { label: 'Federal Territory of Kuala Lumpur', value:'Kuala Lumpur' },
//   { label: 'Federal Territory of Labuan', value:'Labuan' },
//   { label: 'Federal Territory of Putrajaya', value:'Putrajaya' },
//   { label: 'Out of Malaysia', value:'other' },
// ];

// const countryOptions = [
//   { label: 'Malaysia', value: 'MY' },
//   { label: 'Singapore', value: 'SG' },
//   { label: 'Japan', value: 'JP' },
//   { label: 'South Korea', value: 'KR' },
//   { label: 'China', value: 'CN' },
//   { label: 'India', value: 'IN' },
//   { label: 'Hong Kong', value: 'HK' },
//   { label: 'Taiwan', value: 'TW' },
//   { label: 'Thailand', value: 'TH' },
//   { label: 'Philippines', value: 'PH' },
//   { label: 'Indonesia', value: 'ID' },
//   { label: 'Vietnam', value: 'VN' },
//   { label: 'United States', value: 'US' },
//   { label: 'Canada', value: 'CA' },
//   { label: 'United Kingdom', value: 'UK' },
//   { label: 'Australia', value: 'AU' },
//   { label: 'New Zealand', value: 'NZ' },
//   { label: 'Germany', value: 'DE' },
//   { label: 'France', value: 'FR' },
//   { label: 'Italy', value: 'IT' },
//   { label: 'Spain', value: 'ES' },
//   { label: 'Netherlands', value: 'NL' },
//   { label: 'Belgium', value: 'BE' },
//   { label: 'Switzerland', value: 'CH' },
//   { label: 'Austria', value: 'AT' },
//   { label: 'Sweden', value: 'SE' },
//   { label: 'Norway', value: 'NO' },
//   { label: 'Denmark', value: 'DK' },
//   { label: 'Finland', value: 'FI' },
//   { label: 'Ireland', value: 'IE' },
//   { label: 'Greece', value: 'GR' },
//   { label: 'Portugal', value: 'PT' },
//   { label: 'Brazil', value: 'BR' },
//   { label: 'Mexico', value: 'MX' },
//   { label: 'Argentina', value: 'AR' },
//   { label: 'South Africa', value: 'ZA' },
//   { label: 'Russia', value: 'RU' },
//   { label: 'Turkey', value: 'TR' },
//   { label: 'Saudi Arabia', value: 'SA' },
//   { label: 'United Arab Emirates', value: 'AE' },
//   // Add more countries as needed
// ];

// const phoneOptions = [
//   { label: '+60 (Malaysia)', value: '+60' },
//   { label: '+65 (Singapore)', value: '+65' },
//   { label: '+81 (Japan)', value: '+81' },
//   { label: '+82 (South Korea)', value: '+82' },
//   { label: '+86 (China)', value: '+86' },
//   { label: '+852 (Hong Kong)', value: '+852' },
//   { label: '+886 (Taiwan)', value: '+886' },
//   { label: '+66 (Thailand)', value: '+66' },
//   { label: '+63 (Philippines)', value: '+63' },
//   { label: '+62 (Indonesia)', value: '+62' },
//   { label: '+84 (Vietnam)', value: '+84' },
//   { label: '+91 (India)', value: '+91' },
//   { label: '+1 (USA & Canada)', value: '+1' },
//   { label: '+44 (UK)', value: '+44' },
//   { label: '+61 (Australia)', value: '+61' },
//   { label: '+64 (New Zealand)', value: '+64' },
//   { label: '+49 (Germany)', value: '+49' },
//   { label: '+33 (France)', value: '+33' },
//   { label: '+39 (Italy)', value: '+39' },
//   { label: '+34 (Spain)', value: '+34' },
//   { label: '+31 (Netherlands)', value: '+31' },
//   { label: '+32 (Belgium)', value: '+32' },
//   { label: '+41 (Switzerland)', value: '+41' },
//   { label: '+43 (Austria)', value: '+43' },
//   { label: '+46 (Sweden)', value: '+46' },
//   { label: '+47 (Norway)', value: '+47' },
//   { label: '+45 (Denmark)', value: '+45' },
//   { label: '+358 (Finland)', value: '+358' },
//   { label: '+353 (Ireland)', value: '+353' },
//   { label: '+30 (Greece)', value: '+30' },
//   { label: '+351 (Portugal)', value: '+351' },
//   { label: '+55 (Brazil)', value: '+55' },
//   { label: '+52 (Mexico)', value: '+52' },
//   { label: '+54 (Argentina)', value: '+54' },
//   { label: '+27 (South Africa)', value: '+27' },
//   { label: '+7 (Russia)', value: '+7' },
//   { label: '+90 (Turkey)', value: '+90' },
//   { label: '+966 (Saudi Arabia)', value: '+966' },
//   { label: '+971 (United Arab Emirates)', value: '+971' },
//   // Add more options as needed
// ];

// const assistantCoachesOptions = {
//   KL: [
//     { name: 'Jordan', contact: '+60125874931' },
//     { name: 'Hunter', contact: '+60163378098' },
//     { name: 'Denise', contact: '+601139348278' },
//     { name: 'Zeric', contact: '+60173505884' },
//   ],
//   Pg: [
//     { name: 'Luc', contact: '+60187889587' },
//     { name: 'Richard', contact: '+60175410383' },
//   ],
//   Sabah: [
//     { name: 'Mien', contact: '+60168320532' },
//     { name: 'Kenny', contact: '+60149513775' },
//   ],
// };

// export const useFormValidation = (onClose: () => void) => {
//   return useFormik({
//     initialValues: {
//       memberType: '',
//       email: '',
//       gender: '', 
//       name: '',
//       icNo: '',
//       contactNumber: '',
//       dob: '',
//       countryCode: '',
//       country: '',
//       state:'',
//       address: '',
//       password: '',
//       confirmPassword: ''
//     },
//     validationSchema: Yup.object({
//       memberType: Yup.string().required('Please select your member type'),
//       email: Yup.string().email('Invalid email address').required('Please enter your email'),
//       gender: Yup.string().required('Please select your gender'), 
//       name: Yup.string().required('Please enter your name'),
//       icNo: Yup.string()
//         .required('Please enter your identity no.'),
//       contactNumber: Yup.string().required('Please enter your contact number'),
//       dob: Yup.date().required('Please select your date of birth'),
//       countryCode: Yup.string()
//         .oneOf(phoneOptions.map(option => option.value), 'Must select a country code')
//         .required('Please enter your country code'),
//       country: Yup.string().required('Please select your country').notOneOf([''], 'Must select a country'), 
//       state: Yup.string().required('Please select your state').notOneOf([''], 'Must select a state'), 
//       address: Yup.string().required('Please enter your address'),
//       password: Yup.string()
//         .matches(/[A-Za-z]/, 'Password must contain at least one letter')
//         .matches(/\d/, 'Password must contain at least one number')
//         .matches(/[~!@#$%^&*()\-_={}[\];:'",<.>/?]/, 'Password must contain at least one symbol')
//         .min(8, 'Password must be at least 8 characters')
//         .required('Please enter your password'),
//       confirmPassword: Yup.string().oneOf([Yup.ref('password'), undefined], 'Passwords must match').required('Please confirm your password')
//     }),
//     onSubmit: values => {
//       alert('Password setup successful. Please check your email.');
//       onClose();
//     }
//   });
// };

// export { countryOptions, phoneOptions, stateOptions, assistantCoachesOptions };




// // update code --> 06 june 2024
// // src/hooks/useFormValidation.tsx

// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// const countryOptions = [
//   { label: 'United States', value: 'US' },
//   { label: 'United Kingdom', value: 'UK' },
//   { label: 'Singapore', value: 'SG' },
//   { label: 'Malaysia', value: 'MY' },
//   // Add more countries as needed
// ];

// const phoneOptions = [
//   { label: '+1 (USA)', value: '+1' },
//   { label: '+44 (UK)', value: '+44' },
//   { label: '+65 (SG)', value: '+65' },
//   { label: '+60 (MY)', value: '+60' },
//   // Add more options as needed
// ];

// export const useFormValidation = (onClose: () => void) => {
//   return useFormik({
//     initialValues: {
//       memberType: '',
//       email: '',
//       name: '',
//       contactNumber: '',
//       dob: '',
//       countryCode:'',
//       country: '',
//       address: '',
//       password: '',
//       confirmPassword: ''
//     },
//     validationSchema: Yup.object({
//       memberType: Yup.string().required('Please select your member type'),
//       email: Yup.string().email('Invalid email address').required('Please enter your email'),
//       name: Yup.string().required('Please enter your name'),
//       contactNumber: Yup.string().required('Please enter your contact number'),
//       dob: Yup.date().required('Please select your date of birth'),
//       country: Yup.string().required('Please select your country'),
//       address: Yup.string().required('Please enter your address'),
//       password: Yup.string().min(6, 'Password must be at least 6 characters').required('Please enter your password'),
//       confirmPassword: Yup.string().oneOf([Yup.ref('password'), undefined], 'Passwords must match').required('Please confirm your password')
//     }),
//     onSubmit: values => {
//       alert('Password setup successful. Please check your email.');
//       onClose();
//     }
//   });
// };

// export { countryOptions, phoneOptions };


// // // udpate code --> 30 May 2024
// // // src/hooks/useFormValidation.tsx

// // import { useFormik } from 'formik';
// // import * as Yup from 'yup';

// // const countryOptions = [
// //   { label: 'United States', value: 'US' },
// //   { label: 'United Kingdom', value: 'UK' },
// //   { label: 'Singapore', value: 'SG' },
// //   { label: 'Malaysia', value: 'MY' },
// //   // Add more countries as needed
// // ];

// // const phoneOptions = [
// //   { label: '+1 (USA)', value: '+1' },
// //   { label: '+44 (UK)', value: '+44' },
// //   { label: '+65 (SG)', value: '+65' },
// //   { label: '+60 (MY)', value: '+60' },
// //   // Add more options as needed
// // ];

// // export const useFormValidation = (onClose: () => void) => {
// //   return useFormik({
// //     initialValues: {
// //       memberType: '',
// //       email: '',
// //       name: '',
// //       contactNumber: '',
// //       dob: '',
// //       country: '',
// //       address: '',
// //       password: '',
// //       confirmPassword: ''
// //     },
// //     validationSchema: Yup.object({
// //       memberType: Yup.string().required('Please select your member type'),
// //       email: Yup.string().email('Invalid email address').required('Please enter your email'),
// //       name: Yup.string().required('Please enter your name'),
// //       contactNumber: Yup.string().required('Please enter your contact number'),
// //       dob: Yup.date().required('Please select your date of birth'),
// //       country: Yup.string().required('Please select your country'),
// //       address: Yup.string().required('Please enter your address'),
// //       password: Yup.string().min(6, 'Password must be at least 6 characters').required('Please enter your password'),
// //       confirmPassword: Yup.string().oneOf([Yup.ref('password'), undefined], 'Passwords must match').required('Please confirm your password')
// //     }),
// //     onSubmit: values => {
// //       alert('Password setup successful. Please check your email.');
// //       onClose();
// //     }
// //   });
// // };

// // export { countryOptions, phoneOptions };
