// src/context/EnvContext.tsx

import React, { createContext, useContext } from 'react';

interface EnvContextProps {
  apiUrl: string;
}

const EnvContext = createContext<EnvContextProps | undefined>(undefined);

export const EnvProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const envContextValue: EnvContextProps = {
    apiUrl: process.env.REACT_APP_API_URL!
  };

  return <EnvContext.Provider value={envContextValue}>{children}</EnvContext.Provider>;
};

export const useEnv = () => {
  const context = useContext(EnvContext);
  if (!context) {
    throw new Error('useEnv must be used within an EnvProvider');
  }
  return context;
};



// // udpate code --> 14 jan 2024
// // src/context/EnvContext.tsx

// import React, { createContext, useContext } from 'react';

// interface EnvContextProps {
//   apiUrl: string;
//   apiChartUrl: string; 
// }

// const EnvContext = createContext<EnvContextProps | undefined>(undefined);

// export const EnvProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
//   const envContextValue: EnvContextProps = {
//     apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:5000', // Fallback value
//     apiChartUrl: process.env.REACT_CHART_APP_API_URL || 'http://localhost:8000', // Fallback value
//   };

//   return <EnvContext.Provider value={envContextValue}>{children}</EnvContext.Provider>;
// };

// export const useEnv = () => {
//   const context = useContext(EnvContext);
//   if (!context) {
//     throw new Error('useEnv must be used within an EnvProvider');
//   }
//   return context;
// };
