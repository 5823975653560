// src/components/client/Dashboard/UserPointsStats.tsx

import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import useFundedStatus from '../../../hooks/MiniApp/useFundedStatus';

const UserPointsStats: React.FC = () => {
  const { fundStatus } = useFundedStatus();

  return (
    // Card container
    <div className="flex flex-row w-full h-full bg-white rounded-lg shadow-md p-4 
                    sm:flex-col 
                    xs:flex-col">
      
      {/* User Lotsize */}
      <div className="flex flex-col w-full">
        <div className="flex justify-center items-center">
          <h5 className="text-lg text-cyan-800 font-semibold mx-4 
                         md:text-lg md:mx-2 
                         sm:text-xl 
                         xs:text-xl">
            Total Lots Size
          </h5>
          <div className="relative group">
            <AiOutlineInfoCircle
              className="w-4 h-4 text-gray-400 hover:text-gray-900 cursor-pointer"
              aria-hidden="true"
            />
            {/* Tool Tips */}
            <div className="text-white bg-cyan-800 absolute top-full mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white 
                            md:w-48 
                            sm:right-[5px] 
                            xs:right-[5px]">
              lotsize can be used to redeem various rewards such as attending classes and other benefits.
            </div>
          </div>
        </div>

        {/* Total lotsize */}
        <div className="border-solid border-2 rounded-xl my-2 p-8 sm:p-6 xs:p-4">
          <div className="text-cyan-800 text-6xl font-bold flex justify-center items-center">
              0
              <p className="text-lg ml-4">Lot</p>
          </div>
        </div>
      </div>

      <hr className="mx-4" />

      {/* User Stats */}
      <div className="flex flex-col w-full">
        <div className="flex justify-center items-center">
          <h5 className="text-cyan-800 text-lg font-semibold mx-4 
                           md:text-lg md:mx-2 
                           sm:mt-4 
                           xs:mt-4 xs:text-xl">
            Program Stats
          </h5>
          <div className="relative group">
            <AiOutlineInfoCircle
              className="w-4 h-4 text-gray-400 hover:text-gray-900 cursor-pointer 
                         sm:mt-4 
                         xs:mt-4"
              aria-hidden="true"
            />
            {/* Tool Tips */}
            <div className="text-white bg-cyan-800 absolute top-full mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white
                              md:w-48 md:right-0 
                              sm:right-[5px] 
                              xs:right-[5px]">
              User stats include information about the user's current program stages and funding.
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="rounded-lg bg-cyan-800 text-white my-2">
          <div className="flex flex-col text-center my-2 p-6">
            <div className="text-lg">Current Stage</div>
            <div className="font-bold text-2xl">
              {fundStatus?.fundStatus === 'Pending' 
                ? 'Not Started'
                : fundStatus?.fundEvalProgress || 'Not Started'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPointsStats;

// // udpate code --> 30 dec 2024
// // src/components/client/Dashboard/UserPointsStats.tsx

// import React, { useState } from 'react';
// import { AiOutlineInfoCircle } from 'react-icons/ai';
// import { useDummy } from '../../../context/dummy';
// import { useTheme } from '../../../context/ThemeContext';

// const UserPointsStats: React.FC = () => {
//   const { getUser } = useDummy();
//   const { theme } = useTheme();

//   // Replace with the logic to get the currently logged-in user's email
//   const user = getUser('zeric2tan@gmail.com');

//   // Set up initial program state
//   const [currentProgram, setCurrentProgram] = useState('Go4funds Program');

//   if (!user) {
//     return null; // Render nothing if no user is found
//   }

//   const totalPoints = user.totalPoints;
//   const programData = user.programs[currentProgram];
//   const programTitles = Object.keys(user.programs);
//   const currentIndex = programTitles.indexOf(currentProgram);

//   const nextProgram = () => {
//     const nextIndex = (currentIndex + 1) % programTitles.length;
//     setCurrentProgram(programTitles[nextIndex]);
//   };

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     // Card container
//     <div className="flex flex-row w-full h-full bg-white rounded-lg shadow-md p-4 
//                     sm:flex-col 
//                     xs:flex-col">
      
//       {/* User Lotsize */}
//       <div className="flex flex-col w-full">
//         <div className="flex justify-center items-center">
//           <h5 className="text-lg text-cyan-800 font-semibold mx-4 
//                          md:text-lg md:mx-2 
//                          sm:text-xl 
//                          xs:text-xl">
//             Total Lots Size
//           </h5>
//           <div className="relative group">
//             <AiOutlineInfoCircle
//               className="w-4 h-4 text-gray-400 hover:text-gray-900 cursor-pointer"
//               aria-hidden="true"
//             />
//             {/* Tool Tips */}
//             <div className="text-white bg-cyan-800 absolute top-full mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white 
//                             md:w-48 
//                             sm:right-[5px] 
//                             xs:right-[5px]">
//               lotsize can be used to redeem various rewards such as attending classes and other benefits.
//             </div>
//           </div>
//         </div>

//         {/* Total lotsize */}
//         <div className="border-solid border-2 rounded-xl my-2 p-8 sm:p-6 xs:p-4">
//           <div className="text-cyan-800 text-6xl font-bold flex justify-center items-center">
//               {totalPoints}
//               <p className="text-lg ml-4">Lot</p>
//           </div>
//         </div>
//       </div>

//       <hr className="mx-4" />

//       {/* User Stats */}
//       <div className="flex flex-col w-full">
//         <div className="flex justify-center items-center">
//           <h5 className="text-cyan-800 text-lg font-semibold mx-4 
//                            md:text-lg md:mx-2 
//                            sm:mt-4 
//                            xs:mt-4 xs:text-xl">
//             Program Stats
//           </h5>
//           <div className="relative group">
//             <AiOutlineInfoCircle
//               className="w-4 h-4 text-gray-400 hover:text-gray-900 cursor-pointer 
//                          sm:mt-4 
//                          xs:mt-4"
//               aria-hidden="true"
//             />
//             {/* Tool Tips */}
//             <div className="text-white bg-cyan-800 absolute top-full mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white
//                               md:w-48 md:right-0 
//                               sm:right-[5px] 
//                               xs:right-[5px]">
//               User stats include information about the user's current program stages and funding.
//             </div>
//           </div>
//         </div>

//         {/* Program Information
//         <div className="flex justify-center items-center text-center rounded-xl p-2 mx-2">
//           <button 
//             onClick={nextProgram}>
//             <h2 className="text-cyan-800 text-xl font-bold md:text-base sm:text-base xs:text-base">
//               {currentProgram}
//             </h2>
//           </button>
//         </div> */}

//         {/* Stats Section */}
//         <div className="py-5 rounded-lg shadow-inner"
//           style={{ backgroundColor: theme.primaryColor, color:theme.text2Color }}>
//             <div className="text-center">
//               <p>Current Stage</p>
//               <p className="font-semibold">
//                 {programData.stage}
//               </p>
//             </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserPointsStats;