// src/components/admin/FundedProgram/FundProgramModal.tsx

import React, { useState } from 'react';

interface FundProgramPendingModalProps {
  onClose: () => void;
  onSave: (formData: {
    fundAccountNumber: string;
    fundAccountPassword: string;
    fundAccountServer: string;
    fundEvalProgress: string;
    fundEvalExpireDate: string;
    fundEvalExpireDays: number;
  }) => void;
}

const FundProgramPendingModal: React.FC<FundProgramPendingModalProps> = ({ onClose, onSave }) => {
  const [formData, setFormData] = useState({
    fundAccountNumber: '',
    fundAccountPassword: '',
    fundAccountServer: '',
    fundEvalProgress: '',
    fundEvalExpireDate: '',
    fundEvalExpireDays: 0,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
  
    setFormData((prev) => {
      const updatedFormData = { ...prev, [name]: value };
  
      // Auto-calculate days if the expired date is selected
      if (name === 'fundEvalExpireDate') {
        const currentDate = new Date();
        const selectedDate = new Date(value);
  
        if (selectedDate > currentDate) {
          const diffInTime = selectedDate.getTime() - currentDate.getTime();
          const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24)); 
          updatedFormData.fundEvalExpireDays = diffInDays;
        } else {
          updatedFormData.fundEvalExpireDays = 0; 
        }
      }
  
      return updatedFormData;
    });
  };  

  const handleSubmit = () => {
    onSave(formData);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-auto">
        <h2 className="text-xl font-bold mb-4 text-cyan-800">Approve Fund Request</h2>
        
        {/* Fund Account Number */}
        <div className="space-y-2 border p-4 rounded-lg">
          <div className="flex flex-col">
            <label className="mx-2 font-bold text-cyan-800">
              Fund Account Number
            </label>
            <input
              type="number"
              name="fundAccountNumber"
              placeholder="Enter an account number..."
              value={formData.fundAccountNumber}
              onChange={handleChange}
              className="w-full p-2 border rounded mb-2"
            />
          </div>

          <div className="flex flex-col">
            <label className="mx-2 font-bold text-cyan-800">
              Fund Account Password
            </label>
            <input
              type="password"
              name="fundAccountPassword"
              placeholder="Enter an account password..."
              value={formData.fundAccountPassword}
              onChange={handleChange}
              className="w-full p-2 border rounded mb-2"
            />
          </div>

          <div className="flex flex-col">
            <label className="mx-2 font-bold text-cyan-800">
              Fund Account Server
            </label>
            <select
              name="fundAccountServer"
              value={formData.fundAccountServer}
              onChange={handleChange}
              className="w-full p-2 border rounded mb-2"
            >
              <option value="">Select a Server</option>
              <option value="FortunrPrimeGlobal-Live">FPG Live</option>
              <option value="FortunrPrimeGlobal-Demo">FPG Demo</option>
              <option value="LirunexLimited-Live">Lirunex Live</option>
              <option value="LirunexLimited-Demo">Lirunex Demo</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label className="mx-2 font-bold text-cyan-800">
              Evaluation Progress
            </label>
            <select 
              name="fundEvalProgress"
              value={formData.fundEvalProgress}
              onChange={handleChange}
              className="w-full p-2 border rounded mb-2"
            >
              <option value="">Select a progress</option>
              <option value="Stage 01">Stage 01</option>
              <option value="Stage 02">Stage 02</option>
              <option value="Stage 03">Stage 03</option>
              <option value="Live Stage">Funded Account</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label className="mx-2 font-bold text-cyan-800">
              Evaluation Expired Date
            </label>
            <div className="flex justify-between items-center">
              <input
                type="date"
                name="fundEvalExpireDate"
                value={formData.fundEvalExpireDate}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
              <div className="flex justify-normal items-center">
                <label className="mx-4 font-bold text-cyan-800">Days</label>
                <input
                  type="number"
                  name="fundEvalExpireDays"
                  placeholder="Evaluation Expire Days"
                  value={formData.fundEvalExpireDays}
                  className="text-center w-16 p-2 border rounded"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            onClick={onClose} 
            className="bg-gray-400 px-6 py-1 rounded text-white"
          >
            Cancel
          </button>
          <button 
            onClick={handleSubmit} 
            className="bg-cyan-800 px-8 py-1 rounded text-white"
          >
            Save
          </button>
        </div>

      </div>
    </div>
  );
};

export default FundProgramPendingModal;
