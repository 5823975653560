// src/components/CookieNotification.tsx

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LangContext'; 
import { FaCookieBite } from 'react-icons/fa';

const CookieNotification: React.FC = () => {
  const [showCookieNotification, setShowCookieNotification] = useState<boolean>(true);
  const [showAcknowledgement, setShowAcknowledgement] = useState<boolean>(false);
  const { theme } = useTheme();
  const { language } = useLanguage();

  const acceptCookies = () => {
    setShowCookieNotification(false);
    Cookies.set('acceptCookies', 'true', { expires: 365 });
  };

  useEffect(() => {
    if (Cookies.get('acceptCookies') === 'true') {
      setShowCookieNotification(false);
    }
  }, []);

  if (!showCookieNotification) {
    return null;
  }

  // Text based on language
  const cookieMessage =
    language === 'en'
      ? 'This site uses cookies to enhance your experience. By continuing to use this site, you accept our use of cookies.'
      : language === 'zh-TW'
      ? '本網站使用 Cookies 以提升您的體驗。繼續使用本網站即表示您同意我們的 Cookies 使用政策。'
      : '本网站使用 Cookies 以提升您的体验。继续使用本网站即表示您同意我们的 Cookies 使用政策。';

  const detailedMessage =
    language === 'en'
      ? 'We utilize cookies to store login token data locally, which enables us to enhance your overall experience on our site. By clicking "Accept," you consent to the use of cookies for this purpose as outlined in our Cookie declaration. Your continued use of our site signifies your agreement to this policy.'
      : language === 'zh-TW'
      ? '我們使用 Cookies 來本地存儲登錄令牌數據，以提升您在我們網站上的整體體驗。點擊“接受”即表示您同意根據我們的 Cookies 聲明使用 Cookies。繼續使用我們的網站即表示您同意此政策。'
      : '我们使用 Cookies 来本地存储登录令牌数据，以提升您在我们网站上的整体体验。点击“接受”即表示您同意根据我们的 Cookies 声明使用 Cookies。继续使用我们的网站即表示您同意此政策。';

  const acceptText = language === 'en' ? 'Accept' : language === 'zh-TW' ? '接受' : '接受';
  const toggleText = showAcknowledgement
    ? language === 'en'
      ? 'Hide Cookies'
      : language === 'zh-TW'
      ? '隱藏 Cookies'
      : '隐藏 Cookies'
    : language === 'en'
    ? 'Show Cookies'
    : language === 'zh-TW'
    ? '顯示 Cookies'
    : '显示 Cookies';

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-30"></div>
      <motion.div
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: '100%', opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed bottom-0 left-0 right-0 p-6 z-50 bg-white shadow-lg"
        style={{ backgroundColor: theme.backgroundColor, color: theme.textColor, fontFamily: theme.fontFamily }}
      >
        <div className="p-4">
          <p className="flex flex-row items-center justify-between md:flex-row sm:flex-col">
            <span className="flex items-center text-center sm:flex-col xs:flex-col">
              <div className="text-6xl mr-4 md:text-5xl md:mr-2 sm:text-4xl sm:mb-2 xs:text-3xl xs:mb-2">
                <FaCookieBite style={{ color: theme.primaryColor }} />
              </div>
              <span className="text-lg text-left md:mx-2 lg:text-lg md:text-sm sm:text-sm sm:text-center sm:mb-4 xs:text-sm xs:text-center xs:mb-2">
                {cookieMessage}
              </span>
              <button
                onClick={() => setShowAcknowledgement(!showAcknowledgement)}
                className="underline mx-8 text-nowrap md:text-sm md:mx-4 sm:text-xs sm:mb-2 xs:text-xs xs:mb-2"
                style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}
              >
                {toggleText}
              </button>
            </span>
            <button
              onClick={acceptCookies}
              className="bg-blue-500 text-white px-8 py-2 rounded ml-4 sm:text-xs xs:text-xs"
              style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily }}
            >
              {acceptText}
            </button>
          </p>
          {showAcknowledgement && (
            <div className="mt-2 p-4 border border-gray-300 rounded text-base sm:text-xs xs:text-xs">
              <p>{detailedMessage}</p>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default CookieNotification;



// // udpatea code --> 13 dec 2024
// // src/components/CookieNotification.tsx

// import React, { useState, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import Cookies from 'js-cookie';
// import { useTheme } from '../context/ThemeContext';
// import { FaCookieBite } from 'react-icons/fa';

// const CookieNotification: React.FC = () => {
//   const [showCookieNotification, setShowCookieNotification] = useState<boolean>(true);
//   const [showAcknowledgement, setShowAcknowledgement] = useState<boolean>(false);
//   const { theme } = useTheme();

//   const acceptCookies = () => {
//     setShowCookieNotification(false);
//     Cookies.set('acceptCookies', 'true', { expires: 365 });
//   };

//   useEffect(() => {
//     if (Cookies.get('acceptCookies') === 'true') {
//       setShowCookieNotification(false);
//     }
//   }, []);

//   if (!showCookieNotification) {
//     return null;
//   }

//   return (
//     <>
//       <div className="fixed inset-0 bg-black bg-opacity-50 z-30"></div>
//       <motion.div
//         initial={{ y: '100%', opacity: 0 }}
//         animate={{ y: 0, opacity: 1 }}
//         exit={{ y: '100%', opacity: 0 }}
//         transition={{ duration: 0.5 }}
//         className="fixed bottom-0 left-0 right-0 p-6 z-50 bg-white shadow-lg"
//         style={{ backgroundColor: theme.backgroundColor, color: theme.textColor, fontFamily: theme.fontFamily }}
//       >
//         <div className="p-4">
//           <p className="flex flex-row items-center justify-between md:flex-row sm:flex-col">
//             <span className="flex items-center text-center sm:flex-col xs:flex-col">
//               <div className="text-6xl mr-4 md:text-5xl md:mr-2 sm:text-4xl sm:mb-2 xs:text-3xl xs:mb-2">
//                 <FaCookieBite style={{ color:theme.primaryColor }} />
//               </div>
//               <span className='text-lg text-left md:mx-2 lg:text-lg md:text-sm sm:text-sm sm:text-center sm:mb-4 xs:text-sm xs:text-center xs:mb-2 '>
//                 This site uses cookies to enhance your experience. By continuing to use this site, you accept our use of cookies. 
//               </span>
//               <button
//                 onClick={() => setShowAcknowledgement(!showAcknowledgement)}
//                 className="underline mx-8 text-nowrap md:text-sm md:mx-4 sm:text-xs sm:mb-2 xs:text-xs xs:mb-2"
//                 style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}
//               >
//                 {showAcknowledgement ? 'Hide Cookies' : 'Show Cookies'}
//               </button>
//             </span>
//             <button
//               onClick={acceptCookies}
//               className="bg-blue-500 text-white px-8 py-2 rounded ml-4 sm:text-xs xs:text-xs"
//               style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily }}
//             >
//               Accept
//             </button>
//           </p>
//           {showAcknowledgement && (
//             <div className="mt-2 p-4 border border-gray-300 rounded text-base sm:text-xs xs:text-xs">
//               <p>
//               We utilize cookies to store login token data locally, which enables us to enhance your overall experience on our site. 
//               By clicking "Accept," you consent to the use of cookies for this purpose as outlined in our Cookie declaration. 
//               Your continued use of our site signifies your agreement to this policy.
//               </p>
//             </div>
//           )}
//         </div>
//       </motion.div>
//     </>
//   );
// };

// export default CookieNotification;
