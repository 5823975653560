// src/components/admin/FundedProgram/FundProgramSettingModal.tsx

import React, { useState, useEffect, useMemo } from 'react';
import { BsToggleOn, BsToggleOff } from "react-icons/bs";

const FundProgramSettingModal = ({
  newProgram,
  isOpen,
  onClose,
  onSave,
  onChange,
  isEdit = false,
  onUpdate,
}: {
  newProgram: {
    fundSetId?: string; 
    fundNameSet: string;
    fundDescriptionSet: string;
    fundStepSet: string;
    fundSizeSet: string;
    fundFeatureSet: { [key: string]: string };
    fundStatusSet: string;
    fundAmountSet: number,
  };
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedProgram: {
    fundNameSet: string;
    fundDescriptionSet: string;
    fundStepSet: string;
    fundSizeSet: string;
    fundFeatureSet: { [key: string]: string };
    fundStatusSet: string;
    fundAmountSet: number,
  }) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | {
    target: {
      name: string;
      value: { [key: string]: string };
    };
  }) => void;
  isEdit?: boolean;
  onUpdate?: (fundSetId: string, program: typeof newProgram) => void;
}) => {
  const [features, setFeatures] = useState<{ [key: string]: string }>({});
  const [toggleStates, setToggleStates] = useState<{ [key: string]: boolean }>({});
  const featureOptions = useMemo(() => [
    'Evaluation Stage',
    'Profit Target',
    'Maximum Daily Drawdown',
    'Maximum Overall Drawdown',
    'Balance Based Drawdown',
    'Minimum Trading Days',
    'News Trading',
    'Trader Overnight',
    'Minimum profit split',
    'Trading Leverage',
  ], []);

  // Reset features and toggle states when modal opens/closes or switches between add/edit modes
  useEffect(() => {
    if (isOpen) {
      if (isEdit && newProgram.fundFeatureSet) {
        // Initialize features with existing data
        setFeatures(newProgram.fundFeatureSet);
        
        // Set toggle states for existing features to true
        const initialToggleStates: { [key: string]: boolean } = {};
        featureOptions.forEach(feature => {
          initialToggleStates[feature] = !!newProgram.fundFeatureSet[feature];
        });
        setToggleStates(initialToggleStates);
      } else {
        // Reset states for new program
        setFeatures({});
        setToggleStates({});
      }
    }
  }, [isOpen, isEdit, newProgram.fundFeatureSet, featureOptions]);

  const handleToggle = (feature: string) => {
    setToggleStates(prev => {
      const newState = { ...prev, [feature]: !prev[feature] };
      
      // If toggling off, remove the feature
      if (!newState[feature]) {
        const updatedFeatures = { ...features };
        delete updatedFeatures[feature];
        setFeatures(updatedFeatures);
        
        // Update parent component's newProgram state
        onChange({
          target: {
            name: 'fundFeatureSet',
            value: updatedFeatures,
          },
        });
      }
      
      return newState;
    });
  };

  const handleFeatureChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    feature: string
  ) => {
    const value = e.target.value;
    
    // Update local features state
    setFeatures(prev => ({
      ...prev,
      [feature]: value,
    }));
  
    // Update parent component's newProgram state
    onChange({
      target: {
        name: 'fundFeatureSet',
        value: {
          ...newProgram.fundFeatureSet,
          [feature]: value,
        },
      },
    });
  };    

  const handleSave = () => {
    const updatedProgram = {
      ...newProgram,
      fundFeatureSet: features,
    };

    if (isEdit && onUpdate && newProgram.fundSetId) {
      onUpdate(newProgram.fundSetId, updatedProgram);
    } else {
      onSave(updatedProgram);
    }
  };  

  const renderFeatureInput = (feature: string) => {
    switch (feature) {
      case 'Evaluation Stage':
        return (
          <input
            type="number"
            placeholder={toggleStates[feature] ? `Enter evaluation stages...` : ''}
            value={features[feature] || ''}
            onChange={(e) => handleFeatureChange(e, feature)}
            className="border px-2 py-1 rounded w-full"
            disabled={!toggleStates[feature]}
          />
        );
      case 'Profit Target':
        return (
          <div className="flex justify-between items-center">
            <input
              type="text"
              placeholder={toggleStates[feature] ? 'Enter percentage...' : ''}
              value={features[feature] || ''}
              onChange={(e) => handleFeatureChange(e, feature)}
              className="border px-2 py-1 rounded w-full"
              disabled={!toggleStates[feature]}
            />
            <span className={`font-bold ml-2 ${toggleStates[feature] ? 'text-gray-800' : 'text-gray-200'}`}> 
              % 
            </span>
          </div>
        );
      case 'Maximum Daily Drawdown':
        return (
          <div className="flex justify-between items-center">
            <input
              type="text"
              placeholder={toggleStates[feature] ? 'Enter percentage...' : ''}
              value={features[feature] || ''}
              onChange={(e) => handleFeatureChange(e, feature)}
              className="border px-2 py-1 rounded w-full"
              disabled={!toggleStates[feature]}
            />
            <span className={`font-bold ml-2 ${toggleStates[feature] ? 'text-gray-800' : 'text-gray-200'}`}> 
              % 
            </span>
          </div>
        );
      case 'Maximum Overall Drawdown':
        return (
          <div className="flex justify-between items-center">
            <input
              type="text"
              placeholder={toggleStates[feature] ? 'Enter percentage...' : ''}
              value={features[feature] || ''}
              onChange={(e) => handleFeatureChange(e, feature)}
              className="border px-2 py-1 rounded w-full"
              disabled={!toggleStates[feature]}
            />
            <span className={`font-bold ml-2 ${toggleStates[feature] ? 'text-gray-800' : 'text-gray-200'}`}> 
              % 
            </span>
          </div>
        );
      case 'Minimum profit split':
        return (
          <div className="flex justify-between items-center">
            <input
              type="text"
              placeholder={toggleStates[feature] ? 'Enter percentage...' : ''}
              value={features[feature] || ''}
              onChange={(e) => handleFeatureChange(e, feature)}
              className="border px-2 py-1 rounded w-full"
              disabled={!toggleStates[feature]}
            />
            <span className={`font-bold ml-2 ${toggleStates[feature] ? 'text-gray-800' : 'text-gray-200'}`}> 
              % 
            </span>
          </div>
        );
      case 'Balance Based Drawdown':
        return (
          <select
            value={features[feature] || ''}
            onChange={(e) => handleFeatureChange(e, feature)}
            className="border px-2 py-1 rounded w-full"
            disabled={!toggleStates[feature]}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      case 'News Trading':
        return (
          <select
            value={features[feature] || ''}
            onChange={(e) => handleFeatureChange(e, feature)}
            className="border px-2 py-1 rounded w-full"
            disabled={!toggleStates[feature]}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      case 'Trader Overnight':
        return (
          <select
            value={features[feature] || ''}
            onChange={(e) => handleFeatureChange(e, feature)}
            className="border px-2 py-1 rounded w-full"
            disabled={!toggleStates[feature]}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      case 'Minimum Trading Days':
        return (
          <input
            type="number"
            placeholder="Enter days"
            value={features[feature] || ''}
            onChange={(e) => handleFeatureChange(e, feature)}
            className="border px-2 py-1 rounded w-full"
            disabled={!toggleStates[feature]}
          />
        );

      case 'Trading Leverage':
        return (
          <input
            type="text"
            placeholder="Enter as X:Y"
            value={features[feature] || ''}
            onChange={(e) => handleFeatureChange(e, feature)}
            className="border px-2 py-1 rounded w-full"
            disabled={!toggleStates[feature]}
          />
        );

      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-auto min-w-[500px] h-auto max-h-screen relative">
        <div>
          <h2 className="text-lg text-cyan-800 font-bold m-2">
            {isEdit ? 'Update Program' : 'Add New Program'}
          </h2>
          <div className="flex flex-col h-auto max-h-[600px] overflow-y-auto border rounded p-4">
            {/* Fund Name */}
            <div className="mb-4 mt-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Name</label>
              <input
                type="text"
                name="fundNameSet"
                value={newProgram.fundNameSet}
                placeholder="set funded name..."
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              />
            </div>

            {/* Fund Description */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Description</label>
              <input
                type="textarea"
                name="fundDescriptionSet"
                value={newProgram.fundDescriptionSet}
                placeholder="set funded description..."
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              />
            </div>

            {/* Fund Step */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Step</label>
              <input
                type="text"
                name="fundStepSet"
                value={newProgram.fundStepSet}
                placeholder="set funded name..."
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              />
            </div>

            {/* Fund Size */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Size</label>
              <div className="flex justify-between items-center">
                <input
                  type="number"
                  name="fundSizeSet"
                  value={newProgram.fundSizeSet}
                  placeholder="set funded size..."
                  onChange={onChange}
                  className="border px-2 py-1 rounded w-full"
                />
                <span className="text-cyan-800 font-semibold ml-2">USD</span>
              </div>
            </div>

            {/* Fund Features */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Features</label>
              {featureOptions.map((feature) => (
                <div key={feature} className="flex flex-col mb-2 mt-4">
                  <div className="flex justify-between">
                    <label className={`mx-4 text-sm ${toggleStates[feature] ? 'text-gray-600' : 'text-gray-300'}`}>
                      {feature}
                    </label>
                    <button
                      className={`text-xl mr-2 ${toggleStates[feature] ? 'text-cyan-800' : 'text-gray-300'}`}
                      onClick={() => handleToggle(feature)}
                    >
                      {toggleStates[feature] ? <BsToggleOn /> : <BsToggleOff />}
                    </button>
                  </div>
                  {renderFeatureInput(feature)}
                </div>
              ))}
            </div>

            {/* Fund Amount */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Amount</label>
              <div className="flex justify-between items-center">
                <input
                  type="number"
                  name="fundAmountSet"
                  value={newProgram.fundAmountSet}
                  placeholder="Set fund amount..."
                  onChange={onChange}
                  className="border px-2 py-1 rounded w-full"
                />
                <span className="font-semibold text-cyan-800 ml-2">USD</span>
              </div>
            </div>

            {/* Fund Status */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Status</label>
              <select
                name="fundStatusSet"
                value={newProgram.fundStatusSet}
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              >
                <option value="Running">Running</option>
                <option value="Stopped">Stopped</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end mt-4 space-x-2">
            <button
              className="bg-gray-400 text-gray-200 px-6 py-1 rounded"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="bg-cyan-800 text-white px-6 py-1 rounded"
              onClick={() => {
                if (isEdit && onUpdate && newProgram.fundSetId) {
                  onUpdate(newProgram.fundSetId, newProgram);
                } else {
                  handleSave();
                }
              }}
            >
              {isEdit ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundProgramSettingModal;

