// src/components/admin/SubProductSetting/subVoucherSetting.tsx

// import react component
import React, { useState } from 'react';
// import react icon
import { IoIosCheckbox, IoIosCheckboxOutline  } from "react-icons/io";
// import custom components
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import { useAuth } from '../../../context/AuthContext';   
import SubVoucherModal from './subVoucherModal';

const SubVoucherSetting: React.FC = () => {
  // import custom hook
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');
  const { vouchers, products, plans, loading, addVoucher, updateVoucher, deleteVoucher } = useSubSetting();
  // add/edit product modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false); 
  const [currentVoucher, setCurrentVoucher] = useState<any>(null);
  // display custom group email list
  const [isEmailListModalOpen, setIsEmailListModalOpen] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);

  const handleAddVoucherSet = async (voucher: { 
    voucherId: string;
    VoucherLinkPlanID: string; 
    voucherName: string; 
    voucherCode: string; 
    voucherType: string;    
    voucherAmount?: number;     
    voucherPercentage?: number; 
    voucherPeriod: Date;
    voucherDays: number;
    voucherRedeemCount: number;
    voucherMemberType: string;
    voucherGroupName: string;
    voucherGroupMember: string;
    voucherStatus: string;
  }) => {
    try {
      if (isEditMode && currentVoucher) {
        // Update the existing plan
        await updateVoucher(currentVoucher.voucherId, voucher);
      } else {
        // Add a new plan
        await addVoucher(voucher);
      }
    } catch (error) {
      console.error("Failed to save voucher:", error);
    } finally {
      // Reset modal state and editing state
      setIsModalOpen(false);
      setCurrentVoucher(null);
      setIsEditMode(false);
    }
  };
  
  const handleSelectRow = (voucherId: string) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(voucherId)
        ? prevSelectedRows.filter(id => id !== voucherId) 
        : [...prevSelectedRows, voucherId]
    );
  };

  const handleDeleteSelected = async () => {
    for (const voucherId of selectedRows) {
      await deleteVoucher(voucherId); 
    }
    setSelectedRows([]); 
  };

  // Handle row double-click to enter edit mode
  const handleEditVoucher = (voucher: any) => {
    setCurrentVoucher(voucher);
    setIsEditMode(true); 
    setIsModalOpen(true); 
  };

  const handleShowEmailList = (emails: string | null) => {
    setEmailList(emails ? emails.split(',') : []);
    setIsEmailListModalOpen(true);
  };  

  return (
    <div className="p-4 border rounded">

      <div className="flex justify-end mb-2 space-x-2">
        {/* Toggle Multi-Select Mode */}
        <button 
          className={`text-xl mr-2 
            ${permissions?.productSubscription?.editSub !== 'enable'
              ? 'text-gray-400 cursor-not-allowed' 
              : 'text-cyan-800 '
            }`}
          onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
          disabled={permissions?.productSubscription?.editSub !== 'enable'}
        >
          {isMultiSelectEnabled ? <IoIosCheckbox /> : <IoIosCheckboxOutline />}
        </button>

        {/* Add Product Button */}
        <button 
          className={`px-6 py-1 rounded 
            ${permissions?.productSubscription?.addSub !== 'enable'
              ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
              : 'bg-cyan-800 text-white'
            }`}
          onClick={() => {
            setIsEditMode(false);
            setCurrentVoucher(null);
            setIsModalOpen(true);
          }}
          disabled={permissions?.productSubscription?.addSub !== 'enable'}
        >
          Add Voucher Sets
        </button>

        {/* Remove Selected Products Button */}
        <button
          className={`px-6 py-1 rounded 
            ${permissions?.productSubscription?.deleteSub !== 'enable'
              ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
              : 'bg-cyan-800 text-white'
            }`}
          onClick={handleDeleteSelected}
          disabled={!selectedRows.length || permissions?.productSubscription?.deleteSub !== 'enable'}
        >
          Remove Voucher Sets
        </button>
      </div>

      {/* Plan Table */}
      <div className="overflow-x-auto w-auto max-w-[1650px]">
        <table className="bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              {isMultiSelectEnabled && <th className="py-2 px-4 border-b">Select</th>}
              <th className="py-2 px-4 border-b text-nowrap">Voucher Id</th>
              <th className="py-2 px-4 border-b text-nowrap">Linked Plan</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Name</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Code</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Type</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Amount</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Percentage</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Period</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Day</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Count</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Member Type</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Custom Group</th>
              <th className="py-2 px-4 border-b text-nowrap">Voucher Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center py-8">Loading...</td>
              </tr>
            ) : vouchers.length > 0 ? (
              vouchers.map((voucher) => (
                <tr
                  key={voucher.voucherId}
                  className={`text-center border-b hover:bg-gray-50 ${
                    permissions?.productSubscription?.editSub === 'enable' ? '' : 'cursor-not-allowed pointer-events-none'
                  }`}
                  onDoubleClick={() => {
                    if (permissions?.productSubscription?.editSub === 'enable') {
                      handleEditVoucher(voucher);
                    }
                  }}
                >
                  {isMultiSelectEnabled && (
                    <td className="py-2 px-4">
                      <input
                        type="checkbox"
                        className="accent-cyan-800"
                        checked={selectedRows.includes(voucher.voucherId)}
                        onChange={() => handleSelectRow(voucher.voucherId)}
                      />
                    </td>
                  )}
                  <td className="py-2 px-4 text-nowrap">{voucher.voucherId}</td>
                  <td className="py-2 px-4 text-nowrap">
                    {voucher.VoucherLinkPlanID
                      .split(',')
                      .map(planId => {
                        const plan = plans.find(p => p.planId === planId);
                        const product = products.find(p => p.productId === plan?.LinkProductId);
                        return plan && product ? `${product.productName} - ${plan.planName}` : 'Unknown';
                      })
                      .join(', ')}
                  </td>
                  <td className="py-2 px-4 text-nowrap">{voucher.voucherName}</td>
                  <td className="py-2 px-4 text-nowrap">{voucher.voucherCode}</td>
                  <td className="py-2 px-4 text-nowrap">{voucher.voucherType}</td>
                  <td className="py-2 px-4 text-nowrap">
                    <span className={voucher.voucherAmount ? "" : "text-gray-300"}>
                      {voucher.voucherAmount || "No Set by Amount"}
                    </span>
                  </td>
                  <td className="py-2 px-4 text-nowrap">
                    <span className={voucher.voucherPercentage ? "" : "text-gray-300"}>
                      {voucher.voucherPercentage || "No Set by Percentage"}
                    </span>
                  </td>
                  <td className="py-2 px-4 text-nowrap">{new Date(voucher.voucherPeriod).toLocaleDateString()}</td>
                  <td className="py-2 px-4 text-nowrap">{voucher.voucherDays}</td>
                  <td className="py-2 px-4 text-nowrap">{voucher.voucherRedeemCount}</td>
                  <td className="py-2 px-4 text-nowrap">
                    <span className={voucher.voucherMemberType ? "" : "text-gray-300"}>
                      {voucher.voucherMemberType || "No Set voucher member type"}
                    </span>
                  </td>
                  <td 
                    className="py-2 px-4 text-nowrap"
                    onClick={() => handleShowEmailList(voucher.voucherGroupMember)}
                  >
                    <span className={voucher.voucherGroupName ? "" : "text-gray-300"}>
                      {voucher.voucherGroupName || "No set custom group"}
                    </span>
                  </td>
                  <td className={`py-2 px-4 text-nowrap ${
                    voucher.voucherStatus === 'Active' ? 'text-green-500 font-bold' : 'text-rose-500 font-bold'
                  }`}>
                    {voucher.voucherStatus}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center py-8">No voucher set available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for Adding Product */}
      {isModalOpen && (
        <SubVoucherModal
          onClose={() => setIsModalOpen(false)}
          onSave={handleAddVoucherSet}
          editMode={isEditMode}
          initialData={currentVoucher}
        />
      )}

      {/* Modal for to show custom trial group email list */}
      {isEmailListModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold text-center mb-4 text-cyan-800">Email List</h2>
            <ul className="text-sm list-disc pl-5 space-y-2">
              {emailList.length > 0 ? (
                emailList.map((email, index) => <li key={index}>{email}</li>)
              ) : (
                <li>No emails available</li>
              )}
            </ul>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsEmailListModalOpen(false)}
                className="bg-cyan-800 text-white px-4 py-1 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SubVoucherSetting;