// src/components/client/MiniApp/App02_MyFundRegistration.tsx

// import react components
import React, { useState, useEffect } from 'react';
// import react icon
import { IoMdClose } from 'react-icons/io';
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaArrowCircleLeft, FaClipboard } from 'react-icons/fa';
// import custom hook file
import useFundedRegistration from '../../../hooks/MiniApp/useFundedRegistration';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import term context file
import FundedRuleTerm from '../../../context/FundedRuleTerm'; 
import TermsAndConditions from '../../../context/FundedTerm';
// import custom alert file
import MyTicketAlert from './App_AlertMessage';
interface FundedRegistrationProps {
  onClose: () => void;
  fundProgram: {
    fundOrderId: string;
    fundLinkEvalId: string;
    fundProgramSize: number;
    fundProgramAmount: number;
    fundUserName: string;
    fundUserEmail: string;
    fundEvalBeforeProgram: string;
    fundEvalBeforeStage: string;
    fundRuleAgreed: string,
    fundTermAgreed: string,
    fundPaymentMethod: string,
    fundPaymentReceipts: string,
    fundRegNotes: string,
  };
}

// Function to generate order ID based on product name
const generateOrderId = () => {
  const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase(); 
  const randomDigits = String(Math.floor(1000 + Math.random() * 9000)); 
  return `sub-FUND-${randomLetters}${randomDigits}`;
}; 

const FundedRegistration: React.FC<FundedRegistrationProps> = ({ onClose, fundProgram }) => {
  const { registerUser } = useFundedRegistration();
  const { user } = useUserDashboard();
  const [isModalOpen, setIsModalOpen] = useState<null | "rules" | "terms">(null);
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [formData, setFormData] = useState({
    fundOrderId: '',
    fundLinkEvalId: '',
    fundProgramSize: 0,
    fundProgramAmount: 0,
    fundUserName: '',
    fundUserEmail: '',
    fundEvalBeforeProgram: '',
    fundEvalBeforeStage: '',
    fundRuleAgreed: '',
    fundTermAgreed: '',
    fundPaymentMethod: '',
    fundPaymentReceipts: '',
    fundRegNotes: '',
  });
  const [alert, setAlert] = useState({
    type: 'success' as 'success' | 'error',
    message: '',
    visible: false
  });

  // Log user data for debugging
  useEffect(() => {
    if (user) {
      // console.log('User data loaded:', user);
      setFormData((prev) => ({
        ...prev,
        fundOrderId: prev.fundOrderId || generateOrderId(),
        fundUserName: user.username || '',
        fundUserEmail: user.email || '',
        fundLinkEvalId: fundProgram.fundLinkEvalId || '',
        fundProgramAmount: fundProgram.fundProgramAmount || 0,
        fundProgramSize: fundProgram.fundProgramSize || 0,
        fundEvalBeforeProgram: prev.fundEvalBeforeProgram || 'New',
        fundEvalBeforeStage: prev.fundEvalBeforeStage || 'New',
        fundPaymentMethod: paymentMethod || prev.fundPaymentMethod,
      }));
    }
  }, [user, fundProgram, paymentMethod]);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    console.log(`Input changed - ${name}:`, value);
    setFormData({ ...formData, [name]: value });
  };

  const handleProgramEvaluationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    console.log(`Program evaluation change - ${name}:`, value); 
    setFormData({ ...formData, [name]: value || null }); 
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    console.log(`Checkbox changed - ${name}:`, checked);
    setFormData({ ...formData, [name]: checked ? 'Agreed' : 'Disagreed' });
  };  

  const handleSubmit = async () => {
    // console.log('Form submitted with data:', formData);

    // Ensure both checkboxes are checked before submission
    if (formData.fundRuleAgreed !== 'Agreed' || formData.fundTermAgreed !== 'Agreed') {
      setAlert({
        type: 'error',
        message: 'Please accept the Rules and Regulations and Terms and Conditions before submitting.',
        visible: true
      });
      return;
    }

    const payload = {
      ...formData,
      fundLinkEvalId: fundProgram.fundLinkEvalId, 
      fundProgramAmount: fundProgram.fundProgramAmount,
      fundRuleAgreed: formData.fundRuleAgreed || 'Agreed',
      fundTermAgreed: formData.fundTermAgreed || 'Agreed',
    };
    // console.log('Submitting payload:', payload);

    try {
      await registerUser(payload);
      // console.log('Registration submitted successfully');
      setAlert({
        type: 'success',
        message: `
          <div>
            <p>Registration submitted successfully!</p>
            <p>A confirmation email has been sent to ${formData.fundUserEmail}, 
               Please check your email for further instructions.
            </p>
          </div>
        `,
        visible: true
      });
      // Close the form after a short delay
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Error during registration submission:', error);
      setAlert({
        type: 'error',
        message: 'An error occurred during submission. Please try again or contact support if the problem persists.',
        visible: true
      });
    }
  };

  return (
    <>
      <div className="bg-white p-6 rounded-lg w-auto max-w-[600px] h-auto max-h-[650px] overflow-y-scroll px-4 md:text-md md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
        <button 
          className="absolute top-2 right-2 text-gray-600" 
          onClick={onClose}
        >
          <IoMdClose size={20} />
        </button>

        <form>
          {/* Header */}
          <div className="flex justify-center sm:my-0 xs:my-0">
            <span className="text-md bg-cyan-800 text-white px-4 py-2 rounded-full">
              Register for {fundProgram.fundLinkEvalId}
            </span>
          </div>

          {/* Order ID */}
          <div className="my-6">
            <label className="mx-2 font-semibold">Order ID:</label>
            <input
              className="w-full px-3 py-2 border rounded-md"
              type="text"
              value={generateOrderId()}
              disabled
            />
          </div>

          {/* Program Name with Id */}
          <div className="my-6">
            <label className="mx-2 font-semibold">Program Name:</label>
            <input
              className="w-full px-3 py-2 border rounded-md"
              type="text"
              value={fundProgram.fundLinkEvalId || 'N/A'}
              disabled
            />
          </div>

          {/* Evaluation Full Name */}
          <div className="my-6">
            <label className="mx-2 font-semibold">Full Name:</label>
            <input
              type="text"
              name="fundUserName"
              className="w-full border p-2 rounded"
              value={formData.fundUserName}
              disabled
            />
          </div>

          {/* Evaluation Email */}
          <div className="my-6">
            <label className="mx-2 font-semibold">Email:</label>
            <input
              type="email"
              name="fundUserEmail"
              className="w-full border p-2 rounded"
              value={formData.fundUserEmail}
              disabled
            />
          </div>

          {/* Join Program Before */}
          <div className="my-6">
            <label className="mx-2 font-semibold">Join Evalution Program Before:</label>
            <select
              className="w-full px-3 py-2 border rounded-md"
              name="fundEvalBeforeProgram"
              value={formData.fundEvalBeforeProgram || ''}
              onChange={handleProgramEvaluationChange}
            >
              <option value="">Select program before:</option>
              <option value="180">R One 180 Program</option>
              <option value="Go4Funds">R One Go4Funds Program</option>
              <option value="New">New</option>
            </select>
          </div>

          {/* Join Program Stage Before */}
          <div className="my-6">
            <label className="mx-2 font-semibold">Join Evalution Program Before Progress:</label>
            <select
              className="w-full px-3 py-2 border rounded-md"
              name="fundEvalBeforeStage"
              value={formData.fundEvalBeforeStage || ''}
              onChange={handleProgramEvaluationChange}
            >
              <option value="">Select progress before:</option>
              <option value="Stage 01">Stage 01</option>
              <option value="Stage 02">Stage 02</option>
              <option value="Stage 03">Stage 03</option>
              <option value="Live Funds">Live Funds</option>
              <option value="New">New</option>
            </select>
          </div>

          {/* Program Payment Amount */}
          <div className="my-6">
            <label className="mx-2 font-semibold">Payment Amount:</label>
            <input
              type="number"
              name="fundProgramAmount"
              className="w-full border p-2 rounded"
              value={fundProgram.fundProgramAmount || 0}
              disabled
            />
          </div>

          {/* Payment Method */}
          <div className="my-6">
            <label className="ml-2 font-semibold">Payment Method</label>
            <div className="flex items-center mx-4">
              <input 
                type="radio" 
                name="payment" 
                value="USDT" 
                className="accent-cyan-800" 
                onChange={() => setPaymentMethod('USDT')}
              />
              <label className="ml-2">USDT (Cryptocurrency)</label>
            </div>
            {paymentMethod === 'USDT' && (
              <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4
                    sm:text-xs sm:p-2
                    xs:text-[10px] xs:p-2"
              >
                <span>Wallet Address:</span><br />
                <div className="flex justify-between items-center">
                  <div>
                    <li>Tron (TRC20) Network</li>
                    <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
                  </div>
                  <FaClipboard 
                    className="ml-2 cursor-pointer w-6 h-6
                      sm:w-4 sm:h-4 sm:ml-0
                      xs:w-3 xs:h-3 xs:ml-0
                    "
                    onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
                  />
                </div>
              </div>
            )}
          </div>

          {/* Payment Receipts */}
          <div className="my-6">
            <label className="ml-2 font-semibold">Payment Receipts</label>
            <input className="w-full px-3 py-2 border rounded-md" type="file" />
          </div>

          {/* Notes */}
          <div className="my-6">
            <label className="mx-2 font-semibold">Notes</label>
            <textarea
              name="fundRegNotes"
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
              placeholder="Any additional notes..."
            ></textarea>
          </div>

          <div className="my-6 space-y-2">
            {/* Rule and Regulation */}
            <div className="flex justify-normal items-center">
              <input 
                type="checkbox" 
                name="fundRuleAgreed" 
                className="accent-cyan-800 mr-2 w-4 h-4" 
                checked={formData.fundRuleAgreed === 'Agreed'}
                onChange={handleCheckboxChange}
              />
              <span 
                className="cursor-pointer text-cyan-800 hover:underline ml-2"
                onClick={() => setIsModalOpen("rules")}
              >
                I Accept Rules and Regulations
              </span>
            </div>

            {/* Terms and Conditions */}
            <div className="flex justify-normal items-center">
              <input 
                type="checkbox"
                name="fundTermAgreed" 
                className="accent-cyan-800 mr-2 w-4 h-4" 
                checked={formData.fundTermAgreed === 'Agreed'}
                onChange={handleCheckboxChange}
              />
              <span 
                className="cursor-pointer text-cyan-800 hover:underline ml-2"
                onClick={() => setIsModalOpen("terms")}
              >
                I Accept Terms and Conditions
              </span>
            </div>
          </div>

          {/* Submit and Back Buttons */}
          <div className="flex justify-between items-center mt-8 space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
            >
              <FaArrowCircleLeft />
              <div className="ml-2">Back to Details</div>
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={formData.fundRuleAgreed !== 'Agreed' || formData.fundTermAgreed !== 'Agreed'}
              className={`px-4 py-2 rounded-md flex items-center justify-center ${
                formData.fundRuleAgreed === 'Agreed' && formData.fundTermAgreed === 'Agreed' 
                  ? 'bg-cyan-800 text-white' 
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              <BiSolidPurchaseTag />
              <div className="ml-2">Submit</div>
            </button>
          </div>
        </form>
      </div>
    
      {/* Modal for Rules and Regulations */}
      {isModalOpen === "rules" && (
        <FundedRuleTerm 
          onClose={() => setIsModalOpen(null)}
        />
      )}

      {/* Modal for Terms and Conditions */}
      {isModalOpen === "terms" && (
        <TermsAndConditions 
          onClose={() => setIsModalOpen(null)}
        />
      )}

      {/* Add Alert component */}
      <MyTicketAlert
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onClose={() => setAlert(prev => ({ ...prev, visible: false }))}
      />
    </>
  );
};

export default FundedRegistration;


// // udpate code --> 30 dec 2024
// // src/components/client/MiniApp/App02_MyFundRegistration.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// // import react icon
// import { IoMdClose } from 'react-icons/io';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaClipboard } from 'react-icons/fa';
// // import custom hook file
// import useFundedRegistration from '../../../hooks/MiniApp/useFundedRegistration';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// // import term context file
// import FundedRuleTerm from '../../../context/FundedRuleTerm'; 
// import TermsAndConditions from '../../../context/FundedTerm';

// interface FundedRegistrationProps {
//   onClose: () => void;
//   fundProgram: {
//     fundOrderId: string;
//     fundLinkEvalId: string;
//     fundProgramSize: number;
//     fundProgramAmount: number;
//     fundUserName: string;
//     fundUserEmail: string;
//     fundEvalBeforeProgram: string;
//     fundEvalBeforeStage: string;
//     fundRuleAgreed: string,
//     fundTermAgreed: string,
//     fundPaymentMethod: string,
//     fundPaymentReceipts: string,
//     fundRegNotes: string,
//   };
// }

// // Function to generate order ID based on product name
// const generateOrderId = () => {
//   const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase(); 
//   const randomDigits = String(Math.floor(1000 + Math.random() * 9000)); 
//   return `sub-FUND-${randomLetters}${randomDigits}`;
// }; 

// const FundedRegistration: React.FC<FundedRegistrationProps> = ({ onClose, fundProgram }) => {
//   const { registerUser } = useFundedRegistration();
//   const { user } = useUserDashboard();
//   const [isModalOpen, setIsModalOpen] = useState<null | "rules" | "terms">(null);
//   const [paymentMethod, setPaymentMethod] = useState<string>('');
//   const [formData, setFormData] = useState({
//     fundOrderId: '',
//     fundLinkEvalId: '',
//     fundProgramSize: 0,
//     fundProgramAmount: 0,
//     fundUserName: '',
//     fundUserEmail: '',
//     fundEvalBeforeProgram: '',
//     fundEvalBeforeStage: '',
//     fundRuleAgreed: '',
//     fundTermAgreed: '',
//     fundPaymentMethod: '',
//     fundPaymentReceipts: '',
//     fundRegNotes: '',
//   });

//   // Log user data for debugging
//   useEffect(() => {
//     if (user) {
//       // console.log('User data loaded:', user);
//       setFormData((prev) => ({
//         ...prev,
//         fundOrderId: prev.fundOrderId || generateOrderId(),
//         fundUserName: user.username || '',
//         fundUserEmail: user.email || '',
//         fundLinkEvalId: fundProgram.fundLinkEvalId || '',
//         fundProgramAmount: fundProgram.fundProgramAmount || 0,
//         fundProgramSize: fundProgram.fundProgramSize || 0,
//         fundEvalBeforeProgram: prev.fundEvalBeforeProgram || 'New',
//         fundEvalBeforeStage: prev.fundEvalBeforeStage || 'New',
//         fundPaymentMethod: paymentMethod || prev.fundPaymentMethod,
//       }));
//     }
//   }, [user, fundProgram, paymentMethod]);
  
//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     const { name, value } = e.target;
//     console.log(`Input changed - ${name}:`, value);
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleProgramEvaluationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     console.log(`Program evaluation change - ${name}:`, value); 
//     setFormData({ ...formData, [name]: value || null }); 
//   };

//   const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, checked } = e.target;
//     console.log(`Checkbox changed - ${name}:`, checked);
//     setFormData({ ...formData, [name]: checked ? 'Agreed' : 'Disagreed' });
//   };  

//   const handleSubmit = async () => {
//     console.log('Form submitted with data:', formData);

//     // Ensure both checkboxes are checked before submission
//     if (formData.fundRuleAgreed !== 'Agreed' || formData.fundTermAgreed !== 'Agreed') {
//       alert('Please accept the Rules and Regulations and Terms and Conditions before submitting.');
//       return;
//     }

//     const payload = {
//       ...formData,
//       fundLinkEvalId: fundProgram.fundLinkEvalId, 
//       fundProgramAmount: fundProgram.fundProgramAmount,
//       fundRuleAgreed: formData.fundRuleAgreed || 'Agreed',
//       fundTermAgreed: formData.fundTermAgreed || 'Agreed',
//     };
//     console.log('Submitting payload:', payload);

//     try {
//       await registerUser(payload);
//       console.log('Registration submitted successfully'); 
//       alert('Registration Submitted for Approval');
//       onClose();
//     } catch (error) {
//       console.error('Error during registration submission:', error); 
//       alert('An error occurred during submission. Please try again.');
//     }
//   };

//   return (
//     <>
//       <div className="bg-white p-6 rounded-lg w-auto max-w-[600px] h-auto max-h-[650px] overflow-y-scroll px-4 md:text-md md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <button 
//           className="absolute top-2 right-2 text-gray-600" 
//           onClick={onClose}
//         >
//           <IoMdClose size={20} />
//         </button>

//         <form>
//           {/* Header */}
//           <div className="flex justify-center sm:my-0 xs:my-0">
//             <span className="text-md bg-cyan-800 text-white px-4 py-2 rounded-full">
//               Register for {fundProgram.fundLinkEvalId}
//             </span>
//           </div>

//           {/* Order ID */}
//           <div className="my-6">
//             <label className="mx-2 font-semibold">Order ID:</label>
//             <input
//               className="w-full px-3 py-2 border rounded-md"
//               type="text"
//               value={generateOrderId()}
//               disabled
//             />
//           </div>

//           {/* Program Name with Id */}
//           <div className="my-6">
//             <label className="mx-2 font-semibold">Program Name:</label>
//             <input
//               className="w-full px-3 py-2 border rounded-md"
//               type="text"
//               value={fundProgram.fundLinkEvalId || 'N/A'}
//               disabled
//             />
//           </div>

//           {/* Evaluation Full Name */}
//           <div className="my-6">
//             <label className="mx-2 font-semibold">Full Name:</label>
//             <input
//               type="text"
//               name="fundUserName"
//               className="w-full border p-2 rounded"
//               value={formData.fundUserName}
//               disabled
//             />
//           </div>

//           {/* Evaluation Email */}
//           <div className="my-6">
//             <label className="mx-2 font-semibold">Email:</label>
//             <input
//               type="email"
//               name="fundUserEmail"
//               className="w-full border p-2 rounded"
//               value={formData.fundUserEmail}
//               disabled
//             />
//           </div>

//           {/* Join Program Before */}
//           <div className="my-6">
//             <label className="mx-2 font-semibold">Join Evalution Program Before:</label>
//             <select
//               className="w-full px-3 py-2 border rounded-md"
//               name="fundEvalBeforeProgram"
//               value={formData.fundEvalBeforeProgram || ''}
//               onChange={handleProgramEvaluationChange}
//             >
//               <option value="">Select program before:</option>
//               <option value="180">R One 180 Program</option>
//               <option value="Go4Funds">R One Go4Funds Program</option>
//               <option value="New">New</option>
//             </select>
//           </div>

//           {/* Join Program Stage Before */}
//           <div className="my-6">
//             <label className="mx-2 font-semibold">Join Evalution Program Before Progress:</label>
//             <select
//               className="w-full px-3 py-2 border rounded-md"
//               name="fundEvalBeforeStage"
//               value={formData.fundEvalBeforeStage || ''}
//               onChange={handleProgramEvaluationChange}
//             >
//               <option value="">Select progress before:</option>
//               <option value="Stage 01">Stage 01</option>
//               <option value="Stage 02">Stage 02</option>
//               <option value="Stage 03">Stage 03</option>
//               <option value="Live Funds">Live Funds</option>
//               <option value="New">New</option>
//             </select>
//           </div>

//           {/* Program Payment Amount */}
//           <div className="my-6">
//             <label className="mx-2 font-semibold">Payment Amount:</label>
//             <input
//               type="number"
//               name="fundProgramAmount"
//               className="w-full border p-2 rounded"
//               value={fundProgram.fundProgramAmount || 0}
//               disabled
//             />
//           </div>

//           {/* Payment Method */}
//           <div className="my-6">
//             <label className="ml-2 font-semibold">Payment Method</label>
//             <div className="flex items-center mx-4">
//               <input 
//                 type="radio" 
//                 name="payment" 
//                 value="USDT" 
//                 className="accent-cyan-800" 
//                 onChange={() => setPaymentMethod('USDT')}
//               />
//               <label className="ml-2">USDT (Cryptocurrency)</label>
//             </div>
//             {paymentMethod === 'USDT' && (
//               <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4
//                     sm:text-xs sm:p-2
//                     xs:text-[10px] xs:p-2"
//               >
//                 <span>Wallet Address:</span><br />
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <li>Tron (TRC20) Network</li>
//                     <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                   </div>
//                   <FaClipboard 
//                     className="ml-2 cursor-pointer w-6 h-6
//                       sm:w-4 sm:h-4 sm:ml-0
//                       xs:w-3 xs:h-3 xs:ml-0
//                     "
//                     onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                   />
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* Payment Receipts */}
//           <div className="my-6">
//             <label className="ml-2 font-semibold">Payment Receipts</label>
//             <input className="w-full px-3 py-2 border rounded-md" type="file" />
//           </div>

//           {/* Notes */}
//           <div className="my-6">
//             <label className="mx-2 font-semibold">Notes</label>
//             <textarea
//               name="fundRegNotes"
//               onChange={handleInputChange}
//               className="w-full border p-2 rounded"
//               placeholder="Any additional notes..."
//             ></textarea>
//           </div>

//           <div className="my-6 space-y-2">
//             {/* Rule and Regulation */}
//             <div className="flex justify-normal items-center">
//               <input 
//                 type="checkbox" 
//                 name="fundRuleAgreed" 
//                 className="accent-cyan-800 mr-2 w-4 h-4" 
//                 checked={formData.fundRuleAgreed === 'Agreed'}
//                 onChange={handleCheckboxChange}
//               />
//               <span 
//                 className="cursor-pointer text-cyan-800 hover:underline ml-2"
//                 onClick={() => setIsModalOpen("rules")}
//               >
//                 I Accept Rules and Regulations
//               </span>
//             </div>

//             {/* Terms and Conditions */}
//             <div className="flex justify-normal items-center">
//               <input 
//                 type="checkbox"
//                 name="fundTermAgreed" 
//                 className="accent-cyan-800 mr-2 w-4 h-4" 
//                 checked={formData.fundTermAgreed === 'Agreed'}
//                 onChange={handleCheckboxChange}
//               />
//               <span 
//                 className="cursor-pointer text-cyan-800 hover:underline ml-2"
//                 onClick={() => setIsModalOpen("terms")}
//               >
//                 I Accept Terms and Conditions
//               </span>
//             </div>
//           </div>

//           {/* Submit and Back Buttons */}
//           <div className="flex justify-between items-center mt-8 space-x-4">
//             <button
//               onClick={onClose}
//               className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-200"
//             >
//               <FaArrowCircleLeft />
//               <div className="ml-2">Back to Details</div>
//             </button>
//             <button
//               type="button"
//               onClick={handleSubmit}
//               disabled={formData.fundRuleAgreed !== 'Agreed' || formData.fundTermAgreed !== 'Agreed'}
//               className={`px-4 py-2 rounded-md flex items-center justify-center ${
//                 formData.fundRuleAgreed === 'Agreed' && formData.fundTermAgreed === 'Agreed' 
//                   ? 'bg-cyan-800 text-white' 
//                   : 'bg-gray-300 text-gray-500 cursor-not-allowed'
//               }`}
//             >
//               <BiSolidPurchaseTag />
//               <div className="ml-2">Submit</div>
//             </button>
//           </div>
//         </form>
//       </div>
    
//       {/* Modal for Rules and Regulations */}
//       {isModalOpen === "rules" && (
//         <FundedRuleTerm 
//           onClose={() => setIsModalOpen(null)}
//         />
//       )}

//       {/* Modal for Terms and Conditions */}
//       {isModalOpen === "terms" && (
//         <TermsAndConditions 
//           onClose={() => setIsModalOpen(null)}
//         />
//       )}
//     </>
//   );
// };

// export default FundedRegistration;

