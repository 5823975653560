// src/components/admin/FundedProgram/FundProgramPendingModal.tsx

import React, { useState } from 'react';
// import { useEnv } from '../../../context/EnvContext';
import useFundProgramUserData from '../../../hooks/AdminDashboard/useFundProgramPending';
import useFundProgramSetting from '../../../hooks/AdminDashboard/useFundProgramSetting';

interface AddPendingUserModalProps {
  onClose: () => void;
  onSuccess: () => void;
}
// Function to generate order ID based on product name
const generateOrderId = () => {
  const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase(); 
  const randomDigits = String(Math.floor(1000 + Math.random() * 9000)); 
  return `sub-FUND-${randomLetters}${randomDigits}`;
}; 

const FundProgramPendingModal: React.FC<AddPendingUserModalProps> = ({ onClose, onSuccess }) => {
//   const { apiUrl } = useEnv();
  const { addPendingUser } = useFundProgramUserData();
  const { programs } = useFundProgramSetting();
  const [formData, setFormData] = useState({
    fundOrderId: generateOrderId(),
    fundUserName: '',
    fundUserEmail: '',
    fundLinkEvalId: '',
    fundProgramSize: '',
    fundProgramAmount: '',
    fundEvalBeforeProgram: '',
    fundEvalBeforeStage: '',
    fundPaymentMethod: '',
    fundPaymentReceipts: '',
    fundRegNotes: '',
    fundRuleAgreed: 'Agreed',
    fundTermAgreed: 'Agreed'
  });

  // Add this handler for fund name selection
  const handleFundNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFundName = e.target.value;
    const selectedProgram = programs.find(program => program.fundNameSet === selectedFundName);

    setFormData(prev => ({
      ...prev,
      fundLinkEvalId: selectedFundName,
      fundProgramSize: selectedProgram ? selectedProgram.fundSizeSet : '',
      fundProgramAmount: selectedProgram ? String(selectedProgram.fundAmountSet) : ''
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const success = await addPendingUser({
        ...formData,
        fundProgramSize: Number(formData.fundProgramSize),
        fundProgramAmount: Number(formData.fundProgramAmount)
      });
      if (success) {
        onSuccess();
      }
    } catch (error) {
      console.error('Error adding pending user:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4 text-cyan-800">Add New Pending User</h2>
        <form>
          <div className="w-[600px] max-h-[800px] overflow-y-auto space-y-4">
            {/* User Information */}
            <div className="border p-4 rounded-lg">
              <h3 className="font-semibold mb-2">User Information</h3>
              <div className="grid grid-row-2 gap-4 mx-2">
                <div>
                  <label className="block text-sm font-medium mb-1">Order Id</label>
                  <input
                    type="text"
                    name="fundOrderId"
                    value={formData.fundOrderId}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    disabled
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">User Name</label>
                  <input
                    type="text"
                    name="fundUserName"
                    value={formData.fundUserName}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">User Email</label>
                  <input
                    type="email"
                    name="fundUserEmail"
                    value={formData.fundUserEmail}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Fund Information */}
            <div className="border p-4 rounded-lg">
              <h3 className="font-semibold mb-2">Fund Information</h3>
              <div className="grid grid-row-2 gap-4 mx-2">
                <div>
                  <label className="block text-sm font-medium mb-1">Fund Name</label>
                  <select
                    name="fundLinkEvalId"
                    value={formData.fundLinkEvalId}
                    onChange={handleFundNameChange} 
                    className="w-full p-2 border rounded"
                    required
                  >
                    <option value="">Select Fund Name</option>
                    {programs.map((program) => (
                      <option key={program.fundSetId} value={program.fundNameSet}>
                        {program.fundNameSet}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Program Size</label>
                  <select
                    name="fundProgramSize"
                    value={formData.fundProgramSize}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    disabled
                  >
                    <option value="">Select Program Size</option>
                    {programs.map((program) => (
                      <option key={program.fundSetId} value={program.fundSizeSet}>
                        {program.fundSizeSet}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Program Amount</label>
                  <select
                    name="fundProgramAmount"
                    value={formData.fundProgramAmount}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    disabled
                  >
                    <option value="">Select Program Amount</option>
                    {programs.map((program) => (
                      <option key={program.fundSetId} value={program.fundAmountSet}>
                        {program.fundAmountSet}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Previous Program Information */}
            <div className="border p-4 rounded-lg">
              <h3 className="font-semibold mb-2">Previous Program Information</h3>
              <div className="grid grid-row-2 gap-4 mx-2">
                <div>
                  <label className="block text-sm font-medium mb-1">Previous Program</label>
                  <select
                    name="fundEvalBeforeProgram"
                    value={formData.fundEvalBeforeProgram}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select a option</option>
                    <option value="180">180</option>
                    <option value="Go4Funds">Go4Funds</option>
                    <option value="New">New</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Previous Stage</label>
                  <select
                    name="fundEvalBeforeStage"
                    value={formData.fundEvalBeforeStage}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select a option</option>
                    <option value="Stage 01">Stage 01</option>
                    <option value="Stage 02">Stage 02</option>
                    <option value="Stage 03">Stage 03</option>
                    <option value="Live Funds">Live Funds</option>
                    <option value="New">New</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Payment Information */}
            <div className="border p-4 rounded-lg">
              <h3 className="font-semibold mb-4">Payment Information</h3>
              <div className="grid grid-row-2 gap-4 mx-2">
                <div>
                  <label className="block text-sm font-medium mb-1">Payment Method</label>
                  <select
                    name="fundPaymentMethod"
                    value={formData.fundPaymentMethod}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select Payment Method</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="USDT">USDT</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Payment Receipt</label>
                  <input
                    type="file"
                    name="fundPaymentReceipts"
                    value={formData.fundPaymentReceipts}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
            </div>

            {/* Notes */}
            <div className="border p-4 rounded-lg">
              <h3 className="font-semibold mb-2 text-cyan-800">
                Additional Notes
              </h3>
              <textarea
                name="fundRegNotes"
                value={formData.fundRegNotes}
                onChange={handleChange}
                className="w-full p-2 border rounded mx-2"
                rows={3}
              />
            </div>
          </div>
        </form>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2 mt-6">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded-md"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="px-4 py-2 bg-cyan-800 text-white rounded-md"
          >
            Add User
          </button>
        </div>
      </div>
    </div>
  );
};

export default FundProgramPendingModal;