// src/AppVersion.tsx

import React from 'react';
import packageJson from '../package.json';

const Version: React.FC = () => {
  return (
    <span className="version-text">
      Ver: {packageJson.version}
    </span>
  );
};

export default Version;
