// src/components/admin.FetchLogs/AlgoMatrixLogViewer.tsx

import React, { useState } from 'react';
import { BsToggleOn, BsToggleOff } from 'react-icons/bs';
import { useFetchLogs } from '../../../hooks/AdminDashboard/useFetchLogs';

const LogViewer: React.FC = () => {
  const [broker, setBroker] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [keywords, setKeywords] = useState<string[]>(['All']);
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const [textSize, setTextSize] = useState(22);
  const [keywordColors, setKeywordColors] = useState<Record<string, string>>({
    All: '#000000',
    Initialize: '#fc0303',
    Deinitialize: '#0af230',
    Event: '#2e96ff',
    Function: '#f2db0a',
    Run: '#ff0ff3',
  });
  const [searchTerm, setSearchTerm] = useState('');

  const { brokers, logs, isLoading, error, fetchLogs } = useFetchLogs();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetchLogs({ broker, accountNumber, startDate, endDate, keywords });
  };

  const handleKeywordChange = (keyword: string) => {
    if (keyword === 'All') {
      setKeywords(['All']);
    } else {
      setKeywords((prev) => {
        const newKeywords = prev.includes(keyword)
          ? prev.filter((k) => k !== keyword) // Remove the keyword
          : [...prev, keyword]; // Add the keyword
  
        // If the new state becomes empty, default back to 'All'
        return newKeywords.length === 0 ? ['All'] : newKeywords.filter((k) => k !== 'All');
      });
    }
  };  

  const handleThemeChange = () => {
    setTheme((prev) => (prev === 'light' ? 'dark' : 'light'));
  };

  const handleTextSizeChange = (size: number) => {
    setTextSize(size);
  };

  const handleColorChange = (keyword: string, color: string) => {
    setKeywordColors((prev) => ({
      ...prev,
      [keyword]: color,
    }));
  };

  const themeClasses = {
    light: 'bg-gray-50 text-black',
    dark: 'bg-gray-800 text-white',
  };

  const highlightKeywords = (content: string): string => {
    const searchTerms = searchTerm.split(',').map(term => term.trim()).filter(Boolean); 
    const allKeywords = [...keywords.filter((k) => k !== 'All'), ...searchTerms];
    const uniqueKeywords = Array.from(
      allKeywords.reduce((acc, keyword) => {
        if (keyword.trim()) acc.add(keyword);
        return acc;
      }, new Set<string>())
    );
  
    if (uniqueKeywords.length === 0) return content;
  
    // Build regex dynamically for all keywords, allowing partial matches
    const keywordRegex = new RegExp(`(${uniqueKeywords.join('|')})`, 'gi');
    return content.replace(keywordRegex, (match) => {
      const isSearchTerm = searchTerms.some(term => term.toLowerCase() === match.toLowerCase());
      const color = isSearchTerm ? '#ff0000' : keywordColors[match] || '#ffa500';
      const backgroundColor = isSearchTerm ? '#53f5a1' : 'transparent';
      return `<span style="color: ${color}; font-weight: bold; background-color: ${backgroundColor};">${match}</span>`;
    });
  };     

  return (
    <div className="mx-6">
      {/* Logs Filter */}
      <form className="bg-white w-auto border rounded py-4 px-6" onSubmit={handleSubmit}>
        <div className="flex flex-col justify-self-center">
          <div className="flex flex-row space-x-2">
            {/* Broker Selection */}
            <div className="mb-4">
              <label htmlFor="broker" className="block text-gray-700 text-sm font-bold mb-2">
                Broker:
              </label>
              <select
                id="broker"
                value={broker}
                onChange={(e) => setBroker(e.target.value)}
                className="border rounded w-full py-2 px-4 text-gray-700 focus:outline-none"
                required
              >
                <option value="" disabled>
                  Select Broker
                </option>
                {brokers.map((broker) => (
                  <option key={broker} value={broker}>
                    {broker}
                  </option>
                ))}
              </select>
            </div>

            {/* Account Number */}
            <div className="mb-4">
              <label
                htmlFor="accountNumber"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Account Number:
              </label>
              <input
                id="accountNumber"
                type="text"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>

            {/* Start Date */}
            <div className="mb-4">
              <label htmlFor="startDate" className="block text-gray-700 text-sm font-bold mb-2">
                Start Date:
              </label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>

            {/* End Date */}
            <div className="mb-4">
              <label htmlFor="endDate" className="block text-gray-700 text-sm font-bold mb-2">
                End Date:
              </label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>

            {/* Search Bar */}
            <div className="mb-4">
              <label htmlFor="search" className="block text-gray-700 text-sm font-bold mb-2">
                Search Keyword:
              </label>
              <input
                id="search"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Type to search in logs"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Fetch Logs Button */}
            <div className="mt-6">
              <button
                type="submit"
                className="bg-cyan-800 hover:bg-cyan-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Fetch Logs
              </button>
            </div>
          </div>

          {/* Keywords and Settings */}
          <div className="flex flex-row justify-start space-x-8 items-center">
            {/* Keywords Selection */}
            <fieldset className="mb-4">
              <legend className="block text-cyan-800 text-sm font-bold mb-2">
                Select Keywords:
              </legend>
              <div className="flex flex-wrap gap-4">
                {Object.keys(keywordColors).map((keyword) => (
                  <div key={keyword} className="flex items-center gap-4">
                    <label className="flex items-center text-gray-700 text-sm font-bold">
                      <input
                        type="checkbox"
                        value={keyword}
                        checked={keywords.includes(keyword)}
                        onChange={() => handleKeywordChange(keyword)}
                        className="mr-2 leading-tight accent-cyan-800"
                      />
                      {keyword}
                    </label>
                    {keyword !== 'All' && (
                      <input
                        type="color"
                        value={keywordColors[keyword]}
                        onChange={(e) => handleColorChange(keyword, e.target.value)}
                        className="w-8 h-8"
                      />
                    )}
                  </div>
                ))}
              </div>
            </fieldset>

            {/* Text Size Adjustment */}
            <div className="flex flex-col items-center mb-4">
              <label
                htmlFor="textSize"
                className="block text-cyan-800 text-sm font-bold mb-2"
              >
                Text Size:
              </label>
              <input
                type="range"
                id="textSize"
                min="10"
                max="20"
                value={textSize}
                onChange={(e) => handleTextSizeChange(Number(e.target.value))}
                className="w-32 accent-cyan-800"
              />
            </div>

            {/* Theme Toggle */}
            <div className="flex items-center justify-end mt-3">
              <button
                onClick={handleThemeChange}
                className="flex items-center text-2xl focus:outline-none"
              >
                {theme === 'light' ? (
                  <>
                    <BsToggleOff className="text-cyan-800" />
                    <span className="ml-2 text-sm font-medium">Light Mode</span>
                  </>
                ) : (
                  <>
                    <BsToggleOn className="text-cyan-800" />
                    <span className="ml-2 text-sm font-medium">Dark Mode</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* Loading and Error Messages */}
      {isLoading && <p className="text-cyan-800 border py-2 px-10 text-center">Loading...</p>}
      {error && <p className="text-red-500 border py-2 px-10 text-center">Error: {error}</p>}

      {/* Logs Viewer with Pointer */}
      <div className="flex justify-center w-auto h-auto max-h-[750px] max-w-[1600px] ">
        {/* Log Viewer */}
        <div
          className={`
            flex flex-col mt-4 border-2 
            overflow-auto scrollbar scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack
            ${themeClasses[theme]}
          `}
        >
          {logs.length === 0 && !isLoading && (
            <div className="text-gray-500 text-center p-6">
              No logs available for the selected filters.
            </div>
          )}
          {logs.map((log, index) => (
            <div id={`log-${index}`} key={index} className="p-4">
              {/* File Name Header */}
              <h3
                className="text-3xl text-center font-bold mb-2 p-4"
                style={{
                  backgroundColor: theme === 'light' ? '#e0f7fa' : '#004d40',
                  color: theme === 'light' ? '#00796b' : '#ffffff',
                  borderRadius: theme === 'light' ? '8px' : '50px',
                }}
              >
                File: {log.fileName}
              </h3>

               {/* Log Viewer Content */}
              <pre
                className="p-4 rounded overflow-auto"
                style={{
                  fontSize: `${textSize}px`,
                  whiteSpace: 'pre-wrap', 
                }}
                dangerouslySetInnerHTML={{
                  __html: highlightKeywords(log.content),
                }}
              />
            </div>
          ))}
        </div>

        {/* Scrollable Index (Pointer) */}
        <div className="w-[300px] text-center text-xl mt-4 overflow-y-auto border">
          {logs.map((log, index) => {
            const dateMatch = log.fileName.match(/(\d{4}-\d{2}-\d{2})/); // Extract date
            const date = dateMatch ? dateMatch[1] : 'Unknown Date';
            return (
              <button
                key={index}
                onClick={() =>
                  document
                    .getElementById(`log-${index}`)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
                className="block px-4 py-2 hover:bg-cyan-800 hover:text-white text-cyan-800 font-bold"
              >
                {date}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LogViewer;

// // udpate code --> 20 dec 2024
// // src/components/admin.FetchLogs/AlgoMatrixLogViewer.tsx

// import React, { useState } from 'react';
// import { BsToggleOn, BsToggleOff } from 'react-icons/bs';
// import { useFetchLogs } from '../../../hooks/AdminDashboard/useFetchLogs';

// const LogViewer: React.FC = () => {
//   const [broker, setBroker] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [keywords, setKeywords] = useState<string[]>(['All']);
//   const [theme, setTheme] = useState<'light' | 'dark'>('light');
//   const [textSize, setTextSize] = useState(22);
//   const [keywordColors, setKeywordColors] = useState<Record<string, string>>({
//     All: '#000000',
//     Initialize: '#fc0303',
//     Deinitialize: '#0af230',
//     Event: '#2e96ff',
//     Function: '#f2db0a',
//     Run: '#ff0ff3',
//   });

//   const { brokers, logs, isLoading, error, fetchLogs } = useFetchLogs();

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     fetchLogs({ broker, accountNumber, startDate, endDate, keywords });
//   };

//   const handleKeywordChange = (keyword: string) => {
//     if (keyword === 'All') {
//       setKeywords(['All']);
//     } else {
//       setKeywords((prev) => {
//         const newKeywords = prev.includes(keyword)
//           ? prev.filter((k) => k !== keyword) // Remove the keyword
//           : [...prev, keyword]; // Add the keyword
  
//         // If the new state becomes empty, default back to 'All'
//         return newKeywords.length === 0 ? ['All'] : newKeywords.filter((k) => k !== 'All');
//       });
//     }
//   };  

//   const handleThemeChange = () => {
//     setTheme((prev) => (prev === 'light' ? 'dark' : 'light'));
//   };

//   const handleTextSizeChange = (size: number) => {
//     setTextSize(size);
//   };

//   const handleColorChange = (keyword: string, color: string) => {
//     setKeywordColors((prev) => ({
//       ...prev,
//       [keyword]: color,
//     }));
//   };

//   const themeClasses = {
//     light: 'bg-gray-50 text-black',
//     dark: 'bg-gray-800 text-white',
//   };

//   const highlightKeywords = (content: string): string => {
//     if (keywords.includes('All')) return content; // No highlighting for 'All'
//     if (keywords.length === 0) return content; // No keywords to highlight
  
//     return content.replace(
//       new RegExp(`\\b(${keywords.join('|')})\\b`, 'g'),
//       (match) => `<span style="color: ${keywordColors[match]}; font-weight: bold;">${match}</span>`
//     );
//   };  

//   return (
//     <div className="mx-6">
//       {/* Logs Filter */}
//       <form className="bg-white w-auto border rounded py-4 px-6" onSubmit={handleSubmit}>
//         <div className="flex flex-col justify-self-center">
//           <div className="flex flex-row space-x-2">
//             {/* Broker Selection */}
//             <div className="mb-4">
//               <label htmlFor="broker" className="block text-gray-700 text-sm font-bold mb-2">
//                 Broker:
//               </label>
//               <select
//                 id="broker"
//                 value={broker}
//                 onChange={(e) => setBroker(e.target.value)}
//                 className="border rounded w-full py-2 px-4 text-gray-700 focus:outline-none"
//                 required
//               >
//                 <option value="" disabled>
//                   Select Broker
//                 </option>
//                 {brokers.map((broker) => (
//                   <option key={broker} value={broker}>
//                     {broker}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             {/* Account Number */}
//             <div className="mb-4">
//               <label
//                 htmlFor="accountNumber"
//                 className="block text-gray-700 text-sm font-bold mb-2"
//               >
//                 Account Number:
//               </label>
//               <input
//                 id="accountNumber"
//                 type="text"
//                 value={accountNumber}
//                 onChange={(e) => setAccountNumber(e.target.value)}
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 required
//               />
//             </div>

//             {/* Start Date */}
//             <div className="mb-4">
//               <label htmlFor="startDate" className="block text-gray-700 text-sm font-bold mb-2">
//                 Start Date:
//               </label>
//               <input
//                 id="startDate"
//                 type="date"
//                 value={startDate}
//                 onChange={(e) => setStartDate(e.target.value)}
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 required
//               />
//             </div>

//             {/* End Date */}
//             <div className="mb-4">
//               <label htmlFor="endDate" className="block text-gray-700 text-sm font-bold mb-2">
//                 End Date:
//               </label>
//               <input
//                 id="endDate"
//                 type="date"
//                 value={endDate}
//                 onChange={(e) => setEndDate(e.target.value)}
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 required
//               />
//             </div>

//             {/* Keyword input Searchbar */}
//             <div className="mb-4">
//               <label htmlFor="endDate" className="block text-gray-700 text-sm font-bold mb-2">
//                 Saerch Keywords:
//               </label>
//               <input
//                 id="endDate"
//                 type="date"
//                 value={endDate}
//                 onChange={(e) => setEndDate(e.target.value)}
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 required
//               />
//             </div>

//             {/* Fetch Logs Button */}
//             <div className="mt-6">
//               <button
//                 type="submit"
//                 className="bg-cyan-800 hover:bg-cyan-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//               >
//                 Fetch Logs
//               </button>
//             </div>
//           </div>

//           {/* Keywords and Settings */}
//           <div className="flex flex-row justify-start space-x-8 items-center">
//             {/* Keywords Selection */}
//             <fieldset className="mb-4">
//               <legend className="block text-cyan-800 text-sm font-bold mb-2">
//                 Select Keywords:
//               </legend>
//               <div className="flex flex-wrap gap-4">
//                 {Object.keys(keywordColors).map((keyword) => (
//                   <div key={keyword} className="flex items-center gap-4">
//                     <label className="flex items-center text-gray-700 text-sm font-bold">
//                       <input
//                         type="checkbox"
//                         value={keyword}
//                         checked={keywords.includes(keyword)}
//                         onChange={() => handleKeywordChange(keyword)}
//                         className="mr-2 leading-tight accent-cyan-800"
//                       />
//                       {keyword}
//                     </label>
//                     {keyword !== 'All' && (
//                       <input
//                         type="color"
//                         value={keywordColors[keyword]}
//                         onChange={(e) => handleColorChange(keyword, e.target.value)}
//                         className="w-8 h-8"
//                       />
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </fieldset>

//             {/* Text Size Adjustment */}
//             <div className="flex flex-col items-center mb-4">
//               <label
//                 htmlFor="textSize"
//                 className="block text-cyan-800 text-sm font-bold mb-2"
//               >
//                 Text Size:
//               </label>
//               <input
//                 type="range"
//                 id="textSize"
//                 min="10"
//                 max="20"
//                 value={textSize}
//                 onChange={(e) => handleTextSizeChange(Number(e.target.value))}
//                 className="w-32 accent-cyan-800"
//               />
//             </div>

//             {/* Theme Toggle */}
//             <div className="flex items-center justify-end mt-3">
//               <button
//                 onClick={handleThemeChange}
//                 className="flex items-center text-2xl focus:outline-none"
//               >
//                 {theme === 'light' ? (
//                   <>
//                     <BsToggleOff className="text-cyan-800" />
//                     <span className="ml-2 text-sm font-medium">Light Mode</span>
//                   </>
//                 ) : (
//                   <>
//                     <BsToggleOn className="text-cyan-800" />
//                     <span className="ml-2 text-sm font-medium">Dark Mode</span>
//                   </>
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//       </form>

//       {/* Loading and Error Messages */}
//       {isLoading && <p>Loading...</p>}
//       {error && <p className="text-red-500">Error: {error}</p>}

//       {/* Logs Viewer with Pointer */}
//       <div className="flex justify-center w-auto h-auto max-h-[750px] max-w-[1600px] ">
//         {/* Log Viewer */}
//         <div
//           className={`flex flex-col mt-4 border-2 overflow-auto ${themeClasses[theme]}`}
//         >
//           {logs.length === 0 && !isLoading && (
//             <div className="text-gray-500 text-center p-6">
//               No logs available for the selected filters.
//             </div>
//           )}
//           {logs.map((log, index) => (
//             <div id={`log-${index}`} key={index} className="p-4">
//               {/* File Name Header */}
//               <h3
//                 className="text-3xl text-center font-bold mb-2 p-4"
//                 style={{
//                   backgroundColor: theme === 'light' ? '#e0f7fa' : '#004d40',
//                   color: theme === 'light' ? '#00796b' : '#ffffff',
//                   borderRadius: theme === 'light' ? '8px' : '50px',
//                 }}
//               >
//                 File: {log.fileName}
//               </h3>

//                {/* Log Viewer Content */}
//               <pre
//                 className="p-4 rounded overflow-auto"
//                 style={{
//                   fontSize: `${textSize}px`,
//                   whiteSpace: 'pre-wrap', 
//                 }}
//                 dangerouslySetInnerHTML={{
//                   __html: highlightKeywords(log.content),
//                 }}
//               />
//             </div>
//           ))}
//         </div>

//         {/* Scrollable Index (Pointer) */}
//         <div className="w-[300px] text-center text-xl mt-4 overflow-y-auto border">
//           {logs.map((log, index) => {
//             const dateMatch = log.fileName.match(/(\d{4}-\d{2}-\d{2})/); // Extract date
//             const date = dateMatch ? dateMatch[1] : 'Unknown Date';
//             return (
//               <button
//                 key={index}
//                 onClick={() =>
//                   document
//                     .getElementById(`log-${index}`)
//                     ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
//                 }
//                 className="block px-4 py-2 hover:bg-cyan-800 hover:text-white text-cyan-800 font-bold"
//               >
//                 {date}
//               </button>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LogViewer;