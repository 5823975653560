// src/hooks/MiniApp/useFundedRegistration.tsx

import { useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

const useFundedRegistration = () => {
  const { apiUrl } = useEnv();

  const registerUser = useCallback(async (data: any) => {
    try {
      const response = await fetch(`${apiUrl}/api/fund/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to submit registration');
      }
      return await response.json();
    } catch (error) {
      console.error('Error registering user:', error);
    }
  }, [apiUrl]);

  return { registerUser };
};

export default useFundedRegistration;
