// src/hooks/useGeoIPCheck.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../context/EnvContext';

interface GeoData {
  country_code: string;
  ip?: string;
}

const useGeoIPCheck = (mode: 'live' | 'mock-tw' | 'mock-my' | 'mock-cn' ) => {
  const [geoData, setGeoData] = useState<GeoData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { apiUrl } = useEnv();

  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        let endpoint = `${apiUrl}/geoip`; 

        // Choose the endpoint based on mode
        if (mode === 'mock-tw') {
          endpoint = `${apiUrl}/geoip/mock/tw`;
        } else if (mode === 'mock-my') {
          endpoint = `${apiUrl}/geoip/mock/my`;
        } else {
          endpoint = `${apiUrl}/geoip/mock/cn`;
        }

        const response = await fetch(endpoint);
        if (response.ok) {
          const data = await response.json();
          // console.log(`GeoIP Data Received [${mode}]:`, data);
          setGeoData(data);
        } else {
          console.error('Failed to fetch geo IP data');
        }
      } catch (error) {
        console.error('Error during geo IP fetch:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGeoData();
  }, [apiUrl, mode]);

  return { geoData, isLoading };
};

export default useGeoIPCheck;



