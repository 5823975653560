// src/components/UpdateUserData.tsx

import React, { useEffect } from 'react';
import { useFormValidation, countryOptions, stateOptions, phoneOptions  } from '../hooks/Login/useFormValidation'; 
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useEventRegTerms } from '../context/EventRegTermContext';
import useUserDashboard from '../hooks/Dashboard/useUserDashboard';

interface UpdateUserDataProps {
  visible: boolean;
  onSave: (data: any) => void;
  onClose: () => void;
}

const UpdateUserData: React.FC<UpdateUserDataProps> = ({ visible, onSave, onClose }) => {
  const { user } = useUserDashboard();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isAgree, setIsAgree] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const termsAndConditions = useEventRegTerms();
  const formik = useFormValidation(onClose);

  // Pre-fill form with user data
  useEffect(() => {
    if (visible && formik.setValues && user) {
      const initialValues: any = {
        name: user.username || '',
        gender: user.gender || '',
        icNo: user.icNo || '',
        email: user.email || '',
        phone: user.phone || '',
        dob: user.dob || '',
        country: user.country || '',
        state: user.state || '',
        address: user.address || '',
        telegramId: user.telegram_id || '',
        discordId: user.discord_id || '',
        password: '',
        termsAndConditions: 'agree',
        countryCode: user.phone?.substring(0, 3) || '+60',
        contactNumber: user.phone?.substring(3) || '',
      };
  
      // Only set values if they are different to prevent unnecessary state updates
      if (JSON.stringify(formik.values) !== JSON.stringify(initialValues)) {
        formik.setValues(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, user]); 


  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev);
  };

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgree(event.target.checked);
  };

  const handleShowTerms = () => {
    setShowTermsModal(true);
  };

  const handleCloseTerms = () => {
    setShowTermsModal(false);
  };

  const isUpdateButtonDisabled = !isAgree;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Check if Full Name is "name" and prevent submission
    if (formik.values.name.toLowerCase() === 'name' && 'Name') {
      alert('Please enter a valid name.');
      return;
    }
  
    const { countryCode, contactNumber, day, month, year, gender, ...values } = formik.values;
  
    // Combine day, month, and year into a single `dob` value
    if (!day || !month || !year) {
      alert('Please select a valid date of birth.');
      return;
    }
    const dob = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    const phone = `${countryCode}${contactNumber}`;
  
    try {
      await onSave({ ...values, dob, phone });
    } catch (err) {
      console.error('Error in handleSubmit:', err);
    }
  };  

  if (!visible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="
        bg-white p-6 rounded-lg w-auto max-w-[500px] h-auto max-h-[800px] 
            sm:max-w-screen sm:max-h-screen
            xs:max-w-screen xs:max-h-[650px]
          "
      >
        <h2 className="text-xl text-cyan-800 font-bold mx-4">
          Update Your Details
        </h2>
        <div className="border text-rose-600 px-10 py-4 rounded-md my-2 text-sm">
          Thank you for being a valued member of the R One community! 
          To ensure you have the best experience, please complete your profile.
        </div>

        <form onSubmit={formik.handleSubmit} className="p-6 h-auto max-h-[500px] 
                                                overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack
                                                sm:max-w-screen sm:max-h-[500px] sm:p-4 
                                                xs:max-w-screen xs:max-h-[400px] xs:p-2 xs:text-sm
                                              "
        >
          {/* Individual Fields */}
          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              Full Name:
            </label>
            <input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-3 py-2 border rounded"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-red-500 text-sm">{formik.errors.name}</div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              Gender:
            </label>
            <select
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">Select a gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {formik.touched.gender && formik.errors.gender && (
              <div className="text-red-500 text-sm">{formik.errors.gender}</div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              IC Number:
            </label>
            <input
              type="text"
              name="icNo"
              value={formik.values.icNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-3 py-1 border rounded"
            />
            {formik.touched.icNo && formik.errors.icNo && (
              <div className="text-red-500 text-sm">{formik.errors.icNo}</div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              Email:
            </label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-3 py-1 border rounded"
              disabled
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              Phone:
            </label>
            <div className="flex items-center">
              <select
                name="countryCode"
                value={formik.values.countryCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="px-3 py-2 border rounded w-[85px]"
              >
                <option value="">Select a phone code</option>
                {phoneOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Phone number"
                className="w-3/4 px-3 py-2 border rounded"
              />
              {(formik.touched.countryCode && formik.errors.countryCode) || (formik.touched.contactNumber && formik.errors.contactNumber) ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.countryCode || formik.errors.contactNumber}
                </div>
              ) : null}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">Date of Birth:</label>
            <div className="flex space-x-2">
              {/* Month Dropdown */}
              <select
                name="month"
                value={formik.values.month}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-1/3 px-3 py-2 border rounded"
              >
                <option value="">Month</option>
                {[
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ].map((month, index) => (
                  <option key={index + 1} value={index + 1}>
                    {month}
                  </option>
                ))}
              </select>

              {/* Day Dropdown */}
              <select
                name="day"
                value={formik.values.day}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-1/3 px-3 py-2 border rounded"
              >
                <option value="">Day</option>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>

              {/* Year Dropdown */}
              <select
                name="year"
                value={formik.values.year}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-1/3 px-3 py-2 border rounded"
              >
                <option value="">Year</option>
                {Array.from({ length: 101 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>

            {/* Validation Messages */}
            {formik.touched.day && formik.errors.day && (
              <div className="text-red-500 text-sm">{formik.errors.day}</div>
            )}
            {formik.touched.month && formik.errors.month && (
              <div className="text-red-500 text-sm">{formik.errors.month}</div>
            )}
            {formik.touched.year && formik.errors.year && (
              <div className="text-red-500 text-sm">{formik.errors.year}</div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              Country:
            </label>
            <select
              name="country"
              value={formik.values.country}
              onChange={(e) => {
                formik.handleChange(e);
                if (e.target.value !== 'MY') formik.setFieldValue('state', '');
              }}
              onBlur={formik.handleBlur}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">Select a Country</option>
              {countryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 text-sm">{formik.errors.country}</div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">State:</label>
            {formik.values.country !== 'MY' ? (
              <input
                type="text"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your state"
                className="w-full px-3 py-2 border rounded"
              />
            ) : (
              <select
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full px-3 py-2 border rounded"
              >
                <option value="">Select a State</option>
                {stateOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
            {formik.touched.state && formik.errors.state && (
              <div className="text-red-500 text-sm">{formik.errors.state}</div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              Address:
            </label>
            <input
              type="textarea"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-3 py-2 border rounded"
            />
            {formik.touched.address && formik.errors.address && (
              <div className="text-red-500 text-sm">{formik.errors.address}</div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              Telegram ID:
            </label>
            <input
              type="text"
              name="telegramId"
              value={formik.values.telegramId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-3 py-2 border rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-cyan-800 font-bold mx-2">
              Discord ID:
            </label>
            <input
              type="text"
              name="discordId"
              value={formik.values.discordId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-3 py-2 border rounded"
            />
          </div>

          <div className="mb-4">
            <div className="flex justify-between items-center">
              <label className="block text-cyan-800 font-bold mx-2">Reset Password:</label>
              <span className="text-rose-600 text-xs">reset your default password or maintain your current password</span>
            </div>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full px-3 py-2 border rounded"
              />
              <span
                onClick={handlePasswordToggle}
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-cyan-800"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 text-sm">{formik.errors.password}</div>
            )}
          </div>

          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={isAgree}
              onChange={handleAgreeChange}
              className="mr-2 accent-cyan-800"
            />
            <label 
              htmlFor="agreeTerms" 
              className="text-cyan-800 font-bold cursor-pointer hover:underline" 
              onClick={handleShowTerms}
            >
              I agree with Terms and Conditions
            </label>
          </div>
        </form>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2 mt-4">
          <button
            type="submit"
            onClick={handleSubmit}
            className={`px-4 py-1 rounded ${
              isUpdateButtonDisabled
                ? 'bg-gray-300 text-gray-400 cursor-not-allowed'
                : 'bg-cyan-800 text-white hover:bg-cyan-900'
            }`}
            disabled={isUpdateButtonDisabled}
          >
            Update
          </button>
        </div>

        {/* Terms and Conditions Modal */}
        {showTermsModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-12 border rounded-lg w-auto max-w-[600px] h-auto max-h-[700px]">
              <div className="overflow-y-auto h-auto max-h-[500px]">
                <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>
                <div className="text-sm text-gray-700">{termsAndConditions}</div>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleCloseTerms}
                  className="px-4 py-1 bg-cyan-800 text-white rounded hover:bg-cyan-900"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateUserData;

// // udpate code --> 20 dec 2024
// // src/components/UpdateUserData.tsx

// import React, { useEffect } from 'react';
// import { useFormValidation, countryOptions, stateOptions, phoneOptions  } from '../hooks/Login/useFormValidation'; 
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import { useEventRegTerms } from '../context/EventRegTermContext';
// import useUserDashboard from '../hooks/Dashboard/useUserDashboard';

// interface UpdateUserDataProps {
//   visible: boolean;
//   onSave: (data: any) => void;
//   onClose: () => void;
// }

// const UpdateUserData: React.FC<UpdateUserDataProps> = ({ visible, onSave, onClose }) => {
//   const { user } = useUserDashboard();
//   const [showPassword, setShowPassword] = React.useState(false);
//   const [isAgree, setIsAgree] = React.useState(false);
//   const [showTermsModal, setShowTermsModal] = React.useState(false);
//   const termsAndConditions = useEventRegTerms();
//   const formik = useFormValidation(onClose);

//   // Pre-fill form with user data
//   useEffect(() => {
//     if (visible && formik.setValues && user) {
//       const initialValues: any = {
//         name: user.username || '',
//         gender: user.gender || '',
//         icNo: user.icNo || '',
//         email: user.email || '',
//         phone: user.phone || '',
//         dob: user.dob || '',
//         country: user.country || '',
//         state: user.state || '',
//         address: user.address || '',
//         telegramId: user.telegram_id || '',
//         discordId: user.discord_id || '',
//         password: '',
//         termsAndConditions: 'agree',
//         countryCode: user.phone?.substring(0, 3) || '+60',
//         contactNumber: user.phone?.substring(3) || '',
//       };
  
//       // Only set values if they are different to prevent unnecessary state updates
//       if (JSON.stringify(formik.values) !== JSON.stringify(initialValues)) {
//         formik.setValues(initialValues);
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [visible, user]); 


//   const handlePasswordToggle = () => {
//     setShowPassword((prev) => !prev);
//   };

//   const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setIsAgree(event.target.checked);
//   };

//   const handleShowTerms = () => {
//     setShowTermsModal(true);
//   };

//   const handleCloseTerms = () => {
//     setShowTermsModal(false);
//   };

//   const isUpdateButtonDisabled = !isAgree;

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     // Check if Full Name is "name" and prevent submission
//     if (formik.values.name.toLowerCase() === 'name' && 'Name') {
//       alert('Please enter a valid name.');
//       return;
//     }

//     const { countryCode, contactNumber, gender, ...values } = formik.values;
//     const phone = `${countryCode}${contactNumber}`;
//     try {
//       await onSave({ ...values, phone });
//     } catch (err) {
//       console.error('Error in handleSubmit:', err);
//     }
//   };

//   if (!visible) return null;

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="
//         bg-white p-6 rounded-lg w-auto max-w-[500px] h-auto max-h-[800px] 
//             sm:max-w-screen sm:max-h-screen
//             xs:max-w-screen xs:max-h-[650px]
//           "
//       >
//         <h2 className="text-xl text-cyan-800 font-bold mx-4">
//           Update Your Details
//         </h2>
//         <div className="border text-rose-600 px-10 py-4 rounded-md my-2 text-sm">
//           Thank you for being a valued member of the R One community! 
//           To ensure you have the best experience, please complete your profile.
//         </div>

//         <form onSubmit={formik.handleSubmit} className="p-6 h-auto max-h-[500px] 
//                                                 overflow-y-scroll scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-track-scrollbarTrack
//                                                 sm:max-w-screen sm:max-h-[500px] sm:p-4 
//                                                 xs:max-w-screen xs:max-h-[400px] xs:p-2 xs:text-sm
//                                               "
//         >
//           {/* Individual Fields */}
//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               Full Name:
//             </label>
//             <input
//               type="text"
//               name="name"
//               value={formik.values.name}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-2 border rounded"
//             />
//             {formik.touched.name && formik.errors.name && (
//               <div className="text-red-500 text-sm">{formik.errors.name}</div>
//             )}
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               Gender:
//             </label>
//             <select
//               name="gender"
//               value={formik.values.gender}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-2 border rounded"
//             >
//               <option value="">Select a gender</option>
//               <option value="Male">Male</option>
//               <option value="Female">Female</option>
//             </select>
//             {formik.touched.gender && formik.errors.gender && (
//               <div className="text-red-500 text-sm">{formik.errors.gender}</div>
//             )}
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               IC Number:
//             </label>
//             <input
//               type="text"
//               name="icNo"
//               value={formik.values.icNo}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-1 border rounded"
//             />
//             {formik.touched.icNo && formik.errors.icNo && (
//               <div className="text-red-500 text-sm">{formik.errors.icNo}</div>
//             )}
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               Email:
//             </label>
//             <input
//               type="email"
//               name="email"
//               value={formik.values.email}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-1 border rounded"
//               disabled
//             />
//             {formik.touched.email && formik.errors.email && (
//               <div className="text-red-500 text-sm">{formik.errors.email}</div>
//             )}
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               Phone:
//             </label>
//             <div className="flex items-center">
//               <select
//                 name="countryCode"
//                 value={formik.values.countryCode}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="px-3 py-2 border rounded w-[85px]"
//               >
//                 <option value="">Select a phone code</option>
//                 {phoneOptions.map((option) => (
//                   <option key={option.value} value={option.value}>
//                     {option.label}
//                   </option>
//                 ))}
//               </select>
//               <input
//                 type="text"
//                 name="contactNumber"
//                 value={formik.values.contactNumber}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 placeholder="Phone number"
//                 className="w-3/4 px-3 py-2 border rounded"
//               />
//               {(formik.touched.countryCode && formik.errors.countryCode) || (formik.touched.contactNumber && formik.errors.contactNumber) ? (
//                 <div className="text-red-500 text-sm">
//                   {formik.errors.countryCode || formik.errors.contactNumber}
//                 </div>
//               ) : null}
//             </div>
//           </div>

//           <div className="mb-4">
//             <div className="flex justify-between items-center">
//               <label className="block text-cyan-800 font-bold mx-2">
//                 Date of Birth:
//               </label>
//               <span className="text-rose text-xs">( format MM-DD-YYYY e.g: 09-13-2025 )</span>
//             </div>
//             <input
//               type="date"
//               name="dob"
//               value={formik.values.dob}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-2 border rounded"
//             />
//             {formik.touched.dob && formik.errors.dob && (
//               <div className="text-red-500 text-sm">{formik.errors.dob}</div>
//             )}
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               Country:
//             </label>
//             <select
//               name="country"
//               value={formik.values.country}
//               onChange={(e) => {
//                 formik.handleChange(e);
//                 if (e.target.value !== 'MY') formik.setFieldValue('state', '');
//               }}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-2 border rounded"
//             >
//               <option value="">Select a Country</option>
//               {countryOptions.map((option) => (
//                 <option key={option.value} value={option.value}>
//                   {option.label}
//                 </option>
//               ))}
//             </select>
//             {formik.touched.country && formik.errors.country && (
//               <div className="text-red-500 text-sm">{formik.errors.country}</div>
//             )}
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">State:</label>
//             {formik.values.country !== 'MY' ? (
//               <input
//                 type="text"
//                 name="state"
//                 value={formik.values.state}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 placeholder="Enter your state"
//                 className="w-full px-3 py-2 border rounded"
//               />
//             ) : (
//               <select
//                 name="state"
//                 value={formik.values.state}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="w-full px-3 py-2 border rounded"
//               >
//                 <option value="">Select a State</option>
//                 {stateOptions.map((option) => (
//                   <option key={option.value} value={option.value}>
//                     {option.label}
//                   </option>
//                 ))}
//               </select>
//             )}
//             {formik.touched.state && formik.errors.state && (
//               <div className="text-red-500 text-sm">{formik.errors.state}</div>
//             )}
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               Address:
//             </label>
//             <input
//               type="textarea"
//               name="address"
//               value={formik.values.address}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-2 border rounded"
//             />
//             {formik.touched.address && formik.errors.address && (
//               <div className="text-red-500 text-sm">{formik.errors.address}</div>
//             )}
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               Telegram ID:
//             </label>
//             <input
//               type="text"
//               name="telegramId"
//               value={formik.values.telegramId}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-2 border rounded"
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block text-cyan-800 font-bold mx-2">
//               Discord ID:
//             </label>
//             <input
//               type="text"
//               name="discordId"
//               value={formik.values.discordId}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="w-full px-3 py-2 border rounded"
//             />
//           </div>

//           <div className="mb-4">
//             <div className="flex justify-between items-center">
//               <label className="block text-cyan-800 font-bold mx-2">Reset Password:</label>
//               <span className="text-rose-600 text-xs">reset your default password or maintain your current password</span>
//             </div>
//             <div className="relative">
//               <input
//                 type={showPassword ? 'text' : 'password'}
//                 name="password"
//                 value={formik.values.password}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="w-full px-3 py-2 border rounded"
//               />
//               <span
//                 onClick={handlePasswordToggle}
//                 className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-cyan-800"
//               >
//                 {showPassword ? <FaEyeSlash /> : <FaEye />}
//               </span>
//             </div>
//             {formik.touched.password && formik.errors.password && (
//               <div className="text-red-500 text-sm">{formik.errors.password}</div>
//             )}
//           </div>

//           <div className="mb-4 flex items-center">
//             <input
//               type="checkbox"
//               id="agreeTerms"
//               checked={isAgree}
//               onChange={handleAgreeChange}
//               className="mr-2 accent-cyan-800"
//             />
//             <label 
//               htmlFor="agreeTerms" 
//               className="text-cyan-800 font-bold cursor-pointer hover:underline" 
//               onClick={handleShowTerms}
//             >
//               I agree with Terms and Conditions
//             </label>
//           </div>
//         </form>

//         {/* Action Buttons */}
//         <div className="flex justify-end space-x-2 mt-4">
//           <button
//             type="submit"
//             onClick={handleSubmit}
//             className={`px-4 py-1 rounded ${
//               isUpdateButtonDisabled
//                 ? 'bg-gray-300 text-gray-400 cursor-not-allowed'
//                 : 'bg-cyan-800 text-white hover:bg-cyan-900'
//             }`}
//             disabled={isUpdateButtonDisabled}
//           >
//             Update
//           </button>
//         </div>

//         {/* Terms and Conditions Modal */}
//         {showTermsModal && (
//           <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//             <div className="bg-white p-12 border rounded-lg w-auto max-w-[600px] h-auto max-h-[700px]">
//               <div className="overflow-y-auto h-auto max-h-[500px]">
//                 <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>
//                 <div className="text-sm text-gray-700">{termsAndConditions}</div>
//               </div>
//               <div className="flex justify-end mt-4">
//                 <button
//                   onClick={handleCloseTerms}
//                   className="px-4 py-1 bg-cyan-800 text-white rounded hover:bg-cyan-900"
//                 >
//                   Close
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default UpdateUserData;