// src/hooks/AdminDashboard/useFundProgramSetting.ts

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface Program {
  fundSetId?: string;
  fundNameSet: string;
  fundDescriptionSet: string;
  fundStepSet: string;
  fundSizeSet: string;
  fundFeatureSet: { [key: string]: string }; 
  fundStatusSet: string;
  fundAmountSet: number;
}

const useFundProgramSetting = () => {
  const { apiUrl } = useEnv();
  const [programs, setPrograms] = useState<Program[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPrograms = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/fund/settings`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setPrograms(data);
    } catch (error) {
      console.error('Failed to fetch programs:', error);
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);
  
  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);
  
  const addProgram = async (program: Program) => {
    try {
      const response = await fetch(`${apiUrl}/api/fund/settings`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(program),
      });
      if (!response.ok) throw new Error('Failed to add program');
      await fetchPrograms(); 
    } catch (error) {
      console.error('Failed to add program:', error);
    }
  };

  // Add this new function in useFundProgramSetting
  const updateProgram = async (fundSetId: string, program: Program) => {
    try {
      const response = await fetch(`${apiUrl}/api/fund/settings/${fundSetId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...program,
          fundFeatureSet: program.fundFeatureSet || {},
        }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to update program');
      }
      
      await fetchPrograms();
    } catch (error) {
      console.error('Failed to update program:', error);
      throw error;
    }
  };

  const deletePrograms = async (fundSetIds: string[], onDeleteSuccess?: () => void) => {
    try {
      const response = await fetch(`${apiUrl}/api/fund/settings`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fundSetIds }),
      });
      
      if (!response.ok) throw new Error('Failed to delete programs');
      await fetchPrograms(); 
      onDeleteSuccess?.(); 
    } catch (error) {
      console.error('Failed to delete programs:', error);
    }
  };

  return { programs, loading, fetchPrograms, addProgram, updateProgram, deletePrograms };
};

export default useFundProgramSetting;