// src/components/client/MiniApp/MyFundProgram.tsx

import React, { useState, useEffect } from 'react';
import { RiFundsFill } from "react-icons/ri";
import useFundProgramSetting from '../../../hooks/AdminDashboard/useFundProgramSetting';
import FundedRegistration from './App02_MyFundRegistration';

const MyFundedProgram: React.FC = () => {
  // hook state
  const { programs, loading, fetchPrograms } = useFundProgramSetting();

  // tab state
  const [activeTab, setActiveTab] = useState<string | null>(null); 
  const [activeStage, setActiveStage] = useState<string | null>(null);

  // Registration state
  const [viewMode, setViewMode] = useState<'programs' | 'registration'>('programs'); 
  const [selectedProgram, setSelectedProgram] = useState<any>(null);

  // Fetch programs on component mount
  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  // Set default tab and stage on programs update
  useEffect(() => {
    if (programs.length > 0) {
      const firstFund = programs[0];
      setActiveTab(firstFund.fundNameSet); 
      setActiveStage(firstFund.fundStepSet); 
    }
  }, [programs]);

  // Handle the "Challenge Now" button click
  const handleChallengeClick = (program: any) => {
    setSelectedProgram({
      ...program,
      fundLinkEvalId: program.fundNameSet,
      fundProgramAmount: program.fundAmountSet,
      fundProgramSize: program.fundSizeSet, 
    });
    setViewMode('registration');
  };
  
  // Handle "Back to Details" button click
  const handleBackToPrograms = () => {
    setViewMode('programs'); 
    setSelectedProgram(null);
  };

  // Group programs by fundNameSet
  const groupedPrograms = programs.reduce((acc, program) => {
    acc[program.fundNameSet] = acc[program.fundNameSet] || [];
    acc[program.fundNameSet].push(program);
    return acc;
  }, {} as Record<string, typeof programs>);

  return (
    <div className="mx-auto p-4 rounded-lg w-auto h-auto max-w-[600px] max-h-screen md:p-6">
      <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center sm:text-xl xs:text-lg">
        <RiFundsFill className="mr-2 w-6 h-6" />
        R One Funded Trader Program
      </h2>
      <p className="mb-2 xs:text-sm">
        Welcome to "My Program," your gateway to two exceptional funded trader programs.
        Explore opportunities to elevate your trading career and find the perfect fit for
        your goals.
      </p>
      <div className="overflow-y-auto w-auto h-auto max-h-[800px] xl:h-[500px] lg:h-[500px] md:h-[600px] sm:h-[500px] xs:h-[300px]">
        {loading ? (
          <p className="text-center text-gray-500">Loading programs...</p>
        ) : programs.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-center text-gray-600 text-lg font-medium">
              No funded programs available at the moment.
            </p>
            <p className="text-center text-gray-500 text-sm">
              Please check back later or contact support for more information.
            </p>
          </div>
        ) : viewMode === 'programs' ? (
          <>
            <h3 className="text-center text-2xl font-semibold mb-2 sm:text-lg xs:text-base">
              Choose the Funded Program
            </h3>

            {/* Tabs for Funded Programs */}
            <div className="flex justify-center mb-4 bg-cyan-800 rounded-full p-2 mx-[50px]">
              {loading ? (
                <p>Loading programs...</p>
              ) : (
                Object.keys(groupedPrograms).map((fundNameSet) => (
                  <button
                    key={fundNameSet}
                    className={`px-4 py-2 text-sm ${
                      activeTab === fundNameSet
                        ? "border-2 border-white text-white rounded-full"
                        : "text-gray-400 font-semibold"
                    }`}
                    onClick={() => {
                      setActiveTab(fundNameSet);
                      setActiveStage(groupedPrograms[fundNameSet][0].fundStepSet);
                    }}
                  >
                    {fundNameSet}
                  </button>
                ))
              )}
            </div>

            {/* Step Selector */}
            {activeTab && groupedPrograms[activeTab] && (
              <div className="flex justify-center mb-6">
                {groupedPrograms[activeTab].map((program) => (
                  <label key={program.fundStepSet} className="flex items-center space-x-2 mr-4">
                    <input
                      type="radio"
                      name="stage"
                      value={program.fundStepSet}
                      checked={activeStage === program.fundStepSet}
                      onChange={() => setActiveStage(program.fundStepSet)}
                      className="form-radio accent-cyan-800 cursor-pointer"
                    />
                    <span className="text-sm font-medium cursor-pointer">{program.fundStepSet}</span>
                  </label>
                ))}
              </div>
            )}

            {/* Display Content Based on Active Tab and Stage */}
            {activeTab &&
              activeStage &&
              groupedPrograms[activeTab].map((program) =>
                program.fundStepSet === activeStage ? (
                  <div key={program.fundSetId} className="overflow-x-auto">
                    {/* Fancy Table Layout */}
                    <table className="min-w-full border-collapse rounded-lg overflow-hidden shadow-lg">
                      <tbody className="bg-gray-50 divide-y divide-gray-200 border-2">
                        {/* Description */}
                        <tr>
                          <td className="px-6 py-4 text-center text-cyan-800 font-semibold" colSpan={2}>
                            {program.fundDescriptionSet}
                          </td>
                        </tr>
                        
                        {/* Account Size */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Account Size (USD)</td>
                          <td className="px-6 py-4 text-center text-gray-800">{program.fundSizeSet}</td>
                        </tr>

                        {/* Evalution Stages */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Evalution Stages</td>
                          <td className="px-6 py-4 text-center text-gray-800">
                            {program.fundFeatureSet['Evaluation Stage']}
                          </td>
                        </tr>

                        {/* Profit Target */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Profit Target (%)</td>
                          <td className="px-6 py-4 text-center text-gray-800">
                            {program.fundFeatureSet['Profit Target']}
                          </td>
                        </tr>

                        {/* Maximum Daily Drawdown */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Maximum Daily Drawdown (%)</td>
                          <td className="px-6 py-4 text-center text-gray-800">
                            {program.fundFeatureSet['Maximum Daily Drawdown'] || 'N/A'}
                          </td>
                        </tr>

                        {/* Maximum Overall Drawdown */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Maximum Overall Drawdown (%)</td>
                          <td className="px-6 py-4 text-center text-gray-800">
                            {program.fundFeatureSet['Maximum Overall Drawdown'] || 'N/A'}
                          </td>
                        </tr>

                        {/* Balance Based Drawdown */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Balance Based Drawdown</td>
                          <td className="px-6 py-4 text-center">
                            {program.fundFeatureSet['Balance Based Drawdown'] === 'Yes' ? '✔' : 'X'}
                          </td>
                        </tr>

                        {/* Minimum Trading Days */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Minimum Trading Days</td>
                          <td className="px-6 py-4 text-center text-gray-800">
                            {program.fundFeatureSet['Minimum Trading Days']}
                          </td>
                        </tr>

                        {/* Minimum Profit Split */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Profit Split Up To (%)</td>
                          <td className="px-6 py-4 text-center text-gray-800">
                            {program.fundFeatureSet['Minimum profit split']}
                          </td>
                        </tr>

                        {/* News Trading */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">News Trading</td>
                          <td className="px-6 py-4 text-center">
                            {program.fundFeatureSet['News Trading'] === 'Yes' ? '✔' : 'X'}
                          </td>
                        </tr>

                        {/* Trader Overnight */}
                        <tr>
                          <td className="px-6 py-4 font-bold text-cyan-800">Trade Overnight</td>
                          <td className="px-6 py-4 text-center">
                            {program.fundFeatureSet['Trader Overnight'] === 'Yes' ? '✔' : 'X'}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {/* Call-to-Action Section */}
                    <div className="flex justify-center mt-6">
                      <button
                        className="bg-cyan-800 text-white font-bold py-3 px-6 rounded-full hover:bg-cyan-900 transition duration-200 shadow-md"
                        onClick={() => handleChallengeClick(program)} 
                      >
                        Challenge Now: ${program.fundAmountSet}
                      </button>
                    </div>

                    {/* Disclaimer */}
                    <div className="flex flex-col border p-4 mt-4 rounded-lg text-sm">
                      <span className="text-rose-600 font-semibold">
                        Funded Program Rules Disclaimer:
                      </span>
                      <span className="text-rose-600">
                        Participation in the funded program requires adherence to all rules, 
                        including profit targets, drawdown limits, and trading guidelines. 
                        Failure to comply may result in disqualification. Please review the rules carefully before participating.
                      </span>
                    </div>
                  </div>
              ) : null
            )}
          </>
        ) : (
          <FundedRegistration 
            fundProgram={selectedProgram} 
            onClose={handleBackToPrograms} 
          />
        )}
      </div>
    </div>
  );
};

export default MyFundedProgram;

// // update code --> 23 dec 2024 
// // src/components/client/MiniApp/MyFundProgram.tsx

// import React, { useState, useEffect } from 'react';
// import { RiFundsFill } from "react-icons/ri";
// import useFundProgramSetting from '../../../hooks/AdminDashboard/useFundProgramSetting';
// import FundedRegistration from './App02_MyFundRegistration';

// const MyFundedProgram: React.FC = () => {
//   // hook state
//   const { programs, loading, fetchPrograms } = useFundProgramSetting();

//   // tab state
//   const [activeTab, setActiveTab] = useState<string | null>(null); 
//   const [activeStage, setActiveStage] = useState<string | null>(null);

//   // Registration state
//   const [viewMode, setViewMode] = useState<'programs' | 'registration'>('programs'); 
//   const [selectedProgram, setSelectedProgram] = useState<any>(null);

//   // Fetch programs on component mount
//   useEffect(() => {
//     fetchPrograms();
//   }, [fetchPrograms]);

//   // Set default tab and stage on programs update
//   useEffect(() => {
//     if (programs.length > 0) {
//       const firstFund = programs[0];
//       setActiveTab(firstFund.fundNameSet); 
//       setActiveStage(firstFund.fundStepSet); 
//     }
//   }, [programs]);

//   // Handle the "Challenge Now" button click
//   const handleChallengeClick = (program: any) => {
//     setSelectedProgram({
//       ...program,
//       fundLinkEvalId: program.fundNameSet,
//       fundProgramAmount: program.fundAmountSet,
//       fundProgramSize: program.fundSizeSet, 
//     });
//     setViewMode('registration');
//   };
  
//   // Handle "Back to Details" button click
//   const handleBackToPrograms = () => {
//     setViewMode('programs'); 
//     setSelectedProgram(null);
//   };

//   // Group programs by fundNameSet
//   const groupedPrograms = programs.reduce((acc, program) => {
//     acc[program.fundNameSet] = acc[program.fundNameSet] || [];
//     acc[program.fundNameSet].push(program);
//     return acc;
//   }, {} as Record<string, typeof programs>);

//   return (
//     <div className="mx-auto p-4 rounded-lg w-auto h-auto max-w-[600px] max-h-screen md:p-6">
//       <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center sm:text-xl xs:text-lg">
//         <RiFundsFill className="mr-2 w-6 h-6" />
//         R One Funded Trader Program
//       </h2>
//       <p className="mb-2 xs:text-sm">
//         Welcome to "My Program," your gateway to two exceptional funded trader programs.
//         Explore opportunities to elevate your trading career and find the perfect fit for
//         your goals.
//       </p>
//       <div className="overflow-y-auto w-auto h-auto max-h-[800px] 
//                       xl:h-[500px] 
//                       lg:h-[500px] 
//                       md:h-[600px] 
//                       sm:h-[500px] 
//                       xs:h-[300px]
//                     ">
//         {viewMode === 'programs' ? (
//           <>
//             <h3 className="text-center text-2xl font-semibold mb-2 sm:text-lg xs:text-base">
//               Choose the Funded Program
//             </h3>

//             {/* Tabs for Funded Programs */}
//             <div className="flex justify-center mb-4 bg-cyan-800 rounded-full p-2 mx-[50px]">
//               {loading ? (
//                 <p>Loading programs...</p>
//               ) : (
//                 Object.keys(groupedPrograms).map((fundNameSet) => (
//                   <button
//                     key={fundNameSet}
//                     className={`px-4 py-2 text-sm ${
//                       activeTab === fundNameSet
//                         ? "border-2 border-white text-white rounded-full"
//                         : "text-gray-400 font-semibold"
//                     }`}
//                     onClick={() => {
//                       setActiveTab(fundNameSet);
//                       setActiveStage(groupedPrograms[fundNameSet][0].fundStepSet);
//                     }}
//                   >
//                     {fundNameSet}
//                   </button>
//                 ))
//               )}
//             </div>

//             {/* Step Selector */}
//             {activeTab && groupedPrograms[activeTab] && (
//               <div className="flex justify-center mb-6">
//                 {groupedPrograms[activeTab].map((program) => (
//                   <label key={program.fundStepSet} className="flex items-center space-x-2 mr-4">
//                     <input
//                       type="radio"
//                       name="stage"
//                       value={program.fundStepSet}
//                       checked={activeStage === program.fundStepSet}
//                       onChange={() => setActiveStage(program.fundStepSet)}
//                       className="form-radio accent-cyan-800 cursor-pointer"
//                     />
//                     <span className="text-sm font-medium cursor-pointer">{program.fundStepSet}</span>
//                   </label>
//                 ))}
//               </div>
//             )}

//             {/* Display Content Based on Active Tab and Stage */}
//             {activeTab &&
//               activeStage &&
//               groupedPrograms[activeTab].map((program) =>
//                 program.fundStepSet === activeStage ? (
//                   <div key={program.fundSetId} className="overflow-x-auto">
//                     {/* Fancy Table Layout */}
//                     <table className="min-w-full border-collapse rounded-lg overflow-hidden shadow-lg">
//                       <tbody className="bg-gray-50 divide-y divide-gray-200 border-2">
//                         {/* Description */}
//                         <tr>
//                           <td className="px-6 py-4 text-center text-cyan-800 font-semibold" colSpan={2}>
//                             {program.fundDescriptionSet}
//                           </td>
//                         </tr>
                        
//                         {/* Account Size */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Account Size (USD)</td>
//                           <td className="px-6 py-4 text-center text-gray-800">{program.fundSizeSet}</td>
//                         </tr>

//                         {/* Evalution Stages */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Evalution Stages</td>
//                           <td className="px-6 py-4 text-center text-gray-800">
//                             {program.fundFeatureSet['Evaluation Stage']}
//                           </td>
//                         </tr>

//                         {/* Profit Target */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Profit Target (%)</td>
//                           <td className="px-6 py-4 text-center text-gray-800">
//                             {program.fundFeatureSet['Profit Target']}
//                           </td>
//                         </tr>

//                         {/* Maximum Daily Drawdown */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Maximum Daily Drawdown (%)</td>
//                           <td className="px-6 py-4 text-center text-gray-800">
//                             {program.fundFeatureSet['Maximum Daily Drawdown'] || 'N/A'}
//                           </td>
//                         </tr>

//                         {/* Maximum Overall Drawdown */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Maximum Overall Drawdown (%)</td>
//                           <td className="px-6 py-4 text-center text-gray-800">
//                             {program.fundFeatureSet['Maximum Overall Drawdown'] || 'N/A'}
//                           </td>
//                         </tr>

//                         {/* Balance Based Drawdown */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Balance Based Drawdown</td>
//                           <td className="px-6 py-4 text-center">
//                             {program.fundFeatureSet['Balance Based Drawdown'] === 'Yes' ? '✔' : 'X'}
//                           </td>
//                         </tr>

//                         {/* Minimum Trading Days */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Minimum Trading Days</td>
//                           <td className="px-6 py-4 text-center text-gray-800">
//                             {program.fundFeatureSet['Minimum Trading Days']}
//                           </td>
//                         </tr>

//                         {/* Minimum Profit Split */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Profit Split Up To (%)</td>
//                           <td className="px-6 py-4 text-center text-gray-800">
//                             {program.fundFeatureSet['Minimum profit split']}
//                           </td>
//                         </tr>

//                         {/* News Trading */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">News Trading</td>
//                           <td className="px-6 py-4 text-center">
//                             {program.fundFeatureSet['News Trading'] === 'Yes' ? '✔' : 'X'}
//                           </td>
//                         </tr>

//                         {/* Trader Overnight */}
//                         <tr>
//                           <td className="px-6 py-4 font-bold text-cyan-800">Trade Overnight</td>
//                           <td className="px-6 py-4 text-center">
//                             {program.fundFeatureSet['Trader Overnight'] === 'Yes' ? '✔' : 'X'}
//                           </td>
//                         </tr>
//                       </tbody>
//                     </table>

//                     {/* Call-to-Action Section */}
//                     <div className="flex justify-center mt-6">
//                       <button
//                         className="bg-cyan-800 text-white font-bold py-3 px-6 rounded-full hover:bg-cyan-900 transition duration-200 shadow-md"
//                         onClick={() => handleChallengeClick(program)} 
//                       >
//                         Challenge Now: ${program.fundAmountSet}
//                       </button>
//                     </div>

//                     {/* Disclaimer */}
//                     <div className="flex flex-col border p-4 mt-4 rounded-lg text-sm">
//                       <span className="text-rose-600 font-semibold">
//                         Funded Program Rules Disclaimer:
//                       </span>
//                       <span className="text-rose-600">
//                         Participation in the funded program requires adherence to all rules, 
//                         including profit targets, drawdown limits, and trading guidelines. 
//                         Failure to comply may result in disqualification. Please review the rules carefully before participating.
//                       </span>
//                     </div>
//                   </div>
//               ) : null
//             )}
//           </>
//         ) : (
//           <FundedRegistration 
//             fundProgram={selectedProgram} 
//             onClose={handleBackToPrograms} 
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default MyFundedProgram;




