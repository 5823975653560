// src/components/admin/FundProgramPending/FundProgramPending.tsx

import React, { useState } from 'react';
import { MdOutlineLibraryAdd, MdEditDocument , MdDelete } from "react-icons/md";
import useFundProgramUserData, { FundProgramUser } from '../../../hooks/AdminDashboard/useFundProgramPending';
import useFundProgramSetting from '../../../hooks/AdminDashboard/useFundProgramSetting';
import FundProgramModal from './FundProgramModal';
import FundProgramPendingModal from './FundProgramPendingModal';

interface EditFormData {
  fundOrderId: string;
  fundLinkEvalId: string;
  fundProgramSize: number;
  fundProgramAmount: number;
  fundUserName: string;
  fundUserEmail: string;
  fundEvalBeforeProgram: string;
  fundEvalBeforeStage: string;
  fundPaymentMethod: string;
  fundPaymentReceipts: string;
  fundRegNotes: string;
  fundRuleAgreed: string;
  createAt: Date;
}

const FundProgramPending: React.FC = () => {
  const { data, loading, error, processingId, 
    fetchData, updatePendingRequest, deletePendingRequest, approveFundRequest, rejectFundRequest 
  } = useFundProgramUserData();
  const { programs } = useFundProgramSetting();
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [selectedFundOrderId, setSelectedFundOrderId] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editFormData, setEditFormData] = useState<EditFormData | null>(null);
  const [rejectReason, setRejectReason] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // Add search filter function
  const filteredData = data.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.fundUserName.toLowerCase().includes(searchLower) ||
      item.fundUserEmail.toLowerCase().includes(searchLower) ||
      item.fundLinkEvalId.toLowerCase().includes(searchLower) ||
      item.fundProgramSize.toString().includes(searchTerm)
    );
  });

  // Add this to your existing code where the add button is
  const handleAddUserClick = () => {
    setShowAddUserModal(true);
  };

  // Add handleAddUserSuccess function
  const handleAddUserSuccess = async () => {
    setShowAddUserModal(false);
    await fetchData();
  };

  // Update edit handler
  const handleEditClick = (item: FundProgramUser) => {
    setEditingId(item.fundOrderId);
    setEditFormData({
      fundOrderId: item.fundOrderId,
      fundLinkEvalId: item.fundLinkEvalId,
      fundProgramSize: item.fundProgramSize, 
      fundProgramAmount: item.fundProgramAmount,
      fundUserName: item.fundUserName,
      fundUserEmail: item.fundUserEmail,
      fundEvalBeforeProgram: item.fundEvalBeforeProgram,
      fundEvalBeforeStage: item.fundEvalBeforeStage,
      fundPaymentMethod: item.fundPaymentMethod,
      fundPaymentReceipts: item.fundPaymentReceipts,
      fundRegNotes: item.fundRegNotes,
      fundRuleAgreed: item.fundRuleAgreed,
      createAt: item.createdAt
    });
  };
  
  // Add cancel handler
  const handleCancelEdit = () => {
    setEditingId(null);
    setEditFormData(null);
  };

  // Add save handler
  const handleSaveEdit = async (fundOrderId: string) => {
    try {
      await updatePendingRequest(fundOrderId, editFormData);
      setEditingId(null);
      setEditFormData(null);
    } catch (error) {
      console.error('Error updating pending request:', error);
    }
  };

  // Update handleEditFormChange to include textarea
  const handleEditFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditFormData((prev: EditFormData | null) => {
      if (!prev) return null;
      return {
        ...prev,
        [name]: name.includes('fundProgram') ? Number(value) : value 
      };
    });
  };

  // Handle fund name change and auto-fill
  const handleFundNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFundSetId = e.target.value;
    const selectedProgram = programs.find(p => p.fundSetId === selectedFundSetId);
    
    if (selectedProgram && editFormData) {
      setEditFormData({
        ...editFormData,
        fundLinkEvalId: selectedFundSetId,
        fundProgramSize: Number(selectedProgram.fundSizeSet), 
        fundProgramAmount: Number(selectedProgram.fundAmountSet) 
      });
    }
  };

  const handleDeleteClick = async (fundOrderId: string) => {
    if (window.confirm('Are you sure you want to delete this pending request?')) {
      try {
        await deletePendingRequest(fundOrderId);
        // No need to call fetchData here as the state is updated in the hook
      } catch (error) {
        console.error('Error deleting pending request:', error);
      }
    }
  };

  const handleRowClick = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  // Handle Approve Button Click
  const handleApproveClick = (fundOrderId: string) => {
    setSelectedFundOrderId(fundOrderId);
    setShowModal(true);
  };

  const handleRejectClick = (fundOrderId: string) => {
    setSelectedFundOrderId(fundOrderId);
    setShowRejectModal(true);
  };

  const handleRejectSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedFundOrderId && rejectReason.trim()) {
      await rejectFundRequest(selectedFundOrderId, rejectReason);
      setShowRejectModal(false);
      setRejectReason('');
    }
  };

  const handleSave = (formData: any) => {
    if (selectedFundOrderId) {
      approveFundRequest(selectedFundOrderId, formData);
    }
    setShowModal(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="p-4 border rounded">
      {/* Add Search and Action Buttons */}
      <div className="mb-4 flex justify-end items-center">
        <div className="flex gap-2 mx-4">
          <button
            onClick={handleAddUserClick}
            className="text-cyan-800 text-3xl cursor-pointer"
          >
            <MdOutlineLibraryAdd />
          </button>
        </div>
        <div className="flex-1 max-w-md">
          <input
            type="text"
            placeholder="Search by username, email, fund name or size..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-400 rounded focus:outline-none focus:border-cyan-800"
          />
        </div>
      </div>

      {/* user pending table */}
      <div className="max-h-[400px] overflow-auto">
        <table className="min-w-full bg-white border text-sm text-center text-nowrap">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              <th className="py-2 px-4 border-b">Fund Order ID</th>
              <th className="py-2 px-4 border-b">Fund Name</th>
              <th className="py-2 px-4 border-b">Fund Size</th>
              <th className="py-2 px-4 border-b">Fund Pay Amount</th>
              <th className="py-2 px-4 border-b">Fund User Name</th>
              <th className="py-2 px-4 border-b">Fund Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <React.Fragment key={item.fundOrderId}>
                  {/* Main Row */}
                  <tr 
                    onClick={() => handleRowClick(index)} 
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border-b">{item.fundOrderId}</td>
                    <td className="py-2 px-4 border-b">{item.fundLinkEvalId}</td>
                    <td className="py-2 px-4 border-b">{item.fundProgramSize}</td>
                    <td className="py-2 px-4 border-b">{item.fundProgramAmount}</td>
                    <td className="py-2 px-4 border-b">{item.fundUserName}</td>
                    <td className="py-2 px-4 border-b">
                      <span className={`rounded-full text-white text-xs px-4 py-1 ${
                        item.fundStatus === 'Pending' ? 'bg-yellow-500 text-gray-800' : ''
                      }`}>
                        {item.fundStatus}
                      </span>
                    </td>
                  </tr>

                  {/* Expanded Row */}
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan={6} className="bg-gray-50 border-b px-8 py-4">
                        <div className="flex justify-center items-center gap-10">
                          <div className="flex items-center">
                            <label className="font-bold">Status:</label>
                            <span className="bg-yellow-500 text-white px-6 py-2 rounded-full text-xs ml-2">
                              {item.fundStatus}
                            </span>
                          </div>
                          <div className="flex gap-4">
                            {editingId === item.fundOrderId ? (
                              <>
                                <button
                                  onClick={() => handleSaveEdit(item.fundOrderId)}
                                  className="text-cyan-800 font-bold"
                                >
                                  Save
                                </button>
                                <div className="border-r border-2 border-cyan-800"></div>
                                <button
                                  onClick={handleCancelEdit}
                                  className="text-cyan-800 font-bold"
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => handleEditClick(item)}
                                  className="text-cyan-800"
                                >
                                  <MdEditDocument className="text-2xl" />
                                </button>
                                <button
                                  onClick={() => handleDeleteClick(item.fundOrderId)}
                                  className="text-cyan-800"
                                >
                                  <MdDelete className="text-2xl" />
                                </button>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col my-6 justify-self-center">
                          {/* Information Sections */}
                          <div className="flex flex-row justify-between space-x-12">
                            {/* User Info */}
                            <div className="flex flex-col px-4">
                              <label className="text-center font-semibold mb-1 underline">User Info</label>
                              <div className="space-y-1 text-left">
                                <div className="flex items-center">
                                  <span className="w-24 font-semibold">Order Id:</span>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundOrderId"
                                      value={editFormData?.fundOrderId}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1"
                                      disabled
                                    />
                                  ) : (
                                    <span>{item.fundOrderId}</span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-24 font-semibold">User Name:</span>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundUserName"
                                      value={editFormData?.fundUserName}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1 w-48"
                                    />
                                  ) : (
                                    <span>{item.fundUserName}</span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-24 font-semibold">User Email:</span>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="email"
                                      name="fundUserEmail"
                                      value={editFormData?.fundUserEmail}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1 w-48"
                                    />
                                  ) : (
                                    <span>{item.fundUserEmail}</span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-24 font-semibold">Apply Date:</span>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="date"
                                      name="createdAt"
                                      value={new Date(item.createdAt).toISOString().split('T')[0]}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1 w-48"
                                      disabled
                                    />
                                  ) : (
                                    <span>
                                      {new Date(item.createdAt).toISOString().split('T')[0]}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Funded Info */}
                            <div className="flex flex-col px-4">
                              <label className="text-center font-semibold mb-1 underline">Funded Info</label>
                              <div className="space-y-1 text-left">
                                <div className="flex items-center">
                                  <span className="w-24 font-semibold">Fund Name:</span>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundLinkEvalId"
                                      value={editFormData?.fundLinkEvalId}
                                      onChange={handleFundNameChange}
                                      className="ml-4 border rounded px-2 py-1"
                                    >
                                      <option value="">Select Fund Program</option>
                                      {programs.map((program) => (
                                        <option key={program.fundSetId} value={program.fundSetId}>
                                          {program.fundNameSet}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    <span>{item.fundLinkEvalId}</span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-24 font-semibold">Fund Size:</span>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundProgramSize"
                                      value={editFormData?.fundProgramSize}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1"
                                      disabled
                                    />
                                  ) : (
                                    <span>{item.fundProgramSize}</span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-24 font-semibold mr-8">Program Before:</span>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundEvalBeforeProgram"
                                      value={editFormData?.fundEvalBeforeProgram}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1"
                                    >
                                      <option value="">Select a Option</option>
                                      <option value="180">180</option>
                                      <option value="Go4Funds">Go4Funds</option>
                                      <option value="New">New</option>
                                    </select>
                                  ) : (
                                    <span>{item.fundEvalBeforeProgram}</span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-24 font-semibold mr-8">Stage Before:</span>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundEvalBeforeStage"
                                      value={editFormData?.fundEvalBeforeStage}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1"
                                    >
                                      <option value="">Select a Option</option>
                                      <option value="Stage 01">Stage 01</option>
                                      <option value="Stage 02">Stage 02</option>
                                      <option value="Stage 03">Stage 03</option>
                                      <option value="Live Funds">Live Funds</option>
                                      <option value="New">New</option>
                                    </select>
                                  ) : (
                                    <span>{item.fundEvalBeforeStage}</span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Payment Info */}
                            <div className="flex flex-col w-1/2 px-4">
                              <label className="text-center font-semibold mb-1 underline">Payment Info</label>
                              <div className="space-y-1">
                                <div className="flex items-center">
                                  <span className="w-32 text-right font-semibold">Pay Amount:</span>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="text"
                                      name="fundProgramAmount"
                                      value={editFormData?.fundProgramAmount}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1 w-48"
                                      disabled
                                    />
                                  ) : (
                                    <span className="ml-4">{item.fundProgramAmount}</span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-32 text-right font-semibold">Pay Method:</span>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundPaymentMethod"
                                      value={editFormData?.fundPaymentMethod}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1 w-48"
                                    >
                                      <option value="">Select a option</option>
                                      <option value="Bank Transfer">Bank Transfer</option>
                                      <option value="USDT">USDT</option>
                                      <option value="Credit Card">Credit Card</option>
                                    </select>
                                  ) : (
                                    <span className="ml-4">{item.fundPaymentMethod}</span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-32 text-right font-semibold">Pay Receipts:</span>
                                  {editingId === item.fundOrderId ? (
                                    <input
                                      type="file"
                                      name="fundPaymentReceipts"
                                      value={editFormData?.fundPaymentReceipts}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1 w-48"
                                      disabled
                                    />
                                  ) : (
                                    <span className="text-blue-600 underline cursor-pointer ml-4">
                                      {item.fundPaymentReceipts || 'No Receipts Attached'}
                                    </span>
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <span className="w-32 text-right font-semibold">Rule and Term:</span>
                                  {editingId === item.fundOrderId ? (
                                    <select
                                      name="fundRuleAgreed"
                                      value={editFormData?.fundRuleAgreed}
                                      onChange={handleEditFormChange}
                                      className="ml-4 border rounded px-2 py-1 w-48"
                                      disabled
                                    >
                                      <option value="">Select a option</option>
                                      <option value="Agreed">Yes</option>
                                      <option value="Not Agreed">No</option>
                                    </select>
                                  ) : (
                                    <span className="ml-4">{item.fundRuleAgreed}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Notes Section */}
                          <div className="mt-2 px-4">
                            <div className="flex items-start">
                              <span className="w-32 text-right font-semibold">Notes:</span>
                              {editingId === item.fundOrderId ? (
                                <textarea
                                  name="fundRegNotes"
                                  value={editFormData?.fundRegNotes || ''}
                                  onChange={handleEditFormChange}
                                  className="ml-4 border rounded px-2 py-1 w-full"
                                  rows={3}
                                />
                              ) : (
                                <span className="ml-4">{item.fundRegNotes}</span>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Button Section */}
                        <div className="flex justify-center items-center space-x-2">
                          <button
                            onClick={() => handleRejectClick(item.fundOrderId)}
                            disabled={processingId === item.fundOrderId}
                            className="bg-gray-400 text-gray-100 px-6 py-1 rounded"
                          >
                            {processingId === item.fundOrderId ? 'Processing...' : 'Reject'}
                          </button>
                          <button
                            onClick={() => handleApproveClick(item.fundOrderId)}
                            disabled={processingId === item.fundOrderId}
                            className={`px-6 py-1 rounded ${
                              processingId === item.fundOrderId ? 'bg-cyan-800' : 'bg-cyan-900 text-white'
                            }`}
                          >
                            {processingId === item.fundOrderId ? 'Processing...' : 'Approve'}
                          </button>
                        </div>

                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center py-8">
                  {searchTerm ? 'No matching results found' : 'No pending requests available'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* fund program modal */}
      {showModal && (
        <FundProgramModal
          onClose={() => setShowModal(false)}
          onSave={handleSave}
        />
      )}

      {/* Add User Modal */}
      {showAddUserModal && (
        <FundProgramPendingModal
          onClose={() => setShowAddUserModal(false)}
          onSuccess={handleAddUserSuccess}
        />
      )}

      {/* fund program modal */}
      {showRejectModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[500px]">
            <h2 className="text-xl font-bold mb-4">Reject Fund Program</h2>
            <form onSubmit={handleRejectSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Rejection Reason
                </label>
                <textarea
                  className="w-full p-2 border rounded-md"
                  rows={4}
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  placeholder="Please provide a reason for rejection..."
                  required
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => {
                    setShowRejectModal(false);
                    setRejectReason('');
                  }}
                  className="px-4 py-2 bg-gray-200 rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-red-600 text-white rounded-md"
                >
                  Reject
                </button>
              </div>
            </form>
          </div>
        </div>
        )}
    </div>
  );
};

export default FundProgramPending;

// // update code --> 31 dec 2024
// // src/components/admin/FundProgramPending/FundProgramPending.tsx

// import React, { useState } from 'react';
// import useFundProgramUserData from '../../../hooks/AdminDashboard/useFundProgramPending';
// import FundProgramModal from './FundProgramModal';

// const FundProgramPending: React.FC = () => {
//   const { data, loading, error, processingId, approveFundRequest, rejectFundRequest } = useFundProgramUserData();
//   const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
//   const [selectedFundOrderId, setSelectedFundOrderId] = useState<string | null>(null);
//   const [showModal, setShowModal] = useState(false);
//   const [showRejectModal, setShowRejectModal] = useState(false);
//   const [rejectReason, setRejectReason] = useState('');

//   const handleRowClick = (index: number) => {
//     setExpandedRowIndex(expandedRowIndex === index ? null : index);
//   };

//   // Handle Approve Button Click
//   const handleApproveClick = (fundOrderId: string) => {
//     setSelectedFundOrderId(fundOrderId);
//     setShowModal(true);
//   };

//   const handleSave = (formData: any) => {
//     if (selectedFundOrderId) {
//       approveFundRequest(selectedFundOrderId, formData);
//     }
//     setShowModal(false);
//   };

//   const handleRejectClick = (fundOrderId: string) => {
//     setSelectedFundOrderId(fundOrderId);
//     setShowRejectModal(true);
//   };

//   const handleRejectSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     if (selectedFundOrderId && rejectReason.trim()) {
//       await rejectFundRequest(selectedFundOrderId, rejectReason);
//       setShowRejectModal(false);
//       setRejectReason('');
//     }
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div className="text-red-500">{error}</div>;

//   return (
//     <div className="p-4 border rounded">
//       <div className="max-h-[400px] overflow-auto">
//         <table className="min-w-full bg-white border text-sm text-center text-nowrap">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               <th className="py-2 px-4 border-b">Fund Order ID</th>
//               <th className="py-2 px-4 border-b">Fund Name</th>
//               <th className="py-2 px-4 border-b">Fund Size</th>
//               <th className="py-2 px-4 border-b">Fund Pay Amount</th>
//               <th className="py-2 px-4 border-b">Fund User Name</th>
//               <th className="py-2 px-4 border-b">Fund Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.length > 0 ? (
//               data.map((item, index) => (
//                 <React.Fragment key={item.fundOrderId}>
//                   {/* Main Row */}
//                   <tr 
//                     onClick={() => handleRowClick(index)} 
//                     className="cursor-pointer hover:bg-gray-100"
//                   >
//                     <td className="py-2 px-4 border-b">{item.fundOrderId}</td>
//                     <td className="py-2 px-4 border-b">{item.fundLinkEvalId}</td>
//                     <td className="py-2 px-4 border-b">{item.fundProgramSize}</td>
//                     <td className="py-2 px-4 border-b">{item.fundProgramAmount}</td>
//                     <td className="py-2 px-4 border-b">{item.fundUserName}</td>
//                     <td className="py-2 px-4 border-b">
//                       <span className={`rounded-full text-white text-xs px-4 py-1 ${
//                         item.fundStatus === 'Pending' ? 'bg-yellow-500 text-gray-800' : ''
//                       }`}>
//                         {item.fundStatus}
//                       </span>
//                     </td>
//                   </tr>

//                   {/* Expanded Row */}
//                   {expandedRowIndex === index && (
//                     <tr>
//                       <td colSpan={6} className="bg-gray-50 border-b px-8 py-4">
//                         <label className="font-bold">Status:</label>
//                         <span className="bg-yellow-500 text-white px-6 py-2 rounded-full text-xs ml-2">
//                           {item.fundStatus}
//                         </span>

//                         <div className="flex flex-col my-6 justify-self-center">
//                           <div className="flex flex-row">
//                             {/* User info */}
//                             <div className="flex flex-col text-left mx-10">
//                               <label className="underline text-center font-semibold">User Info</label>
//                               <div className="flex flex-row justify-normal">
//                                 <div className="flex flex-col justify-normal text-right">
//                                   <strong>Order Id:</strong> 
//                                   <strong>User Name:</strong>
//                                   <strong>User Email:</strong>
//                                   <strong>Apply Date:</strong>
//                                 </div>
//                                 <div className="flex flex-col justify-normal">
//                                   <span className="ml-4">{item.fundOrderId}</span>
//                                   <span className="ml-4">{item.fundUserName}</span>
//                                   <span className="ml-4">{item.fundUserEmail}</span>
//                                   <span className="ml-4">
//                                     {new Date(item.createdAt).toISOString().split('T')[0]}
//                                   </span>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Funded info */}
//                             <div className="flex flex-col text-left mx-10">
//                               <label className="underline text-center font-semibold">Funded Info</label>
//                               <div className="flex flex-row justify-normal">
//                                 <div className="flex flex-col justify-normal text-right">
//                                   <strong>Fund Name:</strong>
//                                   <strong>Fund Size:</strong> 
//                                   <strong>Fund Program Before:</strong>
//                                   <strong>Fund Stage Before:</strong>
//                                 </div>
//                                 <div className="flex flex-col justify-normal">
//                                   <span className="ml-4">{item.fundLinkEvalId}</span>
//                                   <span className="ml-4">{item.fundProgramSize}</span>
//                                   <span className="ml-4">{item.fundEvalBeforeProgram}</span>
//                                   <span className="ml-4">{item.fundEvalBeforeStage}</span>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Payment info */}
//                             <div className="flex flex-col text-right mx-10">
//                               <label className="underline text-center font-semibold">Payment Info</label>
//                               <div className="flex flex-row justify-normal">
//                                 <div className="flex flex-col justify-normal text-right">
//                                   <strong>Pay Amount:</strong>
//                                   <strong>Pay Method:</strong> 
//                                   <strong>Pay Receipts:</strong>
//                                   <strong>Rule and Term:</strong>
//                                 </div>
//                                 <div className="flex flex-col justify-normal text-left">
//                                   <span className="ml-4">{item.fundProgramAmount}</span>
//                                   <span className="ml-4">{item.fundPaymentMethod}</span>
//                                   <span className="text-blue-600 underline cursor-pointer ml-4">
//                                     {item.fundPaymentReceipts || '______'}
//                                   </span>
//                                   <span className="ml-4">{item.fundRuleAgreed}</span>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           {/* Fund Reg Notes */}
//                           <div className="flex flex-col text-left mx-10 mt-4">
//                             <strong>Notes:</strong>
//                             <span className="ml-4">{item.fundRegNotes}</span>
//                           </div>
//                         </div>

//                         {/* Button Section */}
//                         <div className="flex justify-center items-center space-x-2">
//                           <button
//                             onClick={() => handleRejectClick(item.fundOrderId)}
//                             disabled={processingId === item.fundOrderId}
//                             className="bg-rose-600 text-white px-6 py-1 rounded"
//                           >
//                             {processingId === item.fundOrderId ? 'Processing...' : 'Reject'}
//                           </button>
//                           <button
//                             onClick={() => handleApproveClick(item.fundOrderId)}
//                             disabled={processingId === item.fundOrderId}
//                             className={`px-6 py-1 rounded ${
//                               processingId === item.fundOrderId ? 'bg-gray-400' : 'bg-green-600 text-white'
//                             }`}
//                           >
//                             {processingId === item.fundOrderId ? 'Processing...' : 'Approve'}
//                           </button>
//                         </div>

//                       </td>
//                     </tr>
//                   )}
//                 </React.Fragment>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={6} className="text-center py-8">
//                   No pending requests available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* fund program modal */}
//       {showModal && (
//         <FundProgramModal
//           onClose={() => setShowModal(false)}
//           onSave={handleSave}
//         />
//       )}

//       {showRejectModal && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white p-6 rounded-lg w-[500px]">
//             <h2 className="text-xl font-bold mb-4">Reject Fund Program</h2>
//             <form onSubmit={handleRejectSubmit}>
//               <div className="mb-4">
//                 <label className="block text-sm font-medium text-gray-700 mb-2">
//                   Rejection Reason
//                 </label>
//                 <textarea
//                   className="w-full p-2 border rounded-md"
//                   rows={4}
//                   value={rejectReason}
//                   onChange={(e) => setRejectReason(e.target.value)}
//                   placeholder="Please provide a reason for rejection..."
//                   required
//                 />
//               </div>
//               <div className="flex justify-end space-x-2">
//                 <button
//                   type="button"
//                   onClick={() => {
//                     setShowRejectModal(false);
//                     setRejectReason('');
//                   }}
//                   className="px-4 py-2 bg-gray-200 rounded-md"
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="px-4 py-2 bg-red-600 text-white rounded-md"
//                 >
//                   Reject
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//         )}
//     </div>
//   );
// };

// export default FundProgramPending;