// src/components/admin/admin06_Subscription.tsx

import React, { useState } from 'react';
// import icon
import { TbLayoutBottombarExpandFilled, TbLayoutNavbarExpand  } from "react-icons/tb";
// subscrption product user data
import SubUserPending from './SubProductSetting/subUserPending';
import SubUserData from './SubProductSetting/subUserData';
import TradingJournal from '../TradingJournal';
// subscrption product setting
import SubProductSetting from './SubProductSetting/subProductSetting';
import SubPlanSetting from './SubProductSetting/subPlanSetting';
import SubTrialSetting from './SubProductSetting/subTrialSetting';
import SubVoucherSetting from './SubProductSetting/subVoucherSetting';
// subscrption stats data
import SubUserDataStat from './SubProductSetting/subUserDataStat';
// custom hook and context file
import { useRoleAccess } from '../../hooks/AdminDashboard/useRoleAccess';
import { useAuth } from '../../context/AuthContext';   

const SubSetting: React.FC = () => {
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');   
  
  // Default permissions if not explicitly set
  const defaultPermissions = {
    pendingSub: 'enable',
    allDataSub: 'enable',
    productPlanSub: 'enable',
    trialVoucherSub: 'enable',
  };

  const mergedPermissions = {
    ...defaultPermissions,
    ...(permissions?.productSubscription || {}),
  };

  // State to manage active tab
  const [subDataTab, setsubDataTab] = useState<'pending' | 'allData' | 'tradingJournal'>('pending');
  const [subSettingTab, setSubSettingTab] = useState<'productPlan' | 'trialVoucher'>('productPlan');

  // State to manage expand/collapse for each section
  const [isSubDataExpanded, setIsSubDataExpanded] = useState(true);
  const [isSubSettingExpanded, setIsSubSettingExpanded] = useState(false); 

  return (
    <div className="flex flex-col justify-center p-10 border-2">
      <div className="px-2">
        <div className="flex flex-col w-auto h-auto">
          <h1 className="text-xl text-center font-semibold text-cyan-800">
            R One Subscription Product
          </h1>

          {/* Tabs for user subscription data */}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              className={`text-lg font-bold px-8 py-1 rounded-t 
                ${
                  subDataTab === 'pending'
                    ? 'bg-cyan-800 text-white'
                    :  mergedPermissions.pendingSub !== 'enable'
                    ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                    : 'bg-gray-200 text-cyan-800'
                  }`}
              onClick={() => setsubDataTab('pending')}
              disabled={mergedPermissions.pendingSub !== 'enable'}
            >
              Pending
            </button>
            <button
              className={`text-lg font-bold px-8 py-1 rounded-t 
                ${
                  subDataTab === 'allData' 
                    ? 'bg-cyan-800 text-white' 
                    : mergedPermissions.allDataSub !== 'enable'
                    ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                    : 'bg-gray-200 text-cyan-800'
                  }`}
              onClick={() => setsubDataTab('allData')}
              disabled={mergedPermissions.allDataSub !== 'enable'}
            >
              All Data
            </button>
            <button
              className={`text-lg font-bold px-8 py-1 rounded-t ${
                subDataTab === 'tradingJournal'
                  ? 'bg-cyan-800 text-white'
                  : 'bg-gray-200 text-cyan-800'
              }`}
              onClick={() => setsubDataTab('tradingJournal')}
            >
              Algo Trading Journal
            </button>
            <button
              onClick={() => setIsSubDataExpanded(!isSubDataExpanded)}
              // onClick={() => {
              //   if (permissions?.productSubscription?.expandDataSub === 'enable') {
              //     setIsSubDataExpanded(!isSubDataExpanded);
              //   }
              // }}
              className={`text-2xl ${
                permissions?.productSubscription?.expandDataSub !== 'enable' ? 'text-gray-400 cursor-not-allowed' : 'text-cyan-800'
              }`}
              disabled={permissions?.productSubscription?.expandDataSub !== 'enable'}
            >
              {isSubDataExpanded ? <TbLayoutNavbarExpand /> : <TbLayoutBottombarExpandFilled />}
            </button>
          </div>

          {/* Content for each tab in user subscription data */}
          {isSubDataExpanded && (
            <div>
              {subDataTab === 'pending' && (
                <div className="space-y-4">
                  <SubUserPending />
                </div>
              )}
              {subDataTab === 'allData' && (
                <div className="space-y-4">
                  <SubUserData />
                </div>
              )}
              {subDataTab === 'tradingJournal' && (
                <div className="space-y-4">
                  <TradingJournal />
                </div>
              )}
            </div>
          )}

          {/* Render Subscription Stats */}
          <SubUserDataStat />

          {/* Tabs for sub product setting */}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              className={`text-lg font-bold px-8 py-1 rounded-t 
                ${
                  subSettingTab === 'productPlan' 
                    ? 'bg-cyan-800 text-white'
                    : mergedPermissions.productPlanSub !== 'enable'
                    ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                    : 'bg-gray-200 text-cyan-800'
                  }`}
              onClick={() => setSubSettingTab('productPlan')}
              disabled={mergedPermissions.productPlanSub !== 'enable'}
            >
              Product & Plan
            </button>
            <button
              className={`text-lg font-bold px-8 py-1 rounded-t 
                ${
                  subSettingTab === 'trialVoucher' 
                    ? 'bg-cyan-800 text-white'
                    : mergedPermissions.trialVoucherSub !== 'enable'
                    ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                    : 'bg-gray-200 text-cyan-800'
                  }`}
              onClick={() => setSubSettingTab('trialVoucher')}
              disabled={mergedPermissions.trialVoucherSub !== 'enable'}
            >
              Trials & Voucher
            </button>
            <button
              onClick={() => setIsSubSettingExpanded(!isSubSettingExpanded)}
              // onClick={() => {
              //   if (permissions?.productSubscription?.expandProductSub == 'enable') {
              //     setIsSubSettingExpanded(!isSubSettingExpanded);
              //   }
              // }}
              className={`text-2xl ${
                permissions?.productSubscription?.expandProductSub !== 'enable' ? 'text-gray-400 cursor-not-allowed' : 'text-cyan-800'
              }`}
              disabled={permissions?.productSubscription?.expandProductSub !== 'enable'}
            >
              {isSubSettingExpanded ? <TbLayoutNavbarExpand /> : <TbLayoutBottombarExpandFilled/>}
            </button>
          </div>

          {/* Content for each tab in sub product setting */}
          {isSubSettingExpanded && (
            <div>
              {subSettingTab === 'productPlan' && (
                <div className="space-y-4">
                  <SubProductSetting />
                  <SubPlanSetting />
                </div>
              )}
              {subSettingTab === 'trialVoucher' && (
                <div className="space-y-4">
                  <SubTrialSetting />
                  <SubVoucherSetting />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubSetting;

// // update code --> 23 dec 2024
// // src/components/admin/admin06_Subscription.tsx

// import React, { useState } from 'react';
// // import icon
// import { TbLayoutBottombarExpandFilled, TbLayoutNavbarExpand  } from "react-icons/tb";
// // subscrption product user data
// import SubUserPending from './SubProductSetting/subUserPending';
// import SubUserData from './SubProductSetting/subUserData';
// // subscrption product setting
// import SubProductSetting from './SubProductSetting/subProductSetting';
// import SubPlanSetting from './SubProductSetting/subPlanSetting';
// import SubTrialSetting from './SubProductSetting/subTrialSetting';
// import SubVoucherSetting from './SubProductSetting/subVoucherSetting';
// // subscrption stats data
// import SubUserDataStat from './SubProductSetting/subUserDataStat';
// // custom hook and context file
// import { useRoleAccess } from '../../hooks/AdminDashboard/useRoleAccess';
// import { useAuth } from '../../context/AuthContext';   

// const SubSetting: React.FC = () => {
//   const { user } = useAuth();
//   const { permissions } = useRoleAccess(user?.email || '');   
  
//   // Default permissions if not explicitly set
//   const defaultPermissions = {
//     pendingSub: 'enable',
//     allDataSub: 'enable',
//     productPlanSub: 'enable',
//     trialVoucherSub: 'enable',
//   };

//   const mergedPermissions = {
//     ...defaultPermissions,
//     ...(permissions?.productSubscription || {}),
//   };

//   // State to manage active tab
//   const [subDataTab, setsubDataTab] = useState<'pending' | 'allData'>('pending');
//   const [subSettingTab, setSubSettingTab] = useState<'productPlan' | 'trialVoucher'>('productPlan');

//   // State to manage expand/collapse for each section
//   const [isSubDataExpanded, setIsSubDataExpanded] = useState(true);
//   const [isSubSettingExpanded, setIsSubSettingExpanded] = useState(false); 

//   return (
//     <div className="flex flex-col justify-center p-10 border-2">
//       <div className="px-2">
//         <div className="flex flex-col w-auto h-auto">
//           <h1 className="text-xl text-center font-semibold text-cyan-800">
//             R One Subscription Product
//           </h1>

//           {/* Tabs for user subscription data */}
//           <div className="flex justify-center mt-4 space-x-2">
//             <button
//               className={`text-lg font-bold px-8 py-1 rounded-t 
//                 ${
//                   subDataTab === 'pending'
//                     ? 'bg-cyan-800 text-white'
//                     :  mergedPermissions.pendingSub !== 'enable'
//                     ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
//                     : 'bg-gray-200 text-cyan-800'
//                   }`}
//               onClick={() => setsubDataTab('pending')}
//               disabled={mergedPermissions.pendingSub !== 'enable'}
//             >
//               Pending
//             </button>
//             <button
//               className={`text-lg font-bold px-8 py-1 rounded-t 
//                 ${
//                   subDataTab === 'allData' 
//                     ? 'bg-cyan-800 text-white' 
//                     : mergedPermissions.allDataSub !== 'enable'
//                     ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
//                     : 'bg-gray-200 text-cyan-800'
//                   }`}
//               onClick={() => setsubDataTab('allData')}
//               disabled={mergedPermissions.allDataSub !== 'enable'}
//             >
//               All Data
//             </button>
//             <button
//               onClick={() => setIsSubDataExpanded(!isSubDataExpanded)}
//               // onClick={() => {
//               //   if (permissions?.productSubscription?.expandDataSub === 'enable') {
//               //     setIsSubDataExpanded(!isSubDataExpanded);
//               //   }
//               // }}
//               className={`text-2xl ${
//                 permissions?.productSubscription?.expandDataSub !== 'enable' ? 'text-gray-400 cursor-not-allowed' : 'text-cyan-800'
//               }`}
//               disabled={permissions?.productSubscription?.expandDataSub !== 'enable'}
//             >
//               {isSubDataExpanded ? <TbLayoutNavbarExpand /> : <TbLayoutBottombarExpandFilled />}
//             </button>
//           </div>

//           {/* Content for each tab in user subscription data */}
//           {isSubDataExpanded && (
//             <div>
//               {subDataTab === 'pending' && (
//                 <div className="space-y-4">
//                   <SubUserPending />
//                 </div>
//               )}
//               {subDataTab === 'allData' && (
//                 <div className="space-y-4">
//                   <SubUserData />
//                 </div>
//               )}
//             </div>
//           )}

//           {/* Render Subscription Stats */}
//           <SubUserDataStat />

//           {/* Tabs for sub product setting */}
//           <div className="flex justify-center mt-4 space-x-2">
//             <button
//               className={`text-lg font-bold px-8 py-1 rounded-t 
//                 ${
//                   subSettingTab === 'productPlan' 
//                     ? 'bg-cyan-800 text-white'
//                     : mergedPermissions.productPlanSub !== 'enable'
//                     ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
//                     : 'bg-gray-200 text-cyan-800'
//                   }`}
//               onClick={() => setSubSettingTab('productPlan')}
//               disabled={mergedPermissions.productPlanSub !== 'enable'}
//             >
//               Product & Plan
//             </button>
//             <button
//               className={`text-lg font-bold px-8 py-1 rounded-t 
//                 ${
//                   subSettingTab === 'trialVoucher' 
//                     ? 'bg-cyan-800 text-white'
//                     : mergedPermissions.trialVoucherSub !== 'enable'
//                     ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
//                     : 'bg-gray-200 text-cyan-800'
//                   }`}
//               onClick={() => setSubSettingTab('trialVoucher')}
//               disabled={mergedPermissions.trialVoucherSub !== 'enable'}
//             >
//               Trials & Voucher
//             </button>
//             <button
//               onClick={() => setIsSubSettingExpanded(!isSubSettingExpanded)}
//               // onClick={() => {
//               //   if (permissions?.productSubscription?.expandProductSub == 'enable') {
//               //     setIsSubSettingExpanded(!isSubSettingExpanded);
//               //   }
//               // }}
//               className={`text-2xl ${
//                 permissions?.productSubscription?.expandProductSub !== 'enable' ? 'text-gray-400 cursor-not-allowed' : 'text-cyan-800'
//               }`}
//               disabled={permissions?.productSubscription?.expandProductSub !== 'enable'}
//             >
//               {isSubSettingExpanded ? <TbLayoutNavbarExpand /> : <TbLayoutBottombarExpandFilled/>}
//             </button>
//           </div>

//           {/* Content for each tab in sub product setting */}
//           {isSubSettingExpanded && (
//             <div>
//               {subSettingTab === 'productPlan' && (
//                 <div className="space-y-4">
//                   <SubProductSetting />
//                   <SubPlanSetting />
//                 </div>
//               )}
//               {subSettingTab === 'trialVoucher' && (
//                 <div className="space-y-4">
//                   <SubTrialSetting />
//                   <SubVoucherSetting />
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SubSetting;

// // udptea code --> 04 dec 2024
// // src/components/admin/admin06_Subscription.tsx

// import React, { useState } from 'react';
// // import icon
// import { TbLayoutBottombarExpandFilled, TbLayoutNavbarExpand  } from "react-icons/tb";
// // subscrption product user data
// import SubUserPending from './SubProductSetting/subUserPending';
// import SubUserData from './SubProductSetting/subUserData';
// // subscrption product setting
// import SubProductSetting from './SubProductSetting/subProductSetting';
// import SubPlanSetting from './SubProductSetting/subPlanSetting';
// import SubTrialSetting from './SubProductSetting/subTrialSetting';
// import SubVoucherSetting from './SubProductSetting/subVoucherSetting';
// // subscrption stats data
// import SubUserDataStat from './SubProductSetting/subUserDataStat';
// // custom hook and context file
// import { useRoleAccess } from '../../hooks/AdminDashboard/useRoleAccess';
// import { useAuth } from '../../context/AuthContext';   

// const SubSetting: React.FC = () => {
//   const { user } = useAuth();
//   const { permissions } = useRoleAccess(user?.email || '');   
  
//   // Default permissions if not explicitly set
//   const defaultPermissions = {
//     pendingSub: 'enable',
//     allDataSub: 'enable',
//     productPlanSub: 'enable',
//     trialVoucherSub: 'enable',
//   };

//   const mergedPermissions = {
//     ...defaultPermissions,
//     ...(permissions?.productSubscription || {}),
//   };

//   // State to manage active tab
//   const [subDataTab, setsubDataTab] = useState<'pending' | 'allData'>('pending');
//   const [subSettingTab, setSubSettingTab] = useState<'productPlan' | 'trialVoucher'>('productPlan');

//   // State to manage expand/collapse for each section
//   const [isSubDataExpanded, setIsSubDataExpanded] = useState(true);
//   const [isSubSettingExpanded, setIsSubSettingExpanded] = useState(false); 

//   return (
//     <div className="flex flex-col justify-center p-10 border-2">
//       <div className="px-2">
//         <div className="flex flex-col w-auto h-auto">
//           <h1 className="text-xl text-center font-semibold text-cyan-800">
//             R One Subscription Product
//           </h1>

//           {/* Tabs for user subscription data */}
//           <div className="flex justify-center mt-4 space-x-2">
//             <button
//               className={`text-lg font-bold px-8 py-1 rounded-t 
//                 ${
//                   subDataTab === 'pending'
//                     ? 'bg-cyan-800 text-white'
//                     :  mergedPermissions.pendingSub !== 'enable'
//                     ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
//                     : 'bg-gray-200 text-cyan-800'
//                 }`}
//               onClick={() => setsubDataTab('pending')}
//               disabled={mergedPermissions.pendingSub !== 'enable'}
//             >
//               Pending
//             </button>
//             <button
//               className={`text-lg font-bold px-8 py-1 rounded-t 
//                 ${
//                   subDataTab === 'allData' 
//                     ? 'bg-cyan-800 text-white' 
//                     : mergedPermissions.allDataSub !== 'enable'
//                     ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
//                     : 'bg-gray-200 text-cyan-800'
//                   }`}
//               onClick={() => setsubDataTab('allData')}
//               disabled={mergedPermissions.allDataSub !== 'enable'}
//             >
//               All Data
//             </button>
//             <button
//               onClick={() => setIsSubDataExpanded(!isSubDataExpanded)}
//               className="text-cyan-800 text-2xl"
//             >
//               {isSubDataExpanded ? <TbLayoutNavbarExpand /> : <TbLayoutBottombarExpandFilled />}
//             </button>
//           </div>

//           {/* Content for each tab in user subscription data */}
//           {isSubDataExpanded && (
//             <div>
//               {subDataTab === 'pending' && (
//                 <div className="space-y-4">
//                   <SubUserPending />
//                 </div>
//               )}
//               {subDataTab === 'allData' && (
//                 <div className="space-y-4">
//                   <SubUserData />
//                 </div>
//               )}
//             </div>
//           )}

//           {/* Render Subscription Stats */}
//           <SubUserDataStat />

//           {/* Tabs for sub product setting */}
//           <div className="flex justify-center mt-4 space-x-2">
//             <button
//               className={`text-lg font-bold px-8 py-1 rounded-t 
//                 ${
//                   subSettingTab === 'productPlan' 
//                     ? 'bg-cyan-800 text-white'
//                     : mergedPermissions.productPlanSub !== 'enable'
//                     ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
//                     : 'bg-gray-200 text-cyan-800'
//                   }`}
//               onClick={() => setSubSettingTab('productPlan')}
//               disabled={mergedPermissions.productPlanSub !== 'enable'}
//             >
//               Product & Plan
//             </button>
//             <button
//               className={`text-lg font-bold px-8 py-1 rounded-t 
//                 ${
//                   subSettingTab === 'trialVoucher' 
//                     ? 'bg-cyan-800 text-white'
//                     : mergedPermissions.trialVoucherSub !== 'enable'
//                     ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
//                     : 'bg-gray-200 text-cyan-800'
//                   }`}
//               onClick={() => setSubSettingTab('trialVoucher')}
//               disabled={mergedPermissions.trialVoucherSub !== 'enable'}
//             >
//               Trials & Voucher
//             </button>
//             <button
//               onClick={() => setIsSubSettingExpanded(!isSubSettingExpanded)}
//               className="text-cyan-800 text-2xl"
//             >
//               {isSubSettingExpanded ? <TbLayoutNavbarExpand /> : <TbLayoutBottombarExpandFilled/>}
//             </button>
//           </div>

//           {/* Content for each tab in sub product setting */}
//           {isSubSettingExpanded && (
//             <div>
//               {subSettingTab === 'productPlan' && (
//                 <div className="space-y-4">
//                   <SubProductSetting />
//                   <SubPlanSetting />
//                 </div>
//               )}
//               {subSettingTab === 'trialVoucher' && (
//                 <div className="space-y-4">
//                   <SubTrialSetting />
//                   <SubVoucherSetting />
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SubSetting;