// src/hooks/AdminDashboard/useFundProgramData.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface FundUser {
  fundOrderId: string;
  fundLinkEvalId: string;
  fundUserName: string;
  fundUserEmail: string;
  fundStatus: string;
  fundEvalSize: number;
  fundEvalProgress: string;
  fundEvalExpiredDate: string;
  fundEvalExpiredDays: string;
  fundAccountNumber: number;
  fundAccountPassword: string;
  fundAccountServer: string;
  fundEvalBeforeProgram: string;
  fundEvalBeforeStage: string;
  fundRuleAgreed: string;
  fundTermAgreed: string;
  fundPaymentMethod: string;
  fundPaymentReceipts: string;
  fundRegNotes: string;
  fundRemark: string;
  createdAt: string;
}

const useFundUserData = () => {
  const [data, setData] = useState<FundUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/fund/users`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();
        setData(result.data);
      } catch (err) {
        console.error('Error fetching fund users:', err);
        setError('Failed to load fund user data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiUrl]);

  // Update user details
  const updateUser = async (fundOrderId: string, updateData: Partial<FundUser>) => {
    try {
      const response = await fetch(`${apiUrl}/api/fund/users/${fundOrderId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      setData(prevData =>
        prevData.map(user =>
          user.fundOrderId === fundOrderId
            ? { ...user, ...result.updatedData }
            : user
        )
      );
      return result.updatedData;
    } catch (err) {
      console.error('Error updating fund user:', err);
      setError('Failed to update fund user');
      throw err;
    }
  };

     // Delete user
     const deleteUser = async (fundOrderId: string) => {
      try {
        const response = await fetch(`${apiUrl}/api/fund/users/${fundOrderId}`, {
          method: 'DELETE',
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        setData(prevData => prevData.filter(user => user.fundOrderId !== fundOrderId));
        return true;
      } catch (err) {
        console.error('Error deleting fund user:', err);
        setError('Failed to delete fund user');
        throw err;
      }
    };

  const updateFundProgress = async (fundOrderId: string, action: 'pass' | 'fail') => {
    try {
      const response = await fetch(`${apiUrl}/api/fund/update-progress/${fundOrderId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      setData(prevData =>
        prevData.map(user =>
          user.fundOrderId === fundOrderId
            ? { ...user, ...result.updatedData }
            : user
        )
      );
    } catch (err) {
      console.error('Error updating fund progress:', err);
      setError('Failed to update fund progress');
    }
  };

  return { 
    data, loading, error,
    updateUser, deleteUser, 
    updateFundProgress 
  };
};

export default useFundUserData;