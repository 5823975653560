// src/components/TradingJournalModal.tsx

import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  CartesianGrid,
} from 'recharts';
import { IoCloseCircle } from "react-icons/io5";
import { MdInsertChart, MdStars, MdDateRange } from "react-icons/md";
import { useAccountJournal } from '../hooks/useAccountJournal';

interface AccountDetailsModalProps {
  accountNumber: string | null;
  isOpen: boolean;
  onClose: () => void;
}

interface Stats {
  startedTrade: string;
  growth: string;
  profit: string;
  equity: string;
  balance: string;
  initialBalance: string;
  tradingDays: number;
  tradesPerWeek: string;
  avgHoldingTime: string;
}

const TradingJournalModal: React.FC<AccountDetailsModalProps> = ({ accountNumber, isOpen, onClose }) => {
  // handle table data states
  const { 
    fetchAccountHistoryFromFile, fetchAccountPositionFromFile, fetchAccountStats,
  } = useAccountJournal();

  const [historyData, setHistoryData] = useState<any[]>([]);
  const [positionData, setPositionData] = useState<any[]>([]);
  // handle table sorting states
  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  // handle interface state
  const [activeTab, setActiveTab] = useState<'history' | 'position'>('history');
  const [activeChartTab, setActiveChartTab] = useState<'growth' | 'balance' | 'risk'>('growth');
  const [stats, setStats] = useState<Stats>({
    startedTrade: 'N/A',
    growth: '0.00',
    profit: '0.00',
    equity: '0.00',
    balance: '0.00',
    initialBalance: '0.00',
    tradingDays: 0,
    tradesPerWeek: '0.00',
    avgHoldingTime: '0.00',
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!accountNumber) return;
  
      setLoading(true);
      setError(null);
      
      try {
        // Fetch account stats
        const fetchedStats = await fetchAccountStats(accountNumber);
        
        setStats({
          startedTrade: fetchedStats.startedTrade || 'N/A',
          growth: fetchedStats.growth || '0.00',
          profit: fetchedStats.totalProfit || '0.00',
          equity: fetchedStats.equity || '0.00',
          balance: fetchedStats.balance || '0.00',
          initialBalance: fetchedStats.initialBalance || '0.00',
          tradingDays: fetchedStats.tradingDays || 0,
          tradesPerWeek: fetchedStats.tradesPerWeek || '0.00',
          avgHoldingTime: fetchedStats.avgHoldingTime || '0.00',
        });
  
        // Fetch history and position data
        const [history, position] = await Promise.all([
          fetchAccountHistoryFromFile(accountNumber),
          fetchAccountPositionFromFile(accountNumber),
        ]);
  
        setHistoryData(history);
        setPositionData(position);
      } catch (err: any) {
        // Display error message without "Error:" prefix
        setError(err.message.includes('No Trading Stats for this account')
          ? 'No Trading Stats for this account'
          : err.message || 'Error fetching account data');
      } finally {
        setLoading(false);
      }
    };
  
    if (isOpen) {
      fetchData();
    }
  }, [
    accountNumber, isOpen,
    fetchAccountStats, fetchAccountHistoryFromFile, fetchAccountPositionFromFile
  ]); 
  
  if (!isOpen) return null;

  const formatTableHeaders = (header: string) => {
    const headersMap: { [key: string]: string } = {
      symbol: 'Symbol',
      ticket: 'Ticket',
      open_time: 'Open Time',
      close_time: 'Close Time',
      swaps: 'Swaps',
      volume: 'Volume',
      open_price: 'Open Price',
      tp: 'Take Profit',
      sl: 'Stop Loss',
      close_price: 'Close Price',
      commission: 'Commission',
      comment: 'Comment',
      cmd: 'Order Type',
      profit: 'Profit',
    };
    return headersMap[header] || header;
  };

  const getChartData = () => {
    if (historyData.length === 0 || !stats.initialBalance) return [];
  
    const initialBalance = parseFloat(stats.initialBalance);
  
    switch (activeChartTab) {
      case 'growth':
        return historyData.reduce((acc, trade, index) => {
          const previousBalance = acc[index - 1]?.balance || initialBalance;
          const currentBalance = previousBalance + parseFloat(trade.profit || '0');
          const growthPercentage = ((currentBalance - initialBalance) / initialBalance) * 100;
  
          return [
            ...acc,
            {
              time: `Trade ${index + 1}`,
              growth: growthPercentage,
              balance: Math.round(currentBalance), 
              profit: Math.round(parseFloat(trade.profit || '0')),
            },
          ];
        }, []);
      case 'risk':
        return historyData.map((trade, index) => ({
          time: `Trade ${index + 1}`,
          risk: parseFloat(trade.risk || '0'),
        }));
      default:
        return [];
    }
  };  
  
  const chartData = getChartData();  

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const growthValue = Math.round(payload[0].value || 0);
      const profitValue = payload[0]?.payload?.profit || 0;
      const formattedGrowth = growthValue < 0 ? `-${Math.abs(growthValue)}%` : `${growthValue}%`;
  
      return (
        <div style={{ backgroundColor: '#0f5469', color: '#ffffff', padding: '5px', borderRadius: '3px', fontSize: 12 }}>
          <p>Growth Percentage: {formattedGrowth}</p>
          <p>Profit: ${profitValue}</p>
        </div>
      );
    }
    return null;
  };     

  const formatOrderType = (cmd: string | number) => {
    return cmd === '1' || cmd === 1 ? 'Buy' : cmd === '0' || cmd === 0 ? 'Sell' : cmd;
  };

  const renderStars = (rating: number) => {
    const maxStars = 5;
    return Array.from({ length: maxStars }, (_, i) => (
      <span key={i} className={i < rating ? 'text-yellow-500' : 'text-gray-300'}>
        {i < rating ? '★' : '☆'}
      </span>
    ));
  };

  const handleSort = (key: string) => {
    if (sortKey === key) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };  

  const sortedData = [...(activeTab === 'history' ? historyData : positionData)].sort((a, b) => {
    if (!sortKey) return 0;
  
    const aValue = a[sortKey];
    const bValue = b[sortKey];
  
    // Handle numeric sorting
    if (!isNaN(aValue) && !isNaN(bValue)) {
      return sortOrder === 'asc' 
        ? bValue - aValue 
        : aValue - bValue; 
    }
  
    // Handle string sorting (default)
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortOrder === 'asc'
        ? bValue.localeCompare(aValue)
        : aValue.localeCompare(bValue); 
    }
  
    return 0; 
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-[1600px] h-auto max-h-screen">
        <div className="bg-cyan-800 flex justify-between items-center border-b px-8 py-6">
          <div className="flex justify-start items-center space-x-12">
            
            <div className="flex justify-between items-center">
              <MdInsertChart className="text-2xl text-white mr-2"/>
              <h1 className="text-2xl font-semibold text-white mr-2">Account:</h1>
              <span className="text-xl text-white mr-2">{accountNumber}</span>
            </div>

            <div className="flex justify-between items-center">
              <MdStars className="text-2xl text-white mr-2"/>
              <h1 className="text-2xl font-semibold text-white mr-2">Rating:</h1>
              <span className="text-2xl text-white mr-2">{renderStars(4)}</span>
            </div>

            <div className="flex justify-between items-center">
              <MdDateRange className="text-2xl text-white mr-2"/>
              <h1 className="text-2xl font-semibold text-white mr-2">Started Trade:</h1>
              <span className="text-xl text-white">{stats.startedTrade}</span>
            </div>  
          
          </div>
          <button 
            onClick={onClose} 
            className="text-white flex justify-between items-center"
          >
            Close <IoCloseCircle className="ml-2 text-2xl"/> 
          </button>
        </div>

        {loading && <p className="text-center py-4 text-gray-500">Loading data...</p>}
        {error && <p className="text-center py-4 text-red-500">{error}</p>}

        {!loading && !error && (
          <div className="flex flex-row justify-center space-x-4 p-4">
            {/* Left Section: Trading Stats */}
            <div className="p-6">
              <h3 className="text-lg font-semibold mb-2 text-cyan-800">
                Trading Stats
              </h3>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col border p-4 rounded-md shadow-md">
                  <span className="font-semibold text-cyan-800">Growth:</span>
                  <span className="flex justify-end">{stats.growth} %</span>
                </div>
                <div className="flex flex-col border p-4 rounded-md shadow-md">
                  <span className="font-semibold text-cyan-800">Profit:</span>
                  <span className="flex justify-end">$ {stats.profit}</span>
                </div>
                <div className="flex flex-col border p-4 rounded-md shadow-md">
                  <span className="font-semibold text-cyan-800">Equity:</span>
                  <span className="flex justify-end">$ {stats.equity}</span>
                </div>
                <div className="flex flex-col border p-4 rounded-md shadow-md">
                  <span className="font-semibold text-cyan-800">Balance:</span>
                  <span className="flex justify-end">$ {stats.balance}</span>
                </div>
                <div className="flex flex-col border p-4 rounded-md shadow-md">
                  <span className="font-semibold text-cyan-800">Initial Balance:</span>
                  <span className="flex justify-end">$ {stats.initialBalance}</span>
                </div>
                <div className="flex flex-col border p-4 rounded-md shadow-md">
                  <span className="font-semibold text-cyan-800">Trading Days:</span>
                  <span className="flex justify-end">{stats.tradingDays}</span>
                </div>
                <div className="flex flex-col border p-4 rounded-md shadow-md">
                  <span className="font-semibold text-cyan-800">Trades/Week:</span>
                  <span className="flex justify-end">{stats.tradesPerWeek}</span>
                </div>
                <div className="flex flex-col border p-4 rounded-md shadow-md">
                  <span className="font-semibold text-cyan-800">Avg Holding Time:</span>
                  <span className="flex justify-end">{stats.avgHoldingTime} hrs</span>
                </div>
              </div>
            </div>

            {/* Right Section: Overall Chart */}
            <div className="bg-gray-50 p-6 border rounded-lg w-auto min-w-[1100px]">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold mb-4 text-cyan-800">Overall Performance</h3>
                <div className="flex justify-center space-x-2 mb-4">
                  {['growth', 'risk'].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveChartTab(tab as 'growth' | 'balance' | 'risk')}
                      className={`px-4 py-2 rounded-md ${
                        activeChartTab === tab ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      {tab === 'growth' && 'Account Growth'}
                      {tab === 'risk' && 'Account Risk'}
                    </button>
                  ))}
                </div>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={chartData}>
                  {/* Gradient Definitions */}
                  <defs>
                    <linearGradient id="chartGradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#0f5469" stopOpacity={0.8} />
                      <stop offset="100%" stopColor="#43bae6" stopOpacity={0.1} />
                    </linearGradient>
                  </defs>

                  {/* X-Axis for Balance Amount */}
                  <XAxis
                    dataKey="balance" 
                    domain={[0, 'dataMax']} 
                    tick={{ fill: '#0f5469', fontSize: 10 }}
                    tickFormatter={(tick) => `${Math.round(tick)}`} 
                    interval={Math.ceil(chartData.length / 30)}
                  />

                  {/* Y-Axis for Growth Percentage */}
                  <YAxis
                    tick={{ fill: '#0f5469', fontSize: 10, fontWeight:'bold' }}
                    domain={['auto', 'auto']}
                    tickFormatter={(tick) =>
                      tick < 0 ? `-${Math.abs(tick)}%` : `${Math.round(tick)}%`
                    }
                    allowDecimals={false}
                    axisLine
                    tickLine={{ stroke: '#ddd' }}
                    width={60}
                  />

                  {/* Add Gridlines for Every +10% */}
                  <CartesianGrid stroke="#c4c4c4" strokeDasharray="3 3" vertical={false} />

                  {/* Tooltip */}
                  <Tooltip 
                    content={<CustomTooltip />} 
                  />

                  {/* Area for Growth */}
                  <Area
                    type="monotone"
                    dataKey={activeChartTab === 'growth' ? 'growth' : activeChartTab}
                    stroke="#0f5469"
                    fill="url(#chartGradient)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}

        {/* Bottom Section: Tabbed Position/History Table */}
        {!loading && !error && (
          <div className="p-6">
            <div className="flex justify-between mx-4">
              {/* Active Tab */}
              <div className="flex text-sm">
                <button
                  className={`px-12 py-2 rounded-tl-md ${activeTab === 'history' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'}`}
                  onClick={() => setActiveTab('history')}
                >
                  History
                </button>
                <button
                  className={`px-12 py-2 rounded-tr-md ${activeTab === 'position' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'}`}
                  onClick={() => setActiveTab('position')}
                >
                  Position
                </button>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-cyan-800">
                  {activeTab === 'history' ? 'History Data' : 'Position Data'}
                </h3>
              </div>
            </div>
            <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
              {activeTab === 'history' && (
                <div className="p-4 text-center text-nowrap">
                  {Array.isArray(historyData) && historyData.length > 0 ? (
                    <div className="overflow-auto max-h-[400px]">
                      <table className="min-w-full bg-white">
                        <thead className="bg-gray-100 sticky top-0">
                          <tr>
                            {[
                              "symbol",
                              "ticket",
                              "open_time",
                              "close_time",
                              "cmd",
                              "volume",
                              "open_price",
                              "tp",
                              "close_price",
                              "sl",
                              "commission",
                              "swaps",
                              "comment",
                              "profit",
                            ].map((key) => (
                              <th
                                key={key}
                                className="py-2 px-4 border-b text-center text-cyan-800 hover:text-cyan-600 cursor-pointer font-bold"
                                onClick={() => handleSort(key)}
                              >
                                {formatTableHeaders(key)}
                                {sortKey === key && (
                                  <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {sortedData.map((row, idx) => (
                            <tr key={idx} className="hover:bg-gray-50">
                              {[
                                "symbol",
                                "ticket",
                                "open_time",
                                "close_time",
                                "cmd",
                                "volume",
                                "open_price",
                                "tp",
                                "close_price",
                                "sl",
                                "commission",
                                "swaps",
                                "comment",
                                "profit",
                              ].map((key, keyIdx) => (
                                <td
                                  key={keyIdx}
                                  className="py-2 px-4 border-b text-gray-600 text-center"
                                >
                                  {key === "cmd"
                                    ? formatOrderType(row[key] as string | number)
                                    : key === "volume"
                                    ? (parseFloat(row[key]) * 0.01).toFixed(2)
                                    : String(row[key])}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="text-gray-500">No history data available.</p>
                  )}
                </div>
              )}
              {activeTab === 'position' && (
                <div className="p-4 text-center text-nowrap">
                  {Array.isArray(positionData) && positionData.length > 0 ? (
                    <div className="overflow-auto max-h-[400px]">
                      <table className="min-w-full bg-white">
                        <thead className="bg-gray-100">
                          <tr>
                            {[
                              "symbol",
                              "ticket",
                              "open_time",
                              "cmd", 
                              "volume",
                              "tp", 
                              "open_price", 
                              "sl", 
                              "commission",
                              "swaps",
                              "comment",
                              "profit",
                            ].map((key) => (
                              <th 
                                key={key} 
                                className="py-2 px-4 border-b text-center text-cyan-800 font-bold"
                                onClick={() => handleSort(key)}
                              >
                                {formatTableHeaders(key)}
                                {sortKey === key && (
                                  <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {sortedData.map((row, idx) => (
                            <tr key={idx} className="hover:bg-gray-50">
                              {[
                                "symbol",
                                "ticket",
                                "open_time",
                                "cmd", 
                                "volume", 
                                "tp", 
                                "open_price",
                                "sl",
                                "commission",
                                "swaps",
                                "comment",
                                "profit",
                              ].map((key, keyIdx) => (
                                <td
                                  key={keyIdx}
                                  className="py-2 px-4 border-b text-gray-600 text-center"
                                >
                                  {key === "cmd" 
                                    ? formatOrderType(row[key] as string | number)
                                    : key === "volume"
                                    ? (parseFloat(row[key]) * 0.01).toFixed(2)
                                    : String(row[key])}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="text-gray-500">No position data available.</p>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TradingJournalModal;

// // udpatre code --> 26 dec 2024
// // src/components/TradingJournalModal.tsx

// import React, { useEffect, useState } from 'react';
// import {
//   ResponsiveContainer,
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   Tooltip,
//   TooltipProps,
//   CartesianGrid,
// } from 'recharts';
// import { IoCloseCircle } from "react-icons/io5";
// import { MdInsertChart, MdStars, MdDateRange } from "react-icons/md";
// import { useAccountJournal } from '../hooks/useAccountJournal';

// interface AccountDetailsModalProps {
//   accountNumber: string | null;
//   isOpen: boolean;
//   onClose: () => void;
// }

// interface Stats {
//   startedTrade: string;
//   growth: string;
//   profit: string;
//   equity: string;
//   balance: string;
//   initialBalance: string;
//   tradingDays: number;
//   tradesPerWeek: string;
//   avgHoldingTime: string;
// }

// const TradingJournalModal: React.FC<AccountDetailsModalProps> = ({ accountNumber, isOpen, onClose }) => {
//   // handle table data states
//   const { fetchAccountHistoryFromFile, fetchAccountPositionFromFile, fetchAccountStats } = useAccountJournal();
//   const [historyData, setHistoryData] = useState<any[]>([]);
//   const [positionData, setPositionData] = useState<any[]>([]);
//   // handle table sorting states
//   const [sortKey, setSortKey] = useState<string | null>(null);
//   const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
//   // handle interface state
//   const [activeTab, setActiveTab] = useState<'history' | 'position'>('history');
//   const [activeChartTab, setActiveChartTab] = useState<'growth' | 'balance' | 'risk'>('growth');
//   const [stats, setStats] = useState<Stats>({
//     startedTrade: 'N/A',
//     growth: '0.00',
//     profit: '0.00',
//     equity: '0.00',
//     balance: '0.00',
//     initialBalance: '0.00',
//     tradingDays: 0,
//     tradesPerWeek: '0.00',
//     avgHoldingTime: '0.00',
//   });
  
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!accountNumber) return;
  
//       setLoading(true);
//       setError(null);
//       try {
//         const fetchedStats = await fetchAccountStats(accountNumber);
//         setStats({
//           startedTrade: fetchedStats.startedTrade || 'N/A',
//           growth: fetchedStats.growth || '0.00',
//           profit: fetchedStats.totalProfit || '0.00',
//           equity: fetchedStats.equity || '0.00',
//           balance: fetchedStats.balance || '0.00',
//           initialBalance: fetchedStats.initialBalance || '0.00',
//           tradingDays: fetchedStats.tradingDays || 0,
//           tradesPerWeek: fetchedStats.tradesPerWeek || '0.00',
//           avgHoldingTime: fetchedStats.avgHoldingTime || '0.00',
//         });
  
//         const history = await fetchAccountHistoryFromFile(accountNumber);
//         const position = await fetchAccountPositionFromFile(accountNumber);
  
//         setHistoryData(history);
//         setPositionData(position);
//       } catch (err: any) {
//         // Display error message without "Error:" prefix
//         setError(err.message.includes('No Trading Stats for this account')
//           ? 'No Trading Stats for this account'
//           : err.message || 'Error fetching account data');
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     if (isOpen) {
//       fetchData();
//     }
//   }, [accountNumber, isOpen, fetchAccountStats, fetchAccountHistoryFromFile, fetchAccountPositionFromFile]);  
  
//   if (!isOpen) return null;

//   const formatTableHeaders = (header: string) => {
//     const headersMap: { [key: string]: string } = {
//       symbol: 'Symbol',
//       ticket: 'Ticket',
//       open_time: 'Open Time',
//       close_time: 'Close Time',
//       swaps: 'Swaps',
//       volume: 'Volume',
//       open_price: 'Open Price',
//       tp: 'Take Profit',
//       sl: 'Stop Loss',
//       close_price: 'Close Price',
//       commission: 'Commission',
//       comment: 'Comment',
//       cmd: 'Order Type',
//       profit: 'Profit',
//     };
//     return headersMap[header] || header;
//   };

//   const getChartData = () => {
//     if (historyData.length === 0 || !stats.initialBalance) return [];
  
//     const initialBalance = parseFloat(stats.initialBalance);
  
//     switch (activeChartTab) {
//       case 'growth':
//         return historyData.reduce((acc, trade, index) => {
//           const previousBalance = acc[index - 1]?.balance || initialBalance;
//           const currentBalance = previousBalance + parseFloat(trade.profit || '0');
//           const growthPercentage = ((currentBalance - initialBalance) / initialBalance) * 100;
  
//           return [
//             ...acc,
//             {
//               time: `Trade ${index + 1}`,
//               growth: growthPercentage,
//               balance: Math.round(currentBalance), 
//               profit: Math.round(parseFloat(trade.profit || '0')),
//             },
//           ];
//         }, []);
//       case 'risk':
//         return historyData.map((trade, index) => ({
//           time: `Trade ${index + 1}`,
//           risk: parseFloat(trade.risk || '0'),
//         }));
//       default:
//         return [];
//     }
//   };  
  
//   const chartData = getChartData();  

//   const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       const growthValue = Math.round(payload[0].value || 0);
//       const profitValue = payload[0]?.payload?.profit || 0;
//       const formattedGrowth = growthValue < 0 ? `-${Math.abs(growthValue)}%` : `${growthValue}%`;
  
//       return (
//         <div style={{ backgroundColor: '#0f5469', color: '#ffffff', padding: '5px', borderRadius: '3px', fontSize: 12 }}>
//           <p>Growth Percentage: {formattedGrowth}</p>
//           <p>Profit: ${profitValue}</p>
//         </div>
//       );
//     }
//     return null;
//   };     

//   const formatOrderType = (cmd: string | number) => {
//     return cmd === '1' || cmd === 1 ? 'Buy' : cmd === '0' || cmd === 0 ? 'Sell' : cmd;
//   };

//   const renderStars = (rating: number) => {
//     const maxStars = 5;
//     return Array.from({ length: maxStars }, (_, i) => (
//       <span key={i} className={i < rating ? 'text-yellow-500' : 'text-gray-300'}>
//         {i < rating ? '★' : '☆'}
//       </span>
//     ));
//   };

//   const handleSort = (key: string) => {
//     if (sortKey === key) {
//       setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
//     } else {
//       setSortKey(key);
//       setSortOrder('asc');
//     }
//   };  

//   const sortedData = [...(activeTab === 'history' ? historyData : positionData)].sort((a, b) => {
//     if (!sortKey) return 0;
  
//     const aValue = a[sortKey];
//     const bValue = b[sortKey];
  
//     // Handle numeric sorting
//     if (!isNaN(aValue) && !isNaN(bValue)) {
//       return sortOrder === 'asc' 
//         ? bValue - aValue 
//         : aValue - bValue; 
//     }
  
//     // Handle string sorting (default)
//     if (typeof aValue === 'string' && typeof bValue === 'string') {
//       return sortOrder === 'asc'
//         ? bValue.localeCompare(aValue)
//         : aValue.localeCompare(bValue); 
//     }
  
//     return 0; 
//   });

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
//       <div className="bg-white rounded-lg shadow-lg w-full max-w-[1600px] h-auto max-h-screen">
//         <div className="bg-cyan-800 flex justify-between items-center border-b px-8 py-6">
//           <div className="flex justify-start items-center space-x-12">
            
//             <div className="flex justify-between items-center">
//               <MdInsertChart className="text-2xl text-white mr-2"/>
//               <h1 className="text-2xl font-semibold text-white mr-2">Account:</h1>
//               <span className="text-xl text-white mr-2">{accountNumber}</span>
//             </div>

//             <div className="flex justify-between items-center">
//               <MdStars className="text-2xl text-white mr-2"/>
//               <h1 className="text-2xl font-semibold text-white mr-2">Rating:</h1>
//               <span className="text-2xl text-white mr-2">{renderStars(4)}</span>
//             </div>

//             <div className="flex justify-between items-center">
//               <MdDateRange className="text-2xl text-white mr-2"/>
//               <h1 className="text-2xl font-semibold text-white mr-2">Started Trade:</h1>
//               <span className="text-xl text-white">{stats.startedTrade}</span>
//             </div>  
          
//           </div>
//           <button 
//             onClick={onClose} 
//             className="text-white flex justify-between items-center"
//           >
//             Close <IoCloseCircle className="ml-2 text-2xl"/> 
//           </button>
//         </div>

//         {loading && <p className="text-center py-4 text-gray-500">Loading data...</p>}
//         {error && <p className="text-center py-4 text-red-500">{error}</p>}

//         {!loading && !error && (
//           <div className="flex flex-row justify-center space-x-4 p-4">
//             {/* Left Section: Trading Stats */}
//             <div className="p-6">
//               <h3 className="text-lg font-semibold mb-2 text-cyan-800">
//                 Trading Stats
//               </h3>
//               <div className="grid grid-cols-2 gap-2">
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Growth:</span>
//                   <span className="flex justify-end">{stats.growth} %</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Profit:</span>
//                   <span className="flex justify-end">$ {stats.profit}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Equity:</span>
//                   <span className="flex justify-end">$ {stats.equity}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Balance:</span>
//                   <span className="flex justify-end">$ {stats.balance}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Initial Balance:</span>
//                   <span className="flex justify-end">$ {stats.initialBalance}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Trading Days:</span>
//                   <span className="flex justify-end">{stats.tradingDays}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Trades/Week:</span>
//                   <span className="flex justify-end">{stats.tradesPerWeek}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Avg Holding Time:</span>
//                   <span className="flex justify-end">{stats.avgHoldingTime} hrs</span>
//                 </div>
//               </div>
//             </div>

//             {/* Right Section: Overall Chart */}
//             <div className="bg-gray-50 p-6 border rounded-lg w-auto min-w-[1100px]">
//               <div className="flex justify-between items-center">
//                 <h3 className="text-lg font-semibold mb-4 text-cyan-800">Overall Performance</h3>
//                 <div className="flex justify-center space-x-2 mb-4">
//                   {['growth', 'risk'].map((tab) => (
//                     <button
//                       key={tab}
//                       onClick={() => setActiveChartTab(tab as 'growth' | 'balance' | 'risk')}
//                       className={`px-4 py-2 rounded-md ${
//                         activeChartTab === tab ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'
//                       }`}
//                     >
//                       {tab === 'growth' && 'Account Growth'}
//                       {tab === 'risk' && 'Account Risk'}
//                     </button>
//                   ))}
//                 </div>
//               </div>
//               <ResponsiveContainer width="100%" height={300}>
//                 <AreaChart data={chartData}>
//                   {/* Gradient Definitions */}
//                   <defs>
//                     <linearGradient id="chartGradient" x1="0" y1="0" x2="0" y2="1">
//                       <stop offset="0%" stopColor="#0f5469" stopOpacity={0.8} />
//                       <stop offset="100%" stopColor="#43bae6" stopOpacity={0.1} />
//                     </linearGradient>
//                   </defs>

//                   {/* X-Axis for Balance Amount */}
//                   <XAxis
//                     dataKey="balance" 
//                     domain={[0, 'dataMax']} 
//                     tick={{ fill: '#0f5469', fontSize: 10 }}
//                     tickFormatter={(tick) => `${Math.round(tick)}`} 
//                     interval={Math.ceil(chartData.length / 30)}
//                   />

//                   {/* Y-Axis for Growth Percentage */}
//                   <YAxis
//                     tick={{ fill: '#0f5469', fontSize: 10, fontWeight:'bold' }}
//                     domain={['auto', 'auto']}
//                     tickFormatter={(tick) =>
//                       tick < 0 ? `-${Math.abs(tick)}%` : `${Math.round(tick)}%`
//                     }
//                     allowDecimals={false}
//                     axisLine
//                     tickLine={{ stroke: '#ddd' }}
//                     width={60}
//                   />

//                   {/* Add Gridlines for Every +10% */}
//                   <CartesianGrid stroke="#c4c4c4" strokeDasharray="3 3" vertical={false} />

//                   {/* Tooltip */}
//                   <Tooltip 
//                     content={<CustomTooltip />} 
//                   />

//                   {/* Area for Growth */}
//                   <Area
//                     type="monotone"
//                     dataKey={activeChartTab === 'growth' ? 'growth' : activeChartTab}
//                     stroke="#0f5469"
//                     fill="url(#chartGradient)"
//                   />
//                 </AreaChart>
//               </ResponsiveContainer>
//             </div>
//           </div>
//         )}

//         {/* Bottom Section: Tabbed Position/History Table */}
//         {!loading && !error && (
//           <div className="p-6">
//             <div className="flex justify-between mx-4">
//               {/* Active Tab */}
//               <div className="flex text-sm">
//                 <button
//                   className={`px-12 py-2 rounded-tl-md ${activeTab === 'history' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'}`}
//                   onClick={() => setActiveTab('history')}
//                 >
//                   History
//                 </button>
//                 <button
//                   className={`px-12 py-2 rounded-tr-md ${activeTab === 'position' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'}`}
//                   onClick={() => setActiveTab('position')}
//                 >
//                   Position
//                 </button>
//               </div>
//               <div>
//                 <h3 className="text-xl font-semibold text-cyan-800">
//                   {activeTab === 'history' ? 'History Data' : 'Position Data'}
//                 </h3>
//               </div>
//             </div>
//             <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
//               {activeTab === 'history' && (
//                 <div className="p-4 text-center text-nowrap">
//                   {Array.isArray(historyData) && historyData.length > 0 ? (
//                     <div className="overflow-auto max-h-[400px]">
//                       <table className="min-w-full bg-white">
//                         <thead className="bg-gray-100 sticky top-0">
//                           <tr>
//                             {[
//                               "symbol",
//                               "ticket",
//                               "open_time",
//                               "close_time",
//                               "cmd",
//                               "volume",
//                               "open_price",
//                               "tp",
//                               "close_price",
//                               "sl",
//                               "commission",
//                               "swaps",
//                               "comment",
//                               "profit",
//                             ].map((key) => (
//                               <th
//                                 key={key}
//                                 className="py-2 px-4 border-b text-center text-cyan-800 hover:text-cyan-600 cursor-pointer font-bold"
//                                 onClick={() => handleSort(key)}
//                               >
//                                 {formatTableHeaders(key)}
//                                 {sortKey === key && (
//                                   <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
//                                 )}
//                               </th>
//                             ))}
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {sortedData.map((row, idx) => (
//                             <tr key={idx} className="hover:bg-gray-50">
//                               {[
//                                 "symbol",
//                                 "ticket",
//                                 "open_time",
//                                 "close_time",
//                                 "cmd",
//                                 "volume",
//                                 "open_price",
//                                 "tp",
//                                 "close_price",
//                                 "sl",
//                                 "commission",
//                                 "swaps",
//                                 "comment",
//                                 "profit",
//                               ].map((key, keyIdx) => (
//                                 <td
//                                   key={keyIdx}
//                                   className="py-2 px-4 border-b text-gray-600 text-center"
//                                 >
//                                   {key === "cmd"
//                                     ? formatOrderType(row[key] as string | number)
//                                     : key === "volume"
//                                     ? (parseFloat(row[key]) * 0.01).toFixed(2)
//                                     : String(row[key])}
//                                 </td>
//                               ))}
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                   ) : (
//                     <p className="text-gray-500">No history data available.</p>
//                   )}
//                 </div>
//               )}
//               {activeTab === 'position' && (
//                 <div className="p-4 text-center text-nowrap">
//                   {Array.isArray(positionData) && positionData.length > 0 ? (
//                     <div className="overflow-auto max-h-[400px]">
//                       <table className="min-w-full bg-white">
//                         <thead className="bg-gray-100">
//                           <tr>
//                             {[
//                               "symbol",
//                               "ticket",
//                               "open_time",
//                               "cmd", 
//                               "volume",
//                               "tp", 
//                               "open_price", 
//                               "sl", 
//                               "commission",
//                               "swaps",
//                               "comment",
//                               "profit",
//                             ].map((key) => (
//                               <th 
//                                 key={key} 
//                                 className="py-2 px-4 border-b text-center text-cyan-800 font-bold"
//                                 onClick={() => handleSort(key)}
//                               >
//                                 {formatTableHeaders(key)}
//                                 {sortKey === key && (
//                                   <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
//                                 )}
//                               </th>
//                             ))}
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {sortedData.map((row, idx) => (
//                             <tr key={idx} className="hover:bg-gray-50">
//                               {[
//                                 "symbol",
//                                 "ticket",
//                                 "open_time",
//                                 "cmd", 
//                                 "volume", 
//                                 "tp", 
//                                 "open_price",
//                                 "sl",
//                                 "commission",
//                                 "swaps",
//                                 "comment",
//                                 "profit",
//                               ].map((key, keyIdx) => (
//                                 <td
//                                   key={keyIdx}
//                                   className="py-2 px-4 border-b text-gray-600 text-center"
//                                 >
//                                   {key === "cmd" 
//                                     ? formatOrderType(row[key] as string | number)
//                                     : key === "volume"
//                                     ? (parseFloat(row[key]) * 0.01).toFixed(2)
//                                     : String(row[key])}
//                                 </td>
//                               ))}
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                   ) : (
//                     <p className="text-gray-500">No position data available.</p>
//                   )}
//                 </div>
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default TradingJournalModal;

// // udpate code --> 26 dec 2024
// // src/components/TradingJournalModal.tsx

// import React, { useEffect, useState } from 'react';
// import {
//   ResponsiveContainer,
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   Tooltip,
//   TooltipProps,
//   CartesianGrid,
// } from 'recharts';
// import { IoCloseCircle } from "react-icons/io5";
// import { MdInsertChart, MdStars, MdDateRange } from "react-icons/md";
// import { useAccountJournal } from '../hooks/useAccountJournal';

// interface AccountDetailsModalProps {
//   accountNumber: string | null;
//   isOpen: boolean;
//   onClose: () => void;
// }

// interface Stats {
//   startedTrade: string;
//   growth: string;
//   profit: string;
//   equity: string;
//   balance: string;
//   initialBalance: string;
//   tradingDays: number;
//   tradesPerWeek: string;
//   avgHoldingTime: string;
// }

// const TradingJournalModal: React.FC<AccountDetailsModalProps> = ({ accountNumber, isOpen, onClose }) => {
//   const { fetchAccountHistoryFromFile, fetchAccountPositionFromFile, fetchAccountStats } = useAccountJournal();
//   const [historyData, setHistoryData] = useState<any[]>([]);
//   const [positionData, setPositionData] = useState<any[]>([]);
//   const [activeTab, setActiveTab] = useState<'history' | 'position'>('history');
//   const [activeChartTab, setActiveChartTab] = useState<'growth' | 'balance' | 'risk'>('growth');
//   const [stats, setStats] = useState<Stats>({
//     startedTrade: 'N/A',
//     growth: '0.00',
//     profit: '0.00',
//     equity: '0.00',
//     balance: '0.00',
//     initialBalance: '0.00',
//     tradingDays: 0,
//     tradesPerWeek: '0.00',
//     avgHoldingTime: '0.00',
//   });
  
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!accountNumber) return;
  
//       setLoading(true);
//       setError(null);
//       try {
//         const fetchedStats = await fetchAccountStats(accountNumber);
//         setStats({
//           startedTrade: fetchedStats.startedTrade || 'N/A',
//           growth: fetchedStats.growth || '0.00',
//           profit: fetchedStats.totalProfit || '0.00',
//           equity: fetchedStats.equity || '0.00',
//           balance: fetchedStats.balance || '0.00',
//           initialBalance: fetchedStats.initialBalance || '0.00',
//           tradingDays: fetchedStats.tradingDays || 0,
//           tradesPerWeek: fetchedStats.tradesPerWeek || '0.00',
//           avgHoldingTime: fetchedStats.avgHoldingTime || '0.00',
//         });
  
//         const history = await fetchAccountHistoryFromFile(accountNumber);
//         const position = await fetchAccountPositionFromFile(accountNumber);
  
//         setHistoryData(history);
//         setPositionData(position);
//       } catch (err: any) {
//         setError(err.message || 'Error fetching account data');
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     if (isOpen) {
//       fetchData();
//     }
//   }, [accountNumber, isOpen, fetchAccountStats, fetchAccountHistoryFromFile, fetchAccountPositionFromFile]);
  
    
//   if (!isOpen) return null;

//   const formatHistoryTableHeaders = (header: string) => {
//     const headersMap: { [key: string]: string } = {
//       symbol: 'Symbol',
//       ticket: 'Ticket',
//       open_time: 'Open Time',
//       close_time: 'Close Time',
//       swaps: 'Swaps',
//       volume: 'Volume',
//       open_price: 'Open Price',
//       tp: 'Take Profit',
//       sl: 'Stop Loss',
//       close_price: 'Close Price',
//       commission: 'Commission',
//       comment: 'Comment',
//       cmd: 'Order Type',
//       profit: 'Profit',
//     };
//     return headersMap[header] || header;
//   };

//   const getChartData = () => {
//     if (historyData.length === 0 || !stats.initialBalance) return [];
  
//     const initialBalance = parseFloat(stats.initialBalance);
  
//     switch (activeChartTab) {
//       case 'growth':
//         return historyData.reduce((acc, trade, index) => {
//           const previousBalance = acc[index - 1]?.balance || initialBalance;
//           const currentBalance = previousBalance + parseFloat(trade.profit || '0');
//           const growthPercentage = ((currentBalance - initialBalance) / initialBalance) * 100;
  
//           return [
//             ...acc,
//             {
//               time: `Trade ${index + 1}`,
//               growth: growthPercentage,
//               balance: Math.round(currentBalance), 
//               profit: Math.round(parseFloat(trade.profit || '0')),
//             },
//           ];
//         }, []);
//       case 'risk':
//         return historyData.map((trade, index) => ({
//           time: `Trade ${index + 1}`,
//           risk: parseFloat(trade.risk || '0'),
//         }));
//       default:
//         return [];
//     }
//   };  
  
//   const chartData = getChartData();  

//   const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       const growthValue = Math.round(payload[0].value || 0);
//       const profitValue = payload[0]?.payload?.profit || 0;
//       const formattedGrowth = growthValue < 0 ? `-${Math.abs(growthValue)}%` : `${growthValue}%`;
  
//       return (
//         <div style={{ backgroundColor: '#0f5469', color: '#ffffff', padding: '5px', borderRadius: '3px', fontSize: 12 }}>
//           <p>Growth Percentage: {formattedGrowth}</p>
//           <p>Profit: ${profitValue}</p>
//         </div>
//       );
//     }
//     return null;
//   };     

//   const formatOrderType = (cmd: string | number) => {
//     return cmd === '1' || cmd === 1 ? 'Buy' : cmd === '0' || cmd === 0 ? 'Sell' : cmd;
//   };

//   const renderStars = (rating: number) => {
//     const maxStars = 5;
//     return Array.from({ length: maxStars }, (_, i) => (
//       <span key={i} className={i < rating ? 'text-yellow-500' : 'text-gray-300'}>
//         {i < rating ? '★' : '☆'}
//       </span>
//     ));
//   };

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
//       <div className="bg-white rounded-lg shadow-lg w-full max-w-[1600px] h-auto max-h-screen">
//         <div className="bg-cyan-800 flex justify-between items-center border-b px-8 py-6">
//           <div className="flex justify-start items-center space-x-12">
            
//             <div className="flex justify-between items-center">
//               <MdInsertChart className="text-2xl text-white mr-2"/>
//               <h1 className="text-2xl font-semibold text-white mr-2">Account:</h1>
//               <span className="text-xl text-white mr-2">{accountNumber}</span>
//             </div>

//             <div className="flex justify-between items-center">
//               <MdStars className="text-2xl text-white mr-2"/>
//               <h1 className="text-2xl font-semibold text-white mr-2">Rating:</h1>
//               <span className="text-2xl text-white mr-2">{renderStars(4)}</span>
//             </div>

//             <div className="flex justify-between items-center">
//               <MdDateRange className="text-2xl text-white mr-2"/>
//               <h1 className="text-2xl font-semibold text-white mr-2">Started Trade:</h1>
//               <span className="text-xl text-white">{stats.startedTrade}</span>
//             </div>  
          
//           </div>
//           <button 
//             onClick={onClose} 
//             className="text-white flex justify-between items-center"
//           >
//             Close <IoCloseCircle className="ml-2 text-2xl"/> 
//           </button>
//         </div>

//         {loading && <p className="text-center py-4 text-gray-500">Loading data...</p>}
//         {error && <p className="text-center py-4 text-red-500">Error: {error}</p>}

//         {!loading && !error && (
//           <div className="flex flex-row justify-center space-x-4 p-4">
//             {/* Left Section: Trading Stats */}
//             <div className="p-6">
//               <h3 className="text-lg font-semibold mb-2 text-cyan-800">
//                 Trading Stats
//               </h3>
//               <div className="grid grid-cols-2 gap-2">
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Growth:</span>
//                   <span className="flex justify-end">{stats.growth} %</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Profit:</span>
//                   <span className="flex justify-end">$ {stats.profit}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Equity:</span>
//                   <span className="flex justify-end">$ {stats.equity}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Balance:</span>
//                   <span className="flex justify-end">$ {stats.balance}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Initial Balance:</span>
//                   <span className="flex justify-end">$ {stats.initialBalance}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Trading Days:</span>
//                   <span className="flex justify-end">{stats.tradingDays}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Trades/Week:</span>
//                   <span className="flex justify-end">{stats.tradesPerWeek}</span>
//                 </div>
//                 <div className="flex flex-col border p-4 rounded-md shadow-md">
//                   <span className="font-semibold text-cyan-800">Avg Holding Time:</span>
//                   <span className="flex justify-end">{stats.avgHoldingTime} hrs</span>
//                 </div>
//               </div>
//             </div>

//             {/* Right Section: Overall Chart */}
//             <div className="bg-gray-50 p-6 border rounded-lg w-auto min-w-[1100px]">
//               <div className="flex justify-between items-center">
//                 <h3 className="text-lg font-semibold mb-4 text-cyan-800">Overall Performance</h3>
//                 <div className="flex justify-center space-x-2 mb-4">
//                   {['growth', 'risk'].map((tab) => (
//                     <button
//                       key={tab}
//                       onClick={() => setActiveChartTab(tab as 'growth' | 'balance' | 'risk')}
//                       className={`px-4 py-2 rounded-md ${
//                         activeChartTab === tab ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'
//                       }`}
//                     >
//                       {tab === 'growth' && 'Account Growth'}
//                       {tab === 'risk' && 'Account Risk'}
//                     </button>
//                   ))}
//                 </div>
//               </div>
//               <ResponsiveContainer width="100%" height={300}>
//                 <AreaChart data={chartData}>
//                   {/* Gradient Definitions */}
//                   <defs>
//                     <linearGradient id="chartGradient" x1="0" y1="0" x2="0" y2="1">
//                       <stop offset="0%" stopColor="#0f5469" stopOpacity={0.8} />
//                       <stop offset="100%" stopColor="#43bae6" stopOpacity={0.1} />
//                     </linearGradient>
//                   </defs>

//                   {/* X-Axis for Balance Amount */}
//                   <XAxis
//                     dataKey="balance" 
//                     domain={[0, 'dataMax']} 
//                     tick={{ fill: '#0f5469', fontSize: 10 }}
//                     tickFormatter={(tick) => `${Math.round(tick)}`} 
//                     interval={Math.ceil(chartData.length / 30)}
//                   />

//                   {/* Y-Axis for Growth Percentage */}
//                   <YAxis
//                     tick={{ fill: '#0f5469', fontSize: 10, fontWeight:'bold' }}
//                     domain={['auto', 'auto']}
//                     tickFormatter={(tick) =>
//                       tick < 0 ? `-${Math.abs(tick)}%` : `${Math.round(tick)}%`
//                     }
//                     allowDecimals={false}
//                     axisLine
//                     tickLine={{ stroke: '#ddd' }}
//                     width={60}
//                   />

//                   {/* Add Gridlines for Every +10% */}
//                   <CartesianGrid stroke="#c4c4c4" strokeDasharray="3 3" vertical={false} />

//                   {/* Tooltip */}
//                   <Tooltip 
//                     content={<CustomTooltip />} 
//                   />

//                   {/* Area for Growth */}
//                   <Area
//                     type="monotone"
//                     dataKey={activeChartTab === 'growth' ? 'growth' : activeChartTab}
//                     stroke="#0f5469"
//                     fill="url(#chartGradient)"
//                   />
//                 </AreaChart>
//               </ResponsiveContainer>
//             </div>
//           </div>
//         )}

//         {/* Bottom Section: Tabbed Position/History Table */}
//         {!loading && !error && (
//           <div className="p-6">
//             <div className="flex justify-between mx-4">
//               <div className="flex text-sm">
//                 <button
//                   className={`px-12 py-2 rounded-tl-md ${activeTab === 'history' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'}`}
//                   onClick={() => setActiveTab('history')}
//                 >
//                   History
//                 </button>
//                 <button
//                   className={`px-12 py-2 rounded-tr-md ${activeTab === 'position' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-700'}`}
//                   onClick={() => setActiveTab('position')}
//                 >
//                   Position
//                 </button>
//               </div>
//               <div>
//                 <h3 className="text-xl font-semibold text-cyan-800">
//                   {activeTab === 'history' ? 'History Data' : 'Position Data'}
//                 </h3>
//               </div>
//             </div>
//             <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
//               {activeTab === 'history' && (
//                 <div className="p-4 text-center text-nowrap">
//                   {Array.isArray(historyData) && historyData.length > 0 ? (
//                     <div className="overflow-auto max-h-[400px]">
//                       <table className="min-w-full bg-white">
//                         <thead className="bg-gray-100 sticky top-0">
//                           <tr>
//                             {[
//                               "symbol",
//                               "ticket",
//                               "open_time",
//                               "close_time",
//                               "cmd",
//                               "volume",
//                               "open_price",
//                               "tp",
//                               "close_price",
//                               "sl",
//                               "commission",
//                               "swaps",
//                               "comment",
//                               "profit",
//                             ].map((key) => (
//                               <th
//                                 key={key}
//                                 className="py-2 px-4 border-b text-center text-gray-700 font-medium"
//                               >
//                                 {formatHistoryTableHeaders(key)}
//                               </th>
//                             ))}
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {historyData.map((row, idx) => (
//                             <tr key={idx} className="hover:bg-gray-50">
//                               {[
//                                 "symbol",
//                                 "ticket",
//                                 "open_time",
//                                 "close_time",
//                                 "cmd",
//                                 "volume",
//                                 "open_price",
//                                 "tp",
//                                 "close_price",
//                                 "sl",
//                                 "commission",
//                                 "swaps",
//                                 "comment",
//                                 "profit",
//                               ].map((key, keyIdx) => (
//                                 <td
//                                   key={keyIdx}
//                                   className="py-2 px-4 border-b text-gray-600 text-center"
//                                 >
//                                   {key === "cmd"
//                                     ? formatOrderType(row[key] as string | number)
//                                     : key === "volume"
//                                     ? (parseFloat(row[key]) * 0.01).toFixed(2)
//                                     : String(row[key])}
//                                 </td>
//                               ))}
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                   ) : (
//                     <p className="text-gray-500">No history data available.</p>
//                   )}
//                 </div>
//               )}
//               {activeTab === 'position' && (
//                 <div className="p-4 text-center text-nowrap">
//                   {Array.isArray(positionData) && positionData.length > 0 ? (
//                     <div className="overflow-auto max-h-[400px]">
//                       <table className="min-w-full bg-white">
//                         <thead className="bg-gray-100">
//                           <tr>
//                             {Object.keys(positionData[0] || {}).map((key) => (
//                               <th key={key} className="py-2 px-4 border-b text-left text-gray-700 font-medium">
//                                 {key}
//                               </th>
//                             ))}
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {positionData.map((row, idx) => (
//                             <tr key={idx} className="hover:bg-gray-50">
//                               {Object.values(row).map((value, keyIdx) => (
//                                 <td key={keyIdx} className="py-2 px-4 border-b text-gray-600">
//                                   {String(value)}
//                                 </td>
//                               ))}
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                   ) : (
//                     <p className="text-gray-500">No position data available.</p>
//                   )}
//                 </div>
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default TradingJournalModal;