// src/components/Footer.tsx

// import react components
import React from 'react';
// import { useNavigate } from 'react-router-dom';

// import react icon
// import { FaFacebook, FaInstagram, FaTelegram, FaChartBar } from 'react-icons/fa';
import { FaFacebook, FaInstagram, FaTelegram } from 'react-icons/fa';
import { TiSocialYoutubeCircular } from "react-icons/ti";

// import file from source
import Version from '../AppVersion';

const AppFooter: React.FC = () => {
  // const navigate = useNavigate(); 

  return (
    // Footer container with background color and padding
    <footer className="text-center py-4 px-4 bg-cyan-800 text-white">
      <div className="flex flex-col justify-center items-center flex-wrap sm:flex-row ">
        {/* Social media icons container */}
        <div className="text-2xl mb-2 flex justify-between sm:mb-2 xs:text-lg">
          <a href="https://www.facebook.com/R1Century?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/r1century?igsh=eDdobDN0c29xZ3d3" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
            <FaInstagram />
          </a>
          <a href="https://www.youtube.com/@RONE-TV" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
            <TiSocialYoutubeCircular />
          </a>
          <a href="https://t.me/R1Century" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
            <FaTelegram />
          </a>
          {/* <button 
            onClick={() => navigate('/trading-chart')} 
            className="mx-2 text-white hover:text-gray-200"
          >
            <FaChartBar />
          </button> */}
        </div>
        {/* Footer text with current year and rights reserved message */}
        <div className="text-sm flex justify-center sm:text-xs xs:text-xs/[10px]">
          {new Date().getFullYear()} © R One Century 创市纪. All rights reserved. 
          <span className="ml-2"><Version /></span>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;


// // update code --> 06 jan 2024
// // src/components/Footer.tsx

// // import react components
// import React from 'react';

// // import react icon
// import { FaFacebook, FaInstagram, FaTelegram } from 'react-icons/fa';
// import { TiSocialYoutubeCircular } from "react-icons/ti";

// // import file from source
// import { useTheme } from '../context/ThemeContext';
// import Version from '../AppVersion';

// const AppFooter: React.FC = () => {
//   const { theme } = useTheme();

//   return (
//     // Footer container with background color and padding
//     <footer style={{ backgroundColor: theme.primaryColor }} className="text-center py-4 px-4  text-white">
//       <div className="flex flex-col justify-center items-center flex-wrap sm:flex-row ">
//         {/* Social media icons container */}
//         <div className="text-2xl mb-2 flex justify-between sm:mb-2 xs:text-lg">
//           <a href="https://www.facebook.com/R1Century?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
//             <FaFacebook />
//           </a>
//           <a href="https://www.instagram.com/r1century?igsh=eDdobDN0c29xZ3d3" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
//             <FaInstagram />
//           </a>
//           <a href="https://www.youtube.com/@RONE-TV" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
//             <TiSocialYoutubeCircular />
//           </a>
//           <a href="https://t.me/R1Century" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
//             <FaTelegram />
//           </a>
//         </div>
//         {/* Footer text with current year and rights reserved message */}
//         <p className="text-sm flex justify-center sm:text-xs xs:text-xs/[10px]" style={{ fontFamily: theme.fontFamily }}>
//           {new Date().getFullYear()} © R One Century 创市纪. All rights reserved. <span className="ml-2"><Version /></span>
//         </p>
//       </div>
//     </footer>
//   );
// };

// export default AppFooter;
