// src/hooks/AdminDashboard/useFundProgramPending.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface FundProgramUser {
  fundOrderId: string;
  fundLinkEvalId: string;
  fundProgramSize: number;
  fundProgramAmount: number;
  fundUserName: string;
  fundUserEmail: string;
  fundEvalBeforeProgram: string;
  fundEvalBeforeStage: string;
  fundStatus: string;
  fundRuleAgreed: string;
  fundTermAgreed: string;
  fundPaymentMethod: string;
  fundPaymentReceipts: string;
  fundRegNotes: string;
  createdAt: Date;
}

export interface AddPendingUserData {
  fundUserName: string;
  fundUserEmail: string;
  fundLinkEvalId: string;
  fundProgramSize: number;
  fundProgramAmount: number;
  fundEvalBeforeProgram: string;
  fundEvalBeforeStage: string;
  fundPaymentMethod: string;
  fundPaymentReceipts: string;
  fundRegNotes: string;
  fundRuleAgreed: string;
  fundTermAgreed: string;
}

const useFundProgramUserData = () => {
  const [data, setData] = useState<FundProgramUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [processingId, setProcessingId] = useState<string | null>(null); 
  // Access API URL from the environment context
  const { apiUrl } = useEnv();

  // Add a function to fetch data that can be called multiple times
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/fund/pending`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setData(result.data);
    } catch (err) {
      console.error('Error fetching pending funds:', err);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, [fetchData]);

   // Add new function to handle adding pending users
   const addPendingUser = async (formData: AddPendingUserData) => {
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/fund/pending/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to add pending user');
      }

      // After successful addition, fetch fresh data
      await fetchData();
      return true;
    } catch (err) {
      console.error('Error adding pending user:', err);
      setError(err instanceof Error ? err.message : 'Failed to add pending user');
      throw err;
    }
  };

  // Add delete function
  const deletePendingRequest = async (fundOrderId: string) => {
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/fund/pending/delete/${fundOrderId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete request');
      }

      // Update local state by removing the deleted item
      setData((prevData) => prevData.filter((item) => item.fundOrderId !== fundOrderId));
      return true;
    } catch (err) {
      console.error('Error deleting pending request:', err);
      setError(err instanceof Error ? err.message : 'Failed to delete pending request');
      throw err;
    }
  };

  // Add update function
  const updatePendingRequest = async (fundOrderId: string, updateData: any) => {
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/fund/pending/update/${fundOrderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update request');
      }
  
      const result = await response.json();
      
      // Update local state
      setData(prevData => 
        prevData.map(item => 
          item.fundOrderId === fundOrderId ? result.data : item
        )
      );
  
      return result.data;
    } catch (err) {
      console.error('Error updating pending request:', err);
      setError(err instanceof Error ? err.message : 'Failed to update pending request');
      throw err;
    }
  };

  // Approve Fund Request
  const approveFundRequest = async (fundOrderId: string, formData: any) => {
    setProcessingId(fundOrderId);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/fund/pending/approve/${fundOrderId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...formData,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to approve request');
      }

      setData((prevData) => prevData.filter((item) => item.fundOrderId !== fundOrderId));
    } catch (err) {
      console.error(`Error approving request: ${err}`);
      setError(`Failed to approve request: ${err}`);
    } finally {
      setProcessingId(null);
    }
  };

  const rejectFundRequest = async (fundOrderId: string, rejectReason: string) => {
    setProcessingId(fundOrderId);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/fund/pending/reject/${fundOrderId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rejectReason }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to reject request');
      }

      setData((prevData) => prevData.filter((item) => item.fundOrderId !== fundOrderId));
    } catch (err) {
      console.error(`Error rejecting request: ${err}`);
      setError(`Failed to reject request: ${err}`);
    } finally {
      setProcessingId(null);
    }
  };
  
  return { 
    data, loading, error, processingId,
    fetchData,
    addPendingUser, updatePendingRequest, deletePendingRequest,
    approveFundRequest, rejectFundRequest 
  };
};

export default useFundProgramUserData;


// // udpate code - 01 jan 2025
// // src/hooks/AdminDashboard/useFundProgramPending.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface FundProgramUser {
//   fundOrderId: string;
//   fundLinkEvalId: string;
//   fundProgramSize: number;
//   fundProgramAmount: number;
//   fundUserName: string;
//   fundUserEmail: string;
//   fundEvalBeforeProgram: string;
//   fundEvalBeforeStage: string;
//   fundStatus: string;
//   fundRuleAgreed: string;
//   fundTermAgreed: string;
//   fundPaymentMethod: string;
//   fundPaymentReceipts: string;
//   fundRegNotes: string;
//   createdAt: Date;
// }

// interface AddPendingUserData {
//   fundUserName: string;
//   fundUserEmail: string;
//   fundLinkEvalId: string;
//   fundProgramSize: string;
//   fundProgramAmount: string;
//   fundEvalBeforeProgram: string;
//   fundEvalBeforeStage: string;
//   fundPaymentMethod: string;
//   fundPaymentReceipts: string;
//   fundRegNotes: string;
//   fundRuleAgreed: string;
//   fundTermAgreed: string;
// }

// const useFundProgramUserData = () => {
//   const [data, setData] = useState<FundProgramUser[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [processingId, setProcessingId] = useState<string | null>(null); 
  
//   // Access API URL from the environment context
//   const { apiUrl } = useEnv();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/api/fund/pending`);
//         if (!response.ok) {
//           throw new Error(`Error: ${response.statusText}`);
//         }
//         const result = await response.json();
//         setData(result.data);
//       } catch (err) {
//         console.error('Error fetching pending funds:', err);
//         setError('Failed to load data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [apiUrl]);

//    // Add new function to handle adding pending users
//    const addPendingUser = async (formData: AddPendingUserData) => {
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/fund/pending/add`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Failed to add pending user');
//       }

//       const result = await response.json();
      
//       // Update the local data state with the new entry
//       setData(prevData => [...prevData, result.data]);
      
//       return result;
//     } catch (err) {
//       console.error('Error adding pending user:', err);
//       setError(err instanceof Error ? err.message : 'Failed to add pending user');
//       throw err;
//     }
//   };

//   // Approve Fund Request
//   const approveFundRequest = async (fundOrderId: string, formData: any) => {
//     setProcessingId(fundOrderId);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/fund/pending/approve/${fundOrderId}`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           ...formData,
//         }),
//       });
  
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Failed to approve request');
//       }

//       setData((prevData) => prevData.filter((item) => item.fundOrderId !== fundOrderId));
//     } catch (err) {
//       console.error(`Error approving request: ${err}`);
//       setError(`Failed to approve request: ${err}`);
//     } finally {
//       setProcessingId(null);
//     }
//   };

//   const rejectFundRequest = async (fundOrderId: string, rejectReason: string) => {
//     setProcessingId(fundOrderId);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/fund/pending/reject/${fundOrderId}`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ rejectReason }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Failed to reject request');
//       }

//       setData((prevData) => prevData.filter((item) => item.fundOrderId !== fundOrderId));
//     } catch (err) {
//       console.error(`Error rejecting request: ${err}`);
//       setError(`Failed to reject request: ${err}`);
//     } finally {
//       setProcessingId(null);
//     }
//   };
  
//   return { 
//     data, loading, error, processingId,
//     addPendingUser, 
//     approveFundRequest, rejectFundRequest 
//   };
// };

// export default useFundProgramUserData;
