// src/pages/client/p03_Check_In.tsx

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useProgram from "../../hooks/AdminDashboard/useProgram";
import { useEnv } from "../../context/EnvContext";
import AppFooter from "../../components/Footer";

const EventCheckIn: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { apiUrl } = useEnv(); // Use context to get apiUrl
  const { fetchEventDetails, checkInEvent } = useProgram();
  const [event, setEvent] = useState<any>(null);
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [ticketInfo, setTicketInfo] = useState<{
    ticketId: string;
    userName: string;
    email: string;
  } | null>(null);

  useEffect(() => {
    if (id) {
      fetchEventDetails(id)
        .then((data) => setEvent(data))
        .catch((err) => console.error("Error fetching event:", err));
    }
  }, [id, fetchEventDetails]);

  const handleCheckIn = async () => {
    try {
      if (id) {
        const data = await checkInEvent(id, email);
        if (data.success) {
          setStatus("Check-in successful!");
          setTicketInfo({
            ticketId: data.ticketId,
            userName: data.userName,
            email: data.email,
          });
        } else {
          setStatus(data.message || "Check-in failed.");
        }
      }
    } catch (err) {
      console.error("Error during check-in:", err);
      setStatus("Check-in failed.");
    }
  };

  if (!event) return <p>Loading event details...</p>;

  return (
    <div className="w-full h-auto min-h-screen flex flex-col justify-between bg-gray-100">
      <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
        <div className="flex flex-col items-center 
                        md:flex-col tablet-landscape-max:flex-row">
          {event.image && (
            <img
              src={`${apiUrl}/uploads/forEventTicket/${event.image}`}
              alt={event.name}
              className="w-[500px] rounded-lg mb-4 
                        lg:w-[400px]
                        md:mb-0
                        sm:w-[350px]
                        xs:w-[320px]"
                        
            />
          )}
          <div className="md:ml-6">
            <h1 className="text-3xl text-center text-cyan-800 font-bold
                          sm:text-2xl
                          xs:text-2xl
                          tablet-landscape-max:text-center">
              {event.name}
            </h1>
            <div className="flex flex-row justify-between my-2">
              <div className="flex flex-col text-right
                              sm:text-sm
                              xs:text-sm
                              tablet-landscape-max:text-left tablet-landscape-max:ml-4">
                <strong>Location:</strong>{" "}
                <strong>Start Date:</strong>
                <strong>End Date:</strong>
                <strong>Time:</strong>
                <strong>Description:</strong>
              </div>
              <div className="flex flex-col ml-4 sm:text-sm xs:text-sm">
                <span>{event.location || event.onlineConferenceText || "N/A"}</span>
                <span>{new Date(event.startDate).toISOString().split('T')[0]}</span>
                <span>{new Date(event.endDate).toISOString().split('T')[0]}</span>
                <span>{event.time}</span>
                <span>{event.description}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 border-2 p-4 rounded-lg">
          <label className="block mb-2 mx-4 font-semibold text-gray-700
                            sm:text-sm sm:mb-1
                            xs:text-xs xs:mb-1">
            Enter Your Email:
          </label>
          <div className="flex items-center">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-1 border p-2 rounded shadow-sm sm:text-sm xs:text-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="bg-cyan-800 text-white px-4 py-2 rounded ml-4 shadow-lg hover:bg-cyan-700
                        sm:text-sm
                        xs:text-xs"
              onClick={handleCheckIn}
            >
              Check-In
            </button>
          </div>
          {status && (
            <p
              className={`mt-4 font-semibold text-center sm:text-sm xs:text-sm ${
                  status.includes("successful") ? "text-green-600" : "text-red-600"
              }`}
            >
              {status}
            </p>
          )}
          {ticketInfo && (
            <div className="mt-4 bg-green-50 p-4 rounded-lg shadow-md sm:text-sm xs:text-xs">
              <p>
                <strong>Ticket ID:</strong> {ticketInfo.ticketId}
              </p>
              <p>
                <strong>Name:</strong> {ticketInfo.userName}
              </p>
              <p>
                <strong>Email:</strong> {ticketInfo.email}
              </p>
            </div>
          )}
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default EventCheckIn;

// // udpatea coed --> 14 jan 2024
// // src/pages/client/p03_Check_In.tsx

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import useProgram from "../../hooks/AdminDashboard/useProgram";
// import AppFooter from "../../components/Footer";

// const EventCheckIn: React.FC = () => {
//   const { id } = useParams<{ id: string }>();
//   const { fetchEventDetails, checkInEvent } = useProgram();
//   const [event, setEvent] = useState<any>(null);
//   const [email, setEmail] = useState("");
//   const [status, setStatus] = useState("");
//   const [ticketInfo, setTicketInfo] = useState<{
//     ticketId: string;
//     userName: string;
//     email: string;
//   } | null>(null);

//   useEffect(() => {
//     if (id) {
//       fetchEventDetails(id)
//         .then((data) => setEvent(data))
//         .catch((err) => console.error("Error fetching event:", err));
//     }
//   }, [id, fetchEventDetails]);

//   const handleCheckIn = async () => {
//     try {
//       if (id) {
//         const data = await checkInEvent(id, email);
//         if (data.success) {
//           setStatus("Check-in successful!");
//           setTicketInfo({
//             ticketId: data.ticketId,
//             userName: data.userName,
//             email: data.email,
//           });
//         } else {
//           setStatus(data.message || "Check-in failed.");
//         }
//       }
//     } catch (err) {
//       console.error("Error during check-in:", err);
//       setStatus("Check-in failed.");
//     }
//   };

//   if (!event) return <p>Loading event details...</p>;

//   return (
//     <div className="container mx-auto py-10 px-6 bg-gray-100 rounded-lg shadow-lg">
//       <div className="flex flex-col md:flex-row items-center">
//         {event.image && (
//           <img
//             src={`/uploads/forEventTicket/${event.image}`}
//             alt={event.name}
//             className="w-full md:w-1/3 rounded-lg mb-4 md:mb-0"
//           />
//         )}
//         <div className="md:ml-6">
//           <h1 className="text-3xl font-bold text-cyan-800">{event.name}</h1>
//           <p className="mt-2">
//             <strong>Location:</strong> {event.location || event.onlineConferenceText || "N/A"}
//           </p>
//           <p>
//             <strong>Date:</strong> {event.startDate} - {event.endDate}
//           </p>
//           <p>
//             <strong>Time:</strong> {event.time}
//           </p>
//           <p className="mt-2">
//             <strong>Description:</strong> {event.description}
//           </p>
//         </div>
//       </div>
//       <div className="mt-6">
//         <label className="block mb-2 font-semibold text-gray-700">Enter Your Email:</label>
//         <div className="flex items-center">
//           <input
//             type="email"
//             placeholder="Enter your email"
//             className="flex-1 border p-2 rounded shadow-sm"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           <button
//             className="bg-cyan-800 text-white px-4 py-2 rounded ml-4 shadow-lg hover:bg-cyan-700"
//             onClick={handleCheckIn}
//           >
//             Check-In
//           </button>
//         </div>
//         {status && (
//           <p className={`mt-4 font-semibold ${status.includes("successful") ? "text-green-600" : "text-red-600"}`}>
//             {status}
//           </p>
//         )}
//         {ticketInfo && (
//           <div className="mt-4 bg-green-50 p-4 rounded-lg shadow-md">
//             <p>
//               <strong>Ticket ID:</strong> {ticketInfo.ticketId}
//             </p>
//             <p>
//               <strong>Name:</strong> {ticketInfo.userName}
//             </p>
//             <p>
//               <strong>Email:</strong> {ticketInfo.email}
//             </p>
//           </div>
//         )}
//       </div>
//       <AppFooter />
//     </div>
//   );
// };

// export default EventCheckIn;


