// src/components/client/Dashboard/UserApp.tsx

import React, { useState } from 'react';
import { FaTh, FaList, FaMinus } from 'react-icons/fa';
import { IoMdCloseCircle } from "react-icons/io";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useTheme } from '../../../context/ThemeContext';
import useUserStage from '../../../hooks/Dashboard/useUserStage';
import { motion } from 'framer-motion';

import MyVideo from '../MiniApp/App06_MyVideo';
import MyTicket from '../MiniApp/App01_MyTicket';
// import MyProgram from '../MiniApp/App02_MyProgram';
import MyFundedProgram from '../MiniApp/App02_MyFundProgram';
import MySubscription from '../MiniApp/App03_MySubProduct';
import MyJournal from '../MiniApp/App04_MyJournal';
import AboutUs from '../MiniApp/App05_AboutUS';

const UserApp: React.FC<{ selectedApp?: string }> = ({ selectedApp: initialSelectedApp = null }) => {
  const { theme } = useTheme();
  const { isGuest } = useUserStage();
  const [isGrid, setIsGrid] = useState(false); 
  const [isMinimized, setIsMinimized] = useState(false);
  const [selectedApp, setSelectedApp] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleLayout = () => setIsGrid(!isGrid);
  const toggleMinimize = () => setIsMinimized(!isMinimized);

  const openModal = (appName: string, width: string) => {
    if (!isGuest || appName === 'My Ticket' || appName === 'My Subscription' || appName === 'About Us') {
      setSelectedApp(appName);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setSelectedApp(null);
    setIsModalOpen(false);
  };

  const apps = [
    { id: 1, name: 'My Video', icon: '📽️', description: 'Access R One courses video for playback and review', width: 'max-w-md', disabled: isGuest },
    { id: 2, name: 'My Ticket', icon: '🎟️', description: 'View and manage your lists event tickets', width: 'max-w-md' },
    { id: 3, name: 'My Program', icon: '📚', description: 'View and manage your participation funded programs.', width: 'max-w-md', disabled: isGuest },
    { id: 4, name: 'My Subscription', icon: '📝', description: 'View and manage your subscriptions', width: 'max-w-md' },
    { id: 5, name: 'My Journal', icon: '📊', description: 'View your trading journal', width: 'max-w-md', disabled: isGuest },
    { id: 6, name: 'About Us', icon: '👤', description: 'About our R One Century', width: 'max-w-md' },
  ];

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className="rounded-lg shadow-md p-4 h-full bg-white" style={styles.title}> 
      <div className="flex justify-between items-center mb-4 rounded"
        style={styles.title}>
        <div className="flex items-center">
          <div className="flex items-center mr-4">
            <button onClick={toggleLayout}>
              {isGrid ? <FaList className="text-lg" /> : <FaTh className="text-lg" />}
            </button>
            <button onClick={toggleMinimize}>
              {isMinimized ? <FaMinus className="text-lg" /> : null}
            </button>
          </div>
          <h1 className="font-bold text-xl sm:text-lg xs:text-lg" style={styles.title}>
            R One Mini App
          </h1>
          <div className="relative group ml-2">
            <AiOutlineInfoCircle 
              className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
              aria-hidden="true"
            />
            {/* Tooltip */}
            <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white sm:right-0 sm:w-52 xs:right-0 xs:w-52"
              style={styles.button}>
               R One Mini App provides seamless access to all R One products for an enhanced user experience.
            </div>
          </div>
        </div>
      </div>

      {!isMinimized && (
        <div className="overflow-y-auto h-[255px] sm:h-auto xs:h-auto">
          {isGrid ? (
            <div className="w-auto">
              <div className="grid grid-cols-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {apps.map((app) => (
                  <div
                    key={app.id}
                    className={`relative text-center p-6 rounded-lg cursor-pointer ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
                    onClick={() => !app.disabled && openModal(app.name, app.width)}
                    style={app.disabled ? { cursor: 'not-allowed' } : {}}
                  >
                    <div className="text-4xl text-blue-500 mb-2">{app.icon}</div>
                    <div className="text-sm font-semibold">{app.name}</div>
                    {app.disabled && (
                      <div className="absolute inset-0 bg-gray-200 bg-opacity-90 flex items-center justify-center rounded-lg">
                        <span className="text-red-600 font-bold text-xs">Only members can access</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="space-y-2">
              <div className="overflow-y-auto max-h-[255px]">
                {apps.map((app, index) => (
                  <div
                    key={app.id}
                    className={`relative flex items-center p-6 mb-2 rounded-lg cursor-pointer 
                      sm:flex-col 
                      xs:flex-col  ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`
                    }
                    onClick={() => !app.disabled && openModal(app.name, app.width)}
                    style={app.disabled ? { cursor: 'not-allowed' } : {}}
                  >
                    {/* <div className="text-lg font-semibold mr-8">App {index + 1}</div> */}
                    <div className="text-4xl text-blue-500 mr-2">{app.icon}</div>
                    <div className="text-sm font-semibold flex-1">{app.name}</div>
                    <div className="text-xs text-gray-600 flex-1">{app.description}</div>
                    {app.disabled && (
                      <div className="absolute inset-0 bg-gray-200 bg-opacity-90 flex items-center justify-center rounded-lg">
                        <span className="text-red-600 font-bold text-xs">Only members can access</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Open Mini App */}
      {isModalOpen && selectedApp && (
        <motion.div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div 
            className={`bg-white rounded-lg p-6 h-auto w-auto ${
              selectedApp === 'My Video' ? 'w-full max-w-none' : 'max-w-[800px]'
            } max-h-screen mx-2 my-2 
              lg:h-[1000px] 
              md:max-w-2xl 
              sm:max-w-[420px] 
              xs:max-w-[360px]`
            }
            initial={{ scale: 0.4 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{selectedApp}</h2>
              <button onClick={closeModal} className="text-red-500" title="Close">
                <IoMdCloseCircle />
              </button>
            </div>
            {selectedApp === 'My Video' && <MyVideo />}
            {selectedApp === 'My Ticket' && <MyTicket />}
            {selectedApp === 'My Program' && <MyFundedProgram />}
            {/* {selectedApp === 'My Program' && <MyProgram />} */}
            {selectedApp === 'My Subscription' && <MySubscription />}
            {selectedApp === 'My Journal' && <MyJournal />}
            {selectedApp === 'About Us' && <AboutUs />}
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default UserApp;

// // update code --> 03 Oct 2024
// // src/components/client/Dashboard/UserApp.tsx

// import React, { useState } from 'react';
// import { FaTh, FaList, FaMinus } from 'react-icons/fa';
// import { IoMdCloseCircle } from "react-icons/io";
// import { AiOutlineInfoCircle } from 'react-icons/ai';
// import { useTheme } from '../../../context/ThemeContext';
// import useUserStage from '../../../hooks/Dashboard/useUserStage';
// import { motion } from 'framer-motion';

// import MyVideo from '../MiniApp/App06_MyVideo';
// import MyTicket from '../MiniApp/App01_MyTicket';
// import MyProgram from '../MiniApp/App02_MyProgram';
// import MySubscription from '../MiniApp/App03_MySubscription';
// import MyJournal from '../MiniApp/App04_MyJournal';
// import AboutUs from '../MiniApp/App05_AboutUS';

// const UserApp: React.FC<{ selectedApp?: string }> = ({ selectedApp: initialSelectedApp = null }) => {
//   const { theme } = useTheme();
//   const { isGuest } = useUserStage();
//   const [isGrid, setIsGrid] = useState(false); // Default to list view
//   const [isMinimized, setIsMinimized] = useState(false);
//   const [selectedApp, setSelectedApp] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleLayout = () => setIsGrid(!isGrid);
//   const toggleMinimize = () => setIsMinimized(!isMinimized);

//   const openModal = (appName: string, width: string) => {
//     if (!isGuest || appName === 'My Ticket' || appName === 'My Subscription' || appName === 'About Us') {
//       setSelectedApp(appName);
//       setIsModalOpen(true);
//     }
//   };

//   const closeModal = () => {
//     setSelectedApp(null);
//     setIsModalOpen(false);
//   };

//   const apps = [
//     { id: 1, name: 'My Video', icon: '📽️', description: 'Access R One courses video for playback and review', width: 'max-w-lg' },
//     { id: 2, name: 'My Ticket', icon: '🎟️', description: 'View and manage your lists event tickets', width: 'max-w-lg' },
//     { id: 3, name: 'My Program', icon: '📚', description: 'View and manage your participation programs.', width: 'max-w-md', disabled: isGuest },
//     { id: 4, name: 'My Subscription', icon: '📝', description: 'View and manage your subscriptions', width: 'max-w-lg' },
//     { id: 5, name: 'My Journal', icon: '📓', description: 'View your trading journal', width: 'max-w-md', disabled: isGuest },
//     { id: 6, name: 'About Us', icon: '👤', description: 'About our R One Century', width: 'max-w-md' },
//   ];

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div className="rounded-lg shadow-md p-4 h-full bg-white" style={styles.title}> 
//       <div className="flex justify-between items-center mb-4 rounded"
//         style={styles.title}>
//         <div className="flex items-center">
//           <div className="flex items-center mr-4">
//             <button onClick={toggleLayout}>
//               {isGrid ? <FaList className="text-lg" /> : <FaTh className="text-lg" />}
//             </button>
//             <button onClick={toggleMinimize}>
//               {isMinimized ? <FaMinus className="text-lg" /> : null}
//             </button>
//           </div>
//           <h1 className="font-bold text-xl sm:text-lg xs:text-lg" style={styles.title}>
//             R One Mini App
//           </h1>
//           <div className="relative group ml-2">
//             <AiOutlineInfoCircle 
//               className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
//               aria-hidden="true"
//             />
//             {/* Tooltip */}
//             <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white sm:right-0 sm:w-52 xs:right-0 xs:w-52"
//               style={styles.button}>
//                R One Mini App provides seamless access to all R One products for an enhanced user experience.
//             </div>
//           </div>
//         </div>
//       </div>

//       {!isMinimized && (
//         <div className="overflow-y-auto h-[255px] sm:h-auto xs:h-auto">
//           {isGrid ? (
//             <div className="w-full">
//               <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//                 {apps.map((app) => (
//                   <div
//                     key={app.id}
//                     className={`relative text-center p-6 rounded-lg cursor-pointer ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
//                     onClick={() => !app.disabled && openModal(app.name, app.width)}
//                     style={app.disabled ? { cursor: 'not-allowed' } : {}}
//                   >
//                     <div className="text-4xl text-blue-500 mb-2">{app.icon}</div>
//                     <div className="text-sm font-semibold">{app.name}</div>
//                     {app.disabled && (
//                       <div className="absolute inset-0 bg-gray-200 bg-opacity-90 flex items-center justify-center rounded-lg">
//                         <span className="text-red-600 font-bold text-xs">Only members can access</span>
//                       </div>
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ) : (
//             <div className="space-y-2">
//               <div className="overflow-y-auto max-h-[255px]">
//                 {apps.map((app, index) => (
//                   <div
//                     key={app.id}
//                     className={`relative flex items-center p-4 mb-2 rounded-lg cursor-pointer sm:flex-col xs:flex-col  ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
//                     onClick={() => !app.disabled && openModal(app.name, app.width)}
//                     style={app.disabled ? { cursor: 'not-allowed' } : {}}
//                   >
//                     {/* <div className="text-lg font-semibold mr-8">App {index + 1}</div> */}
//                     <div className="text-4xl text-blue-500 mr-2">{app.icon}</div>
//                     <div className="text-sm font-semibold flex-1">{app.name}</div>
//                     <div className="text-xs text-gray-600 flex-1">{app.description}</div>
//                     {app.disabled && (
//                       <div className="absolute inset-0 bg-gray-200 bg-opacity-90 flex items-center justify-center rounded-lg">
//                         <span className="text-red-600 font-bold text-xs">Only members can access</span>
//                       </div>
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//       )}

//       {/* Open Mini App */}
//       {isModalOpen && selectedApp && (
//         <motion.div 
//           className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//         >
//           <motion.div 
//             className="bg-white rounded-lg p-6 h-auto w-auto max-w-[800px] max-h-screen mx-2 my-2 lg:h-[1000px] md:max-w-2xl sm:max-w-[420px] xs:max-w-[360px]"
//             initial={{ scale: 0.4 }}
//             animate={{ scale: 1 }}
//             exit={{ scale: 0 }}
//           >
//           {/* <motion.div 
//             className="bg-white rounded-lg p-6 w-auto max-h-[80vh] mx-2 my-2 lg:max-w-4xl md:max-w-2xl sm:max-w-lg xs:max-w-sm"
//             initial={{ scale: 0.8 }}
//             animate={{ scale: 1 }}
//             exit={{ scale: 0.8 }}
//           > */}
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-bold">{selectedApp}</h2>
//               <button onClick={closeModal} className="text-red-500" title="Close">
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             {selectedApp === 'My Video' && <MyVideo />}
//             {selectedApp === 'My Ticket' && <MyTicket />}
//             {selectedApp === 'My Program' && <MyProgram />}
//             {selectedApp === 'My Subscription' && <MySubscription />}
//             {selectedApp === 'My Journal' && <MyJournal />}
//             {selectedApp === 'About Us' && <AboutUs />}
//           </motion.div>
//         </motion.div>
//       )}
//     </div>
//   );
// };

// export default UserApp;


// // udpate code --> 04 Aug 2024
// // src/components/client/Dashboard/UserApp.tsx

// import React, { useState } from 'react';
// import { FaTh, FaList, FaMinus } from 'react-icons/fa';
// import { IoMdCloseCircle } from "react-icons/io";
// import { AiOutlineInfoCircle } from 'react-icons/ai';
// import { useTheme } from '../../../context/ThemeContext';
// import useUserStage from '../../../hooks/Dashboard/useUserStage';
// import { motion } from 'framer-motion';

// import MyTicket from '../MiniApp/App01_MyTicket';
// import MyProgram from '../MiniApp/App02_MyProgram';
// import MySubscription from '../MiniApp/App03_MySubscription';
// import MyJournal from '../MiniApp/App04_MyJournal';
// import AboutUs from '../MiniApp/App05_AboutUS';

// const UserApp: React.FC = () => {
//   const { theme } = useTheme();
//   const { isGuest } = useUserStage();
//   const [isGrid, setIsGrid] = useState(false); // Default to list view
//   const [isMinimized, setIsMinimized] = useState(false);
//   const [selectedApp, setSelectedApp] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleLayout = () => setIsGrid(!isGrid);
//   const toggleMinimize = () => setIsMinimized(!isMinimized);

//   const openModal = (appName: string, width: string) => {
//     if (!isGuest || appName === 'My Ticket' || appName === 'My Subscription' || appName === 'About Us') {
//       setSelectedApp(appName);
//       setIsModalOpen(true);
//     }
//   };

//   const closeModal = () => {
//     setSelectedApp(null);
//     setIsModalOpen(false);
//   };

//   const apps = [
//     { id: 1, name: 'My Ticket', icon: '🎟️', description: 'View and manage your lists event tickets', width: 'max-w-lg' },
//     { id: 2, name: 'My Program', icon: '📚', description: 'View and manage your participation programs.', width: 'max-w-md', disabled: isGuest },
//     { id: 3, name: 'My Subscription', icon: '📝', description: 'View and manage your subscriptions', width: 'max-w-lg' },
//     { id: 4, name: 'My Journal', icon: '📓', description: 'View your trading journal', width: 'max-w-md', disabled: isGuest },
//     { id: 5, name: 'About Us', icon: '👤', description: 'About our R One Century', width: 'max-w-md' },
//   ];

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div className="rounded-lg shadow-md p-4 h-full bg-white" style={styles.title}> 
//       <div className="flex justify-between items-center mb-4 rounded"
//         style={styles.title}>
//         <div className="flex items-center">
//           <div className="flex items-center mr-4">
//             <button onClick={toggleLayout}>
//               {isGrid ? <FaList className="text-lg" /> : <FaTh className="text-lg" />}
//             </button>
//             <button onClick={toggleMinimize}>
//               {isMinimized ? <FaMinus className="text-lg" /> : null}
//             </button>
//           </div>
//           <h1 className="font-bold text-xl sm:text-lg xs:text-lg" style={styles.title}>
//             R One Mini App
//           </h1>
//           <div className="relative group ml-2">
//             <AiOutlineInfoCircle 
//               className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
//               aria-hidden="true"
//             />
//             {/* Tooltip */}
//             <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white sm:right-0 sm:w-52 xs:right-0 xs:w-52"
//               style={styles.button}>
//                R One Mini App provides seamless access to all R One products for an enhanced user experience.
//             </div>
//           </div>
//         </div>
//       </div>

//       {!isMinimized && (
//         <div className="overflow-y-auto h-[255px] sm:h-auto xs:h-auto">
//           {isGrid ? (
//             <div className="w-full">
//               <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//                 {apps.map((app) => (
//                   <div
//                     key={app.id}
//                     className={`relative text-center p-6 rounded-lg cursor-pointer ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
//                     onClick={() => !app.disabled && openModal(app.name, app.width)}
//                     style={app.disabled ? { cursor: 'not-allowed' } : {}}
//                   >
//                     <div className="text-4xl text-blue-500 mb-2">{app.icon}</div>
//                     <div className="text-sm font-semibold">{app.name}</div>
//                     {app.disabled && (
//                       <div className="absolute inset-0 bg-gray-200 bg-opacity-90 flex items-center justify-center rounded-lg">
//                         <span className="text-red-600 font-bold text-xs">Only members can access</span>
//                       </div>
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ) : (
//             <div className="space-y-2">
//               <div className="overflow-y-auto max-h-[255px]">
//                 {apps.map((app, index) => (
//                   <div
//                     key={app.id}
//                     className={`relative flex items-center p-4 mb-2 rounded-lg cursor-pointer sm:flex-col xs:flex-col  ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
//                     onClick={() => !app.disabled && openModal(app.name, app.width)}
//                     style={app.disabled ? { cursor: 'not-allowed' } : {}}
//                   >
//                     {/* <div className="text-lg font-semibold mr-8">App {index + 1}</div> */}
//                     <div className="text-4xl text-blue-500 mr-2">{app.icon}</div>
//                     <div className="text-sm font-semibold flex-1">{app.name}</div>
//                     <div className="text-xs text-gray-600 flex-1">{app.description}</div>
//                     {app.disabled && (
//                       <div className="absolute inset-0 bg-gray-200 bg-opacity-90 flex items-center justify-center rounded-lg">
//                         <span className="text-red-600 font-bold text-xs">Only members can access</span>
//                       </div>
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//       )}

//       {/* Open Mini App */}
//       {isModalOpen && (
//         <motion.div 
//           className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//         >
//           <motion.div 
//             className="bg-white rounded-lg p-6 w-auto max-h-[80vh] mx-2 my-2 md:max-w-2xl lg:max-w-4xl"
//             initial={{ scale: 0.8 }}
//             animate={{ scale: 1 }}
//             exit={{ scale: 0.8 }}
//           >
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-bold">{selectedApp}</h2>
//               <button onClick={closeModal} className="text-red-500" title="Close">
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             {selectedApp === 'My Ticket' && <MyTicket />}
//             {selectedApp === 'My Program' && <MyProgram />}
//             {selectedApp === 'My Subscription' && <MySubscription />}
//             {selectedApp === 'My Journal' && <MyJournal />}
//             {selectedApp === 'About Us' && <AboutUs />}
//           </motion.div>
//         </motion.div>
//       )}
//     </div>
//   );
// };

// export default UserApp;



// // update code --> 03 july 2024
// // src/components/client/Dashboard/UserApp.tsx

// import React, { useState } from 'react';
// import { FaTh, FaList, FaMinus } from 'react-icons/fa';
// import { IoMdCloseCircle } from "react-icons/io";
// import { useTheme } from '../../../context/ThemeContext';
// import useUserStage from '../../../hooks/Dashboard/useUserStage';

// import MyTicket from '../MiniApp/App01_MyTicket';
// import MyProgram from '../MiniApp/App02_MyProgram';
// import MySubscription from '../MiniApp/App03_MySubscription';
// import MyJournal from '../MiniApp/App04_MyJournal';
// import AboutUs from '../MiniApp/App05_AboutUS';

// const UserApp: React.FC = () => {
//   const { theme } = useTheme();
//   const { isGuest } = useUserStage();
//   const [isGrid, setIsGrid] = useState(false); // Default to list view
//   const [isMinimized, setIsMinimized] = useState(false);
//   const [selectedApp, setSelectedApp] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // const [modalWidth, setModalWidth] = useState<string>('max-w-md'); // default width

//   const toggleLayout = () => setIsGrid(!isGrid);
//   const toggleMinimize = () => setIsMinimized(!isMinimized);

//   const openModal = (appName: string, width: string) => {
//     if (!isGuest || appName === 'My Ticket' || appName === 'My Subscription' || appName === 'About Us') {
//       setSelectedApp(appName);
//       setIsModalOpen(true);
//     }
//   };  

//   const closeModal = () => {
//     setSelectedApp(null);
//     setIsModalOpen(false);
//   };

//   const apps = [
//     { id: 1, name: 'My Ticket', icon: '🎟️', description: 'View and manage your tickets', width: 'max-w-lg' },
//     { id: 2, name: 'My Program', icon: '📚', description: 'View and manage your programs', width: 'max-w-md' },
//     { id: 3, name: 'My Subscription', icon: '📝', description: 'Manage your subscriptions', width: 'max-w-lg' },
//     { id: 4, name: 'My Journal', icon: '📓', description: 'View and manage your journal', width: 'max-w-md' },
//     { id: 5, name: 'About Us', icon: '👤', description: 'View and edit your profile', width: 'max-w-md' },
//   ];

//   return (
//     <div
//       className="bg-blue-500 rounded-lg shadow-md p-4 h-full"
//       style={{ 
//         backgroundColor: theme.backgroundColor, 
//         color: theme.textColor, 
//         fontFamily: theme.fontFamily 
//       }}>
        
//       <div className="flex justify-between items-center mb-4 text-white p-2 rounded"
//         style={{ 
//           color:theme.textColor, fontFamily:theme.fontFamily
//         }}>
//         <div className="flex space-x-4">
//           <button onClick={toggleLayout}>
//             {isGrid ? <FaList className="text-lg" /> : <FaTh className="text-lg" />}
//           </button>
//           <button onClick={toggleMinimize}>
//             {isMinimized ? <FaMinus className="text-lg" /> : null}
//           </button>
//         </div>
//       </div>

//       {!isMinimized && (
//         <div className="overflow-y-auto max-h-[220px]">
//           {isGrid ? (
//             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
//               {apps.map((app) => (
//                 <div
//                   key={app.id}
//                   className={`text-center p-6 rounded-lg cursor-pointer relative ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
//                   onClick={() => openModal(app.name, app.width)}
//                   title={`${app.description}`}
//                 >
//                   <div className="text-4xl text-blue-500 mb-2">{app.icon}</div>
//                   <div className="text-sm font-semibold">{app.name}</div>
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <div className="space-y-2">
//               <div className="overflow-y-auto max-h-[220px]">
//                 {apps.map((app, index) => (
//                   <div
//                     key={app.id}
//                     className={`flex items-center p-4 mb-2 rounded-lg cursor-pointer ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
//                     onClick={() => openModal(app.name, app.width)}
//                   >
//                     <div className="text-lg font-semibold mr-2">{index + 1}</div>
//                     <div className="text-4xl text-blue-500 mr-2">{app.icon}</div>
//                     <div className="text-sm font-semibold flex-1">{app.name}</div>
//                     <div className="text-xs text-gray-600 flex-1">{app.description}</div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//       )}

//       {isModalOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white rounded-lg p-6 w-full max-w-lg max-h-[80vh] mx-2 my-2 md:max-w-2xl lg:max-w-4xl"> 
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-bold">{selectedApp}</h2>
//               <button onClick={closeModal} className="text-red-500" title="Close">
//               <IoMdCloseCircle />
//               </button>
//             </div>
//             {selectedApp === 'My Ticket' && <MyTicket />}
//             {selectedApp === 'My Program' && <MyProgram />}
//             {selectedApp === 'My Subscription' && <MySubscription />}
//             {selectedApp === 'My Journal' && <MyJournal />}
//             {selectedApp === 'About Us' && <AboutUs />}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserApp;



// // update code --> 17 June 2024
// // src/components/client/Dashboard/UserApp.tsx

// import React, { useState } from 'react';
// import { FaTh, FaList, FaMinus } from 'react-icons/fa';

// import { useTheme } from '../../../context/ThemeContext';

// import App01Online1ToMany from '../MiniApp/App01_Online1toMany';
// import App02Offline1ToMany from '../MiniApp/App02_Offline1toMany';
// import App03Program180 from '../MiniApp/App03_180Program';

// const UserApp: React.FC = () => {
//   const { theme } = useTheme();
//   const [isGrid, setIsGrid] = useState(true);
//   const [isMinimized, setIsMinimized] = useState(false);
//   const [selectedApp, setSelectedApp] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleLayout = () => setIsGrid(!isGrid);
//   const toggleMinimize = () => setIsMinimized(!isMinimized);

//   const openModal = (appName: string) => {
//     setSelectedApp(appName);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setSelectedApp(null);
//     setIsModalOpen(false);
//   };

//   const apps = [
//     { id: 1, name: 'Appointment 1 to Many Online', icon: '📦', description: 'Registration online 1 to many classes' },
//     { id: 2, name: 'Appointment 1 to Many Offline', icon: '🎁', description: 'Registration online 1 to many classes on R One Office'},
//     { id: 3, name: 'R One 180 Program', icon: '🎁', description: 'Participace to R One 180 Program to become R One Trader' },
//     { id: 4, name: 'R One Go4Funds Program', icon: '📊', description: 'Participace to R One Go4Funds to become R One Trader' },
//     { id: 5, name: 'R One Trader Hub Renew', icon: '📊', description: 'Renewal on R One Trader Hub Telegram Group' },
//   ];

//   return (
//     <div
//       className="bg-blue-500 rounded-lg shadow-md p-4 h-full"
//       style={{ 
//         backgroundColor: theme.backgroundColor, 
//         color: theme.textColor, 
//         fontFamily: theme.fontFamily 
//       }}>
        
//       <div className="flex justify-between items-center mb-4 text-white p-2 rounded"
//         style={{ 
//           color:theme.textColor, fontFamily:theme.fontFamily
//         }}>
//         <div className="flex space-x-4">
//           <button onClick={toggleLayout}>
//             {isGrid ? <FaList className="text-lg" /> : <FaTh className="text-lg" />}
//           </button>
//           <button onClick={toggleMinimize}>
//             {isMinimized ? <FaMinus className="text-lg" /> : null}
//           </button>
//         </div>
//       </div>

//       {!isMinimized && (
//         <div className="overflow-y-auto max-h-[220px]">
//           {isGrid ? (
//             <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
//               {apps.map((app) => (
//                 <div
//                   key={app.id}
//                   className={`text-center p-6 rounded-lg cursor-pointer relative ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
//                   onClick={() => openModal(app.name)}
//                   title={`${app.description}`}
//                 >
//                   <div className="text-4xl text-blue-500 mb-2">{app.icon}</div>
//                   <div className="text-sm font-semibold">{app.name}</div>
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <div className="space-y-2">
//               <div className="overflow-y-auto max-h-[220px]">
//                 {apps.map((app, index) => (
//                   <div
//                     key={app.id}
//                     className={`flex items-center p-4 mb-2 rounded-lg cursor-pointer ${selectedApp === app.name ? 'bg-blue-200' : 'bg-gray-100'}`}
//                     onClick={() => openModal(app.name)}
//                   >
//                     <div className="text-lg font-semibold mr-2">{index + 1}</div>
//                     <div className="text-4xl text-blue-500 mr-2">{app.icon}</div>
//                     <div className="text-sm font-semibold flex-1">{app.name}</div>
//                     <div className="text-xs text-gray-600 flex-1">{app.description}</div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//       )}

//       {isModalOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//           <div className="bg-white rounded-lg p-6 w-full max-w-md">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-bold">{selectedApp}</h2>
//               <button onClick={closeModal} className="text-red-500">&times;</button>
//             </div>
//             {selectedApp === 'Appointment 1 to Many Online' && <App01Online1ToMany />}
//             {selectedApp === 'Appointment 1 to Many Offline' && <App02Offline1ToMany />}
//             {selectedApp === 'R One 180 Program' && <App03Program180 />} {/* Corrected name */}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserApp;
